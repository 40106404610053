import React from "react";

function MaintenanceMode(): any {
  return (
    <div className="flex justify-center py-32 px-6">
      <h2 className="text-4xl">
        The site is being updated. We will be back up shortly.
      </h2>
    </div>
  );
}

export default MaintenanceMode;
