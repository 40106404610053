"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDepartmentDataById = exports.findDepartmentsById = exports.findAssociatedDepartment = void 0;
const utils_1 = require("../../../utils");
/**
 * Function to find associated departments and their departmentId
 * @param deptId
 * @param departments
 * @returns departmentId
 */
const findAssociatedDepartment = (deptId, departments) => {
    // So that we can get this out we have to rely on the associationObject on the settings, not the department.
    // let associatedId = null;
    // for (let i = 0; i < departments.length; i++) {
    //   if (deptId === departments[i].id.split('#').pop()) {
    //     associatedId =  departments[i].associatedDeptId;
    //     break;
    //   }
    // }
    //
    // return associatedId;
    if (departments && !(0, utils_1.empty)(departments) && deptId in departments)
        return departments[deptId];
    return null;
};
exports.findAssociatedDepartment = findAssociatedDepartment;
function findDepartmentsById(departmentData, departmentId) {
    if (!departmentData)
        return null;
    const filteredData = departmentData === null || departmentData === void 0 ? void 0 : departmentData.filter((dept) => {
        if (
        // @ts-ignore
        "departmentId" in dept &&
            dept.departmentId &&
            dept.departmentId === departmentId)
            return true;
        else if (
        // @ts-ignore
        "id" in dept &&
            dept.id &&
            // @ts-ignore
            dept.id.split("#").pop() === departmentId)
            return true;
        else
            return false;
    });
    return filteredData.length === 1 ? filteredData[0] : null;
}
exports.findDepartmentsById = findDepartmentsById;
const getDepartmentDataById = (deptId, departments) => {
    for (let i = 0; i < departments.length; i++) {
        if (deptId === departments[i].id.split("#").pop()) {
            return departments[i];
        }
    }
};
exports.getDepartmentDataById = getDepartmentDataById;
