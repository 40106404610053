import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function ApplicationsIcon({
  size = "small",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m397.52 512c-8.4102-1.7734-16.117-5.9766-22.168-12.082-6.375-7.1992-9.7812-16.551-9.5234-26.164v-69.887c-0.41406-7.0781 1.3164-14.117 4.9727-20.195 3.6523-6.0742 9.0586-10.906 15.508-13.855 5.7344-2.5078 11.918-3.832 18.176-3.8906 22.578-0.46094 45.211 0 67.84 0 20.223 0 35.02 11.316 38.965 30.719h-0.003906c0.14062 0.38281 0.33203 0.74609 0.56641 1.0781v82.379c-0.25781 0.92188-0.5625 1.793-0.82031 2.7148-1.6484 8.8086-6.7305 16.602-14.133 21.656-5.4219 2.8867-11.031 5.3984-16.793 7.5273z" />
      <path d="m114.48 0c5.5977 1.9141 11.027 4.293 16.23 7.1172 10.195 6.7969 16.039 18.48 15.359 30.719 0.20312 23.551 0.20312 47.121 0 70.707 0.33594 7.1445-1.5 14.219-5.2656 20.297s-9.2852 10.875-15.828 13.754c-5.3672 2.3281-11.152 3.5312-17 3.5312-23.297 0.35938-46.594 0.25781-69.887 0-8.7539 0.33984-17.336-2.4688-24.195-7.918-6.8594-5.4492-11.535-13.176-13.18-21.777-0.20312-0.66406-0.44141-1.3125-0.71875-1.9453v-82.586c0-0.76953 0.5625-1.5352 0.76953-2.3047 1.5508-8.9688 6.668-16.93 14.18-22.066 5.4688-2.9023 11.133-5.4141 16.949-7.5273z" />
      <path d="m512 114.48c-1.9375 5.6133-4.3359 11.059-7.168 16.281-6.793 10.207-18.48 16.051-30.719 15.359-23.551 0.20312-47.121 0.20312-70.707 0-6.9336 0.36328-13.812-1.3555-19.762-4.9297-5.9492-3.5781-10.691-8.8477-13.621-15.141-2.6211-5.6523-4.0352-11.793-4.1484-18.023-0.46094-23.297-0.30859-46.594 0-69.887-0.37109-8.8477 2.4805-17.527 8.0273-24.43s13.41-11.559 22.129-13.102c0.51172-0.16016 1.0078-0.36719 1.4844-0.61328h82.586c0.61719 0.26172 1.25 0.48438 1.8945 0.66406 9 1.4062 17.031 6.4414 22.223 13.93 2.9922 5.5781 5.5938 11.359 7.7812 17.305z" />
      <path d="m0 397.52c1.8164-5.5156 4.1133-10.859 6.8594-15.973 6.8203-10.375 18.629-16.359 31.027-15.719h70.707c6.9336-0.36328 13.812 1.3555 19.762 4.9297 5.9492 3.5781 10.691 8.8477 13.621 15.141 2.6211 5.6523 4.0352 11.793 4.1484 18.023 0.46094 23.297 0.30859 46.594 0 69.887 0.39062 8.8789-2.4609 17.598-8.0312 24.523s-13.473 11.586-22.227 13.109c-0.51563 0.16016-1.0117 0.36328-1.4883 0.61328h-82.48c-0.61719-0.26172-1.25-0.48438-1.8945-0.66406-9.1406-1.4688-17.27-6.6367-22.477-14.285-2.9219-5.4766-5.4375-11.156-7.5273-17z" />
      <path d="m0 214.27c2.1406-5.8086 4.7578-11.426 7.832-16.797 5.9922-9.0781 16.207-14.469 27.086-14.281h75.211 0.003906c9.6953 0.10547 18.941 4.0898 25.676 11.066 6.7383 6.9766 10.395 16.359 10.164 26.051 0 23.859 0.30859 47.719 0 71.68 0.31641 9.7109-3.3164 19.129-10.074 26.109-6.7539 6.9766-16.055 10.914-25.766 10.91-24.68 0.5625-49.355 0.51172-73.984 0-18.227-0.35938-30.719-11.059-35.379-28.875 0-0.76953-0.51172-1.4844-0.82031-2.2539 0.035156-27.922 0.050781-55.793 0.050781-83.609z" />
      <path d="m512 297.73c-2.0469 5.707-4.582 11.223-7.5781 16.488-6.0234 9.2031-16.34 14.691-27.34 14.539-25.191 0-50.434 0.61328-75.621 0-9.7344 0.09375-19.074-3.8086-25.848-10.797-6.7734-6.9844-10.387-16.445-9.9922-26.168-0.30859-23.859 0-47.719 0-71.68-0.23438-9.6953 3.4258-19.078 10.16-26.051 6.7344-6.9766 15.984-10.965 25.68-11.07 24.68 0 49.355-0.41016 73.984 0h-0.003906c8.4023-0.046875 16.555 2.8633 23.031 8.2148 6.4766 5.3555 10.863 12.812 12.402 21.07 0.19141 0.63281 0.43359 1.25 0.71484 1.8438v83.609z" />
      <path d="m214.27 512c-6.0234-2.2969-11.848-5.0859-17.41-8.3438-8.4961-5.7461-13.566-15.348-13.516-25.602-0.46094-17.254-0.30859-35.176-0.35938-53.094v-23.297c0.12109-9.6914 4.1133-18.93 11.086-25.664 6.9688-6.7305 16.344-10.395 26.035-10.176h71.68c9.6875-0.23438 19.066 3.4258 26.031 10.164 6.9688 6.7344 10.945 15.984 11.039 25.676 0 24.934 0.46094 49.867 0 74.805-0.41016 18.176-11.469 30.719-29.234 35.02h-0.003906c-0.62891 0.19531-1.2461 0.43359-1.8438 0.71484h-83.504z" />
      <path d="m296.96 0c6.6797 1.3633 12.984 4.1562 18.48 8.1914 7.9766 5.9844 12.844 15.23 13.262 25.191 0.46094 20.48 0.30859 40.602 0.35938 60.875v15.359 0.003906c0.003906 9.875-3.9961 19.328-11.09 26.199-7.0938 6.8711-16.672 10.574-26.543 10.254h-70.707c-21.453 0-36.812-14.539-37.324-35.84-0.66406-24.934-0.5625-49.867 0-74.805 0.25781-17.867 11.16-30.309 29.082-34.711 0.61328-0.19922 1.2109-0.4375 1.793-0.71875z" />
      <path d="m328.75 256c0 13.055 0.46094 26.164 0 39.219-0.27734 8.7617-3.8789 17.09-10.066 23.297s-14.504 9.8281-23.262 10.137c-26.281 0.61328-52.566 0.61328-78.848 0h-0.003906c-8.6992-0.29297-16.961-3.8867-23.113-10.047-6.1523-6.1602-9.7305-14.43-10.012-23.129-0.58203-26.281-0.58203-52.566 0-78.848 0.26953-8.6992 3.8359-16.969 9.9805-23.129 6.1406-6.1641 14.398-9.7539 23.094-10.051 26.418-0.61328 52.84-0.61328 79.258 0 8.6055 0.39844 16.746 3.9961 22.836 10.086 6.0898 6.0898 9.6875 14.234 10.086 22.836 0.5625 13.211 0 26.418 0 39.629z" />
    </svg>
  );
}
