import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function WhatIfsIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m1040 124.6c0-68.801-55.801-124.6-124.6-124.6h-630.8c-68.801 0-124.6 55.801-124.6 124.6v950.8c0 68.797 55.801 124.6 124.6 124.6h630.8c68.797 0 124.6-55.801 124.6-124.6zm-669.92 922.28c-45.801 0-82.961-37.199-82.961-82.961 0-45.84 37.199-83 82.961-83 45.84 0 82.961 37.16 82.961 83 0 45.762-37.16 82.961-82.961 82.961zm0-232.92c-45.801 0-82.961-37.16-82.961-82.922 0-45.84 37.199-83 82.961-83 45.84 0 82.961 37.16 82.961 83 0 45.762-37.16 82.922-82.961 82.922zm0-232.96c-45.801 0-82.961-37.121-82.961-82.922s37.199-83 82.961-83c45.84 0 82.961 37.199 82.961 83s-37.16 82.922-82.961 82.922zm232.88 465.88c-45.84 0-82.922-37.199-82.922-82.961 0-45.84 37.121-83 82.922-83 45.801 0 83 37.16 83 83 0 45.762-37.16 82.961-83 82.961zm0-232.92c-45.84 0-82.922-37.16-82.922-82.922 0-45.84 37.121-83 82.922-83 45.801 0 83 37.16 83 83 0 45.762-37.16 82.922-83 82.922zm0-232.96c-45.84 0-82.922-37.121-82.922-82.922s37.121-83 82.922-83c45.801 0 83 37.199 83 83s-37.16 82.922-83 82.922zm232.92 465.88c-45.84 0-82.922-37.199-82.922-82.961 0-45.84 37.078-83 82.922-83 45.84 0 83 37.16 83 83 0.042969 45.762-37.16 82.961-83 82.961zm0-232.92c-45.84 0-82.922-37.16-82.922-82.922 0-45.84 37.078-83 82.922-83 45.84 0 83 37.16 83 83 0.042969 45.762-37.16 82.922-83 82.922zm0-232.96c-45.84 0-82.922-37.121-82.922-82.922s37.078-83 82.922-83c45.84 0 83 37.199 83 83s-37.16 82.922-83 82.922zm84.121-317.8c0 31.359-25.441 56.801-56.762 56.801h-526.48c-31.32 0-56.762-25.441-56.762-56.801v-86.398c0-31.359 25.441-56.801 56.762-56.801h526.48c31.316 0 56.758 25.441 56.758 56.801z" />
    </svg>
  );
}
