import { Popover } from "@headlessui/react";
import React from "react";
import NexstarLogo from "../../utils/components/icons/nexstar/NexstarLogo";

const SimpleAppHeader = () => {
  return (
    <Popover className="relative bg-NexstarBlue-dark">
      <div className="px-6">
        <div className="flex items-center justify-between py-1.5 md:space-x-2">
          <div className="flex items-center gap-6 w-full justify-between lg:justify-start">
            <div className="flex justify-start">
              <a href="/" id="logo" data-testid="logo">
                <NexstarLogo />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default SimpleAppHeader;
