"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingMonthlyTotalsForMaxCapacity = exports.settingTotalsForMaxCapacity = exports.maxCapacityToSaveKey = exports.totalSumOfTotalTurnoversPerMonthCalc = exports.totalSumOfDemandTurnoversCalc = exports.totalSumOfSystemCheckTurnoverCalc = exports.totalTurnoversPerMonthCalc = exports.numOfDemandTurnoversCalc = exports.numOfSystemCheckTurnoversCalc = exports.numOfDemandOpportunitiesCalc = exports.numOfSystemCheckOpportunitiesCalc = void 0;
const callCountCalcsFromFE_1 = require("../callCount/callCountCalcsFromFE");
const utils_1 = require("../../../../utils");
const maxCapacityInit_1 = require("../../objects/maxCapacityInit");
/**
 * MC-(D66)
 * @param workshopData
 * @param month
 */
const numOfSystemCheckOpportunitiesCalc = (workshopData, month) => {
    let agreementValue;
    const demandCallPercent = workshopData.maxCapacity[month].budgetYearPercOfSystemCheckOpportunity /
        100;
    // TP-900 JAH: looking at the correct place.
    if (!workshopData.serviceAgreements.visitsAtTimeOfSale) {
        agreementValue =
            workshopData.serviceAgreements[month].totalVisitIf6MonthFromSale;
    }
    else {
        agreementValue =
            workshopData.serviceAgreements[month]
                .totalVisitsBasedOnTimeOfSaleCalculation;
    }
    return agreementValue * demandCallPercent;
};
exports.numOfSystemCheckOpportunitiesCalc = numOfSystemCheckOpportunitiesCalc;
/**
 * MC-(D67)
 * @param workshopData
 * @param workingDays
 * @param month
 * @param demandType
 */
const numOfDemandOpportunitiesCalc = (workshopData, workingDays, month, demandType) => {
    var _a;
    // TP-1859: this should be good now
    const demandCallPercent = demandType === "demandCalls"
        ? workshopData.maxCapacity[month].budgetYearPercOfDemandCallsOpportunity /
            100
        : ((_a = (0, utils_1.objectFieldValidator)(workshopData, [
            "maxCapacity",
            month,
            "budgetYearPercOfDemandSystemCheckCallsOpportunity",
        ], true)) !== null && _a !== void 0 ? _a : 0) / 100;
    const callCountData = (0, callCountCalcsFromFE_1.requiredDemandCallCountCalc)(workshopData, workingDays, month, demandType);
    return demandCallPercent * callCountData;
};
exports.numOfDemandOpportunitiesCalc = numOfDemandOpportunitiesCalc;
/**
 * MC-(D69)
 * @param workshopData
 * @param month
 */
const numOfSystemCheckTurnoversCalc = (workshopData, month) => {
    if (!(workshopData === null || workshopData === void 0 ? void 0 : workshopData.serviceAgreements))
        return 0;
    if (!workshopData.serviceAgreements ||
        !(month in workshopData.serviceAgreements) ||
        !workshopData.serviceAgreements[month])
        return 0;
    if (!workshopData.maxCapacity ||
        !(month in workshopData.maxCapacity) ||
        !workshopData.maxCapacity[month])
        return 0;
    let agreementValue;
    const percOfSystemTurnover = workshopData.maxCapacity[month].budgetYearPercOfSystemCheckTurnover / 100;
    // TP-900 JAH: looking at the correct place.
    if (!workshopData.serviceAgreements.visitsAtTimeOfSale) {
        agreementValue =
            workshopData.serviceAgreements[month].totalVisitIf6MonthFromSale;
    }
    else {
        agreementValue =
            workshopData.serviceAgreements[month]
                .totalVisitsBasedOnTimeOfSaleCalculation;
    }
    if (!workshopData.maxCapacity[month].budgetYearPercOfSystemCheckOpportunity) {
        return agreementValue * percOfSystemTurnover;
    }
    return ((0, exports.numOfSystemCheckOpportunitiesCalc)(workshopData, month) *
        percOfSystemTurnover);
};
exports.numOfSystemCheckTurnoversCalc = numOfSystemCheckTurnoversCalc;
/**
 * MC-(D70)
 * @param workshopData
 * @param companyData
 * @param month
 * @param decreasedDays
 * @param demandType
 */
const numOfDemandTurnoversCalc = (workshopData, companyData, month, decreasedDays, demandType) => {
    var _a;
    if (!(0, utils_1.objectFieldValidator)(workshopData, ["maxCapacity", month]))
        return 0;
    // TODO FIX THIS
    // TP-1859: this should be good now
    const percCallTurnover = demandType === "demandCalls"
        ? workshopData.maxCapacity[month].budgetYearPercOfDemandCallsTurnover /
            100
        : ((_a = (0, utils_1.objectFieldValidator)(workshopData, [
            "maxCapacity",
            month,
            "budgetYearPercOfDemandSystemCheckCallsTurnover",
        ], true)) !== null && _a !== void 0 ? _a : 0) / 100;
    const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
    const demandOpportunitiesCalc = (0, exports.numOfDemandOpportunitiesCalc)(workshopData, workingDays, month, demandType);
    if (demandOpportunitiesCalc === 0) {
        const callCountData = (0, callCountCalcsFromFE_1.requiredDemandCallCountCalc)(workshopData, workingDays, month, demandType);
        return callCountData * percCallTurnover;
    }
    return demandOpportunitiesCalc * percCallTurnover;
};
exports.numOfDemandTurnoversCalc = numOfDemandTurnoversCalc;
/**
 * MC-(D72)
 * @param workshopData
 * @param companyData
 * @param month
 * @param decreasedDays
 */
const totalTurnoversPerMonthCalc = (workshopData, companyData, month, decreasedDays) => {
    const systemCheckTurnover = (0, exports.numOfSystemCheckTurnoversCalc)(workshopData, month);
    const demandTurnover = (0, exports.numOfDemandTurnoversCalc)(workshopData, companyData, month, decreasedDays, "demandCalls");
    const demandSystemCheckTurnover = (0, exports.numOfDemandTurnoversCalc)(workshopData, companyData, month, decreasedDays, "demandSystemCheckCalls");
    return systemCheckTurnover + demandTurnover + demandSystemCheckTurnover;
};
exports.totalTurnoversPerMonthCalc = totalTurnoversPerMonthCalc;
// // ↑ ↑ ↑ BACKEND MONTHLY CALCULATIONS ↑ ↑ ↑
//
//
//
// ↓ ↓ ↓ BACKEND TOTAL CALCULATIONS ↓ ↓ ↓
/**
 * MC-(AB69)
 * @param workshopData
 * @param companyData
 */
const totalSumOfSystemCheckTurnoverCalc = (workshopData, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += (0, exports.numOfSystemCheckTurnoversCalc)(workshopData, month);
    });
    return totalSum;
};
exports.totalSumOfSystemCheckTurnoverCalc = totalSumOfSystemCheckTurnoverCalc;
/**
 * MC-(AB70)
 * @param workshopData
 * @param companyData
 * @param decreasedDays
 * @param demandType
 */
const totalSumOfDemandTurnoversCalc = (workshopData, companyData, decreasedDays, demandType) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += (0, exports.numOfDemandTurnoversCalc)(workshopData, companyData, month, decreasedDays, demandType);
    });
    return totalSum;
};
exports.totalSumOfDemandTurnoversCalc = totalSumOfDemandTurnoversCalc;
/**
 * MC-(AB72)
 * @param workshopData
 * @param companyData
 * @param decreasedDays
 */
const totalSumOfTotalTurnoversPerMonthCalc = (workshopData, companyData, decreasedDays) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += (0, exports.totalTurnoversPerMonthCalc)(workshopData, companyData, month, decreasedDays);
    });
    return totalSum;
};
exports.totalSumOfTotalTurnoversPerMonthCalc = totalSumOfTotalTurnoversPerMonthCalc;
exports.maxCapacityToSaveKey = [
    "totalSystemTurnovers",
    "totalDemandTurnovers",
    "totalDemandSystemCheckTurnovers",
    "totalTurnovers",
];
const settingTotalsForMaxCapacity = (workshopYearly, companyData, decreasedDays) => {
    exports.maxCapacityToSaveKey.forEach((key) => {
        if (!("maxCapacity" in workshopYearly) ||
            !("maxCapacityTotals" in workshopYearly.maxCapacity)) {
            workshopYearly.maxCapacity = {
                ...workshopYearly.maxCapacity,
                maxCapacityTotals: { ...maxCapacityInit_1.yearlyMaxCapacityDataInit.maxCapacityTotals },
            };
        }
        if (key === "totalSystemTurnovers") {
            workshopYearly.maxCapacity.maxCapacityTotals[key] =
                (0, exports.totalSumOfSystemCheckTurnoverCalc)(workshopYearly, companyData);
        }
        if (key === "totalDemandTurnovers") {
            workshopYearly.maxCapacity.maxCapacityTotals[key] =
                (0, exports.totalSumOfDemandTurnoversCalc)(workshopYearly, companyData, decreasedDays, "demandCalls");
        }
        if (key === "totalDemandSystemCheckTurnovers") {
            workshopYearly.maxCapacity.maxCapacityTotals[key] =
                (0, exports.totalSumOfDemandTurnoversCalc)(workshopYearly, companyData, decreasedDays, "demandSystemCheckCalls");
        }
        if (key === "totalTurnovers") {
            workshopYearly.maxCapacity.maxCapacityTotals[key] =
                (0, exports.totalSumOfTotalTurnoversPerMonthCalc)(workshopYearly, companyData, decreasedDays);
        }
    });
    return { ...workshopYearly };
};
exports.settingTotalsForMaxCapacity = settingTotalsForMaxCapacity;
/**
 * Uses Total Calcs for Max Capacity to update the yearly object
 * @param workshopYearly
 * @param companyData
 * @param decreasedDays
 */
const settingMonthlyTotalsForMaxCapacity = (workshopYearly, companyData, decreasedDays) => {
    Object.values(utils_1.reverseShortMonthValue).forEach((month) => {
        const maxCapacityMonthly = workshopYearly.maxCapacity[month];
        if (!("maxCapacityTotals" in workshopYearly.maxCapacity)) {
            // TODO TP-1799: Figure out the error with removing ts-ignore
            workshopYearly.maxCapacity = {
                // @ts-ignore
                ...workshopYearly.maxCapacity,
                maxCapacityTotals: maxCapacityInit_1.yearlyMaxCapacityDataInit.maxCapacityTotals,
            };
        }
        if ("numOfSystemTurnovers" in maxCapacityMonthly) {
            maxCapacityMonthly.numOfSystemTurnovers = (0, exports.numOfSystemCheckTurnoversCalc)(workshopYearly, month);
        }
        if ("numOfDemandTurnovers" in maxCapacityMonthly) {
            maxCapacityMonthly.numOfDemandTurnovers = (0, exports.numOfDemandTurnoversCalc)(workshopYearly, companyData, month, decreasedDays, "demandCalls");
        }
        if ("numOfDemandSystemCheckTurnovers" in maxCapacityMonthly) {
            maxCapacityMonthly.numOfDemandSystemCheckTurnovers =
                (0, exports.numOfDemandTurnoversCalc)(workshopYearly, companyData, month, decreasedDays, "demandSystemCheckCalls");
        }
        if ("monthlyTurnovers" in maxCapacityMonthly) {
            maxCapacityMonthly.monthlyTurnovers = (0, exports.totalTurnoversPerMonthCalc)(workshopYearly, companyData, month, decreasedDays);
        }
    });
    return { ...workshopYearly };
};
exports.settingMonthlyTotalsForMaxCapacity = settingMonthlyTotalsForMaxCapacity;
