import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {
  BpwStatusTypeFE,
  empty,
  MpwStatusType,
  objectFieldValidator,
  WorkshopAccessType,
} from "@nexstar-network/shared-utils";
import { FiscalYearType } from "../../../types/FiscalYearType";
import { CompanyIdOrDefault } from "../../../utils/helpers/UserHelper";
import { useUserContext } from "../user/UserContext";
import { UseQueryType } from "../../../types/ErrorMessageType";
import { LoadingOverlay } from "../../../utils/components/tailwind/LoadingOverlay";
import LoadingCard from "../../../utils/components/tailwind/LoadingCard";
import { getWorkshopsEventsStatus } from "../../bpw/utils/bpwRequests";
import NexstarLogo from "../../../utils/components/icons/nexstar/NexstarLogo";

const GET_MEMBERS_FISCAL_YEAR = `
  query ($memberId: String!){
    getMembersFiscalYear(memberId: $memberId) {
      fiscalYear
      fiscalYearEnd
      fiscalYearStartDate
      fiscalYearEndDate
      isCurrentFiscalYear
    }
  }
`;

export const CompanyContext = createContext<
  | {
      membersFiscalYear: FiscalYearType[];
      memberCoaches: any[];
      workshopEvents: Record<string, WorkshopAccessType>[];
      validYears: any[];
      getCurrentFiscalYear: () =>
        | number
        | {
            fiscalYear: number;
            index: number;
          };
      getFiscalYearEnd: () => string;
      getGreatestFiscalYear: () => number;
      getEarliestFiscalYear: () => number;
      getEditableFiscalYears: () => number[];
      getWorkshopStatus: (
        workshopEvent: any,
        eventType: "bpw" | "mpw",
        year: string,
      ) => BpwStatusTypeFE | MpwStatusType | null;
      error: any;
      loading: any;
      fetchingWorkshopEvents: boolean;
    }
  | undefined
>(undefined);

type CompanyProviderProps = {
  children: ReactElement | ReactElement[];
};
export async function getCompanyFiscalYear(
  parameters: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  if (parameters === null) {
    return null;
  }
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: GET_MEMBERS_FISCAL_YEAR,
      variables: parameters,
    },
  });
}

export async function getCompanyCoaches(
  parameters: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  if (parameters === null) {
    return null;
  }
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}crm/memberCoaches/${parameters.memberId}`,
    // url: `${process.env.REACT_APP_API_BASE_URL}crm/memberCoaches/360`,
    method: "GET",
  });
}

/**
 *
 * @param memberId
 * @param getAccessTokenSilently
 */
export async function getValidFiscalYears(
  memberId: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  if (memberId === null) {
    return null;
  }
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}member/${memberId}/validFiscalYears`,
    method: "get",
  }).then((res) => res.data);
}

export function CompanyProvider({
  children,
}: CompanyProviderProps): ReactElement {
  const { selectedCompany, getUser } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();
  const [membersFiscalYear, setMembersFiscalYear] = useState(null);
  const memberId: number = CompanyIdOrDefault(getUser(), selectedCompany);
  const params = { memberId: memberId.toString() };

  const {
    data,
    isLoading: loading,
    error,
  }: UseQueryType<any> = useQuery({
    queryKey: ["CompanyContext", params],
    queryFn: () => getCompanyFiscalYear(params, getAccessTokenSilently),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const {
    data: memberCoaches,
    isLoading: memberCoachesLoading,
    error: memberCoachesError,
  }: UseQueryType<any> = useQuery({
    queryKey: ["CompanyCoaches", params],
    queryFn: () => getCompanyCoaches(params, getAccessTokenSilently),
    staleTime: Infinity,
  });

  const {
    data: validYears,
    isLoading: validYearsLoading,
    error: validYearsError,
  }: UseQueryType<any> = useQuery({
    queryKey: ["ValidFiscalYears", params],
    queryFn: () => getValidFiscalYears(memberId, getAccessTokenSilently),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!memberId,
  });

  const {
    data: workshopEvents,
    isLoading: workshopEventsLoading,
    isFetching: fetchingWorkshopEvents,
    error: workshopEventsError,
  }: UseQueryType<any> = useQuery({
    queryKey: ["getWorkshopEvents", params],
    queryFn: () => getWorkshopsEventsStatus(memberId, getAccessTokenSilently),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!memberId,
  });

  const getCurrentFiscalYear = (
    returnIndex = false,
  ): number | { fiscalYear: number; index: number } => {
    if (loading === false) {
      for (let i = 0; i < membersFiscalYear?.length; i++) {
        if (membersFiscalYear[i].isCurrentFiscalYear) {
          return returnIndex
            ? {
                fiscalYear: membersFiscalYear[i].fiscalYear,
                index: i,
              }
            : membersFiscalYear[i].fiscalYear;
        }
      }
    }
    return returnIndex
      ? {
          fiscalYear: null,
          index: null,
        }
      : null;
  };

  //KRJ- just as a note this will always return the fiscal year end month for the current fiscal year
  const getFiscalYearEnd = (): string => {
    if (loading === false && membersFiscalYear) {
      for (let i = 0; i < membersFiscalYear?.length; i++) {
        if (membersFiscalYear[i].isCurrentFiscalYear) {
          return membersFiscalYear[i].fiscalYearEnd;
        }
      }
    }
  };

  const getGreatestFiscalYear = (): number => {
    let year;
    if (membersFiscalYear) {
      membersFiscalYear.forEach((yearlyData) => {
        year = yearlyData.fiscalYear;
      });
    } else {
      year = getCurrentFiscalYear();
    }
    return year;
  };

  const getEarliestFiscalYear = (): number => {
    const yearArray = [];
    if (membersFiscalYear) {
      membersFiscalYear.forEach((yearlyData) => {
        yearArray.push(yearlyData.fiscalYear);
      });
    } else {
      return getCurrentFiscalYear() as number;
    }
    return Math.min(...yearArray);
  };

  const getEditableFiscalYears = () => {
    // All previous fiscal years will be locked and uneditable two months after the FY ends
    const today = new Date().toJSON().slice(0, 10);
    const fiscalYears = [];
    if (membersFiscalYear) {
      membersFiscalYear.map((yearlyData) => {
        const fyEndDate = new Date(yearlyData.fiscalYearEndDate);
        const fyEndDatePlusTwoMonths = new Date(
          fyEndDate.setMonth(fyEndDate.getMonth() + 2),
        )
          .toISOString()
          .split("T")[0];
        // BK -- adding a check to allow previous fiscal years to be editable if there is not a BPW associated with that fiscal year
        if (
          fyEndDatePlusTwoMonths >= today ||
          empty(
            objectFieldValidator(workshopEvents, [
              "bpw",
              yearlyData.fiscalYear,
            ]),
          )
        ) {
          fiscalYears.push(yearlyData.fiscalYear);
        }
      });
      return fiscalYears;
    }
    return null;
  };

  const getBPWStatus = (year: string): BpwStatusTypeFE => {
    const defaultStatus = {
      id: "",
      memberId: null,
      fiscalYear: parseInt(year, 10),
      preWork: {
        complete: false,
        verified: false,
      },
      workshop: {
        complete: false,
      },
      tracking: {
        isUnlocked: false,
        unlockedBy: null,
        unlockedAt: null,
      },
    };
    if (workshopEventsLoading) {
      return defaultStatus;
    }
    if (objectFieldValidator(workshopEvents, ["bpw", `${year}`, "status"])) {
      return workshopEvents.bpw[year].status;
    }
    return defaultStatus;
  };
  const getMPWStatus = (year: string): MpwStatusType => {
    const defaultStatus = {
      id: "",
      preWork: {
        currentSituation: null,
        finalize: false,
        marketingQuestions: null,
      },
      workshop: {
        finalize: false,
        futureMarketingPlan: null,
        implementationChart: null,
        marketingWorksheet: null,
        productionChart: null,
      },
    };
    if (workshopEventsLoading) {
      return defaultStatus;
    }
    if (objectFieldValidator(workshopEvents, ["mpw", `${year}`, "status"])) {
      return workshopEvents.mpw[year].status;
    }
    return defaultStatus;
  };
  const getWorkshopStatus = (eventType: "bpw" | "mpw", year: string) => {
    if (eventType === "bpw") {
      return getBPWStatus(year);
    }
    return getMPWStatus(year);
  };

  useEffect(() => {
    if (loading === false) {
      if (data.data.data.getMembersFiscalYear) {
        setMembersFiscalYear(data.data.data.getMembersFiscalYear);
      }
    }
  }, [loading, selectedCompany]);
  if (error) throw new Error(error.message);
  if (memberCoachesError) throw new Error(memberCoachesError.message);
  if (validYearsError) throw new Error(validYearsError.message);
  if (workshopEventsError) throw new Error(workshopEventsError.message);

  return (
    <CompanyContext.Provider
      value={
        // TODO: TP-450 for finding them
        {
          membersFiscalYear,
          memberCoaches,
          validYears,
          workshopEvents,
          getCurrentFiscalYear,
          getFiscalYearEnd,
          getGreatestFiscalYear,
          getEarliestFiscalYear,
          getEditableFiscalYears,
          error,
          loading,
          getWorkshopStatus,
          fetchingWorkshopEvents,
        }
      }
    >
      {loading ||
      memberCoachesLoading ||
      workshopEventsLoading ||
      validYearsLoading ? (
        <div>
          <LoadingOverlay
            loading
            text={
              process.env.REACT_APP_NODE_ENV !== "development"
                ? "Loading Nexstar Tools"
                : "Loading CompanyContext"
            }
          />
          <header
            id="contextPageHeader"
            data-testid="contextPageHeader"
            className="w-full bg-NexstarBlue-dark z-[1299] py-1 px-6"
          >
            <div className="flex items-center justify-between gap-1">
              <div className="flex gap-4 items-center w-10/12">
                <Link to="/" id="logo" data-testid="logo">
                  <NexstarLogo />
                </Link>
                <div className="h-6 w-full bg-gray-300 rounded-full animate-pulse" />
              </div>
              <div className="h-8 w-8 bg-NexstarSkyBlue rounded-full animate-pulse" />
            </div>
          </header>
          <main className="w-full p-6">
            <LoadingCard />
          </main>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </CompanyContext.Provider>
  );
}

export const useCompanyContext = (): any => useContext(CompanyContext);
