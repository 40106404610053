import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Access, MemberType } from "@nexstar-network/shared-utils";
import { CRMUser, primaryCompany } from "../../../utils/helpers/UserHelper";
import {
  getEmulatedUser,
  getToolsByRole,
  getUserCompanies,
} from "./utils/userContextRequests";
import { addMessage } from "../../../utils/helpers/MessagingHelper";
import { LoadingOverlay } from "../../../utils/components/tailwind/LoadingOverlay";
import LoadingCard from "../../../utils/components/tailwind/LoadingCard";
import NexstarLogo from "../../../utils/components/icons/nexstar/NexstarLogo";

const UserContext = createContext({
  selectedCompany: {
    companyName: null,
    companyId: null,
    companyCountry: null,
    companyZipCode: null,
    companyCity: null,
    companyState: null,
    companyLatitude: null,
    companyLongitude: null,
  },
  resetDisabledSelect: (): any => null,
  shouldDisplay: false,
});

type UserProviderProps = {
  children: JSX.Element;
};

let previousCompanyId = 372;
export function UserProvider({ children }: UserProviderProps): JSX.Element {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  // TP-671 JAH: Updating to new Toast Package.
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<MemberType>(
    primaryCompany(user as CRMUser),
  );
  const [companyOptions, setCompanyOptions] = useState<Array<MemberType>>([]);
  const [userToolsAccess, setUserToolsAccess] = useState<Access[]>(null);
  const [accessLoading, setAccessLoading] = useState(null);
  const [companiesLoading, setCompaniesLoading] = useState(null);
  const [emulatedUser, setEmulatedUser] = useState(
    JSON.parse(window.sessionStorage.getItem("emulatedUser")),
  );

  const getUser = () => {
    if (emulatedUser) return emulatedUser as CRMUser;
    return user as CRMUser;
  };

  useEffect(() => {
    if (
      accessLoading === null ||
      previousCompanyId !== selectedCompany.companyId
    ) {
      getToolsByRole(
        getUser(),
        selectedCompany,
        getAccessTokenSilently,
        setUserToolsAccess,
        navigate,
        addMessage,
        setAccessLoading,
        enqueueSnackbar,
      );
      previousCompanyId = selectedCompany.companyId;
    }
    // TP-690 JAH: Updating the new CRM connections
    if (
      companiesLoading === null ||
      previousCompanyId !== selectedCompany.companyId
    ) {
      getUserCompanies(
        getUser(),
        getAccessTokenSilently,
        setCompanyOptions,
        navigate,
        addMessage,
        setCompaniesLoading,
        setShouldDisplay,
        enqueueSnackbar,
      );
    }
  }, [selectedCompany, accessLoading, companiesLoading]);

  const resetDisabledSelect = () => {
    const baseRoutes = [
      "/profitpricer",
      "/3daycallboard",
      "/actionplan",
      "/management",
      "/dailypace",
      "/whatifs",
      "/business-planning",
    ];
    let isBaseRoute = false;
    baseRoutes.forEach((route) => {
      if (window.location.pathname.startsWith(route)) {
        isBaseRoute = true;
      }
    });
    setDisabled(isBaseRoute);
  };

  const handleCompanyChange = (selected: any) => {
    window.sessionStorage.setItem(
      "userSelectedCompany",
      JSON.stringify(selected),
    );
    previousCompanyId = selectedCompany.companyId;
    setSelectedCompany({
      ...selected,
    });
  };
  const handleEmulatingUser = async (userID: string) => {
    const emulatedUserResponse = await getEmulatedUser(
      user,
      getAccessTokenSilently,
      setEmulatedUser,
      addMessage,
      enqueueSnackbar,
      userID,
    );
    setSelectedCompany(primaryCompany(emulatedUserResponse as CRMUser, true));
    await getToolsByRole(
      emulatedUserResponse,
      primaryCompany(emulatedUserResponse as CRMUser, true),
      getAccessTokenSilently,
      setUserToolsAccess,
      navigate,
      addMessage,
      setAccessLoading,
      enqueueSnackbar,
    );
    await getUserCompanies(
      emulatedUserResponse as CRMUser,
      getAccessTokenSilently,
      setCompanyOptions,
      navigate,
      addMessage,
      setCompaniesLoading,
      setShouldDisplay,
      enqueueSnackbar,
    );
  };
  const handleCancelEmulation = async () => {
    setEmulatedUser(null);
    setSelectedCompany(primaryCompany(user as CRMUser, true));
    await getToolsByRole(
      user,
      primaryCompany(user as CRMUser, true),
      getAccessTokenSilently,
      setUserToolsAccess,
      navigate,
      addMessage,
      setAccessLoading,
      enqueueSnackbar,
    );
    await getUserCompanies(
      user as CRMUser,
      getAccessTokenSilently,
      setCompanyOptions,
      navigate,
      addMessage,
      setCompaniesLoading,
      setShouldDisplay,
      enqueueSnackbar,
    );
    window.sessionStorage.removeItem("emulatedUser");
    window.sessionStorage.removeItem("userSelectedCompany");
    navigate("/");
  };

  // TODO: TP-450 for finding them
  const initValue = {
    selectedCompany,
    resetDisabledSelect,
    companyOptions,
    setCompanyOptions,
    handleCompanyChange,
    disabled,
    accessLoading,
    companiesLoading,
    userToolsAccess,
    setUserToolsAccess,
    shouldDisplay,
    getUser,
    emulatedUser,
    handleEmulatingUser,
    handleCancelEmulation,
  };

  return (
    <UserContext.Provider value={initValue}>
      {companiesLoading === false && accessLoading === false ? (
        <div>{children}</div>
      ) : (
        <div>
          <LoadingOverlay
            loading
            text={
              process.env.REACT_APP_NODE_ENV !== "development"
                ? "Loading Nexstar Tools"
                : "Loading UserContext"
            }
          />
          <header
            id="homePageHeader"
            data-testid="homePageHeader"
            className="w-full bg-NexstarBlue-dark z-[1299] py-1 px-6"
          >
            <div className="flex items-center justify-between gap-1">
              <div className="flex gap-4 items-center w-10/12">
                <Link to="/" id="logo" data-testid="logo">
                  <NexstarLogo />
                </Link>
                <div className="h-6 w-full bg-gray-300 rounded-full animate-pulse" />
              </div>
              <div className="h-8 w-8 bg-NexstarSkyBlue rounded-full animate-pulse" />
            </div>
          </header>
          <main className="w-full p-6">
            <LoadingCard />
          </main>
        </div>
      )}
    </UserContext.Provider>
  );
}

export const useUserContext = (): any => useContext(UserContext);
