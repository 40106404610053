import axios from "axios";
import { Big5Type, WorkshopAccessType } from "@nexstar-network/shared-utils";
import { gql, GraphQLClient } from "graphql-request";

/**
 *
 * @param saveObject
 * @param memberId
 * @param year
 * @param getAccessTokenSilently
 */
async function saveBPWStatusRequest(
  saveObject: any,
  memberId: string,
  year: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/checkBPWStatus`,
    method: "put",
    data: saveObject,
  });
}

const getWorkshopsEventsStatus = async (
  memberId: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<Record<string, WorkshopAccessType>> => {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}workshop-events/memberId/${memberId}`,
    method: "get",
  }).then((res) => res.data);
};

export const getBpwStatusesForCoach = async (
  year: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<Record<string, WorkshopAccessType>> => {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpwStatus/allMembers/fiscalYear/${year}`,
    method: "get",
  }).then((res) => res.data);
};

type GetOverheadsParams = {
  memberId: string;
  fiscalYear: number;
};

/**
 * gqlKey = getBudgetFilePreWorkOverheadsByMemberIdAndFiscalYear
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function getOverheads(
  parameters: GetOverheadsParams,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const WORKSHOP_OVERHEADS_BY_MEMBER_AND_YEAR = gql`
    query ($memberId: String!, $fiscalYear: Float!) {
      getBudgetFilePreWorkOverheadsByMemberIdAndFiscalYear(
        memberId: $memberId
        fiscalYear: $fiscalYear
      ) {
        id
        memberId
        currentYearProjectedRev
        currentYearRevenue
        monthsInCurrentYear
        percentRev
        fiscalYear
        overheads {
          accountNumber
          memberId
          fiscalYear
          id
          big5
          big5Subcategory
          overheadName
          overheadOrder
          currentYTD
          projectedTotal
          percentOfRevenue
          expensePercent
          nextYearIncPercent
          nextYearIncAmount
          nextYearTotal
          budgetMethod
          monthlyVariance {
            jan
            feb
            mar
            apr
            may
            jun
            jul
            aug
            sep
            oct
            nov
            dec
          }
        }
        errorMessage
        errorCode
      }
    }
  `;
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  const graphQLClient = new GraphQLClient(
    `${process.env.REACT_APP_API_BASE_URL}graphql`,
    {
      headers: {
        authorization: accessToken,
      },
    },
  );
  return graphQLClient.request(
    WORKSHOP_OVERHEADS_BY_MEMBER_AND_YEAR,
    parameters,
  );
}

/**
 *
 * @param memberId
 * @param fiscalYear
 * @param getAccessTokenSilently
 */
async function getBig5(
  memberId: string,
  fiscalYear: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<Big5Type> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/member/${memberId}/year/${fiscalYear}/big5`,
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 *
 * @param params
 * @param getAccessTokenSilently
 */
async function getBpwSettings(
  params: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  let memberId;
  let year;
  if (params) {
    memberId = params.memberId;
    year = params.year;
  }
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/member/${memberId}/year/${year}/bpwSettings`,
    method: "get",
  }).then((res) => res.data);
}

/**
 *
 * @param settingsObj
 * @param memberId
 * @param fiscalYear
 * @param getAccessTokenSilently
 */
async function saveBpwSettings(
  settingsObj: any,
  memberId: string,
  fiscalYear: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/member/${memberId}/year/${fiscalYear}/bpwSettings`,
    method: "put",
    data: settingsObj,
  });
}

/**
 *
 * @param memberId
 * @param fiscalYear
 * @param getAccessTokenSilently
 */
async function invalidateBpwSettings(
  memberId: string,
  fiscalYear: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/member/${memberId}/year/${fiscalYear}/invalidateBpwSettings`,
    method: "put",
  });
}

type GetPreWorkParams = {
  memberId: string;
  year: number;
  departmentId: string;
};

/**
 * gqlKey = getPreWorkByMemberYearAndDepartment
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function getPreWork(
  parameters: GetPreWorkParams,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const GET_PREWORK_BY_YEAR = gql`
    query ($memberId: String!, $year: Float!, $departmentId: String!) {
      getPreWorkByMemberYearAndDepartment(
        memberId: $memberId
        year: $year
        departmentId: $departmentId
      ) {
        id
        memberId
        departmentId
        departmentType
        departmentTrade
        fiscalYear
        updatedAt
        updatedBy
        createdAt
        serviceAgreementMonthly {
          projectedVisits
          visits
          runRate
          started
          projectedNew
          projectedEnd
        }
        serviceAgreementYearly {
          projectedVisits
          visits
          runRate
          started
          projectedNew
          projectedEnd
        }
        nonMonthlyData {
          serviceAvgCall {
            avgSale90Days
            techOppCalls90Days
            oppCallsPerc
            convRateLastYear
            techOppCallsLastYear
            avgSaleLastYear
            convRate90Days
          }
          serviceDemandSystemCheckAvgCall {
            avgSale90Days
            techOppCalls90Days
            oppCallsPerc
            convRateLastYear
            techOppCallsLastYear
            avgSaleLastYear
            convRate90Days
          }
          serviceSystemCheck {
            avgSale90Days
            techOppCalls90Days
            convRateLastYear
            agreementsRev
            agreementsSold
            techOppCallsLastYear
            runRateVisits
            avgSaleLastYear
            convRate90Days
          }
          installAvgCall {
            techConvRateLastYear
            techConvRateLast3mo
            techAvgSaleLastYear
            techAvgSaleLast3mo
            marketConvRateLastYear
            marketConvRateLast3mo
            marketAvgSaleLastYear
            marketAvgSaleLast3mo
            otherConvRateLastYear
            otherConvRateLast3mo
            otherAvgRateLastYear
            otherAvgSale
          }
        }
        monthlyData {
          jan {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          feb {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          mar {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          apr {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          may {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          jun {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          jul {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          aug {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          sep {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          oct {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          nov {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
          dec {
            installJobs {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgInstallCallCount
            installCallCountSeasonalityPercentage
            installCallCountSeasonalityPercentageOverride
            totalNumOfInstallLeadsRan
            numOfTechGeneratedLeadsRan
            techGeneratedLeadsConversionRate
            techGeneratedLeadsAverageSale
            numOfMarketedLeadsRan
            marketedLeadsConversionRate
            marketedLeadsAverageSale
            numOfOtherLeadsRan
            otherLeadsConversionRate
            otherLeadsAverageSale
            numOfSalesman
            numOfLeadsPerSalesmanPerDay
            numOfSoldJobs
            numOfInstallCrews
            numOfJobsPerCrewPerDay
            demandCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgDemandCallCount
            demandCallCountSeasonalityPercentage
            demandCallCountSeasonalityPercentageOverride
            numOfAllTechsRunningCalls
            callsPerTechPerDay
            demandOpportunityCalls
            systemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            avgSystemCheckCallCount
            systemCheckCallCountSeasonalityPercentage
            systemCheckCallCountSeasonalityPercentageOverride
            systemOpportunityCalls
            laborPercentage
            materialPercentage
            salesExpensePercentage
            otherPercentage
            demandCallsConversionRate
            demandCallsAverageSale
            systemCheckCallsConversionRate
            systemCheckCallsAverageSale
            avgDemandSystemCheckCallCount
            demandSystemCheckCalls {
              yearMinusFour
              yearMinusThree
              yearMinusTwo
              yearMinusOne
            }
            demandSystemCheckCallsAverageSale
            demandSystemCheckCallsConversionRate
            demandSystemCheckOpportunityCalls
          }
        }
        errorCode
        errorMessage
      }
    }
  `;
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  const graphQLClient = new GraphQLClient(
    `${process.env.REACT_APP_API_BASE_URL}graphql`,
    {
      headers: {
        authorization: accessToken,
      },
    },
  );
  return graphQLClient.request(GET_PREWORK_BY_YEAR, parameters);
}

/**
 *
 * @param memberId
 * @param fiscalYear
 * @param fromDeptId
 * @param toDeptId
 * @param getAccessTokenSilently
 */
async function autoFillPrework(
  memberId: string,
  fiscalYear: number,
  fromDeptId: string,
  toDeptId: string,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/member/${memberId}/year/${fiscalYear}/fromDept/${fromDeptId}/toDept/${toDeptId}/fillPreWork`,
    method: "put",
  });
}

/**
 *
 * @param memberId
 * @param fiscalYear
 * @param getAccessTokenSilently
 */
async function getAllDepartmentsCompletionStatus(
  memberId: string,
  fiscalYear: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/member/${memberId}/year/${fiscalYear}/deptStatus`,
    method: "get",
  });
}

/**
 *
 * @param memberId
 * @param fiscalYear
 * @param departmentId
 * @param getAccessTokenSilently
 */
async function updateDepartmentCompletionStatus(
  memberId: string,
  fiscalYear: number,
  departmentId: string,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/member/${memberId}/year/${fiscalYear}/${departmentId}/deptStatus`,
    method: "put",
  });
}

async function saveBpwForAllDepartments(
  memberId: string,
  fiscalYear: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/member/${memberId}/year/${fiscalYear}/saveAllBpw`,
    method: "put",
  });
}

const MODIFY_PREWORK_OVERHEAD = gql`
  mutation (
    $modifyBudgetFilePreWorkOverheads: ModifyBudgetFilePreWorkOverheads!
  ) {
    modifyBudgetFilePreWorkOverheads(
      modifyBudgetFilePreWorkOverheads: $modifyBudgetFilePreWorkOverheads
    ) {
      id
      memberId
      errorMessage
      errorCode
    }
  }
`;

/**
 * gqlKey = modifyBudgetFilePreWorkOverheads
 * @param parameters
 * @param getAccessTokenSilently
 */
async function modifyPreworkOverhead(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MODIFY_PREWORK_OVERHEAD,
      variables: parameters,
    },
  });
}

const MODIFY_SINGLE_PREWORK_OVERHEAD = gql`
  mutation ($overheadToModify: Overheadsv2Input!) {
    modifySingleOverheadItem(overheadToModify: $overheadToModify) {
      memberId
      id
      overheadName
      errorCode
      errorMessage
    }
  }
`;

/**
 * gqlKey = modifySingleOverheadItem
 * @param parameters
 * @param getAccessTokenSilently
 */
async function modifyPreworkSingleOverhead(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MODIFY_SINGLE_PREWORK_OVERHEAD,
      variables: parameters,
    },
  });
}

const MODIFY_PREWORK = gql`
  mutation ($modifyBudgetFilePreWork: ModifyBudgetFilePreWork!) {
    modifyBudgetFilePreWork(modifyBudgetFilePreWork: $modifyBudgetFilePreWork) {
      id
      memberId
      errorMessage
      errorCode
    }
  }
`;

/**
 * gqlKey = modifyBudgetFilePreWork
 * @param parameters
 * @param getAccessTokenSilently
 */
async function modifyPrework(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MODIFY_PREWORK,
      variables: parameters,
    },
  });
}

const MODIFY_WORKSHOP_OVERHEAD = gql`
  mutation (
    $modifyBudgetFilePreWorkOverheads: ModifyBudgetFilePreWorkOverheads!
  ) {
    modifyBudgetFilePreWorkOverheads(
      modifyBudgetFilePreWorkOverheads: $modifyBudgetFilePreWorkOverheads
    ) {
      id
      memberId
      errorMessage
      errorCode
    }
  }
`;

/**
 * gqlKey = modifyBudgetFilePreWorkOverheads
 * @param parameters
 * @param getAccessTokenSilently
 */
async function modifyWorkshopOverhead(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MODIFY_WORKSHOP_OVERHEAD,
      variables: parameters,
    },
  });
}

const MODIFY_SINGLE_WORKSHOP_OVERHEAD = gql`
  mutation ($overheadToModify: Overheadsv2Input!) {
    modifySingleOverheadItem(overheadToModify: $overheadToModify) {
      memberId
      id
      overheadName
      errorCode
      errorMessage
    }
  }
`;

/**
 * gqlKey = modifySingleOverheadItem
 * @param parameters
 * @param getAccessTokenSilently
 */
async function modifySingleWorkshopOverhead(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MODIFY_SINGLE_WORKSHOP_OVERHEAD,
      variables: parameters,
    },
  });
}

export {
  saveBPWStatusRequest,
  getWorkshopsEventsStatus,
  getBig5,
  getBpwSettings,
  saveBpwSettings,
  invalidateBpwSettings,
  autoFillPrework,
  getAllDepartmentsCompletionStatus,
  updateDepartmentCompletionStatus,
  saveBpwForAllDepartments,
  modifyPreworkOverhead,
  modifyPreworkSingleOverhead,
  modifyPrework,
  modifyWorkshopOverhead,
  modifySingleWorkshopOverhead,
};
