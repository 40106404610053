"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.operatingProfit = exports.totalOverheadExpenses = exports.overheadBudgetVariancePercent = exports.overheadBudgetVarianceAmount = exports.overheadPreviousYearVariancePercent = exports.overheadCurrentYearVarianceAmount = exports.overheadCurrentYearPercent = exports.overheadPreviousYearPercent = void 0;
/**
 * D57
 * @param trackingData
 * @param overheadId
 */
const overheadPreviousYearPercent = (trackingData, overheadId) => {
    if (!trackingData ||
        !trackingData.overheads ||
        !trackingData.overheads[overheadId])
        return 0;
    if (!trackingData.overheads[overheadId].previousYearAmount ||
        !trackingData.netSales.previousYearAmount)
        return 0;
    return ((trackingData.overheads[overheadId].previousYearAmount /
        trackingData.netSales.previousYearAmount) *
        100);
};
exports.overheadPreviousYearPercent = overheadPreviousYearPercent;
/**
 * G57
 * @param trackingData
 * @param overheadId
 */
const overheadCurrentYearPercent = (trackingData, overheadId) => {
    if (!trackingData ||
        !trackingData.overheads ||
        !trackingData.overheads[overheadId])
        return 0;
    if (!trackingData.overheads[overheadId].currentYearAmount ||
        !trackingData.netSales.currentYearAmount)
        return 0;
    return ((trackingData.overheads[overheadId].currentYearAmount /
        trackingData.netSales.currentYearAmount) *
        100);
};
exports.overheadCurrentYearPercent = overheadCurrentYearPercent;
/**
 * I57
 * @param trackingData
 * @param overheadId
 */
const overheadCurrentYearVarianceAmount = (trackingData, overheadId) => {
    if (!trackingData ||
        !trackingData.overheads ||
        !trackingData.overheads[overheadId])
        return 0;
    return (trackingData.overheads[overheadId].currentYearAmount -
        trackingData.overheads[overheadId].previousYearAmount);
};
exports.overheadCurrentYearVarianceAmount = overheadCurrentYearVarianceAmount;
/**
 * J57
 * @param trackingData
 * @param overheadId
 */
const overheadPreviousYearVariancePercent = (trackingData, overheadId) => {
    if (!trackingData ||
        !trackingData.overheads ||
        !trackingData.overheads[overheadId])
        return 0;
    if (!trackingData.overheads[overheadId].previousYearAmount ||
        !trackingData.overheads[overheadId].currentVSPreviousVarianceAmount)
        return 0;
    return ((trackingData.overheads[overheadId].currentVSPreviousVarianceAmount /
        trackingData.overheads[overheadId].previousYearAmount) *
        100);
};
exports.overheadPreviousYearVariancePercent = overheadPreviousYearVariancePercent;
/**
 * N57
 * @param trackingData
 * @param overheadId
 */
const overheadBudgetVarianceAmount = (trackingData, overheadId) => {
    if (!trackingData ||
        !trackingData.overheads ||
        !trackingData.overheads[overheadId])
        return 0;
    return (trackingData.overheads[overheadId].currentYearAmount -
        trackingData.overheads[overheadId].budgetAmount);
};
exports.overheadBudgetVarianceAmount = overheadBudgetVarianceAmount;
/**
 * O57
 * @param trackingData
 * @param overheadId
 */
const overheadBudgetVariancePercent = (trackingData, overheadId) => {
    var _a;
    if (!trackingData ||
        !trackingData.overheads ||
        !trackingData.overheads[overheadId])
        return 0;
    const overheadBudgetAmount = (0, exports.overheadBudgetVarianceAmount)(trackingData, overheadId);
    const budgetAmount1 = (_a = trackingData.overheads[overheadId].budgetAmount) !== null && _a !== void 0 ? _a : 0;
    if (!overheadBudgetAmount || !budgetAmount1)
        return 0;
    return (overheadBudgetAmount / budgetAmount1) * 100;
};
exports.overheadBudgetVariancePercent = overheadBudgetVariancePercent;
/**
 * C & F202
 * @param trackingData
 * @param timeKey
 */
const totalOverheadExpenses = (trackingData, timeKey) => {
    if (!trackingData || !trackingData.overheads)
        return 0;
    let overheadSum = 0;
    Object.entries(trackingData.overheads).forEach(([overheadId, overhead]) => {
        overheadSum += overhead[timeKey];
    });
    return overheadSum;
};
exports.totalOverheadExpenses = totalOverheadExpenses;
/**
 * C & F204
 * @param trackingData
 * @param timeKey
 */
const operatingProfit = (trackingData, timeKey) => {
    if (!trackingData || !trackingData.overheads)
        return 0;
    const totalOverheadExpenses1 = (0, exports.totalOverheadExpenses)(trackingData, timeKey);
    const grossMargin = trackingData.totalGrossMargin[timeKey];
    return grossMargin - totalOverheadExpenses1;
};
exports.operatingProfit = operatingProfit;
