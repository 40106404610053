import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import Auth0LogoutButton from "./Auth0LogoutButton";

function AuthenticationButton({
  className,
}: Record<string, undefined>): JSX.Element {
  const { isAuthenticated } = useAuth0();
  const { isLoading, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        await loginWithRedirect({
          appState: {
            returnTo: `${window.location.pathname}`,
          },
        });
      }
    })();
  }, [isLoading]);

  return isLoading && !user ? null : (
    <div className={className}>
      {isAuthenticated ? <Auth0LogoutButton /> : null}
    </div>
  );
}

export default AuthenticationButton;
