"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.installDC = exports.installCC = exports.installCAS = exports.newInstallObject = void 0;
const utils_1 = require("../../../utils");
exports.newInstallObject = {
    PK: "MEMBER#6982663160",
    SK: "01GBAZ58N69JP4NFW9QAW5F0KC#2023#LATEST",
    fiscalYear: 2023,
    departmentId: "01GBAZ58N69JP4NFW9QAW5F0KC",
    departmentType: utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    conversionAndAverageSale: {
        jan: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        feb: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        mar: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        apr: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        may: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        jun: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        jul: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        aug: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        sep: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        oct: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        nov: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
        december: {
            budgetTechGeneratedClosingRate: 66,
            budgetedMarketedClosingRate: 28,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: 11500,
            budgetedMarketedLeadsAvgSale: 11500,
            budgetedOtherLeadsAvgSale: null,
            systemCheckBudgetYearConvRate: 35,
            demandCallBudgetYearConvRate: 75,
            systemCheckBudgetYearAvgSale: 150,
            demandCallBudgetYearAvgSale: 350,
        },
    },
    callCount: {
        jan: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 640823.7000000002,
        },
        feb: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 582567.0000000001,
        },
        mar: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 669952.0499999998,
        },
        apr: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 582567.0000000001,
        },
        may: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 640823.7000000002,
        },
        jun: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 640823.7000000002,
        },
        jul: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 582567,
        },
        aug: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 669952.05,
        },
        sep: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 582567,
        },
        oct: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 640823.7000000001,
        },
        nov: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 582567,
        },
        december: {
            targetNumOfCallsRanPerTech: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: 1,
            budgetedNumOfSalespersons: 3,
            targetNumOfJobsPerCrew: 1,
            budgetedNumOfCrews: 3,
            monthlyTotalRevenueCalc: 582567,
        },
    },
    directCosts: {
        jan: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 640823.7000000002,
            subcontractorAmount: 0,
            refundAmount: 12816.474000000004,
            financeFeeAmount: 32041.185000000012,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 59596.604100000026,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 202628.4539400001,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 47677.28328000002,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5959.660410000002,
            grossProfit: 280104.03927,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 93.08421973714287,
        },
        feb: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 582567.0000000001,
            subcontractorAmount: 0,
            refundAmount: 11651.340000000002,
            financeFeeAmount: 29128.350000000006,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 54178.73100000001,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 184207.68540000005,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 43342.984800000006,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5417.873100000001,
            grossProfit: 254640.03569999995,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 84.62201794285713,
        },
        mar: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 669952.0499999998,
            subcontractorAmount: 0,
            refundAmount: 13399.040999999997,
            financeFeeAmount: 33497.60249999999,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 62305.54064999998,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 211838.83820999996,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 49844.43251999999,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 6230.554064999998,
            grossProfit: 292836.0410549999,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 97.31532063428567,
        },
        apr: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 582567.0000000001,
            subcontractorAmount: 0,
            refundAmount: 11651.340000000002,
            financeFeeAmount: 29128.350000000006,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 54178.73100000001,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 184207.68540000005,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 43342.984800000006,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5417.873100000001,
            grossProfit: 254640.03569999995,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 84.62201794285713,
        },
        may: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 640823.7000000002,
            subcontractorAmount: 0,
            refundAmount: 12816.474000000004,
            financeFeeAmount: 32041.185000000012,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 59596.604100000026,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 202628.4539400001,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 47677.28328000002,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5959.660410000002,
            grossProfit: 280104.03927,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 93.08421973714287,
        },
        jun: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 640823.7000000002,
            subcontractorAmount: 0,
            refundAmount: 12816.474000000004,
            financeFeeAmount: 32041.185000000012,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 59596.604100000026,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 202628.4539400001,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 47677.28328000002,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5959.660410000002,
            grossProfit: 280104.03927,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 93.08421973714287,
        },
        jul: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 582567,
            subcontractorAmount: 0,
            refundAmount: 11651.34,
            financeFeeAmount: 29128.350000000002,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 54178.73100000001,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 184207.68540000005,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 43342.984800000006,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5417.873100000001,
            grossProfit: 254640.03569999995,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 84.62201794285713,
        },
        aug: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 669952.05,
            subcontractorAmount: 0,
            refundAmount: 13399.041000000001,
            financeFeeAmount: 33497.6025,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 62305.54065000001,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 211838.83821000002,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 49844.43252,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 6230.554065,
            grossProfit: 292836.041055,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 97.31532063428573,
        },
        sep: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 582567,
            subcontractorAmount: 0,
            refundAmount: 11651.34,
            financeFeeAmount: 29128.350000000002,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 54178.73100000001,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 184207.68540000005,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 43342.984800000006,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5417.873100000001,
            grossProfit: 254640.03569999995,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 84.62201794285713,
        },
        oct: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 640823.7000000001,
            subcontractorAmount: 0,
            refundAmount: 12816.474000000002,
            financeFeeAmount: 32041.185000000005,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 59596.60410000001,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 202628.45394000004,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 47677.28328000001,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5959.660410000001,
            grossProfit: 280104.03927,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 93.08421973714287,
        },
        nov: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 582567,
            subcontractorAmount: 0,
            refundAmount: 11651.34,
            financeFeeAmount: 29128.350000000002,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 54178.73100000001,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 184207.68540000005,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 43342.984800000006,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5417.873100000001,
            grossProfit: 254640.03569999995,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 84.62201794285713,
        },
        december: {
            demandAndSystemCheckSales: null,
            sppRevenue: null,
            grossIncome: 582567,
            subcontractorAmount: 0,
            refundAmount: 11651.34,
            financeFeeAmount: 29128.350000000002,
            budgetedLaborPerInput: 10,
            budgetedLaborAmount: 54178.73100000001,
            budgetedMaterialPerInput: 34,
            budgetedMaterialAmount: 184207.68540000005,
            budgetedSalesExpPercInput: 8,
            budgetedSalesExpAmount: 43342.984800000006,
            budgetedOtherDirectPercInput: 1,
            budgetedOtherDirectAmount: 5417.873100000001,
            grossProfit: 254640.03569999995,
            soldHourPerMonthGoal: null,
            soldDayPerMonthInstallGoal: 84.62201794285713,
        },
    },
};
exports.installCAS = {
    PK: "MEMBER#6982663160",
    SK: "01GBAZ58N69JP4NFW9QAW5F0KC#2023#CONV_AVG_SALE#LATEST",
    LSI1SK: "WORKSHOP#01GBAZ58N69JP4NFW9QAW5F0KC#2023#LATEST",
    LSI2SK: "WORKSHOP#2023#CONV_AVG_SALE#LATEST",
    departmentName: "HVAC Install",
    fiscalYear: 2023,
    departmentId: "01GBAZ58N69JP4NFW9QAW5F0KC",
    departmentType: utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    jan: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    feb: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    mar: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    apr: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    may: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    jun: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    jul: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    aug: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    sep: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    oct: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    nov: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
    december: {
        budgetTechGeneratedClosingRate: 66,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: 11500,
        budgetedMarketedLeadsAvgSale: 11500,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 35,
        demandCallBudgetYearConvRate: 75,
        systemCheckBudgetYearAvgSale: 150,
        demandCallBudgetYearAvgSale: 350,
    },
};
exports.installCC = {
    dec: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 582567,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    PK: "MEMBER#6982663160",
    SK: "01GBAZ58N69JP4NFW9QAW5F0KC#2023#CALL_COUNT#LATEST",
    LSI1SK: null,
    LSI2SK: null,
    fiscalYear: 2023,
    departmentId: "01GBAZ58N69JP4NFW9QAW5F0KC",
    departmentType: utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentName: "HVAC Install",
    callCountTotals: {
        totalSumPriorYearAgreementSystemChecks: null,
        totalSumBudgetedSystemCheckCallCount: null,
        totalSumPriorYearDemandCallCount: null,
        totalSumPriorYearDemandSystemCheckCallCount: null,
        totalSumPriorYearTechGeneratedLeads: null,
        totalSumBudgetedTechGeneratedLeads: null,
        totalSumPriorYearMarketedLeadCount: null,
        totalSumPriorYearOtherLeadCount: null,
        totalSumRequiredDemandCallCount: null,
        totalSumRequiredDemandSystemCheckCallCount: null,
        totalSumMarketedLeadCount: null,
        totalConvertedTechLeadCount: null,
        totalSumOtherLeadCount: null,
        totalTechLeadRevenue: null,
        totalConvertedMarketedLeadCount: null,
        totalMarketedLeadRevenue: null,
        totalConvertedOtherLeadCount: null,
        totalOtherLeadRevenue: null,
        totalDemandConvertedCalls: null,
        totalDemandSystemCheckConvertedCalls: null,
        totalDemandRevenue: null,
        totalDemandSystemCheckRevenue: null,
        totalSystemCheckConvertedCalls: null,
        totalSystemCheckRevenue: null,
    },
    jan: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 640823.7000000002,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    feb: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 582567.0000000001,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    mar: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 669952.0499999998,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    apr: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 582567.0000000001,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    may: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 640823.7000000002,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    jun: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 640823.7000000002,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    jul: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 582567,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    aug: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 669952.05,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    sep: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 582567,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    oct: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 640823.7000000001,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    nov: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 582567,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    december: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 1,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: 582567,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
};
exports.installDC = {
    PK: "MEMBER#6982663160",
    SK: "01GBAZ58N69JP4NFW9QAW5F0KC#2023#DC#LATEST",
    fiscalYear: 2023,
    departmentId: "01GBAZ58N69JP4NFW9QAW5F0KC",
    departmentType: utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    jan: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 640823.7000000002,
        subcontractorAmount: 0,
        refundAmount: 12816.474000000004,
        financeFeeAmount: 32041.185000000012,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 59596.604100000026,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 202628.4539400001,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 47677.28328000002,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5959.660410000002,
        grossProfit: 280104.03927,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 93.08421973714287,
    },
    feb: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 582567.0000000001,
        subcontractorAmount: 0,
        refundAmount: 11651.340000000002,
        financeFeeAmount: 29128.350000000006,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 54178.73100000001,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 184207.68540000005,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 43342.984800000006,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5417.873100000001,
        grossProfit: 254640.03569999995,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 84.62201794285713,
    },
    mar: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 669952.0499999998,
        subcontractorAmount: 0,
        refundAmount: 13399.040999999997,
        financeFeeAmount: 33497.60249999999,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 62305.54064999998,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 211838.83820999996,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 49844.43251999999,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 6230.554064999998,
        grossProfit: 292836.0410549999,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 97.31532063428567,
    },
    apr: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 582567.0000000001,
        subcontractorAmount: 0,
        refundAmount: 11651.340000000002,
        financeFeeAmount: 29128.350000000006,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 54178.73100000001,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 184207.68540000005,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 43342.984800000006,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5417.873100000001,
        grossProfit: 254640.03569999995,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 84.62201794285713,
    },
    may: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 640823.7000000002,
        subcontractorAmount: 0,
        refundAmount: 12816.474000000004,
        financeFeeAmount: 32041.185000000012,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 59596.604100000026,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 202628.4539400001,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 47677.28328000002,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5959.660410000002,
        grossProfit: 280104.03927,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 93.08421973714287,
    },
    jun: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 640823.7000000002,
        subcontractorAmount: 0,
        refundAmount: 12816.474000000004,
        financeFeeAmount: 32041.185000000012,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 59596.604100000026,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 202628.4539400001,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 47677.28328000002,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5959.660410000002,
        grossProfit: 280104.03927,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 93.08421973714287,
    },
    jul: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 582567,
        subcontractorAmount: 0,
        refundAmount: 11651.34,
        financeFeeAmount: 29128.350000000002,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 54178.73100000001,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 184207.68540000005,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 43342.984800000006,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5417.873100000001,
        grossProfit: 254640.03569999995,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 84.62201794285713,
    },
    aug: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 669952.05,
        subcontractorAmount: 0,
        refundAmount: 13399.041000000001,
        financeFeeAmount: 33497.6025,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 62305.54065000001,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 211838.83821000002,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 49844.43252,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 6230.554065,
        grossProfit: 292836.041055,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 97.31532063428573,
    },
    sep: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 582567,
        subcontractorAmount: 0,
        refundAmount: 11651.34,
        financeFeeAmount: 29128.350000000002,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 54178.73100000001,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 184207.68540000005,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 43342.984800000006,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5417.873100000001,
        grossProfit: 254640.03569999995,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 84.62201794285713,
    },
    oct: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 640823.7000000001,
        subcontractorAmount: 0,
        refundAmount: 12816.474000000002,
        financeFeeAmount: 32041.185000000005,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 59596.60410000001,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 202628.45394000004,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 47677.28328000001,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5959.660410000001,
        grossProfit: 280104.03927,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 93.08421973714287,
    },
    nov: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 582567,
        subcontractorAmount: 0,
        refundAmount: 11651.34,
        financeFeeAmount: 29128.350000000002,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 54178.73100000001,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 184207.68540000005,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 43342.984800000006,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5417.873100000001,
        grossProfit: 254640.03569999995,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 84.62201794285713,
    },
    december: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: 582567,
        subcontractorAmount: 0,
        refundAmount: 11651.34,
        financeFeeAmount: 29128.350000000002,
        budgetedLaborPerInput: 10,
        budgetedLaborAmount: 54178.73100000001,
        budgetedMaterialPerInput: 34,
        budgetedMaterialAmount: 184207.68540000005,
        budgetedSalesExpPercInput: 8,
        budgetedSalesExpAmount: 43342.984800000006,
        budgetedOtherDirectPercInput: 1,
        budgetedOtherDirectAmount: 5417.873100000001,
        grossProfit: 254640.03569999995,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: 84.62201794285713,
    },
};
