"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.overrideObjectInit = void 0;
exports.overrideObjectInit = {
    apr: null,
    aug: null,
    dec: null,
    feb: null,
    jan: null,
    jul: null,
    jun: null,
    mar: null,
    may: null,
    nov: null,
    oct: null,
    sep: null,
    total: null,
};
