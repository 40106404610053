import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function ImportIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m678.78,1113.99c0,43.2,34.44,71.95,76.75,62.73,0,0-23.03,6.43,2.82-.76,247.95-68.89,429.94-296.29,429.94-566.2,0-324.52-263.07-587.58-587.58-587.58S13.12,285.25,13.12,609.76c0,270.38,182.63,498.11,431.24,566.54,25.45,7.01,1.52.42,1.52.42,42.39,8.55,76.76-19.61,76.76-62.73v-491.18c0-10.82-6.17-13.4-13.88-5.68,0,0-63.31,63.52-86.06,86.61-74.31,74.6-184.71-36.21-111.12-110.06l239.15-238.07c26.81-26.66,70.43-26.78,97.41-.28l241.64,238.36c73.59,73.85-36.8,184.65-110.4,110.79-23.39-23.76-86.73-87.31-86.73-87.31-7.68-7.7-13.9-5.16-13.9,5.65v491.18Z" />
    </svg>
  );
}
