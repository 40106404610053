"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.callCountDynamoObj = exports.ccDataForTest = exports.ccInputData = exports.preWorkTestDataForCC = exports.workshopTestDataForCC = exports.totalsTestObj = exports.monthlyRevenueTestObj = void 0;
const utils_1 = require("../../../utils");
exports.monthlyRevenueTestObj = {
    jan: {
        monthlyTotalRevenueCalc: 253442.13348021515,
    },
    feb: {
        monthlyTotalRevenueCalc: 234940.22054406107,
    },
    mar: {
        monthlyTotalRevenueCalc: 306136.5355673317,
    },
    apr: {
        monthlyTotalRevenueCalc: 285944.9992466932,
    },
};
exports.totalsTestObj = {
    totalSumPriorYearAgreementSystemChecks: null,
    totalSumRequiredDemandCallCount: null,
    totalSumBudgetedSystemCheckCallCount: null,
    totalSumPriorYearDemandCallCount: null,
    totalSumPriorYearTechGeneratedLeads: 559,
    totalSumBudgetedTechGeneratedLeads: 869.1922823564167,
    totalSumPriorYearMarketedLeadCount: 354,
    totalSumPriorYearOtherLeadCount: 0,
    totalSumMarketedLeadCount: 427.3077176435833,
    totalConvertedTechLeadCount: 401.1002012161981,
    totalSumOtherLeadCount: 0,
    totalTechLeadRevenue: 3519324.879528013,
    totalConvertedMarketedLeadCount: 116.48432116543545,
    totalMarketedLeadRevenue: 1137519.7785692813,
    totalConvertedOtherLeadCount: 0,
    totalOtherLeadRevenue: 0,
    totalDemandConvertedCalls: null,
    totalDemandRevenue: null,
    totalSystemCheckConvertedCalls: null,
    totalSystemCheckRevenue: null,
    totalSumPriorYearDemandSystemCheckCallCount: null,
    totalSumRequiredDemandSystemCheckCallCount: null,
    totalDemandSystemCheckConvertedCalls: null,
    totalDemandSystemCheckRevenue: null,
};
const objToSave = {
    callCountTotals: {
        totalSumPriorYearAgreementSystemChecks: null,
        totalSumBudgetedSystemCheckCallCount: null,
        totalSumPriorYearDemandCallCount: null,
        totalSumPriorYearDemandSystemCheckCallCount: null,
        totalSumPriorYearTechGeneratedLeads: null,
        totalSumBudgetedTechGeneratedLeads: null,
        totalSumPriorYearMarketedLeadCount: null,
        totalSumPriorYearOtherLeadCount: null,
        totalSumRequiredDemandCallCount: null,
        totalSumRequiredDemandSystemCheckCallCount: null,
        totalSumMarketedLeadCount: null,
        totalConvertedTechLeadCount: null,
        totalSumOtherLeadCount: null,
        totalTechLeadRevenue: null,
        totalConvertedMarketedLeadCount: null,
        totalMarketedLeadRevenue: null,
        totalConvertedOtherLeadCount: null,
        totalOtherLeadRevenue: null,
        totalDemandConvertedCalls: null,
        totalDemandSystemCheckConvertedCalls: null,
        totalDemandRevenue: null,
        totalDemandSystemCheckRevenue: null,
        totalSystemCheckConvertedCalls: null,
        totalSystemCheckRevenue: null,
    },
    departmentId: null,
    departmentName: null,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    fiscalYear: 0,
    id: "",
    memberId: "",
    jan: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1.8,
        budgetedNumOfSalespersons: 2,
        targetNumOfJobsPerCrew: 0.8,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    feb: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1.8,
        budgetedNumOfSalespersons: 2,
        targetNumOfJobsPerCrew: 0.8,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    mar: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 2.1,
        budgetedNumOfSalespersons: 2,
        targetNumOfJobsPerCrew: 0.8,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    apr: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 2.3,
        budgetedNumOfSalespersons: 2,
        targetNumOfJobsPerCrew: 0.9,
        budgetedNumOfCrews: 3,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    may: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 2.2,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 0.8,
        budgetedNumOfCrews: 4,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    jun: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 2.1,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 0.8,
        budgetedNumOfCrews: 4,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    jul: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 2.4,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 0.9,
        budgetedNumOfCrews: 4,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    aug: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1.8,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 0.7,
        budgetedNumOfCrews: 4,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    sep: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1.8,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 0.7,
        budgetedNumOfCrews: 4,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    oct: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1.6,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 0.7,
        budgetedNumOfCrews: 4,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    nov: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1.8,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 0.7,
        budgetedNumOfCrews: 4,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    dec: {
        targetNumOfCallsRanPerTech: null,
        budgetedNumOfTechs: null,
        targetNumOfLeadsRanPerSalesperson: 1.6,
        budgetedNumOfSalespersons: 3,
        targetNumOfJobsPerCrew: 0.7,
        budgetedNumOfCrews: 4,
        monthlyTotalRevenueCalc: null,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
};
const convAvgSale = {
    departmentId: "",
    departmentName: "",
    departmentTrade: null,
    departmentType: null,
    fiscalYear: 0,
    id: "",
    memberId: "",
    updatedAt: "date-time",
    updatedBy: "me",
    createdAt: "me",
    jan: {
        budgetedTechGeneratedLeadsAvgSale: 8581,
        budgetedMarketedLeadsAvgSale: 9507,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 41.9,
        budgetedMarketedClosingRate: 24.2,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    feb: {
        budgetedTechGeneratedLeadsAvgSale: 8581,
        budgetedMarketedLeadsAvgSale: 9507,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 41.9,
        budgetedMarketedClosingRate: 24.2,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    mar: {
        budgetedTechGeneratedLeadsAvgSale: 8581,
        budgetedMarketedLeadsAvgSale: 9507,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 41.9,
        budgetedMarketedClosingRate: 24.2,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    apr: {
        budgetedTechGeneratedLeadsAvgSale: 8650,
        budgetedMarketedLeadsAvgSale: 9650,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 45,
        budgetedMarketedClosingRate: 26,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    may: {
        budgetedTechGeneratedLeadsAvgSale: 8650,
        budgetedMarketedLeadsAvgSale: 9650,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 45,
        budgetedMarketedClosingRate: 26,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    jun: {
        budgetedTechGeneratedLeadsAvgSale: 8650,
        budgetedMarketedLeadsAvgSale: 9650,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 45,
        budgetedMarketedClosingRate: 26,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    jul: {
        budgetedTechGeneratedLeadsAvgSale: 8800,
        budgetedMarketedLeadsAvgSale: 9800,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 47,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    aug: {
        budgetedTechGeneratedLeadsAvgSale: 8800,
        budgetedMarketedLeadsAvgSale: 9800,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 47,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    sep: {
        budgetedTechGeneratedLeadsAvgSale: 8800,
        budgetedMarketedLeadsAvgSale: 9800,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 47,
        budgetedMarketedClosingRate: 28,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    oct: {
        budgetedTechGeneratedLeadsAvgSale: 9000,
        budgetedMarketedLeadsAvgSale: 10000,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 50,
        budgetedMarketedClosingRate: 30,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    nov: {
        budgetedTechGeneratedLeadsAvgSale: 9000,
        budgetedMarketedLeadsAvgSale: 10000,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 50,
        budgetedMarketedClosingRate: 30,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    dec: {
        budgetedTechGeneratedLeadsAvgSale: 9000,
        budgetedMarketedLeadsAvgSale: 10000,
        budgetedOtherLeadsAvgSale: 0,
        budgetTechGeneratedClosingRate: 50,
        budgetedMarketedClosingRate: 30,
        budgetedOtherLeadsClosingRate: 0,
        systemCheckBudgetYearConvRate: null,
        systemCheckBudgetYearAvgSale: null,
        demandCallBudgetYearConvRate: null,
        demandCallBudgetYearAvgSale: null,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
};
const maxCapacity = {
    id: null,
    memberId: null,
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentId: null,
    departmentName: null,
    useOpportunity: false,
    maxCapacityTotals: null,
    fiscalYear: 2022,
    jan: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 61.40951853856562,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    feb: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 59.913535826792966,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    mar: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 64.79229127537214,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    apr: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 39.83881405615697,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    may: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 76.90759537753722,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    jun: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 104.61126783491204,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    jul: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 93.78514462731397,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    aug: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 87.6645687032123,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    sep: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 63.64072339216511,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    oct: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 55.02821394665782,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    nov: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 79.76209436338006,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    dec: {
        budgetYearPercOfSystemCheckOpportunity: null,
        budgetYearPercOfDemandCallsOpportunity: null,
        budgetYearPercOfSystemCheckTurnover: null,
        budgetYearPercOfDemandCallsTurnover: null,
        numOfSystemTurnovers: null,
        numOfDemandTurnovers: null,
        monthlyTurnovers: 81.83851441435056,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
};
exports.workshopTestDataForCC = {
    serviceAgreementsMain: {
        areAgreementsFromOtherDeptsUsed: null,
        areAgreementsRecognizedWhenVisitsRan: null,
    },
    serviceAgreements: {
        jan: null,
        feb: null,
        mar: null,
        apr: null,
        may: null,
        jun: null,
        jul: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
    },
    conversionAndAverageSale: convAvgSale,
    maxCapacity,
    callCount: objToSave,
};
exports.preWorkTestDataForCC = {
    departmentName: "",
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    nonMonthlyData: null,
    serviceAgreementMonthly: null,
    serviceAgreementYearly: null,
    departmentId: "01G52ECDCT0GNV1ZV41N7FG1HN",
    departmentType: utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT,
    fiscalYear: 2023,
    id: "01G52ECDCT0GNV1ZV41N7FG1HN#2023",
    memberId: "MEMBER#226",
    monthlyData: {
        apr: {
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 2,
            numOfJobsPerCrewPerDay: 0.5952380952380952,
            numOfLeadsPerSalesmanPerDay: 3.7142857142857144,
            numOfMarketedLeadsRan: 35,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 1,
            numOfSoldJobs: 25,
            numOfTechGeneratedLeadsRan: 43,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
        },
        aug: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 3,
            numOfJobsPerCrewPerDay: 0.5238095238095238,
            numOfLeadsPerSalesmanPerDay: 3.857142857142857,
            numOfMarketedLeadsRan: 28,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 1,
            numOfSoldJobs: 33,
            numOfTechGeneratedLeadsRan: 53,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        dec: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 2.3,
            numOfJobsPerCrewPerDay: 0.6625258799171844,
            numOfLeadsPerSalesmanPerDay: 1.9047619047619047,
            numOfMarketedLeadsRan: 22,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 2,
            numOfSoldJobs: 32,
            numOfTechGeneratedLeadsRan: 58,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        feb: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 2,
            numOfJobsPerCrewPerDay: 0.42857142857142855,
            numOfLeadsPerSalesmanPerDay: 2.5714285714285716,
            numOfMarketedLeadsRan: 24,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 1,
            numOfSoldJobs: 18,
            numOfTechGeneratedLeadsRan: 30,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        jan: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 2,
            numOfJobsPerCrewPerDay: 0.30952380952380953,
            numOfLeadsPerSalesmanPerDay: 2.5714285714285716,
            numOfMarketedLeadsRan: 20,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 1,
            numOfSoldJobs: 13,
            numOfTechGeneratedLeadsRan: 34,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        jul: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 3,
            numOfJobsPerCrewPerDay: 0.5396825396825398,
            numOfLeadsPerSalesmanPerDay: 2.3095238095238093,
            numOfMarketedLeadsRan: 44,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 2,
            numOfSoldJobs: 34,
            numOfTechGeneratedLeadsRan: 53,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        jun: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 3,
            numOfJobsPerCrewPerDay: 0.5396825396825398,
            numOfLeadsPerSalesmanPerDay: 2.0238095238095237,
            numOfMarketedLeadsRan: 36,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 2,
            numOfSoldJobs: 34,
            numOfTechGeneratedLeadsRan: 49,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        mar: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 2,
            numOfJobsPerCrewPerDay: 0.42857142857142855,
            numOfLeadsPerSalesmanPerDay: 3.0476190476190474,
            numOfMarketedLeadsRan: 31,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 1,
            numOfSoldJobs: 18,
            numOfTechGeneratedLeadsRan: 33,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        may: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 3,
            numOfJobsPerCrewPerDay: 0.052380952380952375,
            numOfLeadsPerSalesmanPerDay: 2.261904761904762,
            numOfMarketedLeadsRan: 34,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 2,
            numOfSoldJobs: 3.3,
            numOfTechGeneratedLeadsRan: 61,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        nov: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 2.3,
            numOfJobsPerCrewPerDay: 0.45548654244306425,
            numOfLeadsPerSalesmanPerDay: 1.6666666666666667,
            numOfMarketedLeadsRan: 24,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 2,
            numOfSoldJobs: 22,
            numOfTechGeneratedLeadsRan: 46,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        oct: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 2,
            numOfJobsPerCrewPerDay: 0.5238095238095238,
            numOfLeadsPerSalesmanPerDay: 3.3333333333333335,
            numOfMarketedLeadsRan: 24,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 1,
            numOfSoldJobs: 22,
            numOfTechGeneratedLeadsRan: 46,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
        sep: {
            // numOfAllTechsRunningDemandSystemCheckCalls: null,
            avgDemandCallCount: null,
            avgDemandSystemCheckCallCount: null,
            avgInstallCallCount: null,
            avgSystemCheckCallCount: null,
            callsPerTechPerDay: 0,
            demandCallCountSeasonalityPercentage: null,
            demandCallCountSeasonalityPercentageOverride: null,
            demandCalls: {
                yearMinusOne: null,
            },
            demandCallsAverageSale: null,
            demandCallsConversionRate: null,
            demandOpportunityCalls: null,
            demandSystemCheckCalls: {
                yearMinusOne: null,
            },
            demandSystemCheckCallsAverageSale: null,
            demandSystemCheckCallsConversionRate: null,
            demandSystemCheckOpportunityCalls: null,
            installCallCountSeasonalityPercentage: null,
            installCallCountSeasonalityPercentageOverride: null,
            installJobs: {
                yearMinusOne: null,
            },
            laborPercentage: null,
            marketedLeadsAverageSale: null,
            marketedLeadsConversionRate: null,
            materialPercentage: null,
            numOfAllTechsRunningCalls: null,
            numOfInstallCrews: 3,
            numOfJobsPerCrewPerDay: 0.47619047619047616,
            numOfLeadsPerSalesmanPerDay: 4.0476190476190474,
            numOfMarketedLeadsRan: 32,
            numOfOtherLeadsRan: 0,
            numOfSalesman: 1,
            numOfSoldJobs: 30,
            numOfTechGeneratedLeadsRan: 53,
            otherLeadsAverageSale: null,
            otherLeadsConversionRate: null,
            otherPercentage: null,
            salesExpensePercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentageOverride: null,
            systemCheckCalls: {
                yearMinusOne: null,
            },
            systemCheckCallsAverageSale: null,
            systemCheckCallsConversionRate: null,
            systemOpportunityCalls: null,
            techGeneratedLeadsAverageSale: null,
            techGeneratedLeadsConversionRate: null,
            totalNumOfInstallLeadsRan: null,
        },
    },
};
exports.ccInputData = {
    callCount: {
        apr: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 7.5,
            // "monthlyTotalRevenueCalc": 44242.081765899864,
            targetNumOfCallsRanPerTech: 2.34,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.34,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        aug: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 8.5,
            // "monthlyTotalRevenueCalc": 100981.58455817596,
            targetNumOfCallsRanPerTech: 2.11,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.11,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        dec: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 8.5,
            // "monthlyTotalRevenueCalc": 83235.64641136039,
            targetNumOfCallsRanPerTech: 2.65,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.65,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        departmentId: "01G42ZYM2122HNGSRFW9DRD5J8",
        departmentName: "HVAC Service",
        departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
        departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
        feb: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 6.5,
            // "monthlyTotalRevenueCalc": 73901.60653856563,
            targetNumOfCallsRanPerTech: 2.3,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.3,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        fiscalYear: 2022,
        id: "01G42ZYM2122HNGSRFW9DRD5J8#2022",
        jan: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 6.5,
            // "monthlyTotalRevenueCalc": 67590.17511231393,
            targetNumOfCallsRanPerTech: 2.3,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.3,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        jul: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 8.5,
            // "monthlyTotalRevenueCalc": 90110.28846799319,
            targetNumOfCallsRanPerTech: 2.33,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.33,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        jun: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 8.5,
            // "monthlyTotalRevenueCalc": 128409.13179296348,
            targetNumOfCallsRanPerTech: 2.87,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.87,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        mar: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 6.5,
            // "monthlyTotalRevenueCalc": 61782.78828903925,
            targetNumOfCallsRanPerTech: 2.29,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.29,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        may: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 8.5,
            // "monthlyTotalRevenueCalc": 95119.84597428958,
            targetNumOfCallsRanPerTech: 2.5,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.5,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        memberId: "MEMBER#226",
        nov: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 8.5,
            // "monthlyTotalRevenueCalc": 89630.57350469037,
            targetNumOfCallsRanPerTech: 2.85,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.85,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        oct: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 8.5,
            // "monthlyTotalRevenueCalc": 50119.994928576954,
            targetNumOfCallsRanPerTech: 2.63,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.63,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
        sep: {
            budgetedNumOfCrews: null,
            budgetedNumOfSalespersons: null,
            budgetedNumOfTechs: 8.5,
            // "monthlyTotalRevenueCalc": 65811.15083671358,
            targetNumOfCallsRanPerTech: 2.47,
            targetNumOfJobsPerCrew: null,
            targetNumOfLeadsRanPerSalesperson: null,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.47,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
            monthlyTotalRevenueCalc: null,
        },
    },
    conversionAndAverageSale: {
        jan: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 508,
            demandCallBudgetYearConvRate: 56,
            systemCheckBudgetYearAvgSale: 392,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 56,
            demandSystemCheckCallBudgetYearAvgSale: 508,
        },
        feb: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 508,
            demandCallBudgetYearConvRate: 56,
            systemCheckBudgetYearAvgSale: 392,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 56,
            demandSystemCheckCallBudgetYearAvgSale: 508,
        },
        mar: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 508,
            demandCallBudgetYearConvRate: 56,
            systemCheckBudgetYearAvgSale: 392,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 56,
            demandSystemCheckCallBudgetYearAvgSale: 508,
        },
        apr: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 525,
            demandCallBudgetYearConvRate: 58,
            systemCheckBudgetYearAvgSale: 415,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 58,
            demandSystemCheckCallBudgetYearAvgSale: 525,
        },
        may: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 525,
            demandCallBudgetYearConvRate: 58,
            systemCheckBudgetYearAvgSale: 415,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 58,
            demandSystemCheckCallBudgetYearAvgSale: 525,
        },
        jun: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 525,
            demandCallBudgetYearConvRate: 58,
            systemCheckBudgetYearAvgSale: 415,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 58,
            demandSystemCheckCallBudgetYearAvgSale: 525,
        },
        jul: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 550,
            demandCallBudgetYearConvRate: 59,
            systemCheckBudgetYearAvgSale: 425,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 59,
            demandSystemCheckCallBudgetYearAvgSale: 550,
        },
        aug: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 550,
            demandCallBudgetYearConvRate: 59,
            systemCheckBudgetYearAvgSale: 425,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 59,
            demandSystemCheckCallBudgetYearAvgSale: 550,
        },
        sep: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 550,
            demandCallBudgetYearConvRate: 59,
            systemCheckBudgetYearAvgSale: 425,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 59,
            demandSystemCheckCallBudgetYearAvgSale: 550,
        },
        oct: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 560,
            demandCallBudgetYearConvRate: 60,
            systemCheckBudgetYearAvgSale: 435,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 60,
            demandSystemCheckCallBudgetYearAvgSale: 560,
        },
        nov: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 560,
            demandCallBudgetYearConvRate: 60,
            systemCheckBudgetYearAvgSale: 435,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 60,
            demandSystemCheckCallBudgetYearAvgSale: 560,
        },
        dec: {
            budgetedMarketedClosingRate: null,
            budgetedMarketedLeadsAvgSale: null,
            budgetedOtherLeadsAvgSale: null,
            budgetedOtherLeadsClosingRate: null,
            budgetedTechGeneratedLeadsAvgSale: null,
            budgetTechGeneratedClosingRate: null,
            demandCallBudgetYearAvgSale: 575,
            demandCallBudgetYearConvRate: 56,
            systemCheckBudgetYearAvgSale: 450,
            systemCheckBudgetYearConvRate: 25,
            demandSystemCheckCallBudgetYearConvRate: 56,
            demandSystemCheckCallBudgetYearAvgSale: 575,
        },
        id: "",
        memberId: "",
        fiscalYear: 0,
        departmentId: "",
        departmentName: "",
        departmentType: utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT,
        departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    },
    departmentId: "01G42ZYM2122HNGSRFW9DRD5J8",
    fiscalYear: 2022,
    id: "01G42ZYM2122HNGSRFW9DRD5J8#2022",
    memberId: "MEMBER#226",
    serviceAgreements: {
        jan: {
            retainedAgreementsVisits: 116.48606224627876,
            totalRevenueMonthlyRecognition: 17274.715396853455,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 9610.100135317998,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 14979.016964025795,
            totalVisitIf6MonthFromSale: 116.48606224627876,
            totalVisitsBasedOnTimeOfSaleCalculation: 116.48606224627876,
        },
        feb: {
            retainedAgreementsVisits: 59.834370771312585,
            totalRevenueMonthlyRecognition: 18346.617465087504,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 4936.335588633288,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 10145.779640250754,
            totalVisitIf6MonthFromSale: 59.834370771312585,
            totalVisitsBasedOnTimeOfSaleCalculation: 59.834370771312585,
        },
        mar: {
            retainedAgreementsVisits: 190.96075778078486,
            totalRevenueMonthlyRecognition: 19305.495845854693,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 15754.26251691475,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 20414.4114474433,
            totalVisitIf6MonthFromSale: 190.96075778078486,
            totalVisitsBasedOnTimeOfSaleCalculation: 190.96075778078486,
        },
        apr: {
            retainedAgreementsVisits: 338.6370771312585,
            totalRevenueMonthlyRecognition: 20289.89570443698,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 27937.558863328824,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 32721.74217603874,
            totalVisitIf6MonthFromSale: 338.6370771312585,
            totalVisitsBasedOnTimeOfSaleCalculation: 338.6370771312585,
        },
        may: {
            retainedAgreementsVisits: 203.0549391069012,
            totalRevenueMonthlyRecognition: 22214.944316775676,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 16752.03247631935,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 26107.76873228541,
            totalVisitIf6MonthFromSale: 203.0549391069012,
            totalVisitsBasedOnTimeOfSaleCalculation: 203.0549391069012,
        },
        jun: {
            retainedAgreementsVisits: 174.41082543978348,
            totalRevenueMonthlyRecognition: 24526.461021742973,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 14388.893098782137,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 25622.864284923202,
            totalVisitIf6MonthFromSale: 174.41082543978348,
            totalVisitsBasedOnTimeOfSaleCalculation: 174.41082543978348,
        },
        jul: {
            retainedAgreementsVisits: 119.0322056833559,
            totalRevenueMonthlyRecognition: 27082.254728654763,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 14524.597142682991,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 26945.754558274297,
            totalVisitIf6MonthFromSale: 176.055722941612,
            totalVisitsBasedOnTimeOfSaleCalculation: 176.055722941612,
        },
        aug: {
            retainedAgreementsVisits: 101.84573748308526,
            totalRevenueMonthlyRecognition: 29087.513699840903,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 12427.462795878495,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 22173.02139584314,
            totalVisitIf6MonthFromSale: 150.63591267731508,
            totalVisitsBasedOnTimeOfSaleCalculation: 150.63591267731508,
        },
        sep: {
            retainedAgreementsVisits: 239.33748308525034,
            totalRevenueMonthlyRecognition: 30965.165281951562,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 29204.53757031446,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 38329.92425937227,
            totalVisitIf6MonthFromSale: 353.9943947916904,
            totalVisitsBasedOnTimeOfSaleCalculation: 353.9943947916904,
        },
        oct: {
            retainedAgreementsVisits: 320.1775372124493,
            totalRevenueMonthlyRecognition: 32941.25684992045,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 39068.83616454302,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 48672.64118487181,
            totalVisitIf6MonthFromSale: 473.5616504793093,
            totalVisitsBasedOnTimeOfSaleCalculation: 473.5616504793093,
        },
        nov: {
            retainedAgreementsVisits: 241.88362652232746,
            totalRevenueMonthlyRecognition: 35088.706911790694,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 29515.224140211423,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 39951.83144090083,
            totalVisitIf6MonthFromSale: 357.7602926086233,
            totalVisitsBasedOnTimeOfSaleCalculation: 357.7602926086233,
        },
        dec: {
            retainedAgreementsVisits: 246.33937753721247,
            totalRevenueMonthlyRecognition: 36795,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 30058.92563753111,
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 38351.5100462283,
            totalVisitIf6MonthFromSale: 364.3506137882558,
            totalVisitsBasedOnTimeOfSaleCalculation: 364.3506137882558,
        },
    },
    serviceAgreementsMain: {
        areAgreementsFromOtherDeptsUsed: null,
        areAgreementsRecognizedWhenVisitsRan: null,
        visitsAtTimeOfSale: false,
    },
};
const preWorkForCC = {
    departmentId: "01G42ZYM2122HNGSRFW9DRD5J8",
    departmentName: "HVAC Service",
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    fiscalYear: 2022,
    id: "",
    memberId: "MEMBER#226",
    monthlyData: {
        apr: {
            avgDemandCallCount: 270,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 532,
            callsPerTechPerDay: 3.0595238095238093,
            demandCallCountSeasonalityPercentage: 0.04772847799186848,
            demandCalls: {
                yearMinusFour: 30,
                yearMinusOne: 128,
                yearMinusThree: 49,
                yearMinusTwo: 63,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 30,
                yearMinusOne: 128,
                yearMinusThree: 49,
                yearMinusTwo: 63,
            },
            demandOpportunityCalls: 45,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 42.7,
            materialPercentage: 17.8,
            numOfAllTechsRunningCalls: 4,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.14397834912043303,
            systemCheckCalls: {
                yearMinusFour: 96,
                yearMinusOne: 129,
                yearMinusThree: 120,
                yearMinusTwo: 187,
            },
            systemOpportunityCalls: 39,
            totalNumOfInstallLeadsRan: 0,
        },
        aug: {
            avgDemandCallCount: 550,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 160,
            callsPerTechPerDay: 2.196620583717358,
            demandCallCountSeasonalityPercentage: 0.0972246773908432,
            demandCalls: {
                yearMinusFour: 99,
                yearMinusOne: 212,
                yearMinusThree: 103,
                yearMinusTwo: 136,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 99,
                yearMinusOne: 212,
                yearMinusThree: 103,
                yearMinusTwo: 136,
            },
            demandOpportunityCalls: 50,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 42.1,
            materialPercentage: 16.4,
            numOfAllTechsRunningCalls: 6.2,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.04330175913396482,
            systemCheckCalls: {
                yearMinusFour: 20,
                yearMinusOne: 74,
                yearMinusThree: 16,
                yearMinusTwo: 50,
            },
            systemOpportunityCalls: 38,
            totalNumOfInstallLeadsRan: 0,
        },
        dec: {
            avgDemandCallCount: 468,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 387,
            callsPerTechPerDay: 3.2010582010582005,
            demandCallCountSeasonalityPercentage: 0.08272936185257203,
            demandCalls: {
                yearMinusFour: 74,
                yearMinusOne: 137,
                yearMinusThree: 105,
                yearMinusTwo: 152,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 74,
                yearMinusOne: 137,
                yearMinusThree: 105,
                yearMinusTwo: 152,
            },
            demandOpportunityCalls: 47,
            fiscalYear: 2022,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 0,
            materialPercentage: 0,
            numOfAllTechsRunningCalls: 5.4,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.1047361299052774,
            systemCheckCalls: {
                yearMinusFour: 40,
                yearMinusOne: 226,
                yearMinusThree: 62,
                yearMinusTwo: 59,
            },
            systemOpportunityCalls: 47,
            totalNumOfInstallLeadsRan: 0,
        },
        feb: {
            avgDemandCallCount: 294,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 94,
            callsPerTechPerDay: 1.9404761904761905,
            demandCallCountSeasonalityPercentage: 0.05197100936892346,
            demandCalls: {
                yearMinusFour: 51,
                yearMinusOne: 137,
                yearMinusThree: 39,
                yearMinusTwo: 67,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 51,
                yearMinusOne: 137,
                yearMinusThree: 39,
                yearMinusTwo: 67,
            },
            demandOpportunityCalls: 48,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 39.8,
            materialPercentage: 15.7,
            numOfAllTechsRunningCalls: 4,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.02543978349120433,
            systemCheckCalls: {
                yearMinusFour: 23,
                yearMinusOne: 26,
                yearMinusThree: 20,
                yearMinusTwo: 25,
            },
            systemOpportunityCalls: 46,
            totalNumOfInstallLeadsRan: 0,
        },
        jan: {
            avgDemandCallCount: 303,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 183,
            callsPerTechPerDay: 1.3095238095238095,
            demandCallCountSeasonalityPercentage: 0.053561958635319075,
            demandCalls: {
                yearMinusFour: 95,
                yearMinusOne: 11,
                yearMinusThree: 105,
                yearMinusTwo: 92,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 95,
                yearMinusOne: 11,
                yearMinusThree: 105,
                yearMinusTwo: 92,
            },
            demandOpportunityCalls: 54,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 50.4,
            materialPercentage: 24.2,
            numOfAllTechsRunningCalls: 4,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.04952638700947226,
            systemCheckCalls: {
                yearMinusFour: 4,
                yearMinusOne: 99,
                yearMinusThree: 48,
                yearMinusTwo: 32,
            },
            systemOpportunityCalls: 39,
            totalNumOfInstallLeadsRan: 0,
        },
        jul: {
            avgDemandCallCount: 303,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 183,
            callsPerTechPerDay: 1.3095238095238095,
            demandCallCountSeasonalityPercentage: 0.053561958635319075,
            demandCalls: {
                yearMinusFour: 95,
                yearMinusOne: 11,
                yearMinusThree: 105,
                yearMinusTwo: 92,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 95,
                yearMinusOne: 11,
                yearMinusThree: 105,
                yearMinusTwo: 92,
            },
            demandOpportunityCalls: 54,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 50.4,
            materialPercentage: 24.2,
            numOfAllTechsRunningCalls: 4,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.04952638700947226,
            systemCheckCalls: {
                yearMinusFour: 4,
                yearMinusOne: 99,
                yearMinusThree: 48,
                yearMinusTwo: 32,
            },
            systemOpportunityCalls: 39,
            totalNumOfInstallLeadsRan: 0,
        },
        jun: {
            avgDemandCallCount: 303,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 183,
            callsPerTechPerDay: 1.3095238095238095,
            demandCallCountSeasonalityPercentage: 0.053561958635319075,
            demandCalls: {
                yearMinusFour: 95,
                yearMinusOne: 11,
                yearMinusThree: 105,
                yearMinusTwo: 92,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 95,
                yearMinusOne: 11,
                yearMinusThree: 105,
                yearMinusTwo: 92,
            },
            demandOpportunityCalls: 54,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 50.4,
            materialPercentage: 24.2,
            numOfAllTechsRunningCalls: 4,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.04952638700947226,
            systemCheckCalls: {
                yearMinusFour: 4,
                yearMinusOne: 99,
                yearMinusThree: 48,
                yearMinusTwo: 32,
            },
            systemOpportunityCalls: 39,
            totalNumOfInstallLeadsRan: 0,
        },
        mar: {
            avgDemandCallCount: 263,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 300,
            callsPerTechPerDay: 2.507936507936508,
            demandCallCountSeasonalityPercentage: 0.04649107300689411,
            demandCalls: {
                yearMinusFour: 56,
                yearMinusOne: 102,
                yearMinusThree: 43,
                yearMinusTwo: 62,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 56,
                yearMinusOne: 102,
                yearMinusThree: 43,
                yearMinusTwo: 62,
            },
            demandOpportunityCalls: 55,
            fiscalYear: 2022,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 51.7,
            materialPercentage: 28.2,
            numOfAllTechsRunningCalls: 3,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.08119079837618404,
            systemCheckCalls: {
                yearMinusFour: 29,
                yearMinusOne: 56,
                yearMinusThree: 77,
                yearMinusTwo: 138,
            },
            systemOpportunityCalls: 50,
            totalNumOfInstallLeadsRan: 0,
        },
        may: {
            avgDemandCallCount: 528,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 319,
            callsPerTechPerDay: 2.5476190476190474,
            demandCallCountSeasonalityPercentage: 0.09333569029520948,
            demandCalls: {
                yearMinusFour: 117,
                yearMinusOne: 151,
                yearMinusThree: 127,
                yearMinusTwo: 133,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 117,
                yearMinusOne: 151,
                yearMinusThree: 127,
                yearMinusTwo: 133,
            },
            demandOpportunityCalls: 48,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 53.1,
            materialPercentage: 26.9,
            numOfAllTechsRunningCalls: 6,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.08633288227334235,
            systemCheckCalls: {
                yearMinusFour: 38,
                yearMinusOne: 170,
                yearMinusThree: 41,
                yearMinusTwo: 70,
            },
            systemOpportunityCalls: 39,
            totalNumOfInstallLeadsRan: 0,
        },
        nov: {
            avgDemandCallCount: 589,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 380,
            callsPerTechPerDay: 3.171608265947889,
            demandCallCountSeasonalityPercentage: 0.10411879087855754,
            demandCalls: {
                yearMinusFour: 89,
                yearMinusOne: 204,
                yearMinusThree: 117,
                yearMinusTwo: 179,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 89,
                yearMinusOne: 204,
                yearMinusThree: 117,
                yearMinusTwo: 179,
            },
            demandOpportunityCalls: 47,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 61.2,
            materialPercentage: 18,
            numOfAllTechsRunningCalls: 5.3,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.10284167794316644,
            systemCheckCalls: {
                yearMinusFour: 51,
                yearMinusOne: 149,
                yearMinusThree: 115,
                yearMinusTwo: 65,
            },
            systemOpportunityCalls: 49,
            totalNumOfInstallLeadsRan: 0,
        },
        oct: {
            avgDemandCallCount: 542,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 503,
            callsPerTechPerDay: 2.649769585253456,
            demandCallCountSeasonalityPercentage: 0.09581050026515821,
            demandCalls: {
                yearMinusFour: 91,
                yearMinusOne: 172,
                yearMinusThree: 96,
                yearMinusTwo: 183,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 91,
                yearMinusOne: 172,
                yearMinusThree: 96,
                yearMinusTwo: 183,
            },
            demandOpportunityCalls: 46,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 80.3,
            materialPercentage: 17.6,
            numOfAllTechsRunningCalls: 6.2,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.1361299052774019,
            systemCheckCalls: {
                yearMinusFour: 82,
                yearMinusOne: 173,
                yearMinusThree: 183,
                yearMinusTwo: 65,
            },
            systemOpportunityCalls: 46,
            totalNumOfInstallLeadsRan: 0,
        },
        sep: {
            avgDemandCallCount: 515,
            avgInstallCallCount: 0,
            avgSystemCheckCallCount: 376,
            callsPerTechPerDay: 2.4577572964669736,
            demandCallCountSeasonalityPercentage: 0.09103765246597137,
            demandCalls: {
                yearMinusFour: 56,
                yearMinusOne: 232,
                yearMinusThree: 89,
                yearMinusTwo: 138,
            },
            demandSystemCheckCalls: {
                yearMinusFour: 56,
                yearMinusOne: 232,
                yearMinusThree: 89,
                yearMinusTwo: 138,
            },
            demandOpportunityCalls: 44,
            installCallCountSeasonalityPercentage: 0,
            installJobs: {
                yearMinusFour: null,
                yearMinusOne: null,
                yearMinusThree: null,
                yearMinusTwo: null,
            },
            laborPercentage: 44.9,
            materialPercentage: 13.3,
            numOfAllTechsRunningCalls: 6.2,
            numOfInstallCrews: null,
            numOfJobsPerCrewPerDay: 0,
            numOfLeadsPerSalesmanPerDay: 0,
            numOfMarketedLeadsRan: null,
            numOfOtherLeadsRan: null,
            numOfSalesman: null,
            numOfSoldJobs: null,
            numOfTechGeneratedLeadsRan: null,
            otherPercentage: 0,
            salesExpensePercentage: 0,
            systemCheckCallCountSeasonalityPercentage: 0.10175913396481732,
            systemCheckCalls: {
                yearMinusFour: 32,
                yearMinusOne: 88,
                yearMinusThree: 149,
                yearMinusTwo: 107,
            },
            systemOpportunityCalls: 49,
            totalNumOfInstallLeadsRan: 0,
        },
    },
    nonMonthlyData: {
        installAvgCall: {
            marketAvgSaleLast3mo: null,
            marketAvgSaleLastYear: null,
            marketConvRateLast3mo: null,
            marketConvRateLastYear: null,
            otherAvgRateLastYear: null,
            otherAvgSale: null,
            otherConvRateLast3mo: null,
            otherConvRateLastYear: null,
            techAvgSaleLast3mo: null,
            techAvgSaleLastYear: null,
            techConvRateLast3mo: null,
            techConvRateLastYear: null,
        },
        serviceAvgCall: {
            avgSale90Days: 4,
            avgSaleLastYear: 508,
            convRate90Days: 6,
            convRateLastYear: 55,
            oppCallsPerc: null,
            techOppCalls90Days: 23,
            techOppCallsLastYear: 48,
        },
        serviceSystemCheck: {
            agreementsRev: 126827,
            agreementsSold: 406,
            avgSale90Days: 354,
            avgSaleLastYear: 392,
            convRate90Days: 24,
            convRateLastYear: 25,
            runRateVisits: 76,
            techOppCalls90Days: 12,
            techOppCallsLastYear: 25,
        },
    },
    serviceAgreementMonthly: {
        projectedEnd: 3,
        projectedNew: null,
        projectedVisits: 7,
        runRate: 2.33,
        started: null,
        visits: 1,
    },
    serviceAgreementYearly: {
        projectedEnd: null,
        projectedNew: null,
        projectedVisits: null,
        runRate: 0,
        started: null,
        visits: null,
    },
};
const companyTestData = {
    PK: "MEMBER#226",
    SK: "MANAGE#COMPANY#2022#01G42ZVJD5SR9BFQH47VZDTKTE",
    createdAt: "2022-05-27T14:48:47.269Z",
    fiscalYear: "2022",
    fiscalYearEnd: "December",
    nonWorkingDays: [
        {
            date: "2022-05-30",
            label: "Memorial Day",
        },
        {
            date: "2022-07-04T00:00:00.000-05:00",
            label: "July 4th",
        },
        {
            date: "2022-09-05",
            label: "Labor Day",
        },
        {
            date: "2022-11-24",
            label: "Thanksgiving Day",
        },
        {
            date: "2022-12-25",
            label: "Christmas Day",
        },
    ],
    updatedAt: "2022-06-13T14:39:08.147Z",
    updatedBy: "123#Default User#226",
    weeklyWorkdays: {
        fri: true,
        mon: true,
        sat: false,
        sun: false,
        thu: true,
        tue: true,
        wed: true,
    },
};
exports.ccDataForTest = {
    ccData: exports.ccInputData,
    companyData: companyTestData,
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    preWork: preWorkForCC,
};
//
// "departmentId": "01G42ZYM2122HNGSRFW9DRD5J8",
//   "departmentName": "HVAC Service",
//   "departmentTrade": DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
//   "departmentType": DepartmentTypeEnum.SERVICE_DEPARTMENT,
// "fiscalYear": 2022,
exports.callCountDynamoObj = {
    //@ts-ignore
    LSI1SK: "WORKSHOP#01G42ZYM2122HNGSRFW9DRD5J8#2022#LATEST",
    LSI2SK: "WORKSHOP#2022#CALL_COUNT#LATEST",
    PK: "MEMBER#226",
    SK: "01G42ZYM2122HNGSRFW9DRD5J8#2022#CALL_COUNT#LATEST",
    apr: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 7.5,
        monthlyTotalRevenueCalc: 44242.081765899864,
        targetNumOfCallsRanPerTech: 2.34,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.34,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    aug: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 8.5,
        monthlyTotalRevenueCalc: 100981.58455817596,
        targetNumOfCallsRanPerTech: 2.11,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.11,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    callCountTotals: {
        totalConvertedMarketedLeadCount: null,
        totalConvertedOtherLeadCount: null,
        totalConvertedTechLeadCount: null,
        totalDemandConvertedCalls: 1195.004617463255,
        totalDemandRevenue: 636927.0455651351,
        totalMarketedLeadRevenue: null,
        totalOtherLeadRevenue: null,
        totalSumBudgetedSystemCheckCallCount: 2959.7426197631253,
        totalSumBudgetedTechGeneratedLeads: null,
        totalSumMarketedLeadCount: null,
        totalSumOtherLeadCount: null,
        totalSumPriorYearAgreementSystemChecks: 1388,
        totalSumPriorYearDemandCallCount: 1508,
        totalSumPriorYearMarketedLeadCount: null,
        totalSumPriorYearOtherLeadCount: null,
        totalSumPriorYearTechGeneratedLeads: null,
        totalSumRequiredDemandCallCount: 2070.2823802368744,
        totalSystemCheckConvertedCalls: 739.9356549407813,
        totalSystemCheckRevenue: 314007.822615447,
        totalTechLeadRevenue: null,
        totalSumPriorYearDemandSystemCheckCallCount: 1508,
        totalSumRequiredDemandSystemCheckCallCount: 2070.2823802368744,
        totalDemandSystemCheckConvertedCalls: 1195.004617463255,
        totalDemandSystemCheckRevenue: 636927.0455651351,
    },
    december: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 8.5,
        monthlyTotalRevenueCalc: 83235.64641136039,
        targetNumOfCallsRanPerTech: 2.65,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.65,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    departmentId: "01G42ZYM2122HNGSRFW9DRD5J8",
    departmentName: "HVAC Service",
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    feb: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 6.5,
        monthlyTotalRevenueCalc: 73901.60653856563,
        targetNumOfCallsRanPerTech: 2.3,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.3,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    fiscalYear: 2022,
    jan: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 6.5,
        monthlyTotalRevenueCalc: 67590.17511231393,
        targetNumOfCallsRanPerTech: 2.3,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.3,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    jul: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 8.5,
        monthlyTotalRevenueCalc: 90110.28846799319,
        targetNumOfCallsRanPerTech: 2.33,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.33,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    jun: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 8.5,
        monthlyTotalRevenueCalc: 128409.13179296348,
        targetNumOfCallsRanPerTech: 2.87,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.87,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    mar: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 6.5,
        monthlyTotalRevenueCalc: 61782.78828903925,
        targetNumOfCallsRanPerTech: 2.29,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.29,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    may: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 8.5,
        monthlyTotalRevenueCalc: 95119.84597428958,
        targetNumOfCallsRanPerTech: 2.5,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.5,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    nov: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 8.5,
        monthlyTotalRevenueCalc: 89630.57350469037,
        targetNumOfCallsRanPerTech: 2.85,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.85,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    oct: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 8.5,
        monthlyTotalRevenueCalc: 50119.994928576954,
        targetNumOfCallsRanPerTech: 2.63,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.63,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    sep: {
        budgetedNumOfCrews: null,
        budgetedNumOfSalespersons: null,
        budgetedNumOfTechs: 8.5,
        monthlyTotalRevenueCalc: 65811.15083671358,
        targetNumOfCallsRanPerTech: 2.47,
        targetNumOfJobsPerCrew: null,
        targetNumOfLeadsRanPerSalesperson: null,
        percentOfDemandCallsThatAreDemandSystemCheck: 2.47,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
};
