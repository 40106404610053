import SimpleAppHeader from "../../navigation/SimpleAppHeader";
import CardWrapper from "../../../utils/components/tailwind/CardWrapper";
import CardHeader from "../../../utils/components/tailwind/CardHeader";
import CardBody from "../../../utils/components/tailwind/CardBody";
import ErrorIcon from "../../../utils/components/icons/ErrorPageIcon";
import React from "react";
import InNewTabIcon from "../../../utils/components/icons/InNewTabIcon";

function UnsupportedBrowserLandingPage({ userBrowser }: { userBrowser: any }) {
  const browserIsAccepted = () => {
    switch (userBrowser.name) {
      case "Chrome":
        return true;
      case "Safari":
        return true;
      case "Firefox":
        return true;
      case "Edge":
        return true;
      default:
        return false;
    }
  };
  const browserUpdateLink = () => {
    let url = "";
    switch (userBrowser.name) {
      case "Chrome":
        url = "https://www.google.com/chrome/update/";
        break;
      case "Safari":
        url = "https://support.apple.com/en-us/102665";
        break;
      case "Firefox":
        url =
          "https://support.mozilla.org/en-US/kb/update-firefox-latest-release";
        break;
      case "Edge":
        url =
          "https://support.microsoft.com/en-us/topic/update-to-the-new-microsoft-edge-182d0668-e3f0-49da-b8bb-db5675245dc2";
        break;
      default:
        return null;
    }
    return (
      <div className="flex justify-center gap-2">
        <a style={{ cursor: "pointer" }} className="underline" href={url}>
          Update Version Here
        </a>
        <InNewTabIcon color="gray" />
      </div>
    );
  };

  return (
    <main className="bg-NexstarSoftGray flex-grow">
      <SimpleAppHeader />
      <div className="p-6">
        <CardWrapper id="unsupported_browser_card_wrapper">
          <CardHeader
            title="Unsupported Browser..."
            justify="justify-center"
            headingTextSize="text-3xl"
          />
          <CardBody padding="py-12 px-6 content-center">
            <div className="flex flex-col items-center">
              <div className="flex-row">
                <ErrorIcon color="NexstarBlue" size="large" />
              </div>
              <div className="flex-row">
                <p className="text-center text-2xl lg:w-[700px] md:w-[500px] mt-7">
                  {browserIsAccepted()
                    ? `You are using an unsupported version of ${userBrowser.name}.`
                    : "You are using an unsupported browser. Please try again using Chrome, FireFox, Safari or Edge."}
                </p>
                <div className="text-center text-2xl lg:w-[700px] md:w-[500px] mt-7">
                  {browserUpdateLink()}
                </div>
              </div>
            </div>
          </CardBody>
        </CardWrapper>
      </div>
    </main>
  );
}

export default UnsupportedBrowserLandingPage;
