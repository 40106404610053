"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.directCostsInit = exports.directCostsMonthlyInit = void 0;
exports.directCostsMonthlyInit = {
    demandCallSales: null,
    demandSystemCheckCallSales: null,
    systemCheckCallSales: null,
    // service
    demandAndSystemCheckSales: null,
    sppRevenue: null,
    // install
    grossIncome: null,
    // both
    // TOP Start
    subcontractorAmount: null,
    refundAmount: null,
    financeFeeAmount: null,
    totalIncome: null,
    // TOP End
    // MID Start
    budgetedLaborPerInput: null, // input
    budgetedLaborAmount: null,
    budgetedMaterialPerInput: null, // input
    budgetedMaterialAmount: null,
    budgetedSalesExpPercInput: null, // input
    budgetedSalesExpAmount: null,
    budgetedOtherDirectPercInput: null, // input
    budgetedOtherDirectAmount: null,
    totalDirectCost: null, // no save
    grossProfit: null,
    // MID End
    // both ^^^
    // Service
    soldHourPerMonthGoal: null,
    // soldHourPerDayGoal: null, // no save
    // install
    soldDayPerMonthInstallGoal: null,
    reductions: null,
    // soldDaysPerDayGoal: number; // no save
};
exports.directCostsInit = {
    directCostsYearly: {
        subcontractorPercInput: null, // input
        refundPercInput: null, // input
        financeFeePercInput: null, // input
        soldBreakEvenFromPPInput: null, // input
        profitPercOnMaterialsInput: null, // input
        soldProfitAndCommissionInput: null, // input
        desiredProfitPlusCommissionInput: null, // input
    },
    jan: exports.directCostsMonthlyInit,
    feb: exports.directCostsMonthlyInit,
    mar: exports.directCostsMonthlyInit,
    apr: exports.directCostsMonthlyInit,
    may: exports.directCostsMonthlyInit,
    jun: exports.directCostsMonthlyInit,
    jul: exports.directCostsMonthlyInit,
    aug: exports.directCostsMonthlyInit,
    sep: exports.directCostsMonthlyInit,
    oct: exports.directCostsMonthlyInit,
    nov: exports.directCostsMonthlyInit,
    dec: exports.directCostsMonthlyInit,
};
