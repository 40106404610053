import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function InformationIcon({
  size = "small",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m256 94.781c89.039 0 161.22 72.18 161.22 161.22s-72.18 161.22-161.22 161.22-161.22-72.18-161.22-161.22 72.18-161.22 161.22-161.22zm0 32.242c-71.23 0-128.98 57.746-128.98 128.98s57.746 128.98 128.98 128.98 128.98-57.746 128.98-128.98-57.746-128.98-128.98-128.98zm23.352 89.551-18.164 91.477c-0.41797 1.4805-0.68359 2.9609-0.79688 4.4023-0.12891 1.4531-0.19141 2.8633-0.19141 4.2773 0 3.7695 0.82422 6.3672 2.4688 7.8203 1.6562 1.4414 4.25 2.168 7.7773 2.168 2.3906 0 4.125-0.17578 5.1992-0.52734 1.0625-0.36328 2.0508-0.75391 2.9609-1.1836-0.32812 4.8164-1.3398 8.9648-3.0234 12.422-1.6953 3.4453-3.8203 6.3242-6.3633 8.6016-2.5547 2.2734-5.4375 3.9688-8.6523 5.0586-3.2109 1.0898-6.4648 1.6406-9.7539 1.6406-3.793 0-7.3359-0.61719-10.625-1.8281-3.2891-1.2266-6.1602-3.1641-8.5898-5.7891-2.4258-2.625-4.3359-5.9492-5.7422-9.9844-1.4023-4.0391-2.0977-8.8945-2.0977-14.59 0-2.4609 0.16406-5.1523 0.49219-8.0859 0.21875-1.957 0.51172-4.0195 0.875-6.1914l0.60547-3.3398 17.176-86.348zm-11.371-57.305c2.793 0 5.4141 0.56641 7.8398 1.707 2.4297 1.1445 4.582 2.707 6.4297 4.6719 1.8438 1.9648 3.3125 4.2383 4.3867 6.8359 1.0625 2.5859 1.6055 5.3555 1.6055 8.3438s-0.54297 5.7617-1.6055 8.3438c-1.0742 2.5859-2.543 4.8203-4.3867 6.7031-1.8477 1.8828-4 3.3789-6.4297 4.4688-2.4258 1.0898-5.0469 1.6406-7.8398 1.6406-2.7969 0-5.4141-0.55078-7.8438-1.6406-2.4297-1.0898-4.5547-2.5859-6.3633-4.4688-1.8086-1.8828-3.2266-4.1172-4.2617-6.7031-1.0234-2.582-1.543-5.3555-1.543-8.3438s0.51953-5.7578 1.543-8.3438c1.0352-2.5977 2.4531-4.8711 4.2617-6.8359 1.8086-1.9648 3.9336-3.5273 6.3633-4.6719 2.4297-1.1406 5.0469-1.707 7.8438-1.707z" />
    </svg>
  );
}
