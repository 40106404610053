"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateIncomeLossBeforeTaxes = exports.calculateOtherTotal = exports.calculateOverheadsTotal = exports.calculateGrossMarginTotal = exports.calculateTotalCostOfSalesTotal = exports.calculateNetSalesTotal = exports.calculateGrossMargin = exports.calculateTotalCostOfSales = exports.calculateNetSales = exports.getSum = void 0;
const getSum = (values) => {
    if (values) {
        return values.reduce((a, b) => a + b, 0);
    }
    else
        return 0;
};
exports.getSum = getSum;
const calculateNetSales = (data) => {
    var _a, _b;
    if (data) {
        return ((_a = data["grossRevenue"]) !== null && _a !== void 0 ? _a : 0) - ((_b = data["reductionSales"]) !== null && _b !== void 0 ? _b : 0);
    }
    else
        return 0;
};
exports.calculateNetSales = calculateNetSales;
const calculateTotalCostOfSales = (data) => {
    var _a, _b, _c, _d;
    if (data) {
        return (((_a = data["costSalesDirectMaterials"]) !== null && _a !== void 0 ? _a : 0) +
            ((_b = data["labor"]) !== null && _b !== void 0 ? _b : 0) +
            ((_c = data["salesExpenseCommissionAmount"]) !== null && _c !== void 0 ? _c : 0) +
            ((_d = data["otherDirectCost"]) !== null && _d !== void 0 ? _d : 0));
    }
    else
        return 0;
};
exports.calculateTotalCostOfSales = calculateTotalCostOfSales;
const calculateGrossMargin = (data) => {
    if (data) {
        return (0, exports.calculateNetSales)(data) - (0, exports.calculateTotalCostOfSales)(data);
    }
    else
        return 0;
};
exports.calculateGrossMargin = calculateGrossMargin;
const calculateNetSalesTotal = (data, year) => {
    const yearData = [];
    if (data) {
        Object.entries(data["tradeData"]).map(([deptTrade, deptTradeData]) => {
            Object.keys(deptTradeData).map((deptType) => {
                if (year in data["tradeData"][deptTrade][deptType]) {
                    yearData.push((0, exports.calculateNetSales)(data["tradeData"][deptTrade][deptType][year]));
                }
            });
        });
        return (0, exports.getSum)(yearData);
    }
    else
        return 0;
};
exports.calculateNetSalesTotal = calculateNetSalesTotal;
const calculateTotalCostOfSalesTotal = (data, year) => {
    const yearData = [];
    if (data) {
        Object.entries(data["tradeData"]).map(([deptTrade, deptTradeData]) => {
            Object.keys(deptTradeData).map((deptType) => {
                if (year in data["tradeData"][deptTrade][deptType]) {
                    yearData.push((0, exports.calculateTotalCostOfSales)(data["tradeData"][deptTrade][deptType][year]));
                }
            });
        });
        return (0, exports.getSum)(yearData);
    }
    else
        return 0;
};
exports.calculateTotalCostOfSalesTotal = calculateTotalCostOfSalesTotal;
const calculateGrossMarginTotal = (data, year) => {
    const yearData = [];
    if (data) {
        Object.entries(data["tradeData"]).map(([deptTrade, deptTradeData]) => {
            Object.keys(deptTradeData).map((deptType) => {
                if (year in data["tradeData"][deptTrade][deptType]) {
                    yearData.push((0, exports.calculateGrossMargin)(data["tradeData"][deptTrade][deptType][year]));
                }
            });
        });
        return (0, exports.getSum)(yearData);
    }
    else
        return 0;
};
exports.calculateGrossMarginTotal = calculateGrossMarginTotal;
const calculateOverheadsTotal = (data, year) => {
    const yearData = [];
    if (data) {
        if (year in data["overheads"]) {
            Object.entries(data["overheads"][year]).map(([key, value]) => {
                if (key !== "OFFICE_SALARY_BENEFITS" &&
                    key !== "OTHER" &&
                    key !== "VEHICLE" &&
                    key !== "DIRECT_LABOR" &&
                    key !== "MATERIALS") {
                    // only adding values from overhead subcategories and not from the parent overhead big 5 categories
                    yearData.push(value !== null && value !== void 0 ? value : 0);
                }
            });
            return (0, exports.getSum)(yearData);
        }
    }
    else
        return 0;
};
exports.calculateOverheadsTotal = calculateOverheadsTotal;
const calculateOtherTotal = (data, year, section) => {
    const yearData = [];
    if (data) {
        if (year in data["otherFinances"]) {
            Object.values(data["otherFinances"][year][section]).map((value) => {
                yearData.push(value !== null && value !== void 0 ? value : 0);
            });
        }
        return (0, exports.getSum)(yearData);
    }
    else
        return 0;
};
exports.calculateOtherTotal = calculateOtherTotal;
const calculateIncomeLossBeforeTaxes = (data, year) => {
    return ((0, exports.calculateGrossMarginTotal)(data, year) -
        (0, exports.calculateOverheadsTotal)(data, year) +
        (0, exports.calculateOtherTotal)(data, year, "otherIncome") -
        (0, exports.calculateOtherTotal)(data, year, "otherExpenses"));
};
exports.calculateIncomeLossBeforeTaxes = calculateIncomeLossBeforeTaxes;
