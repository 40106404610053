import { RESTAPIErrorType } from "../../types/ErrorMessageType";
import { addMessage, MessageEnqueueSnackbar } from "./MessagingHelper";

/**
 * This will clean out the unnecessary info that we don't want to send to the backend
 * @param data
 * @param memberId
 */
export const preProcessDataSending = (
  data: Record<string, any>,
  memberId?: string,
): Record<string, any> => {
  if (data === null) {
    return data;
  }
  if (memberId) {
    data.memberId = memberId;
  }
  if ("errorCode" in data) {
    delete data.errorCode;
  }
  if ("errorMessage" in data) {
    delete data.errorMessage;
  }
  if (data) return data;
};

/**
 * This sets the createdBy and updatedBy keys on request data.
 *
 * @param obj
 * @param updatedBy
 * @param idKey
 */
export const addCreatedUpdatedBy = (
  obj: Record<string, any>,
  updatedBy: string,
  idKey = "id",
): Record<string, any> => {
  if (obj === null) {
    return obj;
  }
  if (idKey in obj && obj[idKey].startsWith("NEW")) obj.createdBy = updatedBy;
  obj.updatedBy = updatedBy;
  return obj;
};

/**
 *
 * @param parameters
 * @param parameterKey
 * @param companyId
 * @param updatedBy
 */
export const preProcessGQLRequest = (
  parameters: any,
  parameterKey: string,
  companyId: string,
  updatedBy: string,
) => {
  // Need to sanitize data
  if (Array.isArray(parameters)) {
    parameters.forEach((d) => {
      d = preProcessDataSending(d, companyId);
      if (parameterKey === "toolInput") {
        d = addCreatedUpdatedBy(d, updatedBy, "toolId");
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } else d = addCreatedUpdatedBy(d, updatedBy);
    });
  } else {
    parameters = preProcessDataSending(parameters, companyId);
    if (
      parameterKey === "toolInput" ||
      parameterKey === "toolDeleteInput" ||
      parameterKey === "existingPricer" ||
      parameterKey === "pricerInput"
    ) {
      parameters = addCreatedUpdatedBy(parameters, updatedBy, "toolId");
    } else parameters = addCreatedUpdatedBy(parameters, updatedBy);
  }
  return { [`${parameterKey}`]: parameters };
};

/**
 * Helping process REST errors
 *
 * @param error
 * @param enqueueSnackbar
 */
export function handleRESTErrors(
  error: RESTAPIErrorType,
  enqueueSnackbar: MessageEnqueueSnackbar,
) {
  const errorMessage =
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errorMessage
      ? error.response.data.errorMessage
      : error.message;
  const errorCode =
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errorCode
      ? error.response.data.errorCode
      : error.name;
  addMessage(errorMessage, "error", enqueueSnackbar);
  throw new Error(errorCode);
}
