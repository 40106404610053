import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { DirtyDialog } from "../../../utils/components/tailwind/DirtyDialog";

export type DirtyContextType = {
  openDirty: boolean;
  setOpenDirty: React.Dispatch<React.SetStateAction<boolean>>;
  dirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  dirtySave: () => void;
  setDirtySave: React.Dispatch<React.SetStateAction<() => void>>;
  dirtyNav: () => void;
  setDirtyNav: React.Dispatch<React.SetStateAction<() => void>>;
  dirtyMessage: string;
  setDirtyMessage: React.Dispatch<React.SetStateAction<string>>;
  handleSetDirty: (saveFunction: () => void) => void;
  handleOpenDirty: (nav: any) => void;
};
const DirtyContext = createContext<DirtyContextType>({
  openDirty: false,
  setOpenDirty: (): void => null,
  dirty: false,
  setDirty: (): void => null,
  dirtySave: (): any => null,
  setDirtySave: (): void => {},
  dirtyNav: (): any => null,
  setDirtyNav: (): void => {},
  dirtyMessage: "",
  setDirtyMessage: (): void => {},
  handleSetDirty: () => null,
  handleOpenDirty: (): any => null,
});

type DirtyProviderProps = {
  children: ReactElement | ReactElement[];
};

export function DirtyProvider({ children }: DirtyProviderProps): ReactElement {
  const [openDirty, setOpenDirty] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [dirtySave, setDirtySave] = useState(() => () => {});
  const [dirtyNav, setDirtyNav] = useState(() => () => {});
  //TP-1619 KRJ: setter for main message on dirty dialog modal
  const [dirtyMessage, setDirtyMessage] = useState(
    "You are navigating away with unsaved data, do you want to save?",
  );

  /*TP-1761 KRJ runs when dirty is switched from true to false (this is happening in the onSuccess for saves)
  Makes sure that navigation via dirty dialog only happens on successful save
   */
  useEffect(() => {
    if (!dirty) {
      dirtyNav();
      setDirtyNav(() => () => {});
    }
  }, [dirty]);

  //TP-1619 KRJ: In order to avoid needing to worry about when an object is updated relative to the save it seems to be safer to pass in any
  //required parameters (specifically data objects or values held in state (months, department numbers, etc.)
  //Generally this would be called in places like onChanges were the data is changing but hasn't been saved yet, so we can track that there are unsaved changes
  /**
   * Pass in the save function that would be called on the data. Format () => someSaveFunction(any save params)
   * @param saveFunction
   */
  const handleSetDirty = (saveFunction: () => void) => {
    setDirty(true);
    setDirtySave(() => saveFunction);
  };

  //TP-1619 KRJ: This is getting called in the places were we were calling setOpenDirty(). Generally anywhere that could potentially lose existing changes to the data
  //So in places like nav functions, etc. Should look like setOpenDirty(() => {all navigation things here})

  /**
   * To add dirty dialog handling pass the current/standard navigation in as a prop. If the data is dirty this will open the dialog otherwise the function just passes through
   * @param nav =>{all necessary navigation functionality here}
   * @author KRJ
   */
  const handleOpenDirty = (nav: () => void) => {
    if (dirty) {
      setDirtyNav(() => nav);
      setOpenDirty(true);
    } else {
      nav();
    }
  };

  const initValue = {
    openDirty,
    setOpenDirty,
    dirty,
    setDirty,
    dirtySave,
    setDirtySave,
    dirtyNav,
    setDirtyNav,
    dirtyMessage,
    setDirtyMessage,
    handleSetDirty,
    handleOpenDirty,
  };
  return (
    <DirtyContext.Provider value={initValue}>
      <DirtyDialog
        open={openDirty}
        setOpen={setOpenDirty}
        setDirty={setDirty}
        handleDirtySave={dirtySave}
        dirtyMessage={dirtyMessage}
      />
      <div>{children}</div>
    </DirtyContext.Provider>
  );
}
export const useDirtyContext = (): any => useContext(DirtyContext);
