import axios from "axios";
import { User } from "auth0";
import { Dispatch, SetStateAction } from "react";
import { Access, empty, MemberType } from "@nexstar-network/shared-utils";
import {
  AccessLevelIdOrDefault,
  CompanyIdOrDefault,
  CRMUser,
  FunctionalRoleIdOrDefault,
  UserIdOrDefault,
} from "../../../../utils/helpers/UserHelper";
import {
  MessageEnqueueSnackbar,
  MessagingEnumSelections,
  MessagingStatusTypes,
} from "../../../../utils/helpers/MessagingHelper";

/**
 *
 * @param user
 * @param getAccessTokenSilently
 * @param setCompanyOptions
 * @param navigate
 * @param addMessage
 * @param setLoading
 * @param setShouldDisplay
 * @param enqueueSnackbar
 */
async function getCompanyAccess(
  user: CRMUser,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
  setCompanyOptions: Dispatch<SetStateAction<any>>,
  navigate: (location: string) => void,
  addMessage: (
    a: MessagingEnumSelections,
    b: MessagingStatusTypes,
    c: MessageEnqueueSnackbar,
  ) => void,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setShouldDisplay: Dispatch<SetStateAction<boolean>>,
  enqueueSnackbar: MessageEnqueueSnackbar,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  setLoading(true);
  await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}crm/access/${UserIdOrDefault(
      user as CRMUser,
    )}/companies`,
    method: "get",
  })
    .then((result: any) => {
      // Now I need to process the data to make the formats match.
      const companyAccessData = [];
      if (
        result.data &&
        "members" in result.data &&
        !empty(result.data.members)
      ) {
        result.data.members.forEach((cc) => {
          companyAccessData.push({
            companyId: cc.companyID ?? cc.companyId,
            companyName: cc.companyName,
            companyCountry: cc.companyCountry,
            companyCity: cc.companyCity,
            companyState: cc.companyState,
            companyZipCode: cc.companyZipCode,
            companyLatitude: cc.companyLatitude,
            companyLongitude: cc.companyLongitude,
          });
        });
      } else if (result.data && !empty(result.data)) {
        result.data.forEach((cc) => {
          companyAccessData.push({
            companyId: cc.companyID ?? cc.companyId,
            companyName: cc.companyName,
            companyCountry: cc.companyCountry,
            companyCity: cc.companyCity,
            companyState: cc.companyState,
            companyZipCode: cc.companyZipCode,
            companyLatitude: cc.companyLatitude,
            companyLongitude: cc.companyLongitude,
          });
        });
      }
      setCompanyOptions(companyAccessData);
      setShouldDisplay(
        !empty(companyAccessData) && companyAccessData.length > 1,
      );
    })
    .catch((err) => {
      if (err?.response) {
        if (err.isAxiosError && err.message === "Network Error") {
          navigate("/maintenance");
        }
        if (err?.response.status >= 500) {
          addMessage("CRM_CONNECTION_ISSUE", "error", enqueueSnackbar);
        } else if (
          err?.response.status === 401 ||
          err?.response.status === 403
        ) {
          addMessage("CRM_CONNECTION_ISSUE", "warning", enqueueSnackbar);
          navigate("/");
        } else {
          addMessage("CRM_CONNECTION_ISSUE", "warning", enqueueSnackbar);
        }
      } else throw Error(err.message);
    })
    .finally(() => setLoading(false));
}
// TP-690 JAH: Adding in code for the coaches new CRM connection
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getCoachesCompanies(
  user: User,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
  setCompanyOptions: Dispatch<SetStateAction<any>>,
  navigate: (location: string) => void,
  addMessage: (
    a: MessagingEnumSelections,
    b: MessagingStatusTypes,
    c: MessageEnqueueSnackbar,
  ) => void,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setShouldDisplay: Dispatch<SetStateAction<boolean>>,
  enqueueSnackbar: MessageEnqueueSnackbar,
): Promise<void> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  setLoading(true);
  await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${
      process.env.REACT_APP_API_BASE_URL
    }crm/coachMembers/${UserIdOrDefault(user as CRMUser)}`,
    method: "get",
  })
    .then((result: any) => {
      // Now I need to process the data to make the formats match.
      const coachesCompanyData = [
        {
          companyName: "Nexstar Network",
          companyId: 372,
          companyCountry: "United States",
          companyZipCode: 55101,
          companyCity: "St Paul",
          companyState: "Minnesota",
          companyLatitude: 44.9476,
          companyLongitude: -93.0919,
        },
      ];
      if (result.data) {
        result.data.forEach((cc) => {
          coachesCompanyData.push(cc);
        });
      }
      setCompanyOptions(coachesCompanyData);
      setShouldDisplay(
        !empty(coachesCompanyData) && coachesCompanyData.length > 1,
      );
    })
    .catch((err) => {
      if (err?.response) {
        if (err.isAxiosError && err.message === "Network Error") {
          navigate("/maintenance");
        }
        if (err?.response.status >= 500) {
          addMessage("CRM_CONNECTION_ISSUE", "error", enqueueSnackbar);
        } else if (
          err?.response.status === 401 ||
          err?.response.status === 403
        ) {
          addMessage("CRM_CONNECTION_ISSUE", "warning", enqueueSnackbar);
          navigate("/");
        } else {
          addMessage("CRM_CONNECTION_ISSUE", "warning", enqueueSnackbar);
        }
      } else throw Error(err.message);
    })
    .finally(() => setLoading(false));
}

// TP-690 JAH: Adding in code for the owners new CRM connection
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getOwnerCompanies(
  user: User,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
  setCompanyOptions: Dispatch<SetStateAction<any>>,
  navigate: (location: string) => void,
  addMessage: (
    a: MessagingEnumSelections,
    b: MessagingStatusTypes,
    c: MessageEnqueueSnackbar,
  ) => void,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setShouldDisplay: Dispatch<SetStateAction<boolean>>,
  enqueueSnackbar: MessageEnqueueSnackbar,
): Promise<void> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  setLoading(true);
  await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${
      process.env.REACT_APP_API_BASE_URL
    }crm/ownerCompanies/${UserIdOrDefault(user as CRMUser)}`,
    method: "get",
  })
    .then((result: any) => {
      // Now I need to process the data to make the formats match.
      const ownersCompanyData = [];
      if (result.data) {
        result.data.forEach((cc) => {
          ownersCompanyData.push({
            companyId: cc.companyID,
            companyName: cc.companyName,
            companyCountry: cc.companyCountry,
            companyCity: cc.companyCity,
            companyState: cc.companyState,
            companyZipCode: cc.companyZipCode,
            companyLatitude: cc.companyLatitude,
            companyLongitude: cc.companyLongitude,
          });
        });
      }
      setCompanyOptions(ownersCompanyData);
      setShouldDisplay(
        !empty(ownersCompanyData) && ownersCompanyData.length > 1,
      );
    })
    .catch((err) => {
      if (err?.response) {
        if (err.isAxiosError && err.message === "Network Error") {
          navigate("/maintenance");
        }
        if (err?.response.status >= 500) {
          addMessage("CRM_CONNECTION_ISSUE", "error", enqueueSnackbar);
        } else if (
          err?.response.status === 401 ||
          err?.response.status === 403
        ) {
          addMessage("CRM_CONNECTION_ISSUE", "warning", enqueueSnackbar);
          navigate("/");
        } else {
          addMessage("CRM_CONNECTION_ISSUE", "warning", enqueueSnackbar);
        }
      } else throw Error(err.message);
    })
    .finally(() => setLoading(false));
}

/**
 * gqlKey = getCoachesCompanies
 * @param user
 * @param getAccessTokenSilently
 * @param setCompanyOptions
 * @param navigate
 * @param addMessage
 * @param setLoading
 * @param setShouldDisplay
 * @param enqueueSnackbar
 */
async function getCoachesTestCompanies(
  user: User,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
  setCompanyOptions: Dispatch<SetStateAction<any>>,
  navigate: (location: string) => void,
  addMessage: (
    a: MessagingEnumSelections,
    b: MessagingStatusTypes,
    c: MessageEnqueueSnackbar,
  ) => void,
  setLoading: Dispatch<SetStateAction<any>>,
  setShouldDisplay: Dispatch<SetStateAction<boolean>>,
  enqueueSnackbar: MessageEnqueueSnackbar,
): Promise<void> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  setLoading(true);
  await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: `query($coachId: String!){
              getCoachesCompanies(coachId: $coachId){
                id
                coachId
                companyName
                companyCountry
                companyCity
                companyState
                companyZipCode
                companyLatitude
                companyLongitude
              }
            }`,
      variables: {
        coachId: UserIdOrDefault(user as CRMUser).toString(),
        toolType: "TOOL_LIST",
        memberId: CompanyIdOrDefault(user as CRMUser),
        roleId: AccessLevelIdOrDefault(user as CRMUser),
        userId: `${UserIdOrDefault(user as CRMUser)}`,
        functionalRoleId: FunctionalRoleIdOrDefault(user as CRMUser),
      },
    },
  })
    .then((result: any) => {
      if (result.data.errors) {
        const errorString = `${result.data.errors[0].message}||${
          result.data.errors[0].extensions.code
        } => ${result.data.errors[0].extensions.exception.stacktrace.join("")}`;
        throw Error(errorString);
      }
      // Now I need to process the data to make the formats match.
      const testCompanyData = [
        {
          companyName: "Nexstar Network",
          companyId: 372,
          companyCountry: "United States",
          companyZipCode: 55101,
          companyCity: "St Paul",
          companyState: "Minnesota",
          companyLatitude: 44.9476,
          companyLongitude: -93.0919,
        },
      ];
      if (result.data.data.getCoachesCompanies) {
        result.data.data.getCoachesCompanies.forEach((cc) => {
          let processedData = {
            ...cc,
            companyId: parseInt(cc.id.split("#").pop(), 10),
          };
          processedData = { ...processedData, companyCity: cc.companyCity };
          processedData = { ...processedData, companyState: cc.companyState };
          processedData = {
            ...processedData,
            companyZipCode: cc.companyZipCode,
          };
          processedData = {
            ...processedData,
            companyLatitude: cc.companyLatitude,
          };
          processedData = {
            ...processedData,
            companyLongitude: cc.companyLongitude,
          };
          testCompanyData.push(processedData);
        });
      }
      setCompanyOptions(testCompanyData);
      setShouldDisplay(!empty(testCompanyData) && testCompanyData.length > 0);
    })
    .catch((err) => {
      if (err?.response) {
        if (err.isAxiosError && err.message === "Network Error") {
          navigate("/maintenance");
        }
        if (err?.response.status >= 500) {
          addMessage(err.message, "error", enqueueSnackbar);
        } else if (
          err?.response.status === 401 ||
          err?.response.status === 403
        ) {
          addMessage("ACCESS_DENIED", "warning", enqueueSnackbar);
          navigate("/");
        } else {
          addMessage(err.message, "warning", enqueueSnackbar);
        }
      } else throw Error(err.message);
    })
    .finally(() => setLoading(false));
}

// TP-690 JAH: Updating this helper so that it uses the right endpoint to access the data.
/**
 *
 * @param user
 * @param getAccessTokenSilently
 * @param setCompanyOptions
 * @param navigate
 * @param addMessage
 * @param setLoading
 * @param setShouldDisplay
 * @param enqueueSnackbar
 */
async function getUserCompanies(
  user: CRMUser,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
  setCompanyOptions: Dispatch<SetStateAction<any>>,
  navigate: (location: string) => void,
  addMessage: (a: string, b: string, c: any) => void,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setShouldDisplay: Dispatch<SetStateAction<boolean>>,
  enqueueSnackbar: MessageEnqueueSnackbar,
): Promise<void> {
  if (
    CompanyIdOrDefault(user as CRMUser) === 372 &&
    process.env.REACT_APP_NODE_ENV !== "production"
  ) {
    await getCoachesTestCompanies(
      user,
      getAccessTokenSilently,
      setCompanyOptions,
      navigate,
      addMessage,
      setLoading,
      setShouldDisplay,
      enqueueSnackbar,
    );
  } else {
    await getCompanyAccess(
      user,
      getAccessTokenSilently,
      setCompanyOptions,
      navigate,
      addMessage,
      setLoading,
      setShouldDisplay,
      enqueueSnackbar,
    );
  }
}

/**
 *
 * @param user
 * @param selectedCompany
 * @param getAccessTokenSilently
 * @param setUserToolsAccess
 * @param navigate
 * @param addMessage
 * @param setLoading
 * @param enqueueSnackbar
 */
async function getToolsByRole(
  user: User,
  selectedCompany: MemberType,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
  setUserToolsAccess: Dispatch<SetStateAction<Access[]>>,
  navigate: (location: string) => void,
  addMessage: (a: string, b: string, c: any) => void,
  setLoading: Dispatch<SetStateAction<boolean>>,
  enqueueSnackbar: MessageEnqueueSnackbar,
): Promise<void> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  setLoading(true);
  await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${
      process.env.REACT_APP_API_BASE_URL
    }access/byMemberAndAccessLevel/memberID/${CompanyIdOrDefault(
      user as CRMUser,
      selectedCompany,
    )}/accessLevelID/${AccessLevelIdOrDefault(user as CRMUser)}`,
    method: "get",
  })
    .then((result: any) => {
      if (result.data) {
        const access = result.data;
        setUserToolsAccess(access);
      } else {
        setUserToolsAccess(null);
      }
    })
    .catch((err) => {
      if (err?.response) {
        if (err.isAxiosError && err.message === "Network Error") {
          navigate("/maintenance");
        }
        if (err?.response.status >= 500) {
          addMessage(err.message, "error", enqueueSnackbar);
        } else if (
          err?.response.status === 401 ||
          err?.response.status === 403
        ) {
          addMessage("ACCESS_DENIED", "warning", enqueueSnackbar);
          navigate("/");
        } else {
          addMessage(err.message, "warning", enqueueSnackbar);
        }
      } else throw Error(err.message);
    })
    .finally(() => setLoading(false));
}

/**
 *
 * @param user
 * @param getAccessTokenSilently
 * @param setEmulatedUser
 * @param addMessage
 * @param enqueueSnackbar
 * @param userIDToEmulate
 */
async function getEmulatedUser(
  user: User,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
  setEmulatedUser: Dispatch<SetStateAction<User>>,
  addMessage: (
    a: MessagingEnumSelections,
    b: MessagingStatusTypes,
    c: MessageEnqueueSnackbar,
  ) => void,
  enqueueSnackbar: MessageEnqueueSnackbar,
  userIDToEmulate: string,
): Promise<User> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}crm/userEmulation/${userIDToEmulate}`,
    method: "get",
  })
    .then((result: any) => {
      if (result.data) {
        setEmulatedUser(result.data);
        window.sessionStorage.setItem(
          "emulatedUser",
          JSON.stringify(result.data),
        );
        return result.data;
      }
    })
    .catch((err) => {
      if (err?.response) {
        if (err?.response.status >= 500) {
          addMessage("CRM_CONNECTION_ISSUE", "error", enqueueSnackbar);
        } else if (
          err?.response.status === 401 ||
          err?.response.status === 403
        ) {
          addMessage("CRM_CONNECTION_ISSUE", "warning", enqueueSnackbar);
        } else {
          addMessage("CRM_CONNECTION_ISSUE", "warning", enqueueSnackbar);
        }
      } else throw Error(err.message);
    });
}

export { getUserCompanies, getToolsByRole, getEmulatedUser };
