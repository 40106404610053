import React from "react";

type CardBodyProps = {
  border?: string;
  children: React.ReactNode;
  padding?: string;
  minHeight?: string;
  className?: string;
};

export default function CardBody({
  border = "",
  children,
  padding = "p-6",
  minHeight = "min-h-0",
  className,
}: CardBodyProps): JSX.Element {
  return (
    <div
      className={`bg-white shadow-md h-full ${minHeight} ${border} ${className}`}
    >
      <div className={`mx-auto ${padding}`}>
        <div className="w-full justify-between">{children}</div>
      </div>
    </div>
  );
}
