import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AccessLevelIdOrDefault,
  CRMUser,
} from "../../../utils/helpers/UserHelper";
import NexstarModal from "../../../utils/components/tailwind/NexstarModal";
import CardHeader from "../../../utils/components/tailwind/CardHeader";

function EnvironmentWarning(): JSX.Element {
  const { user } = useAuth0();
  const roleId = AccessLevelIdOrDefault(user as CRMUser);

  const [openEnvWarnDialog] = useState(
    (roleId === 2 || roleId === 3 || roleId === 4) &&
      process.env.REACT_APP_NODE_ENV === "qa",
  );
  const startingMinutes = 0;
  const startingSeconds = 30;
  const [mins, setMinutes] = useState(startingMinutes);
  const [secs, setSeconds] = useState(startingSeconds);
  useEffect(() => {
    if (openEnvWarnDialog) {
      const sampleInterval = setInterval(() => {
        if (secs > 0) {
          setSeconds(secs - 1);
        }
        if (secs === 0) {
          if (mins === 0) {
            clearInterval(sampleInterval);
            window.location.href = "https://staging.tools.nexstar.dev";
          } else {
            setMinutes(mins - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(sampleInterval);
      };
    }
  });

  return (
    <NexstarModal
      id="environmentWarningModal"
      open={openEnvWarnDialog}
      onClose={() => null}
      maxWidth="max-w-[600px]"
    >
      <CardHeader title="Development Environment Warning" />
      <div className="p-6">
        <h3 className="text-xl text-center mb-4">
          You are on the QA site, this means that there are continual updates to
          this site as the development team is updating code. If you want to
          test a tool and want a more stable environment to test click the
          following link to go to the
        </h3>
        <p className="text-center mb-4">
          <a
            className="underline text-3xl font-bold text-NexstarBlue hover:text-NexstarOrange"
            href="https://staging.tools.nexstar.dev"
          >
            Staging Site
          </a>
        </p>
        <p className="text-xl text-center mb-4">
          If you do not click the above link to go to the staging site you will
          be redirected there in...
        </p>
        <p className="text-4xl text-NexstarSienna-dark font-bold text-center">{`${mins}:${
          secs < 10 ? `0${secs}` : secs
        }`}</p>
      </div>
    </NexstarModal>
  );
}
export default EnvironmentWarning;
