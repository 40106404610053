"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accessDefault = void 0;
const utils_1 = require("../utils");
exports.accessDefault = {
    id: null,
    PROFITPRICER: utils_1.AppAccessEnum.NO_ACCESS,
    THREEDAYCALL: utils_1.AppAccessEnum.NO_ACCESS,
    ACTIONPLAN: utils_1.AppAccessEnum.NO_ACCESS,
    IMPORT: utils_1.AppAccessEnum.NO_ACCESS,
    EXPORT: utils_1.AppAccessEnum.NO_ACCESS,
    REPORTS: utils_1.AppAccessEnum.NO_ACCESS,
    COMPANYMANAGEMENT: utils_1.AppAccessEnum.NO_ACCESS,
    DAILYPACE: utils_1.AppAccessEnum.NO_ACCESS,
    WHATIFS: utils_1.AppAccessEnum.NO_ACCESS,
    ADMIN: utils_1.AppAccessEnum.NO_ACCESS,
    DMT: utils_1.AppAccessEnum.NO_ACCESS,
    MPW: utils_1.AppAccessEnum.NO_ACCESS,
    PROFITREPORT: utils_1.AppAccessEnum.NO_ACCESS,
    FINANCIALSURVEY: utils_1.AppAccessEnum.NO_ACCESS,
    BPW: utils_1.AppAccessEnum.NO_ACCESS,
    CHARTS: utils_1.AppAccessEnum.NO_ACCESS,
};
