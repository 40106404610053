import React from "react";
import NexstarTooltip from "./NexstarTooltip";

interface NexstarTextFieldProps {
  "data-testid"?: string;
  id: string;
  className?: string;
  name: string;
  value: string;
  onChange: any;
  placeholder?: string;
  label?: string;
  textAlign?: string;
  helperText?: string | boolean;
  error?: boolean;
  disabled?: boolean;
  maxLength?: number;
  tooltip?: string;
  required?: boolean;
  width?: string;
}

export function NexstarTextField({
  "data-testid": dataTestId,
  id,
  className,
  name,
  value,
  onChange,
  placeholder,
  label,
  helperText,
  error,
  textAlign = "text-left",
  disabled,
  maxLength,
  tooltip,
  required,
  width,
}: NexstarTextFieldProps) {
  const generatePlaceholderText = () => {
    if (placeholder) {
      return placeholder;
    }
    if (placeholder && required) {
      return `${placeholder} * Required`;
    }
    if (required) {
      return "* Required";
    }
    return null;
  };
  return (
    <div className={width}>
      <div className="relative">
        <div className="flex gap-2 items-center mb-1">
          {label && (
            <label
              className={`${
                disabled ? "text-NexstarGray-dark" : "text-NexstarBlue"
              } block leading-5 text-sm font-bold uppercase`}
              htmlFor={name}
            >
              {`${label}${required ? " *" : ""}`}
            </label>
          )}
          {tooltip && <NexstarTooltip content={tooltip} />}
        </div>
        <input
          data-testid={dataTestId}
          id={id}
          name={name}
          value={value || ""}
          onChange={onChange}
          placeholder={generatePlaceholderText()}
          className={`${className} h-[42px] py-1 border border-NexstarGray-dark focus:border-NexstarBlue-light focus:shadow-md focus:bg-white focus:ring-1 focus:ring-NexstarBlue-light focus:outline-none placeholder:text-[13px] placeholder:text-left ${
            !value && !disabled ? "bg-NexstarSoftGray" : "bg-white"
          } ${
            error && !value && "bg-red-100 border-NexstarSienna-dark"
          } block w-full py-1 px-2 text-md ${textAlign} ${
            disabled
              ? "text-NexstarGray-dark border-NexstarGray"
              : "text-NexstarBlue"
          } focus:bg-white focus:border-NexstarBlue-light focus:ring-0 focus:outline-none`}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
      {error ? (
        <p className="text-xs text-NexstarSienna-dark mt-1 relative">
          {helperText}
        </p>
      ) : null}
    </div>
  );
}
