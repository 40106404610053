"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingMonthlyTotalsForCallCount = exports.settingTotalsForCallCount = exports.totalOtherLeadRevenue = exports.totalConvertedOtherLeadCount = exports.totalMarketedLeadRevenue = exports.totalConvertedMarketedLeadCount = exports.totalTechLeadRevenue = exports.totalConvertedTechLeadCount = exports.totalSumOtherLeadCount = exports.totalSumMarketedLeadCount = exports.totalDemandConvertedCalls = exports.totalDemandRevenue = exports.totalSystemCheckRevenue = exports.totalSystemCheckConvertedCalls = exports.totalSumRequiredDemandCallCountStaffingCalc = exports.totalSumPriorYearDemandCallCountCalc = exports.totalSumBudgetedSystemCheckCallCountCalc = exports.totalSumNewAgreementSystemChecks = exports.totalSumRetainedAgreementSystemChecksCalc = exports.totalSumPriorYearSystemChecksCalc = exports.totalSumOfBudgetedTechGeneratedLeadsCalc = exports.totalSumOfPriorYearTechLeadsCalc = exports.requiredNumOfCrewsCalc = exports.budgetedSoldJobsCalc = exports.requiredOtherLeadCountBasedStaffingCalc = exports.minimumRequiredNumOfSalesmenCalc = exports.minimumRequiredTechsCalculation = exports.newAgreementSystemCheckCalc = exports.totalServiceRevenueCalc = exports.demandRevenueCalc = exports.demandConvertedCallsCalc = exports.systemCheckRevenueCalc = exports.systemCheckConvertedCallsCalc = exports.totalInstallRevenueCalc = exports.otherLeadRevenueCalc = exports.convertedOtherLeadsCalc = exports.marketLeadRevenueCalc = exports.convertedMarketLeadsCalc = exports.techLeadRevenueCalc = exports.convertedTechLeadsCalc = exports.requiredDemandCallCountCalc = exports.requiredMarketLeadCountBasedOnStaffingCalc = exports.ac187TotalCalc = exports.totalSumOfPriorYearOtherLeadCountCalc = exports.totalSumOfPriorYearMarketedLeadCountCalc = void 0;
const utils_1 = require("../../../../utils");
const callCountInit_1 = require("../../objects/callCountInit");
function getMonthlyCallCountData(yearlyData, month) {
    let monthlyCallCountData = null;
    if (yearlyData &&
        "callCount" in yearlyData &&
        yearlyData.callCount &&
        month in yearlyData.callCount) {
        monthlyCallCountData = yearlyData.callCount[month];
    }
    return monthlyCallCountData;
}
// Total Calc but has to be here
/**
 * AB187
 * @param preWork
 * @param companyData
 */
const totalSumOfPriorYearMarketedLeadCountCalc = (preWork, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        if (preWork.monthlyData[month]) {
            totalSum += preWork.monthlyData[month].numOfMarketedLeadsRan;
        }
    });
    return totalSum;
};
exports.totalSumOfPriorYearMarketedLeadCountCalc = totalSumOfPriorYearMarketedLeadCountCalc;
// Total Calc but has to be here
/**
 * AB191
 * @param preWork
 * @param companyData
 */
const totalSumOfPriorYearOtherLeadCountCalc = (preWork, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        if (preWork.monthlyData[month]) {
            totalSum += preWork.monthlyData[month].numOfOtherLeadsRan || 0;
        }
    });
    return totalSum;
};
exports.totalSumOfPriorYearOtherLeadCountCalc = totalSumOfPriorYearOtherLeadCountCalc;
// Display Calc but has to be here
/**
 * AC187
 * @param preWork
 * @param companyData
 */
const ac187TotalCalc = (preWork, companyData) => {
    const sumOfPriorYearMarketLeadCount = (0, exports.totalSumOfPriorYearMarketedLeadCountCalc)(preWork, companyData);
    const sumOfPriorYearMarketAndOther = sumOfPriorYearMarketLeadCount +
        (0, exports.totalSumOfPriorYearOtherLeadCountCalc)(preWork, companyData);
    if (sumOfPriorYearMarketAndOther) {
        return sumOfPriorYearMarketLeadCount / sumOfPriorYearMarketAndOther;
    }
    return 0;
};
exports.ac187TotalCalc = ac187TotalCalc;
// Display Calc but has to be here
/**
 * D188
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const requiredMarketLeadCountBasedOnStaffingCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    const monthlyCallCountData = getMonthlyCallCountData(yearlyData, month);
    if (!monthlyCallCountData)
        return 0;
    let monthlyTurnovers = 0;
    if (yearlyData.maxCapacity &&
        month in yearlyData.maxCapacity &&
        yearlyData.maxCapacity[month].monthlyTurnovers)
        monthlyTurnovers = yearlyData.maxCapacity[month].monthlyTurnovers;
    return ((monthlyCallCountData.budgetedNumOfSalespersons *
        monthlyCallCountData.targetNumOfLeadsRanPerSalesperson *
        workingDays -
        monthlyTurnovers) *
        (0, exports.ac187TotalCalc)(preWork, companyData));
};
exports.requiredMarketLeadCountBasedOnStaffingCalc = requiredMarketLeadCountBasedOnStaffingCalc;
// Display Calc but has to be here
/**
 * D160
 * @param yearlyData
 * @param workingDays
 * @param month
 * @param demandCallType
 */
const requiredDemandCallCountCalc = (yearlyData, workingDays, month, demandCallType) => {
    var _a, _b;
    // Making this null safe
    if (!yearlyData)
        return 0;
    const monthlyCallCountData = getMonthlyCallCountData(yearlyData, month);
    if (!monthlyCallCountData)
        return 0;
    let agreements = 0;
    if (yearlyData.serviceAgreements && yearlyData.serviceAgreements[month]) {
        // TP-900 JAH: looking at the correct place.
        if (yearlyData.serviceAgreements.visitsAtTimeOfSale) {
            agreements =
                yearlyData.serviceAgreements[month]
                    .totalVisitsBasedOnTimeOfSaleCalculation;
        }
        else {
            agreements =
                yearlyData.serviceAgreements[month].totalVisitIf6MonthFromSale;
        }
    }
    // TP-1799: need to updates this
    const targetNumOfCallsRanPerTech = (_a = monthlyCallCountData.targetNumOfCallsRanPerTech) !== null && _a !== void 0 ? _a : 0;
    const budgetedNumOfTechs = (_b = monthlyCallCountData.budgetedNumOfTechs) !== null && _b !== void 0 ? _b : 0;
    const totalDemandCalls = workingDays * targetNumOfCallsRanPerTech * budgetedNumOfTechs - agreements;
    if (demandCallType === "total")
        return totalDemandCalls;
    // TP-1859: this should be good now
    if (!(0, utils_1.objectFieldValidator)(monthlyCallCountData, [
        "percentOfDemandCallsThatAreDemandSystemCheck",
    ])) {
        return demandCallType === "demandCalls" ? totalDemandCalls : 0;
    }
    return demandCallType === "demandCalls"
        ? totalDemandCalls *
            (1 -
                monthlyCallCountData.percentOfDemandCallsThatAreDemandSystemCheck /
                    100)
        : totalDemandCalls *
            (monthlyCallCountData.percentOfDemandCallsThatAreDemandSystemCheck /
                100);
};
exports.requiredDemandCallCountCalc = requiredDemandCallCountCalc;
//
//
//
//  ↓ ↓ ↓ BACKEND CALCULATIONS ↓ ↓ ↓
/**
 * D195
 * @param yearlyData
 * @param month
 */
const convertedTechLeadsCalc = (yearlyData, month) => {
    if (!yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    const techGeneratedClosingRate = convAvgSaleData.budgetTechGeneratedClosingRate / 100;
    let monthlyTurnovers = 0;
    if (yearlyData.maxCapacity &&
        month in yearlyData.maxCapacity &&
        yearlyData.maxCapacity[month].monthlyTurnovers)
        monthlyTurnovers = yearlyData.maxCapacity[month].monthlyTurnovers;
    if (monthlyTurnovers && techGeneratedClosingRate) {
        return monthlyTurnovers * techGeneratedClosingRate;
    }
    return 0;
};
exports.convertedTechLeadsCalc = convertedTechLeadsCalc;
/**
 * D196
 * @param yearlyData
 * @param month
 */
const techLeadRevenueCalc = (yearlyData, month) => {
    if (!yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    const convertedTechLeads = (0, exports.convertedTechLeadsCalc)(yearlyData, month);
    if (convertedTechLeads && convAvgSaleData.budgetedTechGeneratedLeadsAvgSale) {
        return (convertedTechLeads * convAvgSaleData.budgetedTechGeneratedLeadsAvgSale);
    }
    return 0;
};
exports.techLeadRevenueCalc = techLeadRevenueCalc;
/**
 * D197
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const convertedMarketLeadsCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    if (!yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    if (!convAvgSaleData)
        return 0;
    const requiredLeadCountBasedOnStaffing = (0, exports.requiredMarketLeadCountBasedOnStaffingCalc)(yearlyData, preWork, companyData, workingDays, month);
    const budgetedMarketClosingRate = convAvgSaleData.budgetedMarketedClosingRate / 100;
    if (requiredLeadCountBasedOnStaffing && budgetedMarketClosingRate) {
        return requiredLeadCountBasedOnStaffing * budgetedMarketClosingRate;
    }
    return 0;
};
exports.convertedMarketLeadsCalc = convertedMarketLeadsCalc;
/**
 * D198
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const marketLeadRevenueCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    const convertedMarketLeads = (0, exports.convertedMarketLeadsCalc)(yearlyData, preWork, companyData, workingDays, month);
    if (!yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    if (convAvgSaleData &&
        convertedMarketLeads &&
        convAvgSaleData.budgetedMarketedLeadsAvgSale) {
        return convertedMarketLeads * convAvgSaleData.budgetedMarketedLeadsAvgSale;
    }
    return 0;
};
exports.marketLeadRevenueCalc = marketLeadRevenueCalc;
/**
 * D199
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const convertedOtherLeadsCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    if (!yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    if (!convAvgSaleData)
        return 0;
    const requiredLeadCountBasedOnStaffing = (0, exports.requiredOtherLeadCountBasedStaffingCalc)(yearlyData, preWork, companyData, workingDays, month);
    const bugetedOtherClosingRate = convAvgSaleData.budgetedOtherLeadsClosingRate / 100;
    if (requiredLeadCountBasedOnStaffing && bugetedOtherClosingRate) {
        return requiredLeadCountBasedOnStaffing * bugetedOtherClosingRate;
    }
    return 0;
};
exports.convertedOtherLeadsCalc = convertedOtherLeadsCalc;
/**
 * D200
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const otherLeadRevenueCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    if (!yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    if (!convAvgSaleData)
        return 0;
    const convertedOtherLeads = (0, exports.convertedOtherLeadsCalc)(yearlyData, preWork, companyData, workingDays, month);
    if (convertedOtherLeads && convAvgSaleData.budgetedOtherLeadsAvgSale) {
        return convertedOtherLeads * convAvgSaleData.budgetedOtherLeadsAvgSale;
    }
    return 0;
};
exports.otherLeadRevenueCalc = otherLeadRevenueCalc;
/**
 * D201
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const totalInstallRevenueCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    const a = (0, exports.marketLeadRevenueCalc)(yearlyData, preWork, companyData, workingDays, month);
    const b = (0, exports.techLeadRevenueCalc)(yearlyData, month);
    const c = (0, exports.otherLeadRevenueCalc)(yearlyData, preWork, companyData, workingDays, month);
    return a + b + c;
};
exports.totalInstallRevenueCalc = totalInstallRevenueCalc;
//** SERVICE **//
/**
 * D164
 * @param yearlyData
 * @param month
 */
const systemCheckConvertedCallsCalc = (yearlyData, month) => {
    if (yearlyData === null ||
        yearlyData === undefined ||
        !yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    if (!convAvgSaleData)
        return 0;
    const budgetYearConvRate = convAvgSaleData.systemCheckBudgetYearConvRate / 100;
    if (!(0, utils_1.empty)(yearlyData) &&
        "serviceAgreements" in yearlyData &&
        !(0, utils_1.empty)(yearlyData.serviceAgreements) &&
        month in yearlyData.serviceAgreements &&
        yearlyData.serviceAgreements[month]) {
        // Need to take into account the visits at time of sale. on this like we are in teh FE.
        const numberOfAgreements = !yearlyData.serviceAgreements.visitsAtTimeOfSale
            ? yearlyData.serviceAgreements[month].totalVisitIf6MonthFromSale
            : yearlyData.serviceAgreements[month]
                .totalVisitsBasedOnTimeOfSaleCalculation;
        if (numberOfAgreements && budgetYearConvRate) {
            return numberOfAgreements * budgetYearConvRate;
        }
    }
    return 0;
};
exports.systemCheckConvertedCallsCalc = systemCheckConvertedCallsCalc;
/**
 * D165
 * @param yearlyData
 * @param month
 */
const systemCheckRevenueCalc = (yearlyData, month) => {
    if (yearlyData === null ||
        yearlyData === undefined ||
        !yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    const systemCheckConvertedCalls = (0, exports.systemCheckConvertedCallsCalc)(yearlyData, month);
    if (convAvgSaleData &&
        systemCheckConvertedCalls &&
        convAvgSaleData.systemCheckBudgetYearAvgSale) {
        return (systemCheckConvertedCalls * convAvgSaleData.systemCheckBudgetYearAvgSale);
    }
    return 0;
};
exports.systemCheckRevenueCalc = systemCheckRevenueCalc;
/**
 * D166
 * @param yearlyData
 * @param workingDays
 * @param month
 * @param demandCallType
 */
const demandConvertedCallsCalc = (yearlyData, workingDays, month, demandCallType) => {
    if (yearlyData === null ||
        yearlyData === undefined ||
        !yearlyData.conversionAndAverageSale)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale[month];
    if (!convAvgSaleData)
        return 0;
    const demandBudgetConvRate = demandCallType === "demandCalls"
        ? convAvgSaleData.demandCallBudgetYearConvRate / 100
        : convAvgSaleData.demandSystemCheckCallBudgetYearConvRate / 100;
    const requiredDemandCallCount = (0, exports.requiredDemandCallCountCalc)(yearlyData, workingDays, month, demandCallType);
    if (requiredDemandCallCount && demandBudgetConvRate) {
        return requiredDemandCallCount * demandBudgetConvRate;
    }
    return 0;
};
exports.demandConvertedCallsCalc = demandConvertedCallsCalc;
/**
 * D167
 * @param yearlyData
 * @param workingDays
 * @param month
 * @param demandCallType
 */
const demandRevenueCalc = (yearlyData, workingDays, month, demandCallType) => {
    if (yearlyData === null || yearlyData === undefined)
        return 0;
    const convAvgSaleData = yearlyData.conversionAndAverageSale
        ? yearlyData.conversionAndAverageSale[month]
        : null;
    if (!convAvgSaleData)
        return 0;
    const budgetYearConvRate = demandCallType === "demandCalls"
        ? convAvgSaleData.demandCallBudgetYearConvRate / 100
        : convAvgSaleData.demandSystemCheckCallBudgetYearConvRate / 100;
    const budgetYearAvgSale = demandCallType === "demandCalls"
        ? convAvgSaleData.demandCallBudgetYearAvgSale
        : convAvgSaleData.demandSystemCheckCallBudgetYearAvgSale;
    const requiredDemandCallCount = (0, exports.requiredDemandCallCountCalc)(yearlyData, workingDays, month, demandCallType);
    if (requiredDemandCallCount && budgetYearConvRate && budgetYearAvgSale) {
        return requiredDemandCallCount * budgetYearConvRate * budgetYearAvgSale;
    }
    return 0;
};
exports.demandRevenueCalc = demandRevenueCalc;
/**
 * D170
 * @param yearlyData
 * @param workingDays
 * @param month
 */
const totalServiceRevenueCalc = (yearlyData, workingDays, month) => (0, exports.systemCheckRevenueCalc)(yearlyData, month) +
    (0, exports.demandRevenueCalc)(yearlyData, workingDays, month, "demandCalls") +
    (0, exports.demandRevenueCalc)(yearlyData, workingDays, month, "demandSystemCheckCalls");
exports.totalServiceRevenueCalc = totalServiceRevenueCalc;
// ↑ ↑ ↑ END OF BACKEND CALCULATIONS ↑ ↑ ↑
//
//
//
//  ↓ ↓ ↓ DISPLAY CALCULATIONS ↓ ↓ ↓
/**
 * D148
 * @param yearlyData
 * @param month
 */
const newAgreementSystemCheckCalc = (yearlyData, month) => {
    if (!yearlyData || !(yearlyData === null || yearlyData === void 0 ? void 0 : yearlyData.serviceAgreements))
        return 0;
    if (yearlyData.serviceAgreements[month]) {
        return (yearlyData.serviceAgreements[month].totalVisitIf6MonthFromSale -
            yearlyData.serviceAgreements[month].retainedAgreementsVisits);
    }
    return 0;
};
exports.newAgreementSystemCheckCalc = newAgreementSystemCheckCalc;
/**
 * D156
 * @param yearlyData
 * @param preWork
 * @param workingDays
 * @param month
 */
const minimumRequiredTechsCalculation = (yearlyData, preWork, workingDays, month) => {
    if (!yearlyData)
        return 0;
    const monthlyCallCountData = getMonthlyCallCountData(yearlyData, month);
    if (!monthlyCallCountData)
        return 0;
    if (workingDays && monthlyCallCountData.targetNumOfCallsRanPerTech) {
        let agreementValue;
        if (!yearlyData.serviceAgreements || !yearlyData.serviceAgreements[month])
            return 0;
        // TP-900 JAH: looking at the correct place.
        if (yearlyData.serviceAgreements.visitsAtTimeOfSale === false) {
            agreementValue =
                yearlyData.serviceAgreements[month].totalVisitIf6MonthFromSale;
        }
        else {
            agreementValue =
                yearlyData.serviceAgreements[month]
                    .totalVisitsBasedOnTimeOfSaleCalculation;
        }
        return ((agreementValue + preWork.monthlyData[month].demandCalls.yearMinusOne) /
            workingDays /
            monthlyCallCountData.targetNumOfCallsRanPerTech);
    }
    return 0;
};
exports.minimumRequiredTechsCalculation = minimumRequiredTechsCalculation;
/**
 * D184
 * @param yearlyData
 * @param preWork
 * @param workingDays
 * @param month
 */
const minimumRequiredNumOfSalesmenCalc = (yearlyData, preWork, workingDays, month) => {
    const monthlyCallCountData = getMonthlyCallCountData(yearlyData, month);
    if (!monthlyCallCountData)
        return 0;
    let monthlyTurnovers = 0;
    if (yearlyData.maxCapacity &&
        month in yearlyData.maxCapacity &&
        yearlyData.maxCapacity[month].monthlyTurnovers)
        monthlyTurnovers = yearlyData.maxCapacity[month].monthlyTurnovers;
    if (workingDays && monthlyCallCountData.targetNumOfLeadsRanPerSalesperson) {
        return ((monthlyTurnovers +
            preWork.monthlyData[month].numOfMarketedLeadsRan +
            (preWork.monthlyData[month].numOfOtherLeadsRan || 0)) /
            workingDays /
            monthlyCallCountData.targetNumOfLeadsRanPerSalesperson);
    }
    return 0;
};
exports.minimumRequiredNumOfSalesmenCalc = minimumRequiredNumOfSalesmenCalc;
/**
 * D192
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const requiredOtherLeadCountBasedStaffingCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    const monthlyCallCountData = getMonthlyCallCountData(yearlyData, month);
    if (!monthlyCallCountData)
        return 0;
    let monthlyTurnovers = 0;
    if (yearlyData.maxCapacity &&
        month in yearlyData.maxCapacity &&
        yearlyData.maxCapacity[month].monthlyTurnovers)
        monthlyTurnovers = yearlyData.maxCapacity[month].monthlyTurnovers;
    if (monthlyCallCountData.budgetedNumOfSalespersons &&
        monthlyCallCountData.targetNumOfLeadsRanPerSalesperson) {
        return (monthlyCallCountData.budgetedNumOfSalespersons *
            monthlyCallCountData.targetNumOfLeadsRanPerSalesperson *
            workingDays -
            monthlyTurnovers -
            (0, exports.requiredMarketLeadCountBasedOnStaffingCalc)(yearlyData, preWork, companyData, workingDays, month));
    }
    return 0;
};
exports.requiredOtherLeadCountBasedStaffingCalc = requiredOtherLeadCountBasedStaffingCalc;
/**
 * D204
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const budgetedSoldJobsCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    const monthlyCallCountData = getMonthlyCallCountData(yearlyData, month);
    if (!monthlyCallCountData)
        return 0;
    const convertedTechLeads = (0, exports.convertedTechLeadsCalc)(yearlyData, month);
    const convertedMarketLeads = (0, exports.convertedMarketLeadsCalc)(yearlyData, preWork, companyData, workingDays, month);
    const convertedOtherLeads = (0, exports.convertedOtherLeadsCalc)(yearlyData, preWork, companyData, workingDays, month);
    return convertedTechLeads + convertedMarketLeads + convertedOtherLeads;
};
exports.budgetedSoldJobsCalc = budgetedSoldJobsCalc;
/**
 * D210
 * @param yearlyData
 * @param preWork
 * @param companyData
 * @param workingDays
 * @param month
 */
const requiredNumOfCrewsCalc = (yearlyData, preWork, companyData, workingDays, month) => {
    const monthlyCallCountData = getMonthlyCallCountData(yearlyData, month);
    if (!monthlyCallCountData)
        return 0;
    const budgetedSoldJobs = (0, exports.budgetedSoldJobsCalc)(yearlyData, preWork, companyData, workingDays, month);
    if (budgetedSoldJobs &&
        workingDays &&
        monthlyCallCountData.targetNumOfJobsPerCrew) {
        return (budgetedSoldJobs /
            workingDays /
            monthlyCallCountData.targetNumOfJobsPerCrew);
    }
    return 0;
};
exports.requiredNumOfCrewsCalc = requiredNumOfCrewsCalc;
// // ↑ ↑ ↑ END OF DISPLAY CALCULATIONS ↑ ↑ ↑
//
//
//
//
// ↓ ↓ ↓ TOTAL CALCULATIONS ↓ ↓ ↓
//** INSTALL **//
/**
 * AB180
 * @param preWork
 * @param companyData
 */
const totalSumOfPriorYearTechLeadsCalc = (preWork, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        if (preWork.monthlyData[month]) {
            totalSum += preWork.monthlyData[month].numOfTechGeneratedLeadsRan;
        }
    });
    return totalSum;
};
exports.totalSumOfPriorYearTechLeadsCalc = totalSumOfPriorYearTechLeadsCalc;
/**
 * AB181
 * @param yearlyData
 * @param companyData
 */
const totalSumOfBudgetedTechGeneratedLeadsCalc = (yearlyData, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    if (!yearlyData.maxCapacity)
        return 0;
    months.forEach((month) => {
        totalSum += yearlyData.maxCapacity[month].monthlyTurnovers;
    });
    return totalSum;
};
exports.totalSumOfBudgetedTechGeneratedLeadsCalc = totalSumOfBudgetedTechGeneratedLeadsCalc;
//** SERVICE **//
/**
 * AB146
 * @param preWork
 * @param companyData
 */
const totalSumPriorYearSystemChecksCalc = (preWork, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        if (preWork.monthlyData[month]) {
            totalSum += preWork === null || preWork === void 0 ? void 0 : preWork.monthlyData[month].systemCheckCalls.yearMinusOne;
        }
    });
    return totalSum;
};
exports.totalSumPriorYearSystemChecksCalc = totalSumPriorYearSystemChecksCalc;
/**
 * AB147
 * @param yearlyData
 * @param companyData
 */
const totalSumRetainedAgreementSystemChecksCalc = (yearlyData, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += yearlyData.serviceAgreements[month].retainedAgreementsVisits;
    });
    return totalSum;
};
exports.totalSumRetainedAgreementSystemChecksCalc = totalSumRetainedAgreementSystemChecksCalc;
/**
 * AB148
 * @param yearlyData
 * @param companyData
 */
const totalSumNewAgreementSystemChecks = (yearlyData, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += (0, exports.newAgreementSystemCheckCalc)(yearlyData, month);
    });
    return totalSum;
};
exports.totalSumNewAgreementSystemChecks = totalSumNewAgreementSystemChecks;
/**
 * AB149
 * @param yearlyData
 * @param companyData
 */
const totalSumBudgetedSystemCheckCallCountCalc = (yearlyData, companyData) => {
    if (yearlyData === null ||
        yearlyData === undefined ||
        !yearlyData.serviceAgreements ||
        !yearlyData.serviceAgreementsMain)
        return 0;
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    // TP-900 JAH: looking at the correct place.
    if (!yearlyData.serviceAgreements.visitsAtTimeOfSale) {
        months.forEach((month) => {
            if (yearlyData.serviceAgreements[month]) {
                totalSum +=
                    yearlyData.serviceAgreements[month].totalVisitIf6MonthFromSale;
            }
            else {
                totalSum += 0;
            }
        });
    }
    else {
        months.forEach((month) => {
            if (yearlyData.serviceAgreements[month]) {
                totalSum +=
                    yearlyData.serviceAgreements[month]
                        .totalVisitsBasedOnTimeOfSaleCalculation;
            }
            else {
                totalSum += 0;
            }
        });
    }
    return totalSum;
};
exports.totalSumBudgetedSystemCheckCallCountCalc = totalSumBudgetedSystemCheckCallCountCalc;
/**
 * AB159
 * @param preWork
 * @param companyData
 * @param demandCallType
 */
const totalSumPriorYearDemandCallCountCalc = (preWork, companyData, demandCallType) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        var _a, _b;
        if (preWork.monthlyData[month]) {
            if (demandCallType === "demandCalls") {
                totalSum +=
                    (_a = (0, utils_1.objectFieldValidator)(preWork, ["monthlyData", month, "demandCalls", "yearMinusOne"], true)) !== null && _a !== void 0 ? _a : 0;
            }
            if (demandCallType === "demandSystemCheckCalls") {
                totalSum +=
                    (_b = (0, utils_1.objectFieldValidator)(preWork, ["monthlyData", month, "demandSystemCheckCalls", "yearMinusOne"], true)) !== null && _b !== void 0 ? _b : 0;
            }
        }
    });
    return totalSum;
};
exports.totalSumPriorYearDemandCallCountCalc = totalSumPriorYearDemandCallCountCalc;
/**
 * AB160
 * @param yearlyData
 * @param companyData
 * @param decreasedDays
 * @param demandCallType
 */
const totalSumRequiredDemandCallCountStaffingCalc = (yearlyData, companyData, decreasedDays, demandCallType) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        totalSum += (0, exports.requiredDemandCallCountCalc)(yearlyData, workingDays, month, demandCallType);
    });
    return totalSum;
};
exports.totalSumRequiredDemandCallCountStaffingCalc = totalSumRequiredDemandCallCountStaffingCalc;
/**
 * AB164
 * @param yearlyData
 * @param companyData
 */
const totalSystemCheckConvertedCalls = (yearlyData, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += (0, exports.systemCheckConvertedCallsCalc)(yearlyData, month);
    });
    return totalSum;
};
exports.totalSystemCheckConvertedCalls = totalSystemCheckConvertedCalls;
/**
 * AB165
 * @param yearlyData
 * @param companyData
 */
const totalSystemCheckRevenue = (yearlyData, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += (0, exports.systemCheckRevenueCalc)(yearlyData, month);
    });
    return totalSum;
};
exports.totalSystemCheckRevenue = totalSystemCheckRevenue;
/**
 * AB167
 * @param yearlyData
 * @param companyData
 * @param decreasedDays
 */
const totalDemandRevenue = (yearlyData, companyData, decreasedDays, demandCallType) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        totalSum += (0, exports.demandRevenueCalc)(yearlyData, workingDays, month, demandCallType);
    });
    return totalSum;
};
exports.totalDemandRevenue = totalDemandRevenue;
/**
 * AB166
 * @param yearlyData
 * @param companyData
 * @param decreasedDays
 */
const totalDemandConvertedCalls = (yearlyData, companyData, decreasedDays, demandCallType) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        totalSum += (0, exports.demandConvertedCallsCalc)(yearlyData, workingDays, month, demandCallType);
    });
    return totalSum;
};
exports.totalDemandConvertedCalls = totalDemandConvertedCalls;
/**
 * AB188
 */
const totalSumMarketedLeadCount = (yearlyData, preWork, companyData, decreasedDays) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        totalSum += (0, exports.requiredMarketLeadCountBasedOnStaffingCalc)(yearlyData, preWork, companyData, workingDays, month);
    });
    return totalSum;
};
exports.totalSumMarketedLeadCount = totalSumMarketedLeadCount;
/**
 * AB192
 */
const totalSumOtherLeadCount = (yearlyData, preWork, companyData, decreasedDays) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        totalSum += (0, exports.requiredOtherLeadCountBasedStaffingCalc)(yearlyData, preWork, companyData, workingDays, month);
    });
    return totalSum;
};
exports.totalSumOtherLeadCount = totalSumOtherLeadCount;
/**
 * AB195
 */
const totalConvertedTechLeadCount = (yearlyData, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += (0, exports.convertedTechLeadsCalc)(yearlyData, month);
    });
    return totalSum;
};
exports.totalConvertedTechLeadCount = totalConvertedTechLeadCount;
/**
 * AB196
 */
const totalTechLeadRevenue = (yearlyData, companyData) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        totalSum += (0, exports.techLeadRevenueCalc)(yearlyData, month);
    });
    return totalSum;
};
exports.totalTechLeadRevenue = totalTechLeadRevenue;
/**
 * AB197
 */
const totalConvertedMarketedLeadCount = (yearlyData, preWork, companyData, decreasedDays) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        totalSum += (0, exports.convertedMarketLeadsCalc)(yearlyData, preWork, companyData, workingDays, month);
    });
    return totalSum;
};
exports.totalConvertedMarketedLeadCount = totalConvertedMarketedLeadCount;
/**
 * AB198
 */
const totalMarketedLeadRevenue = (yearlyData, preWork, companyData, decreasedDays) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        const a = (0, exports.marketLeadRevenueCalc)(yearlyData, preWork, companyData, workingDays, month);
        totalSum += a;
    });
    return totalSum;
};
exports.totalMarketedLeadRevenue = totalMarketedLeadRevenue;
/**
 * AB199
 */
const totalConvertedOtherLeadCount = (yearlyData, preWork, companyData, decreasedDays) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        totalSum += (0, exports.convertedOtherLeadsCalc)(yearlyData, preWork, companyData, workingDays, month);
    });
    return totalSum;
};
exports.totalConvertedOtherLeadCount = totalConvertedOtherLeadCount;
/**
 * AB200
 */
const totalOtherLeadRevenue = (yearlyData, preWork, companyData, decreasedDays) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalSum = 0;
    months.forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(companyData === null || companyData === void 0 ? void 0 : companyData.weeklyWorkdays, Number(companyData === null || companyData === void 0 ? void 0 : companyData.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), companyData.fiscalYearEnd, companyData === null || companyData === void 0 ? void 0 : companyData.nonWorkingDays, decreasedDays);
        totalSum += (0, exports.otherLeadRevenueCalc)(yearlyData, preWork, companyData, workingDays, month);
    });
    return totalSum;
};
exports.totalOtherLeadRevenue = totalOtherLeadRevenue;
//  ↑ ↑ ↑ END OF TOTAL CALCULATIONS ↑ ↑ ↑
/**
 * Uses total calculations for Call Count to update yearly object
 * @param preWork
 * @param workshopData
 * @param companyData
 * @param departmentType
 * @param decreasedDays
 */
const settingTotalsForCallCount = (workshopData, preWork, companyData, departmentType, decreasedDays) => {
    let callCountData = workshopData.callCount || null;
    if (!("callCountTotals" in workshopData)) {
        callCountData = {
            ...workshopData.callCount,
            callCountTotals: { ...callCountInit_1.yearlyCallCountDataInit.callCountTotals },
        };
    }
    Object.keys(callCountInit_1.yearlyCallCountDataInit.callCountTotals).forEach((key) => {
        if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
            if (key === "totalSumPriorYearAgreementSystemChecks") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumPriorYearSystemChecksCalc)(preWork, companyData);
            }
            if (key === "totalSumBudgetedSystemCheckCallCount") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumBudgetedSystemCheckCallCountCalc)(workshopData, companyData);
            }
            if (key === "totalSystemCheckConvertedCalls") {
                callCountData.callCountTotals[key] = (0, exports.totalSystemCheckConvertedCalls)(workshopData, companyData);
            }
            if (key === "totalSumPriorYearDemandCallCount") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumPriorYearDemandCallCountCalc)(preWork, companyData, "demandCalls");
            }
            if (key === "totalSumPriorYearDemandSystemCheckCallCount") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumPriorYearDemandCallCountCalc)(preWork, companyData, "demandSystemCheckCalls");
            }
            if (key === "totalSumRequiredDemandCallCount") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumRequiredDemandCallCountStaffingCalc)(workshopData, companyData, decreasedDays, "demandCalls");
            }
            if (key === "totalSumRequiredDemandSystemCheckCallCount") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumRequiredDemandCallCountStaffingCalc)(workshopData, companyData, decreasedDays, "demandSystemCheckCalls");
            }
            if (key === "totalDemandConvertedCalls") {
                callCountData.callCountTotals[key] = (0, exports.totalDemandConvertedCalls)(workshopData, companyData, decreasedDays, "demandCalls");
            }
            if (key === "totalDemandSystemCheckConvertedCalls") {
                callCountData.callCountTotals[key] = (0, exports.totalDemandConvertedCalls)(workshopData, companyData, decreasedDays, "demandSystemCheckCalls");
            }
            if (key === "totalDemandRevenue") {
                callCountData.callCountTotals[key] = (0, exports.totalDemandRevenue)(workshopData, companyData, decreasedDays, "demandCalls");
            }
            if (key === "totalDemandSystemCheckRevenue") {
                callCountData.callCountTotals[key] = (0, exports.totalDemandRevenue)(workshopData, companyData, decreasedDays, "demandSystemCheckCalls");
            }
            if (key === "totalSystemCheckRevenue") {
                callCountData.callCountTotals[key] = (0, exports.totalSystemCheckRevenue)(workshopData, companyData);
            }
        }
        // install
        if (departmentType === utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT) {
            // AB180
            if (key === "totalSumPriorYearTechGeneratedLeads") {
                callCountData.callCountTotals[key] = (0, exports.totalSumOfPriorYearTechLeadsCalc)(preWork, companyData);
            }
            // AB181
            if (key === "totalSumBudgetedTechGeneratedLeads") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumOfBudgetedTechGeneratedLeadsCalc)(workshopData, companyData);
            }
            // AB187
            if (key === "totalSumPriorYearMarketedLeadCount") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumOfPriorYearMarketedLeadCountCalc)(preWork, companyData);
            }
            // AB188/AB189
            if (key === "totalSumMarketedLeadCount") {
                callCountData.callCountTotals[key] = (0, exports.totalSumMarketedLeadCount)(workshopData, preWork, companyData, decreasedDays);
            }
            // AB191
            if (key === "totalSumPriorYearOtherLeadCount") {
                callCountData.callCountTotals[key] =
                    (0, exports.totalSumOfPriorYearOtherLeadCountCalc)(preWork, companyData);
            }
            // AB192/AB193
            if (key === "totalSumOtherLeadCount") {
                callCountData.callCountTotals[key] = (0, exports.totalSumOtherLeadCount)(workshopData, preWork, companyData, decreasedDays);
            }
            // AB195
            if (key === "totalConvertedTechLeadCount") {
                callCountData.callCountTotals[key] = (0, exports.totalConvertedTechLeadCount)(workshopData, companyData);
            }
            // AB196
            if (key === "totalTechLeadRevenue") {
                callCountData.callCountTotals[key] = (0, exports.totalTechLeadRevenue)(workshopData, companyData);
            }
            // AB197
            if (key === "totalConvertedMarketedLeadCount") {
                callCountData.callCountTotals[key] = (0, exports.totalConvertedMarketedLeadCount)(workshopData, preWork, companyData, decreasedDays);
            }
            // AB198
            if (key === "totalMarketedLeadRevenue") {
                callCountData.callCountTotals[key] = (0, exports.totalMarketedLeadRevenue)(workshopData, preWork, companyData, decreasedDays);
            }
            // AB199
            if (key === "totalConvertedOtherLeadCount") {
                callCountData.callCountTotals[key] = (0, exports.totalConvertedOtherLeadCount)(workshopData, preWork, companyData, decreasedDays);
            }
            // AB200
            if (key === "totalOtherLeadRevenue") {
                callCountData.callCountTotals[key] = (0, exports.totalOtherLeadRevenue)(workshopData, preWork, companyData, decreasedDays);
            }
        }
    });
    return { ...workshopData, callCount: callCountData };
};
exports.settingTotalsForCallCount = settingTotalsForCallCount;
/**
 * Uses Total Calcs for Max Capacity to update the yearly object
 * @param workshopYearly
 * @param preWork
 * @param company
 * @param departmentType
 * @param decreasedDays
 */
const settingMonthlyTotalsForCallCount = (workshopYearly, preWork, company, departmentType, decreasedDays) => {
    Object.values(utils_1.reverseShortMonthValue).forEach((month) => {
        const workingDays = (0, utils_1.getWorkingDaysPerMonth)(company === null || company === void 0 ? void 0 : company.weeklyWorkdays, Number(company === null || company === void 0 ? void 0 : company.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), company.fiscalYearEnd, company === null || company === void 0 ? void 0 : company.nonWorkingDays, decreasedDays);
        if (workshopYearly.callCount[month]) {
            // TODO TP-1799: fix this for service to slit so that we have demandService, demandSystemCheckService and ServiceCalls
            const callCountMonthly = workshopYearly.callCount[month];
            // if ('monthlyTotalRevenueCalc' in callCountMonthly) {
            if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                callCountMonthly.monthlyTotalRevenueCalc = workshopYearly
                    ? (0, exports.totalServiceRevenueCalc)(workshopYearly, workingDays, month)
                    : 0;
                callCountMonthly.monthlyDemandCallRevenue = (0, exports.demandRevenueCalc)(workshopYearly, workingDays, month, "demandCalls");
                callCountMonthly.monthlyDemandSystemCheckCallRevenue =
                    (0, exports.demandRevenueCalc)(workshopYearly, workingDays, month, "demandSystemCheckCalls");
                callCountMonthly.monthlySystemCheckCallRevenue = (0, exports.systemCheckRevenueCalc)(workshopYearly, month);
            }
            else {
                callCountMonthly.monthlyTotalRevenueCalc = workshopYearly
                    ? (0, exports.totalInstallRevenueCalc)(workshopYearly, preWork, company, workingDays, month)
                    : 0;
            }
            // }
        }
    });
    return { ...workshopYearly };
};
exports.settingMonthlyTotalsForCallCount = settingMonthlyTotalsForCallCount;
