import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function DailyPaceIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <g>
        <path d="m750 0v300h300z" />
        <path d="m675 300v-300h-450c-41.324 0-75 33.676-75 75v1050c0 41.398 33.676 75 75 75h750c41.398 0 75-33.602 75-75v-750h-300c-41.324 0-75-33.676-75-75zm-225 750h-150v-225h150zm225 0h-150v-375h150zm225 0h-150v-525h150z" />
      </g>
    </svg>
  );
}
