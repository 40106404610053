"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nonMonthlyInstallInit = exports.nonMonthlyServiceInit = exports.serviceAgreementInit = exports.yearlyDataInit = exports.monthlyDataInit = exports.callCountByYear = void 0;
const utils_1 = require("../../../utils");
exports.callCountByYear = {
    yearMinusFour: null,
    yearMinusThree: null,
    yearMinusTwo: null,
    yearMinusOne: null,
};
exports.monthlyDataInit = {
    avgDemandCallCount: null,
    avgDemandSystemCheckCallCount: null,
    avgInstallCallCount: null,
    avgSystemCheckCallCount: null,
    callsPerTechPerDay: null,
    demandCallCountSeasonalityPercentage: null,
    demandCallCountSeasonalityPercentageOverride: null,
    demandCalls: (0, utils_1.cloneDeep)(exports.callCountByYear),
    demandCallsAverageSale: null,
    demandCallsConversionRate: null,
    demandOpportunityCalls: null,
    demandSystemCheckCalls: (0, utils_1.cloneDeep)(exports.callCountByYear),
    demandSystemCheckCallsAverageSale: null,
    demandSystemCheckCallsConversionRate: null,
    demandSystemCheckOpportunityCalls: null,
    installCallCountSeasonalityPercentage: null,
    installCallCountSeasonalityPercentageOverride: null,
    installJobs: (0, utils_1.cloneDeep)(exports.callCountByYear),
    laborPercentage: null,
    marketedLeadsAverageSale: null,
    marketedLeadsConversionRate: null,
    materialPercentage: null,
    numOfAllTechsRunningCalls: null,
    // numOfAllTechsRunningDemandSystemCheckCalls: null,
    numOfInstallCrews: null,
    numOfJobsPerCrewPerDay: null,
    numOfLeadsPerSalesmanPerDay: null,
    numOfMarketedLeadsRan: null,
    numOfOtherLeadsRan: null,
    numOfSalesman: null,
    numOfSoldJobs: null,
    numOfTechGeneratedLeadsRan: null,
    otherLeadsAverageSale: null,
    otherLeadsConversionRate: null,
    otherPercentage: null,
    salesExpensePercentage: null,
    systemCheckCallCountSeasonalityPercentage: null,
    systemCheckCallCountSeasonalityPercentageOverride: null,
    systemCheckCalls: (0, utils_1.cloneDeep)(exports.callCountByYear),
    systemCheckCallsAverageSale: null,
    systemCheckCallsConversionRate: null,
    systemOpportunityCalls: null,
    techGeneratedLeadsAverageSale: null,
    techGeneratedLeadsConversionRate: null,
    totalNumOfInstallLeadsRan: null,
};
exports.yearlyDataInit = {
    jan: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    feb: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    mar: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    apr: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    may: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    jun: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    jul: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    aug: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    sep: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    oct: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    nov: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
    dec: (0, utils_1.cloneDeep)(exports.monthlyDataInit),
};
exports.serviceAgreementInit = {
    started: null,
    projectedNew: null,
    projectedEnd: null,
    visits: null,
    projectedVisits: null,
    runRate: null,
};
exports.nonMonthlyServiceInit = {
    serviceAvgCall: {
        oppCallsPerc: null,
        convRateLastYear: null,
        avgSaleLastYear: null,
        techOppCallsLastYear: null,
        convRate90Days: null,
        avgSale90Days: null,
        techOppCalls90Days: null,
    },
    serviceDemandSystemCheckAvgCall: {
        oppCallsPerc: null,
        convRateLastYear: null,
        avgSaleLastYear: null,
        techOppCallsLastYear: null,
        convRate90Days: null,
        avgSale90Days: null,
        techOppCalls90Days: null,
    },
    serviceSystemCheck: {
        agreementsSold: null,
        agreementsRev: null,
        runRateVisits: null,
        convRateLastYear: null,
        avgSaleLastYear: null,
        techOppCallsLastYear: null,
        convRate90Days: null,
        avgSale90Days: null,
        techOppCalls90Days: null,
    },
};
exports.nonMonthlyInstallInit = {
    installAvgCall: {
        techConvRateLastYear: null,
        techConvRateLast3mo: null,
        techAvgSaleLastYear: null,
        techAvgSaleLast3mo: null,
        marketConvRateLastYear: null,
        marketConvRateLast3mo: null,
        marketAvgSaleLastYear: null,
        marketAvgSaleLast3mo: null,
        otherConvRateLastYear: null,
        otherConvRateLast3mo: null,
        otherAvgRateLastYear: null,
        otherAvgSale: null,
    },
};
