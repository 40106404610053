"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcAgreementGrowthOverLastYearPercent = exports.calcAgreementGrowthOverLastYear = exports.getBudgetYearTotalAgreementRevenue = exports.getBudgetYearTotalNewAgreementsSold = exports.calcBudgetYearIncreasePercentage = exports.getNewAndRetainedAgreements = exports.getPriorYearAgreementsRevenue = exports.getTotalPriorYearAgreements = exports.calcSystemCheckGrowthOverLastYearPercent = exports.calcSystemCheckGrowthOverLastYear = exports.calcBudgetedYearSystemCheckRevenue = exports.calcBudgetedYearSystemCheckAverageSale = exports.calcBudgetedYearSystemCheckConvertedCalls = exports.calcBudgetedYearSystemCheckTurnoverRate = exports.calcBudgetedYearSystemCheckConversionRate = exports.calcBudgetedYearSystemCheckCalls = exports.calcPreviousYearSystemCheckRevenue = exports.calcPreviousYearSystemCheckConvertedCalls = exports.calcPreviousYearSystemCheckDispatchedCalls = exports.calcGrowthOverLastYearPercent = exports.calcGrowthOverLastYear = exports.calcBudgetedYearDemandRevenue = exports.calcPreviousYearDemandRevenue = exports.calcBudgetYearAverageSale = exports.calcBudgetYearConvertedCalls = exports.calcBudgetYearTurnoverPercent = exports.calcBudgetYearConversionRate = exports.calcSystemCheckPreviousYearTurnoverPercent = exports.calcDemandPreviousYearTurnoverPercent = exports.calcPreviousYearConvertedCalls = exports.calcPreviousYearDispatchedCalls = void 0;
const utils_1 = require("../../../../utils");
function calcPreviousYearDispatchedCalls(preWorkData, departmentId, demandCallType) {
    let totalPreviousDispatchedCalls = 0;
    Object.values(preWorkData[departmentId].monthlyData).forEach((data) => {
        var _a, _b;
        const calls = demandCallType === "demandCalls"
            ? (_a = (0, utils_1.objectFieldValidator)(data, ["demandCalls", "yearMinusOne"], true)) !== null && _a !== void 0 ? _a : 0
            : (_b = (0, utils_1.objectFieldValidator)(data, ["demandSystemCheckCalls", "yearMinusOne"], true)) !== null && _b !== void 0 ? _b : 0;
        totalPreviousDispatchedCalls += calls;
    });
    return totalPreviousDispatchedCalls;
}
exports.calcPreviousYearDispatchedCalls = calcPreviousYearDispatchedCalls;
function calcPreviousYearConvertedCalls(preWorkData, departmentId, demandCallType) {
    // Making this null safe
    if (demandCallType === "demandCalls" &&
        (!(departmentId in preWorkData) ||
            !preWorkData[departmentId].nonMonthlyData ||
            !preWorkData[departmentId].nonMonthlyData.serviceAvgCall))
        return 0;
    if (demandCallType === "demandSystemCheckCalls" &&
        (!(departmentId in preWorkData) ||
            !preWorkData[departmentId].nonMonthlyData ||
            !preWorkData[departmentId].nonMonthlyData.serviceDemandSystemCheckAvgCall))
        return 0;
    const previousYearDispatchedCalls = calcPreviousYearDispatchedCalls(preWorkData, departmentId, demandCallType);
    const convRateLastYear = demandCallType === "demandCalls"
        ? (preWorkData[departmentId].nonMonthlyData.serviceAvgCall
            .convRateLastYear || 0) / 100
        : (preWorkData[departmentId].nonMonthlyData
            .serviceDemandSystemCheckAvgCall.convRateLastYear || 0) / 100;
    if (previousYearDispatchedCalls && convRateLastYear)
        return previousYearDispatchedCalls * convRateLastYear;
    return 0;
}
exports.calcPreviousYearConvertedCalls = calcPreviousYearConvertedCalls;
function calcDemandPreviousYearTurnoverPercent(preWorkData, departmentId, demandCallType) {
    // Making this null safe
    const techOppLastYear = demandCallType === "demandCalls"
        ? (0, utils_1.objectFieldValidator)(preWorkData, [
            departmentId,
            "nonMonthlyData",
            "serviceAvgCall",
            "techOppCallsLastYear",
        ], true)
        : (0, utils_1.objectFieldValidator)(preWorkData, [
            departmentId,
            "nonMonthlyData",
            "serviceDemandSystemCheckAvgCall",
            "techOppCallsLastYear",
        ], true);
    const demandOppsLastYear = demandCallType === "demandCalls"
        ? (0, utils_1.objectFieldValidator)(preWorkData, [departmentId, "monthlyData", "jan", "demandOpportunityCalls"], true)
        : (0, utils_1.objectFieldValidator)(preWorkData, [departmentId, "monthlyData", "jan", "demandOpportunityCalls"], true);
    if ((0, utils_1.empty)(techOppLastYear))
        return 0;
    if ((0, utils_1.empty)(demandOppsLastYear)) {
        return techOppLastYear || 0;
    }
    return (demandOppsLastYear / 100) * (techOppLastYear / 100) * 100;
}
exports.calcDemandPreviousYearTurnoverPercent = calcDemandPreviousYearTurnoverPercent;
function calcSystemCheckPreviousYearTurnoverPercent(preWorkData, departmentId) {
    if (!(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.serviceAvgCall)
        return 0;
    if (!preWorkData[departmentId].monthlyData ||
        !preWorkData[departmentId].monthlyData.jan ||
        !preWorkData[departmentId].monthlyData.jan.systemOpportunityCalls) {
        return (preWorkData[departmentId].nonMonthlyData.serviceSystemCheck
            .techOppCallsLastYear || 0);
    }
    return ((preWorkData[departmentId].monthlyData.jan.systemOpportunityCalls / 100) *
        (preWorkData[departmentId].nonMonthlyData.serviceSystemCheck
            .techOppCallsLastYear /
            100) *
        100);
}
exports.calcSystemCheckPreviousYearTurnoverPercent = calcSystemCheckPreviousYearTurnoverPercent;
function calcBudgetYearConversionRate(workshopData, departmentId, demandCallType) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const { totalDemandConvertedCalls, totalDemandSystemCheckConvertedCalls } = workshopData[departmentId].callCount || {};
    const { totalSumRequiredDemandCallCount, totalSumRequiredDemandSystemCheckCallCount, } = workshopData[departmentId].callCount || {};
    const demandConvertedCalls = demandCallType === "demandCalls"
        ? totalDemandConvertedCalls
        : totalDemandSystemCheckConvertedCalls;
    const sumRequiredDemandCalls = demandCallType === "demandCalls"
        ? totalSumRequiredDemandCallCount
        : totalSumRequiredDemandSystemCheckCallCount;
    if (demandConvertedCalls && sumRequiredDemandCalls)
        return demandConvertedCalls / sumRequiredDemandCalls;
    return 0;
}
exports.calcBudgetYearConversionRate = calcBudgetYearConversionRate;
function calcBudgetYearTurnoverPercent(workshopData, departmentId, demandCallType) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("maxCapacity" in workshopData[departmentId]))
        return 0;
    const { totalDemandTurnovers, totalDemandSystemCheckTurnovers } = workshopData[departmentId].maxCapacity || {};
    const turnovers = demandCallType === "demandCalls"
        ? totalDemandTurnovers !== null && totalDemandTurnovers !== void 0 ? totalDemandTurnovers : 0
        : totalDemandSystemCheckTurnovers !== null && totalDemandSystemCheckTurnovers !== void 0 ? totalDemandSystemCheckTurnovers : 0;
    const { totalSumRequiredDemandCallCount, totalSumRequiredDemandSystemCheckCallCount, } = workshopData[departmentId].callCount || {};
    const callCount = demandCallType === "demandCalls"
        ? totalSumRequiredDemandCallCount !== null && totalSumRequiredDemandCallCount !== void 0 ? totalSumRequiredDemandCallCount : 0
        : totalSumRequiredDemandSystemCheckCallCount !== null && totalSumRequiredDemandSystemCheckCallCount !== void 0 ? totalSumRequiredDemandSystemCheckCallCount : 0;
    if (turnovers && callCount)
        return turnovers / callCount;
    return 0;
}
exports.calcBudgetYearTurnoverPercent = calcBudgetYearTurnoverPercent;
function calcBudgetYearConvertedCalls(workshopData, departmentId, demandCallType) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const { totalSumRequiredDemandCallCount, totalSumRequiredDemandSystemCheckCallCount, } = workshopData[departmentId].callCount || {};
    const requiredDemandCallCount = demandCallType === "demandCalls"
        ? totalSumRequiredDemandCallCount
        : totalSumRequiredDemandSystemCheckCallCount;
    const budgetYearConversionRate = calcBudgetYearConversionRate(workshopData, departmentId, demandCallType);
    if (requiredDemandCallCount && budgetYearConversionRate)
        return requiredDemandCallCount * budgetYearConversionRate;
    return 0;
}
exports.calcBudgetYearConvertedCalls = calcBudgetYearConvertedCalls;
function calcBudgetYearAverageSale(workshopData, departmentId, demandCallType) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const { totalDemandRevenue, totalDemandSystemCheckRevenue } = workshopData[departmentId].callCount || {};
    const revenue = demandCallType === "demandCalls"
        ? totalDemandRevenue !== null && totalDemandRevenue !== void 0 ? totalDemandRevenue : 0
        : totalDemandSystemCheckRevenue !== null && totalDemandSystemCheckRevenue !== void 0 ? totalDemandSystemCheckRevenue : 0;
    const { totalDemandConvertedCalls, totalDemandSystemCheckConvertedCalls } = workshopData[departmentId].callCount || {};
    const convertedCalls = demandCallType === "demandCalls"
        ? totalDemandConvertedCalls !== null && totalDemandConvertedCalls !== void 0 ? totalDemandConvertedCalls : 0
        : totalDemandSystemCheckConvertedCalls !== null && totalDemandSystemCheckConvertedCalls !== void 0 ? totalDemandSystemCheckConvertedCalls : 0;
    if (revenue && convertedCalls)
        return revenue / convertedCalls;
    return 0;
}
exports.calcBudgetYearAverageSale = calcBudgetYearAverageSale;
function calcPreviousYearDemandRevenue(preWorkData, departmentId, demandCallType) {
    // Making this null safe
    const convertedCall = calcPreviousYearConvertedCalls(preWorkData, departmentId, demandCallType);
    if (demandCallType === "demandCalls" &&
        (!(departmentId in preWorkData) ||
            !preWorkData[departmentId].nonMonthlyData ||
            !preWorkData[departmentId].nonMonthlyData.serviceAvgCall))
        return 0;
    if (demandCallType === "demandSystemCheckCalls" &&
        (!(departmentId in preWorkData) ||
            !preWorkData[departmentId].nonMonthlyData ||
            !preWorkData[departmentId].nonMonthlyData.serviceDemandSystemCheckAvgCall))
        return 0;
    const averageSale = demandCallType === "demandCalls"
        ? preWorkData[departmentId].nonMonthlyData.serviceAvgCall
            .avgSaleLastYear || 0
        : preWorkData[departmentId].nonMonthlyData.serviceDemandSystemCheckAvgCall
            .avgSaleLastYear || 0;
    return convertedCall * averageSale;
}
exports.calcPreviousYearDemandRevenue = calcPreviousYearDemandRevenue;
function calcBudgetedYearDemandRevenue(workshopData, departmentId, demandCallType) {
    const convertedCalls = calcBudgetYearConvertedCalls(workshopData, departmentId, demandCallType);
    const avgSale = calcBudgetYearAverageSale(workshopData, departmentId, demandCallType);
    if (!convertedCalls || !avgSale)
        return 0;
    return convertedCalls * avgSale;
}
exports.calcBudgetedYearDemandRevenue = calcBudgetedYearDemandRevenue;
function calcGrowthOverLastYear(preWorkData, workshopData, departmentId, demandCallType) {
    const budgetDemandRevenue = calcBudgetedYearDemandRevenue(workshopData, departmentId, demandCallType);
    const previousDemandRevenue = calcPreviousYearDemandRevenue(preWorkData, departmentId, demandCallType);
    return budgetDemandRevenue - previousDemandRevenue;
}
exports.calcGrowthOverLastYear = calcGrowthOverLastYear;
function calcGrowthOverLastYearPercent(preWorkData, workshopData, departmentId, demandCallType) {
    const budgetDemandRevenue = calcBudgetedYearDemandRevenue(workshopData, departmentId, demandCallType);
    const previousDemandRevenue = calcPreviousYearDemandRevenue(preWorkData, departmentId, demandCallType);
    const budgetMinusPreviousRevenue = budgetDemandRevenue - previousDemandRevenue;
    if (!budgetMinusPreviousRevenue || !previousDemandRevenue)
        return 0;
    return (budgetMinusPreviousRevenue / previousDemandRevenue) * 100;
}
exports.calcGrowthOverLastYearPercent = calcGrowthOverLastYearPercent;
// SYSTEM CHECK
function calcPreviousYearSystemCheckDispatchedCalls(preWorkData, departmentId) {
    let totalPreviousSystemCheckDispatchedCalls = 0;
    if (preWorkData && preWorkData[departmentId].monthlyData) {
        Object.values(preWorkData[departmentId].monthlyData).forEach((data) => {
            //@ts-ignore
            totalPreviousSystemCheckDispatchedCalls += !(data === null || data === void 0 ? void 0 : data.systemCheckCalls)
                ? 0
                : data.systemCheckCalls.yearMinusOne || 0;
        });
    }
    return totalPreviousSystemCheckDispatchedCalls;
}
exports.calcPreviousYearSystemCheckDispatchedCalls = calcPreviousYearSystemCheckDispatchedCalls;
function calcPreviousYearSystemCheckConvertedCalls(preWorkData, departmentId) {
    // Making this null safe
    const dispatchedCalls = calcPreviousYearSystemCheckDispatchedCalls(preWorkData, departmentId);
    if (!(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.serviceSystemCheck ||
        !dispatchedCalls)
        return 0;
    return (dispatchedCalls *
        ((preWorkData[departmentId].nonMonthlyData.serviceSystemCheck
            .convRateLastYear || 0) /
            100));
}
exports.calcPreviousYearSystemCheckConvertedCalls = calcPreviousYearSystemCheckConvertedCalls;
function calcPreviousYearSystemCheckRevenue(preWorkData, departmentId) {
    // Making this null safe
    const convertedCalls = calcPreviousYearSystemCheckConvertedCalls(preWorkData, departmentId);
    if (!(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.serviceSystemCheck ||
        !convertedCalls)
        return 0;
    return (convertedCalls *
        (preWorkData[departmentId].nonMonthlyData.serviceSystemCheck
            .avgSaleLastYear || 0));
}
exports.calcPreviousYearSystemCheckRevenue = calcPreviousYearSystemCheckRevenue;
function calcBudgetedYearSystemCheckCalls(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    return (workshopData[departmentId].callCount.totalSumBudgetedSystemCheckCallCount ||
        0);
}
exports.calcBudgetedYearSystemCheckCalls = calcBudgetedYearSystemCheckCalls;
function calcBudgetedYearSystemCheckConversionRate(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const calculatedTotalSystemCheckConveretedCalls = workshopData[departmentId].callCount.totalSystemCheckConvertedCalls || 0;
    const budgetedYearSystemCheckCalls = calcBudgetedYearSystemCheckCalls(workshopData, departmentId);
    if (calculatedTotalSystemCheckConveretedCalls && budgetedYearSystemCheckCalls)
        return (calculatedTotalSystemCheckConveretedCalls / budgetedYearSystemCheckCalls);
    return 0;
}
exports.calcBudgetedYearSystemCheckConversionRate = calcBudgetedYearSystemCheckConversionRate;
function calcBudgetedYearSystemCheckTurnoverRate(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("maxCapacity" in workshopData[departmentId]))
        return 0;
    const { totalSystemTurnovers } = workshopData[departmentId].maxCapacity || {};
    const budgetedYearSystemCheckCalls = calcBudgetedYearSystemCheckCalls(workshopData, departmentId);
    if (totalSystemTurnovers && budgetedYearSystemCheckCalls)
        return totalSystemTurnovers / budgetedYearSystemCheckCalls;
    return 0;
}
exports.calcBudgetedYearSystemCheckTurnoverRate = calcBudgetedYearSystemCheckTurnoverRate;
function calcBudgetedYearSystemCheckConvertedCalls(workshopData, departmentId) {
    const budgetedYearSystemCheckCalls = calcBudgetedYearSystemCheckCalls(workshopData, departmentId);
    const budgetedYearSystemCheckConversionRate = calcBudgetedYearSystemCheckConversionRate(workshopData, departmentId);
    if (budgetedYearSystemCheckCalls && budgetedYearSystemCheckConversionRate)
        return budgetedYearSystemCheckCalls * budgetedYearSystemCheckConversionRate;
    return 0;
}
exports.calcBudgetedYearSystemCheckConvertedCalls = calcBudgetedYearSystemCheckConvertedCalls;
function calcBudgetedYearSystemCheckAverageSale(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const calculatedTotalCheckCheckRevenue = workshopData[departmentId].callCount.totalSystemCheckRevenue || 0;
    const budgetedYearSystemCheckConvertedCalls = calcBudgetedYearSystemCheckConvertedCalls(workshopData, departmentId);
    if (calculatedTotalCheckCheckRevenue && budgetedYearSystemCheckConvertedCalls)
        return (calculatedTotalCheckCheckRevenue / budgetedYearSystemCheckConvertedCalls);
    return 0;
}
exports.calcBudgetedYearSystemCheckAverageSale = calcBudgetedYearSystemCheckAverageSale;
function calcBudgetedYearSystemCheckRevenue(workshopData, departmentId) {
    const budgetedYearSystemCheckConvertedCalls = calcBudgetedYearSystemCheckConvertedCalls(workshopData, departmentId);
    const budgetedYearSystemCheckAverageSale = calcBudgetedYearSystemCheckAverageSale(workshopData, departmentId);
    if (budgetedYearSystemCheckConvertedCalls &&
        budgetedYearSystemCheckAverageSale)
        return (budgetedYearSystemCheckConvertedCalls * budgetedYearSystemCheckAverageSale);
    return 0;
}
exports.calcBudgetedYearSystemCheckRevenue = calcBudgetedYearSystemCheckRevenue;
function calcSystemCheckGrowthOverLastYear(preWorkData, workshopData, departmentId) {
    const budgetedYearSystemCheckRevenue = calcBudgetedYearSystemCheckRevenue(workshopData, departmentId);
    const previousYearSystemCheckRevenue = calcPreviousYearSystemCheckRevenue(preWorkData, departmentId);
    return budgetedYearSystemCheckRevenue - previousYearSystemCheckRevenue;
}
exports.calcSystemCheckGrowthOverLastYear = calcSystemCheckGrowthOverLastYear;
function calcSystemCheckGrowthOverLastYearPercent(preWorkData, workshopData, departmentId) {
    const systemCheckGrowthOverLastYear = calcSystemCheckGrowthOverLastYear(preWorkData, workshopData, departmentId);
    const previousYearSystemCheckRevenue = calcPreviousYearSystemCheckRevenue(preWorkData, departmentId);
    if (systemCheckGrowthOverLastYear && previousYearSystemCheckRevenue)
        return systemCheckGrowthOverLastYear / previousYearSystemCheckRevenue;
    return 0;
}
exports.calcSystemCheckGrowthOverLastYearPercent = calcSystemCheckGrowthOverLastYearPercent;
function getTotalPriorYearAgreements(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("serviceAgreements" in workshopData[departmentId]))
        return 0;
    if (!workshopData[departmentId].serviceAgreements)
        return 0;
    return (workshopData[departmentId].serviceAgreements.totalPriorYearAgreements || 0);
}
exports.getTotalPriorYearAgreements = getTotalPriorYearAgreements;
function getPriorYearAgreementsRevenue(preWorkData, departmentId) {
    // Making this null safe
    if (!preWorkData ||
        !(departmentId in preWorkData) ||
        !("nonMonthlyData" in preWorkData[departmentId]) ||
        !preWorkData[departmentId].nonMonthlyData.serviceSystemCheck)
        return 0;
    return (preWorkData[departmentId].nonMonthlyData.serviceSystemCheck.agreementsRev ||
        0);
}
exports.getPriorYearAgreementsRevenue = getPriorYearAgreementsRevenue;
function getNewAndRetainedAgreements(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("serviceAgreements" in workshopData[departmentId]))
        return 0;
    if (!workshopData[departmentId].serviceAgreements)
        return 0;
    return (workshopData[departmentId].serviceAgreements
        .totalNewAndRetainedAgreementsForDepartment || 0);
}
exports.getNewAndRetainedAgreements = getNewAndRetainedAgreements;
function calcBudgetYearIncreasePercentage(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("serviceAgreements" in workshopData[departmentId]))
        return 0;
    const { totalNewAndRetainedAgreementsForDepartment, totalPriorYearAgreements, } = workshopData[departmentId].serviceAgreements || {};
    if (!totalNewAndRetainedAgreementsForDepartment || !totalPriorYearAgreements)
        return 0;
    return ((totalNewAndRetainedAgreementsForDepartment - totalPriorYearAgreements) /
        totalPriorYearAgreements);
}
exports.calcBudgetYearIncreasePercentage = calcBudgetYearIncreasePercentage;
function getBudgetYearTotalNewAgreementsSold(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("serviceAgreements" in workshopData[departmentId]))
        return 0;
    return (workshopData[departmentId].serviceAgreements.totalNewAgreementsSold || 0);
}
exports.getBudgetYearTotalNewAgreementsSold = getBudgetYearTotalNewAgreementsSold;
function getBudgetYearTotalAgreementRevenue(workshopData, departmentId) {
    // Making this null safe
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("serviceAgreements" in workshopData[departmentId]))
        return 0;
    return (workshopData[departmentId].serviceAgreements.totalAgreementRevenue || 0);
}
exports.getBudgetYearTotalAgreementRevenue = getBudgetYearTotalAgreementRevenue;
function calcAgreementGrowthOverLastYear(preWorkData, workshopData, departmentId) {
    const budgetYearTotalAgreementRevenue = getBudgetYearTotalAgreementRevenue(workshopData, departmentId);
    const priorYearAgreementsRevenue = getPriorYearAgreementsRevenue(preWorkData, departmentId);
    return budgetYearTotalAgreementRevenue - priorYearAgreementsRevenue;
}
exports.calcAgreementGrowthOverLastYear = calcAgreementGrowthOverLastYear;
function calcAgreementGrowthOverLastYearPercent(preWorkData, workshopData, departmentId) {
    const agreementGrowthOverLastYear = calcAgreementGrowthOverLastYear(preWorkData, workshopData, departmentId);
    const priorYearAgreementsRevenue = getPriorYearAgreementsRevenue(preWorkData, departmentId);
    if (agreementGrowthOverLastYear && priorYearAgreementsRevenue)
        return agreementGrowthOverLastYear / priorYearAgreementsRevenue;
    return 0;
}
exports.calcAgreementGrowthOverLastYearPercent = calcAgreementGrowthOverLastYearPercent;
