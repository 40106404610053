"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.grossMarginBudgetVariancePercent = exports.grossMarginBudgetVarianceAmount = exports.totalsBudgetVarianceAmount = exports.totalsBudgetVariancePercent = exports.budgetedPercentCalc = exports.grossMarginVariancePercentCalc = exports.grossMarginCalc = exports.totalsVariancePercentCalc = exports.totalsVarianceCurrencyCalc = exports.totalsPercentageCalc = exports.totalSumDirectCostsCalc = exports.totalSumOtherDirectsCalc = exports.totalSumDirectLaborCalc = exports.totalSumMaterialsCalc = exports.netSalesBudgetVariancePercent = exports.netSalesBudgetVariance = exports.variancePercentCalc = exports.varianceCurrencyCalc = exports.netSalesPercentageCalc = exports.totalPercentOfNetSalesCalc = exports.percentOfNetSalesCalc = exports.netSalesVariancePercent = exports.netSalesVariance = exports.totalReturnsServicePercentCalc = exports.netSalesCalc = exports.totalReturnsAndAllowancesVariancePercent = exports.totalReturnsAndAllowancesVariance = exports.totalReturnsAndAllowancesServiceCalc = exports.totalSalesServiceVariancePercentCalc = exports.totalSalesServiceVarianceCurrencyCalc = exports.totalSalesSumCalc = void 0;
const utils_1 = require("../../../utils");
/**
 * C11/F11
 * @param trackingData
 * @param timeKey
 */
const totalSalesSumCalc = (trackingData, timeKey) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!trackingData)
        return 0;
    const keyObject = timeKey in trackingData ? trackingData[timeKey] : null;
    if (!keyObject)
        return 0;
    const sales = (_a = keyObject.sales) !== null && _a !== void 0 ? _a : 0;
    const demandService = ((_b = keyObject.demandService) !== null && _b !== void 0 ? _b : 0) +
        ((_c = keyObject.demandSystemCheckService) !== null && _c !== void 0 ? _c : 0) +
        ((_d = keyObject.systemCheckService) !== null && _d !== void 0 ? _d : 0);
    const spp = (_e = keyObject.spp) !== null && _e !== void 0 ? _e : 0;
    const customField1 = (_f = keyObject.customField1) !== null && _f !== void 0 ? _f : 0;
    const customField2 = (_g = keyObject.customField2) !== null && _g !== void 0 ? _g : 0;
    const customField3 = (_h = keyObject.customField3) !== null && _h !== void 0 ? _h : 0;
    return (demandService + sales + spp + customField1 + customField2 + customField3);
};
exports.totalSalesSumCalc = totalSalesSumCalc;
/**
 *
 * @param trackingData
 * @param valueKey
 * @param comparisonKey
 *
 * @example trackingData(valueKey) - trackingData(comparisonKey)
 * keys = lastYear|budget|currentYear|
 */
const totalSalesServiceVarianceCurrencyCalc = (trackingData, valueKey, comparisonKey) => {
    if (!trackingData)
        return 0;
    const totalSalesSumCalc1 = (0, exports.totalSalesSumCalc)(trackingData, valueKey);
    const totalSalesSumCalc2 = (0, exports.totalSalesSumCalc)(trackingData, comparisonKey);
    return totalSalesSumCalc1 - totalSalesSumCalc2;
};
exports.totalSalesServiceVarianceCurrencyCalc = totalSalesServiceVarianceCurrencyCalc;
const totalSalesServiceVariancePercentCalc = (trackingData, valueKey, comparisonKey) => {
    if (!trackingData)
        return 0;
    const totalSalesVarianceCurrency = (0, exports.totalSalesServiceVarianceCurrencyCalc)(trackingData, valueKey, comparisonKey);
    const totalSalesSum = (0, exports.totalSalesSumCalc)(trackingData, comparisonKey);
    if (!totalSalesVarianceCurrency || !totalSalesSum)
        return 0;
    return (totalSalesVarianceCurrency / totalSalesSum) * 100;
};
exports.totalSalesServiceVariancePercentCalc = totalSalesServiceVariancePercentCalc;
// Total Sales calculations ^^^^^^^
//
//
//
/**
 * C11/F11
 * @param trackingData
 * @param key
 */
const totalReturnsAndAllowancesServiceCalc = (trackingData, key) => {
    var _a, _b, _c;
    if (!trackingData)
        return 0;
    const keyObject = key in trackingData ? trackingData[key] : null;
    if (!keyObject)
        return 0;
    const subcontractors = (_a = keyObject.subcontractors) !== null && _a !== void 0 ? _a : 0;
    const refunds = (_b = keyObject.refunds) !== null && _b !== void 0 ? _b : 0;
    const financeFees = (_c = keyObject.financeFees) !== null && _c !== void 0 ? _c : 0;
    return subcontractors + refunds + financeFees;
};
exports.totalReturnsAndAllowancesServiceCalc = totalReturnsAndAllowancesServiceCalc;
/**
 * I17
 * @param trackingData
 */
function totalReturnsAndAllowancesVariance(trackingData) {
    if (!trackingData)
        return 0;
    const totalSalesSumCalc1 = (0, exports.totalReturnsAndAllowancesServiceCalc)(trackingData, "currentYear");
    const totalSalesSumCalc2 = (0, exports.totalReturnsAndAllowancesServiceCalc)(trackingData, "lastYear");
    return totalSalesSumCalc1 - totalSalesSumCalc2;
}
exports.totalReturnsAndAllowancesVariance = totalReturnsAndAllowancesVariance;
/**
 * J17
 * @param trackingData
 */
function totalReturnsAndAllowancesVariancePercent(trackingData) {
    if (!trackingData)
        return 0;
    const totalRNAVariance = totalReturnsAndAllowancesVariance(trackingData);
    const totalRNA = (0, exports.totalReturnsAndAllowancesServiceCalc)(trackingData, "lastYear");
    if (!totalRNAVariance || !totalRNA)
        return 0;
    return (totalRNAVariance / totalRNA) * 100;
}
exports.totalReturnsAndAllowancesVariancePercent = totalReturnsAndAllowancesVariancePercent;
/**
 * C18/F18/L18
 *
 * @param trackingData
 * @param timeKey
 */
const netSalesCalc = (trackingData, timeKey) => {
    const totalInstallSales = (0, exports.totalSalesSumCalc)(trackingData, timeKey);
    // const totalServiceSales = totalSalesService;
    const totalReturnsAndAllowancesCalc = (0, exports.totalReturnsAndAllowancesServiceCalc)(trackingData, timeKey);
    return totalInstallSales - totalReturnsAndAllowancesCalc;
};
exports.netSalesCalc = netSalesCalc;
/**
 * D17/G17/M17
 * @param trackingData
 * @param timeKey
 */
const totalReturnsServicePercentCalc = (trackingData, timeKey) => {
    if (!trackingData)
        return 0;
    const returnsAndAllowances = (0, exports.totalReturnsAndAllowancesServiceCalc)(trackingData, timeKey);
    const netSales = (0, exports.netSalesCalc)(trackingData, timeKey);
    if (!returnsAndAllowances || !netSales)
        return 0;
    return (returnsAndAllowances / netSales) * 100;
};
exports.totalReturnsServicePercentCalc = totalReturnsServicePercentCalc;
/**
 * I18
 *
 * @param trackingData
 */
const netSalesVariance = (trackingData) => {
    if (!trackingData)
        return 0;
    const totalNetSalesCalc1 = (0, exports.netSalesCalc)(trackingData, "currentYear");
    const totalNetSalesCalc2 = (0, exports.netSalesCalc)(trackingData, "lastYear");
    return totalNetSalesCalc1 - totalNetSalesCalc2;
};
exports.netSalesVariance = netSalesVariance;
/**
 * J18
 * @param trackingData
 */
function netSalesVariancePercent(trackingData) {
    if (!trackingData)
        return 0;
    const totalNSVariance = (0, exports.netSalesVariance)(trackingData);
    const totalNS = (0, exports.netSalesCalc)(trackingData, "lastYear");
    if (!totalNSVariance || !totalNS)
        return 0;
    return (totalNSVariance / totalNS) * 100;
}
exports.netSalesVariancePercent = netSalesVariancePercent;
/**
 * Column D & G & M
 * @param trackingData
 * @param timeKey - lastYear, currentYear or budget
 * @param keyName - fieldName
 */
const percentOfNetSalesCalc = (trackingData, timeKey, keyName) => {
    if (!trackingData)
        return 0;
    const netSales = (0, exports.netSalesCalc)(trackingData, timeKey);
    const keyObject = timeKey in trackingData ? trackingData[timeKey] : null;
    if (!keyObject || !(keyName in keyObject) || !keyObject[keyName] || !netSales)
        return 0;
    return (keyObject[keyName] / netSales) * 100;
};
exports.percentOfNetSalesCalc = percentOfNetSalesCalc;
/**
 * Columns D-G-M12
 * @param trackingData
 * @param timeKey
 * @param departmentType
 */
const totalPercentOfNetSalesCalc = (trackingData, timeKey, departmentType) => {
    if (!trackingData)
        return 0;
    let installOrServiceSales;
    if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
        installOrServiceSales =
            (0, exports.percentOfNetSalesCalc)(trackingData, timeKey, "spp") +
                (0, exports.percentOfNetSalesCalc)(trackingData, timeKey, "demandService") +
                (0, exports.percentOfNetSalesCalc)(trackingData, timeKey, "demandSystemCheckService") +
                (0, exports.percentOfNetSalesCalc)(trackingData, timeKey, "systemCheckService");
    }
    else {
        installOrServiceSales = (0, exports.percentOfNetSalesCalc)(trackingData, timeKey, "sales");
    }
    const percentOfCustom1 = (0, exports.percentOfNetSalesCalc)(trackingData, timeKey, "customField1");
    const percentOfCustom2 = (0, exports.percentOfNetSalesCalc)(trackingData, timeKey, "customField2");
    const percentOfCustom3 = (0, exports.percentOfNetSalesCalc)(trackingData, timeKey, "customField3");
    return (installOrServiceSales +
        percentOfCustom1 +
        percentOfCustom2 +
        percentOfCustom3);
};
exports.totalPercentOfNetSalesCalc = totalPercentOfNetSalesCalc;
const netSalesPercentageCalc = (trackingData, timeKey, departmentType) => {
    if (!trackingData)
        return 0;
    const totalSalesPercentage = (0, exports.totalPercentOfNetSalesCalc)(trackingData, timeKey, departmentType);
    const totalReturns = (0, exports.totalReturnsServicePercentCalc)(trackingData, timeKey);
    return totalSalesPercentage - totalReturns;
};
exports.netSalesPercentageCalc = netSalesPercentageCalc;
/**
 * Column I
 * @param trackingData
 * @param keyName
 */
const varianceCurrencyCalc = (trackingData, keyName) => {
    var _a, _b;
    if (!trackingData)
        return 0;
    const currentYear = "currentYear" in trackingData ? trackingData.currentYear : null;
    const lastYear = "lastYear" in trackingData ? trackingData.lastYear : null;
    return ((_a = currentYear[keyName]) !== null && _a !== void 0 ? _a : 0) - ((_b = lastYear[keyName]) !== null && _b !== void 0 ? _b : 0);
};
exports.varianceCurrencyCalc = varianceCurrencyCalc;
/**
 * Column J
 * @param trackingData
 * @param timeKey
 * @param keyName the key used to define
 */
const variancePercentCalc = (trackingData, timeKey, keyName) => {
    if (!trackingData)
        return 0;
    const keyObject = timeKey in trackingData ? trackingData[timeKey] : null;
    if (!keyObject || !(keyName in keyObject) || !keyObject[keyName])
        return 0;
    const varianceCurrency = (0, exports.varianceCurrencyCalc)(trackingData, keyName);
    if (!varianceCurrency)
        return 0;
    return (varianceCurrency / keyObject[keyName]) * 100;
};
exports.variancePercentCalc = variancePercentCalc;
/**
 * N18
 * @param trackingData
 */
function netSalesBudgetVariance(trackingData) {
    if (!trackingData)
        return 0;
    const totalNetSalesCalc1 = (0, exports.netSalesCalc)(trackingData, "currentYear");
    const totalNetSalesCalc2 = (0, exports.netSalesCalc)(trackingData, "budget");
    return totalNetSalesCalc1 - totalNetSalesCalc2;
}
exports.netSalesBudgetVariance = netSalesBudgetVariance;
/**
 * O18
 * @param trackingData
 */
const netSalesBudgetVariancePercent = (trackingData) => {
    if (!trackingData)
        return 0;
    const totalNSVariance = netSalesBudgetVariance(trackingData);
    const totalNS = (0, exports.netSalesCalc)(trackingData, "budget");
    if (!totalNSVariance || !totalNS)
        return 0;
    return (totalNSVariance / totalNS) * 100;
};
exports.netSalesBudgetVariancePercent = netSalesBudgetVariancePercent;
/**
 * C,F,L25
 * @param trackingData
 * @param timeKey
 */
const totalSumMaterialsCalc = (trackingData, timeKey) => {
    var _a, _b;
    if (!trackingData)
        return 0;
    const keyObject = timeKey in trackingData ? trackingData[timeKey] : null;
    if (!keyObject)
        return 0;
    const parts = (_a = keyObject.parts) !== null && _a !== void 0 ? _a : 0;
    const equipment = (_b = keyObject.equipment) !== null && _b !== void 0 ? _b : 0;
    return parts + equipment;
};
exports.totalSumMaterialsCalc = totalSumMaterialsCalc;
/**
 * C,F,L36
 * @param trackingData
 * @param timeKey
 */
const totalSumDirectLaborCalc = (trackingData, timeKey) => {
    var _a, _b, _c, _d, _e, _f;
    if (!trackingData)
        return 0;
    const keyObject = timeKey in trackingData ? trackingData[timeKey] : null;
    if (!keyObject)
        return 0;
    const wages = (_a = keyObject.wages) !== null && _a !== void 0 ? _a : 0;
    const ficaExpense = (_b = keyObject.ficaExpense) !== null && _b !== void 0 ? _b : 0;
    const workersCompInsurance = (_c = keyObject.workersCompInsurance) !== null && _c !== void 0 ? _c : 0;
    const unemploymentTax = (_d = keyObject.unemploymentTax) !== null && _d !== void 0 ? _d : 0;
    const medicalExpense = (_e = keyObject.medicalExpense) !== null && _e !== void 0 ? _e : 0;
    const retirementContributions = (_f = keyObject.retirementContributions) !== null && _f !== void 0 ? _f : 0;
    return (wages +
        ficaExpense +
        workersCompInsurance +
        unemploymentTax +
        medicalExpense +
        retirementContributions);
};
exports.totalSumDirectLaborCalc = totalSumDirectLaborCalc;
/**
 * C,F,L42
 * @param trackingData
 * @param timeKey
 */
const totalSumOtherDirectsCalc = (trackingData, timeKey) => {
    var _a, _b;
    if (!trackingData)
        return 0;
    const keyObject = timeKey in trackingData ? trackingData[timeKey] : null;
    if (!keyObject)
        return 0;
    const salesExpense = (_a = keyObject.salesExpense) !== null && _a !== void 0 ? _a : 0;
    const other = (_b = keyObject.other) !== null && _b !== void 0 ? _b : 0;
    return salesExpense + other;
};
exports.totalSumOtherDirectsCalc = totalSumOtherDirectsCalc;
/**
 * C,F,L44
 * @param trackingData
 * @param timeKey
 */
const totalSumDirectCostsCalc = (trackingData, timeKey) => {
    if (!trackingData)
        return 0;
    const keyObject = timeKey in trackingData ? trackingData[timeKey] : null;
    if (!keyObject)
        return 0;
    const totalSumMaterialsCalc1 = (0, exports.totalSumMaterialsCalc)(trackingData, timeKey);
    const totalSumDirectLaborCalc1 = (0, exports.totalSumDirectLaborCalc)(trackingData, timeKey);
    const totalSumOtherDirectsCalc1 = (0, exports.totalSumOtherDirectsCalc)(trackingData, timeKey);
    return (totalSumMaterialsCalc1 +
        totalSumDirectLaborCalc1 +
        totalSumOtherDirectsCalc1);
};
exports.totalSumDirectCostsCalc = totalSumDirectCostsCalc;
/**
 * col-D & col-G
 * (1 of 3 generic total calculations for Materials, Direct Labor & Other Directs)
 * @param trackingData
 * @param totalsCalc
 * @param timeKey
 */
const totalsPercentageCalc = (trackingData, totalsCalc, timeKey) => {
    if (!trackingData)
        return 0;
    const netSales = (0, exports.netSalesCalc)(trackingData, timeKey);
    if (!netSales)
        return 0;
    // the totals calculation passed in
    const totals = totalsCalc(trackingData, timeKey);
    return (totals / netSales) * 100;
};
exports.totalsPercentageCalc = totalsPercentageCalc;
/**
 * col-I
 * (2 of 3 generic total calculations for Materials, Direct Labor & Other Directs)
 * @param trackingData
 * @param totalsCalc
 */
const totalsVarianceCurrencyCalc = (trackingData, totalsCalc) => {
    if (!trackingData)
        return 0;
    const lastTotal = totalsCalc(trackingData, "lastYear");
    const currentTotal = totalsCalc(trackingData, "currentYear");
    return currentTotal - lastTotal;
};
exports.totalsVarianceCurrencyCalc = totalsVarianceCurrencyCalc;
/**T
 * col-J
 * (3 of 3 generic total calculations for Materials, Direct Labor & Other Directs)
 * @param trackingData
 * @param totalsCalc
 */
const totalsVariancePercentCalc = (trackingData, totalsCalc) => {
    if (!trackingData)
        return 0;
    const lastTotal = (0, exports.totalsPercentageCalc)(trackingData, totalsCalc, "lastYear");
    const currentTotal = (0, exports.totalsPercentageCalc)(trackingData, totalsCalc, "currentYear");
    return currentTotal - lastTotal;
};
exports.totalsVariancePercentCalc = totalsVariancePercentCalc;
/**
 * C,F,L46
 * @param trackingData
 * @param timeKey
 */
const grossMarginCalc = (trackingData, timeKey) => {
    if (!trackingData)
        return 0;
    const keyObject = timeKey in trackingData ? trackingData[timeKey] : null;
    if (!keyObject)
        return 0;
    const totalDirectCost = (0, exports.totalSumDirectCostsCalc)(trackingData, timeKey);
    const netSales = (0, exports.netSalesCalc)(trackingData, timeKey);
    return netSales - totalDirectCost;
};
exports.grossMarginCalc = grossMarginCalc;
/**
 * O48
 * @param trackingData
 * @param totalsCalc
 */
const grossMarginVariancePercentCalc = (trackingData) => {
    if (!trackingData)
        return 0;
    const lastTotal = (0, exports.totalsPercentageCalc)(trackingData, exports.grossMarginCalc, "lastYear");
    const currentTotal = (0, exports.totalsPercentageCalc)(trackingData, exports.grossMarginCalc, "currentYear");
    return lastTotal - currentTotal;
};
exports.grossMarginVariancePercentCalc = grossMarginVariancePercentCalc;
/**
 * M44
 * @param trackingData
 * @param totalsCalc
 */
const budgetedPercentCalc = (trackingData, totalsCalc) => {
    if (!trackingData)
        return 0;
    const netSales = (0, exports.netSalesCalc)(trackingData, "budget");
    const totalCalc = totalsCalc(trackingData, "budget");
    if (!netSales)
        return 0;
    return (totalCalc / netSales) * 100;
};
exports.budgetedPercentCalc = budgetedPercentCalc;
/**
 * Column O for totalsRow
 * @param trackingData
 * @param totalsCalc
 */
const totalsBudgetVariancePercent = (trackingData, totalsCalc) => {
    if (!trackingData)
        return 0;
    const variancePercent = (0, exports.totalsVariancePercentCalc)(trackingData, totalsCalc);
    const currentTotalPercent = (0, exports.totalsPercentageCalc)(trackingData, totalsCalc, "currentYear");
    return variancePercent - currentTotalPercent;
};
exports.totalsBudgetVariancePercent = totalsBudgetVariancePercent;
/**
 * Column N for totalsRow
 * @param trackingData
 * @param totalsCalc
 */
const totalsBudgetVarianceAmount = (trackingData, totalsCalc) => {
    if (!trackingData)
        return 0;
    const currentTotal = totalsCalc(trackingData, "currentYear");
    const budgetTotal = totalsCalc(trackingData, "budget");
    return currentTotal - budgetTotal;
};
exports.totalsBudgetVarianceAmount = totalsBudgetVarianceAmount;
/**
 * (N48 - service) (N47 - install)
 * @param trackingData
 */
const grossMarginBudgetVarianceAmount = (trackingData) => {
    if (!trackingData)
        return 0;
    const currentYear = (0, exports.grossMarginCalc)(trackingData, "currentYear");
    const budgetYear = (0, exports.grossMarginCalc)(trackingData, "budget");
    return currentYear - budgetYear;
};
exports.grossMarginBudgetVarianceAmount = grossMarginBudgetVarianceAmount;
/**
 * (O48 - service) (O47 - install)
 * @param trackingData
 */
const grossMarginBudgetVariancePercent = (trackingData) => {
    if (!trackingData)
        return 0;
    const variancePercent = (0, exports.budgetedPercentCalc)(trackingData, exports.grossMarginCalc);
    const currentTotalPercent = (0, exports.totalsPercentageCalc)(trackingData, exports.grossMarginCalc, "currentYear");
    return currentTotalPercent - variancePercent;
};
exports.grossMarginBudgetVariancePercent = grossMarginBudgetVariancePercent;
