"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serviceSA = exports.serviceDC = exports.serviceCC = exports.serviceMC = exports.serviceCAS = void 0;
const utils_1 = require("../../../utils");
exports.serviceCAS = {
    PK: "MEMBER#6982663160",
    SK: "01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#CONV_AVG_SALE#LATEST",
    LSI1SK: "WORKSHOP#01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#LATEST",
    LSI2SK: "WORKSHOP#2023#CONV_AVG_SALE#LATEST",
    fiscalYear: 2023,
    departmentId: "01GBAZ4RZDRGYDCHEBNXFVT2Q9",
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentName: "HVAC Service",
    jan: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    feb: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    mar: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    apr: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    may: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    jun: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    jul: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    aug: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    sep: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    oct: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    nov: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
    dec: {
        budgetTechGeneratedClosingRate: null,
        budgetedMarketedClosingRate: null,
        budgetedOtherLeadsClosingRate: null,
        budgetedTechGeneratedLeadsAvgSale: null,
        budgetedMarketedLeadsAvgSale: null,
        budgetedOtherLeadsAvgSale: null,
        systemCheckBudgetYearConvRate: 0,
        demandCallBudgetYearConvRate: 0,
        systemCheckBudgetYearAvgSale: 0,
        demandCallBudgetYearAvgSale: 0,
        demandSystemCheckCallBudgetYearConvRate: null,
        demandSystemCheckCallBudgetYearAvgSale: null,
    },
};
exports.serviceMC = {
    PK: "MEMBER#6982663160",
    SK: "01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#MAX_CAPACITY#LATEST",
    LSI1SK: "WORKSHOP#01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#LATEST",
    LSI2SK: "WORKSHOP#2023#MAX_CAPACITY#LATEST",
    fiscalYear: 2023,
    departmentId: "01GBAZ4RZDRGYDCHEBNXFVT2Q9",
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentName: "HVAC Service",
    useOpportunity: false,
    jan: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 11.88,
        numOfDemandTurnovers: 86.13000000000001,
        monthlyTurnovers: 98.01,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    feb: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 11.88,
        numOfDemandTurnovers: 77.22000000000001,
        monthlyTurnovers: 89.10000000000001,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    mar: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 11.88,
        numOfDemandTurnovers: 90.585,
        monthlyTurnovers: 102.46499999999999,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    apr: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 11.88,
        numOfDemandTurnovers: 77.22000000000001,
        monthlyTurnovers: 89.10000000000001,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    may: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 11.88,
        numOfDemandTurnovers: 86.13000000000001,
        monthlyTurnovers: 98.01,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    jun: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 11.88,
        numOfDemandTurnovers: 86.13000000000001,
        monthlyTurnovers: 98.01,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    jul: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 17.82,
        numOfDemandTurnovers: 71.28,
        monthlyTurnovers: 89.1,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    aug: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 17.82,
        numOfDemandTurnovers: 84.64500000000001,
        monthlyTurnovers: 102.465,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    sep: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 17.82,
        numOfDemandTurnovers: 71.28,
        monthlyTurnovers: 89.1,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    oct: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 17.82,
        numOfDemandTurnovers: 80.19,
        monthlyTurnovers: 98.00999999999999,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    nov: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 17.82,
        numOfDemandTurnovers: 71.28,
        monthlyTurnovers: 89.1,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    dec: {
        budgetYearPercOfSystemCheckOpportunity: 33,
        budgetYearPercOfDemandCallsOpportunity: 33,
        budgetYearPercOfSystemCheckTurnover: 60,
        budgetYearPercOfDemandCallsTurnover: 60,
        numOfSystemTurnovers: 17.82,
        numOfDemandTurnovers: 71.28,
        monthlyTurnovers: 89.1,
        budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
        budgetYearPercOfDemandSystemCheckCallsTurnover: null,
        numOfDemandSystemCheckTurnovers: null,
    },
    maxCapacityTotals: {
        totalSystemTurnovers: 178.19999999999996,
        totalDemandTurnovers: 953.3699999999999,
        totalDemandSystemCheckTurnovers: null,
        totalTurnovers: 1131.57,
    },
};
exports.serviceCC = {
    PK: "MEMBER#6982663160",
    SK: "01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#CALL_COUNT#LATEST",
    LSI1SK: "WORKSHOP#01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#LATEST",
    LSI2SK: "WORKSHOP#2023#CALL_COUNT#LATEST",
    fiscalYear: 2023,
    departmentId: "01GBAZ4RZDRGYDCHEBNXFVT2Q9",
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentName: "HVAC Service",
    callCountTotals: {
        totalSumPriorYearAgreementSystemChecks: null,
        totalSumBudgetedSystemCheckCallCount: null,
        totalSumPriorYearDemandCallCount: null,
        totalSumPriorYearDemandSystemCheckCallCount: null,
        totalSumPriorYearTechGeneratedLeads: null,
        totalSumBudgetedTechGeneratedLeads: null,
        totalSumPriorYearMarketedLeadCount: null,
        totalSumPriorYearOtherLeadCount: null,
        totalSumRequiredDemandCallCount: null,
        totalSumRequiredDemandSystemCheckCallCount: null,
        totalSumMarketedLeadCount: null,
        totalConvertedTechLeadCount: null,
        totalSumOtherLeadCount: null,
        totalTechLeadRevenue: null,
        totalConvertedMarketedLeadCount: null,
        totalMarketedLeadRevenue: null,
        totalConvertedOtherLeadCount: null,
        totalOtherLeadRevenue: null,
        totalDemandConvertedCalls: null,
        totalDemandSystemCheckConvertedCalls: null,
        totalDemandRevenue: null,
        totalDemandSystemCheckRevenue: null,
        totalSystemCheckConvertedCalls: null,
        totalSystemCheckRevenue: null,
    },
    jan: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    feb: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    mar: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    apr: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    may: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    jun: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    jul: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    aug: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    sep: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    oct: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    nov: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
    dec: {
        targetNumOfCallsRanPerTech: 2.5,
        budgetedNumOfTechs: 9,
        targetNumOfLeadsRanPerSalesperson: null,
        budgetedNumOfSalespersons: null,
        targetNumOfJobsPerCrew: null,
        budgetedNumOfCrews: null,
        monthlyTotalRevenueCalc: 0,
        percentOfDemandCallsThatAreDemandSystemCheck: null,
        monthlyDemandCallRevenue: null,
        monthlyDemandSystemCheckCallRevenue: null,
        monthlySystemCheckCallRevenue: null,
    },
};
exports.serviceDC = {
    PK: "MEMBER#6982663160",
    SK: "01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#DIRECT_COSTS#LATEST",
    LSI1SK: "WORKSHOP#01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#LATEST",
    LSI2SK: "WORKSHOP#2023#DIRECT_COSTS#LATEST",
    fiscalYear: 2023,
    departmentId: "01GBAZ4RZDRGYDCHEBNXFVT2Q9",
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentName: "HVAC Service",
    yearly: {
        subcontractorPercInput: 0,
        refundPercInput: 2,
        financeFeePercInput: 0,
        soldBreakEvenFromPPInput: 450,
        profitPercOnMaterialsInput: 25,
        soldProfitAndCommissionInput: null,
        desiredProfitPlusCommissionInput: null,
        pricerId: null,
        pricerDepartmentId: null,
    },
    jan: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 6500,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 2275,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 975,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 3250,
        soldHourPerMonthGoal: 11.555555555555555,
        soldDayPerMonthInstallGoal: null,
    },
    feb: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 7000,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 2450,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1050,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 3500,
        soldHourPerMonthGoal: 12.444444444444445,
        soldDayPerMonthInstallGoal: null,
    },
    mar: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 7500,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 2625,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1125,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 3750,
        soldHourPerMonthGoal: 13.333333333333334,
        soldDayPerMonthInstallGoal: null,
    },
    apr: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 8000,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 2800,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1200,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 4000,
        soldHourPerMonthGoal: 14.222222222222221,
        soldDayPerMonthInstallGoal: null,
    },
    may: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 8500,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 2975,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1275,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 4250,
        soldHourPerMonthGoal: 15.11111111111111,
        soldDayPerMonthInstallGoal: null,
    },
    jun: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 9000,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 3150,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1350,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 4500,
        soldHourPerMonthGoal: 16,
        soldDayPerMonthInstallGoal: null,
    },
    jul: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 9500,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 3325,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1425,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 4750,
        soldHourPerMonthGoal: 16.88888888888889,
        soldDayPerMonthInstallGoal: null,
    },
    aug: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 10000,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 3500,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1500,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 5000,
        soldHourPerMonthGoal: 17.77777777777778,
        soldDayPerMonthInstallGoal: null,
    },
    sep: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 10500,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 3674.9999999999995,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1575,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 5250,
        soldHourPerMonthGoal: 18.666666666666668,
        soldDayPerMonthInstallGoal: null,
    },
    oct: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 11000,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 3849.9999999999995,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1650,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 5500,
        soldHourPerMonthGoal: 19.555555555555557,
        soldDayPerMonthInstallGoal: null,
    },
    nov: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 11500,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 4024.9999999999995,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1725,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 5750,
        soldHourPerMonthGoal: 20.444444444444443,
        soldDayPerMonthInstallGoal: null,
    },
    dec: {
        demandAndSystemCheckSales: 0,
        sppRevenue: 12000,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 0,
        financeFeeAmount: 0,
        budgetedLaborPerInput: 35,
        budgetedLaborAmount: 4200,
        budgetedMaterialPerInput: 15,
        budgetedMaterialAmount: 1800,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 6000,
        soldHourPerMonthGoal: 21.333333333333332,
        soldDayPerMonthInstallGoal: null,
    },
};
exports.serviceSA = {
    PK: "MEMBER#6982663160",
    SK: "01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#AGREEMENTS#LATEST",
    LSI1SK: "WORKSHOP#01GBAZ4RZDRGYDCHEBNXFVT2Q9#2023#LATEST",
    LSI2SK: "WORKSHOP#2023#AGREEMENTS#LATEST",
    fiscalYear: 2023,
    departmentId: "01GBAZ4RZDRGYDCHEBNXFVT2Q9",
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentName: "HVAC Service",
    monthly: {
        numOfAgreementsStartOfYear: 350,
        projectedNumOfNewAgreements: 200,
        projectedNumOfAgreementsEndOfYear: 500,
        retentionRateGoalNextYear: 80,
        goalForNewAgreementsToAdd: 400,
    },
    annual: {
        numOfAgreementsStartOfYear: null,
        projectedNumOfNewAgreements: null,
        projectedNumOfAgreementsEndOfYear: null,
        retentionRateGoalNextYear: null,
        goalForNewAgreementsToAdd: null,
    },
    multiTradeSalesDistribution: null,
    numOfVisits: 2,
    newRunRate: 90,
    visitsAtTimeOfSale: false,
    revenueRecognizedPerSystemCheckVisit: null,
    totalPriorYearAgreements: 500,
    totalNewAndRetainedAgreementsForDepartment: 800,
    totalAgreementRevenue: 111000,
    totalNewAgreementsSold: 400,
    jan: {
        totalVisitsBasedOnTimeOfSaleCalculation: 60,
        totalVisitIf6MonthFromSale: 60,
        totalRevenueMonthlyRecognition: 6500,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 7830,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 5400,
        retainedAgreementsVisits: 60,
    },
    feb: {
        totalVisitsBasedOnTimeOfSaleCalculation: 60,
        totalVisitIf6MonthFromSale: 60,
        totalRevenueMonthlyRecognition: 7000,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 7830,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 5400,
        retainedAgreementsVisits: 60,
    },
    mar: {
        totalVisitsBasedOnTimeOfSaleCalculation: 60,
        totalVisitIf6MonthFromSale: 60,
        totalRevenueMonthlyRecognition: 7500,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 7830,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 5400,
        retainedAgreementsVisits: 60,
    },
    apr: {
        totalVisitsBasedOnTimeOfSaleCalculation: 60,
        totalVisitIf6MonthFromSale: 60,
        totalRevenueMonthlyRecognition: 8000,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 7830,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 5400,
        retainedAgreementsVisits: 60,
    },
    may: {
        totalVisitsBasedOnTimeOfSaleCalculation: 60,
        totalVisitIf6MonthFromSale: 60,
        totalRevenueMonthlyRecognition: 8500,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 7830,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 5400,
        retainedAgreementsVisits: 60,
    },
    jun: {
        totalVisitsBasedOnTimeOfSaleCalculation: 60,
        totalVisitIf6MonthFromSale: 60,
        totalRevenueMonthlyRecognition: 9000,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 7830,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 5400,
        retainedAgreementsVisits: 60,
    },
    jul: {
        totalVisitsBasedOnTimeOfSaleCalculation: 90,
        totalVisitIf6MonthFromSale: 90,
        totalRevenueMonthlyRecognition: 9500,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 10530,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 8100,
        retainedAgreementsVisits: 60,
    },
    aug: {
        totalVisitsBasedOnTimeOfSaleCalculation: 90,
        totalVisitIf6MonthFromSale: 90,
        totalRevenueMonthlyRecognition: 10000,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 10530,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 8100,
        retainedAgreementsVisits: 60,
    },
    sep: {
        totalVisitsBasedOnTimeOfSaleCalculation: 90,
        totalVisitIf6MonthFromSale: 90,
        totalRevenueMonthlyRecognition: 10500,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 10530,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 8100,
        retainedAgreementsVisits: 60,
    },
    oct: {
        totalVisitsBasedOnTimeOfSaleCalculation: 90,
        totalVisitIf6MonthFromSale: 90,
        totalRevenueMonthlyRecognition: 11000,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 10530,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 8100,
        retainedAgreementsVisits: 60,
    },
    nov: {
        totalVisitsBasedOnTimeOfSaleCalculation: 90,
        totalVisitIf6MonthFromSale: 90,
        totalRevenueMonthlyRecognition: 11500,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 10530,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 8100,
        retainedAgreementsVisits: 60,
    },
    dec: {
        totalVisitsBasedOnTimeOfSaleCalculation: 90,
        totalVisitIf6MonthFromSale: 90,
        totalRevenueMonthlyRecognition: 12000,
        totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 10530,
        totalRevenueTimeOfVisit_Visit6MonthFromSale: 8100,
        retainedAgreementsVisits: 60,
    },
};
