import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function ChevronUpDownIcon({
  size = "medium",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m387.77,359.45c-7.67-7.67-20.11-7.67-27.79,0l-103.99,103.99-103.99-103.99c-7.67-7.67-20.11-7.67-27.79,0s-7.67,20.11,0,27.79l117.89,117.89c7.67,7.67,20.11,7.67,27.79,0l117.88-117.9c7.67-7.67,7.67-20.11,0-27.79h0Z" />
      <path d="m124.23,152.43c7.67,7.67,20.11,7.67,27.79,0l103.99-103.99,103.99,103.99c7.67,7.67,20.11,7.67,27.79,0,7.67-7.67,7.67-20.11,0-27.79L269.89,6.76c-7.67-7.67-20.11-7.67-27.79,0l-117.88,117.9c-7.67,7.67-7.67,20.11,0,27.79h0Z" />
    </svg>
  );
}
