import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function LogoutIcon({
  size = "small",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        stroke={IconColors[color]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="56.32px"
      >
        <path d="m466.27,249.95l-293.42,1.69" />
        <path d="m389.01,163.12l84.65,84.65-84.08,84.08" />
        <path d="m299.78,93.4H49.35v316.63h164.49" />
      </g>
      <polyline
        fill="none"
        stroke={IconColors[color]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="56.32px"
        points="299.78 410.03 49.35 410.03 49.35 93.4 299.78 93.4"
      />
    </svg>
  );
}
