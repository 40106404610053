import React, { ReactElement } from "react";

export default function NexstarSwoosh(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 552.16 792"
    >
      <path
        fill="#1f3c6c"
        d="m103.89,760.95c-64.09-37.36-73.69-146.9-32.49-270.44l18.8-49.09c10.43-24.19,22.66-48.6,36.77-72.85,37.15-63.83,81.87-117.21,127.3-156.16-67.08,30.29-135.96,94.85-186.81,182.12-2.02,3.46-3.94,6.93-5.87,10.41l-22.16,44.49c-61.57,139.38-50.93,278.13,31.28,325.93,86.91,50.85,223.24-18.75,315.2-156.51-93.4,121.54-210.25,183.92-282.02,142.09Z"
      />
      <path
        fill="#e29025"
        d="m281.32,199.79c19.12-10.46,38.23-19.84,57.7-28.13,58.06-23.8,113.96-35.34,153.63-29.21,9.74,1.8,18.03,4.33,24.53,7.58l34.98-16.59c-7.57-3.25-16.23-5.77-26.33-7.57-1.8-.36-3.97-.72-5.77-.72l-21.64-1.44c-54.1-.72-124.78,19.84-192.22,54.46,45.08-25.61,91.6-50.49,135.6-63.11h0L471.74,0l-110.35,111.44-123.7,1.8,53.01,76.81-9.38,9.74h0Z"
      />
      <path
        fill="#e29025"
        d="m396,168.06c-49.77,16.59-102.78,45.08-146.06,81.14-24.16,20.2-42.55,40.39-54.46,59.14l129.11-60.59,59.5,71.77,33.54-116.85,91.96-45.8c-28.13-8.3-68.88-3.61-113.6,11.18h0Z"
      />
    </svg>
  );
}
