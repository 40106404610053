import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function ReportsIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m911.27,130.8V47.69c0-20.26-16.38-36.63-36.64-36.63H150.32c-20.26,0-36.77,16.38-36.77,36.63v917.04c0,20.26,16.51,36.63,36.77,36.63h82.97V236.81c0-58.41,47.6-106.01,106.15-106.01h571.82Z" />
      <path d="m1063.77,200.18H339.45c-20.26,0-36.77,16.38-36.77,36.63v917.04c0,20.12,16.51,36.63,36.77,36.63h724.31c20.12,0,36.63-16.51,36.63-36.63V236.81c0-20.26-16.51-36.63-36.63-36.63h0Zm-78.53,842.93H417.86c-19.15,0-34.69-15.54-34.69-34.69s15.54-34.69,34.69-34.69h567.38c19.15,0,34.69,15.54,34.69,34.69s-15.54,34.69-34.69,34.69h0Zm0-208.69H417.86c-19.15,0-34.69-15.54-34.69-34.69s15.54-34.69,34.69-34.69h567.38c19.15,0,34.69,15.54,34.69,34.69s-15.54,34.69-34.69,34.69h0Zm0-208.83H417.86c-19.15,0-34.69-15.54-34.69-34.69s15.54-34.69,34.69-34.69h567.38c19.15,0,34.69,15.54,34.69,34.69s-15.54,34.69-34.69,34.69h0Zm0-208.68H417.86c-19.15,0-34.69-15.54-34.69-34.69s15.54-34.69,34.69-34.69h567.38c19.15,0,34.69,15.54,34.69,34.69s-15.54,34.69-34.69,34.69h0Z" />
    </svg>
  );
}
