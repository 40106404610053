"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNexstarCoach = exports.isNexstarBase = exports.nexstarAccessLevel = exports.nexstarAllEmployees = exports.nexstarBaseEmployees = exports.nexstarCoaches = exports.UserAccessLevels = void 0;
var UserAccessLevels;
(function (UserAccessLevels) {
    UserAccessLevels[UserAccessLevels["ROOT_ADMINISTRATOR"] = 1] = "ROOT_ADMINISTRATOR";
    UserAccessLevels[UserAccessLevels["NEXSTAR_TRUSTED_USER"] = 2] = "NEXSTAR_TRUSTED_USER";
    UserAccessLevels[UserAccessLevels["STAFF"] = 3] = "STAFF";
    UserAccessLevels[UserAccessLevels["COACHING_MANAGER"] = 4] = "COACHING_MANAGER";
    UserAccessLevels[UserAccessLevels["TRUSTED_USER"] = 5] = "TRUSTED_USER";
    UserAccessLevels[UserAccessLevels["BASE_USER"] = 6] = "BASE_USER";
    UserAccessLevels[UserAccessLevels["EMPLOYEE_EXPERIENCE"] = 7] = "EMPLOYEE_EXPERIENCE";
    UserAccessLevels[UserAccessLevels["BUYERS_GROUP"] = 8] = "BUYERS_GROUP";
    UserAccessLevels[UserAccessLevels["STRATEGIC_PARTNER"] = 9] = "STRATEGIC_PARTNER";
    UserAccessLevels[UserAccessLevels["ACCOUNTING"] = 10] = "ACCOUNTING";
    UserAccessLevels[UserAccessLevels["EVENT_PLANNER"] = 11] = "EVENT_PLANNER";
    UserAccessLevels[UserAccessLevels["SITE_ADMIN"] = 12] = "SITE_ADMIN";
    UserAccessLevels[UserAccessLevels["STAFF_TESTING"] = 13] = "STAFF_TESTING";
    UserAccessLevels[UserAccessLevels["SUPER_MEETING_PARTICIPANT"] = 14] = "SUPER_MEETING_PARTICIPANT";
    UserAccessLevels[UserAccessLevels["EXPLORE_THE_TRADES"] = 15] = "EXPLORE_THE_TRADES";
    UserAccessLevels[UserAccessLevels["BUSINESS_COACH"] = 16] = "BUSINESS_COACH";
    UserAccessLevels[UserAccessLevels["MARKETING_COACH"] = 17] = "MARKETING_COACH";
    UserAccessLevels[UserAccessLevels["DIGITAL_MARKETING_COACH"] = 18] = "DIGITAL_MARKETING_COACH";
    UserAccessLevels[UserAccessLevels["TALENT_COACH"] = 19] = "TALENT_COACH";
    UserAccessLevels[UserAccessLevels["FINANCE_COACH"] = 20] = "FINANCE_COACH";
    UserAccessLevels[UserAccessLevels["IMPLEMENTATION_COACH"] = 21] = "IMPLEMENTATION_COACH";
    UserAccessLevels[UserAccessLevels["TECHNICAL_COACH"] = 22] = "TECHNICAL_COACH";
    UserAccessLevels[UserAccessLevels["CUSTOMER_EXPERIENCE_COACH"] = 23] = "CUSTOMER_EXPERIENCE_COACH";
    UserAccessLevels[UserAccessLevels["CONNECTIONS_COACH"] = 24] = "CONNECTIONS_COACH";
    UserAccessLevels[UserAccessLevels["ACCOUNTING_COACH"] = 25] = "ACCOUNTING_COACH";
    UserAccessLevels[UserAccessLevels["RECRUITING_RETENTION_COACH"] = 26] = "RECRUITING_RETENTION_COACH";
    UserAccessLevels[UserAccessLevels["WORKFORCE_DEVELOPMENT_COACH"] = 27] = "WORKFORCE_DEVELOPMENT_COACH";
    UserAccessLevels[UserAccessLevels["INVENTORY_COACH"] = 30] = "INVENTORY_COACH";
    UserAccessLevels[UserAccessLevels["REBATE_COACH"] = 31] = "REBATE_COACH";
    UserAccessLevels[UserAccessLevels["LEADERSHIP_COACH"] = 32] = "LEADERSHIP_COACH";
    UserAccessLevels[UserAccessLevels["SALES_COACH"] = 33] = "SALES_COACH";
    UserAccessLevels[UserAccessLevels["SOFTWARE_COACH"] = 34] = "SOFTWARE_COACH";
    UserAccessLevels[UserAccessLevels["ENGAGEMENT_COACH"] = 35] = "ENGAGEMENT_COACH";
    UserAccessLevels[UserAccessLevels["UNDEFINED"] = 36] = "UNDEFINED";
    UserAccessLevels[UserAccessLevels["SALES"] = 37] = "SALES";
})(UserAccessLevels || (exports.UserAccessLevels = UserAccessLevels = {}));
exports.nexstarCoaches = [
    UserAccessLevels.BUSINESS_COACH,
    UserAccessLevels.MARKETING_COACH,
    UserAccessLevels.DIGITAL_MARKETING_COACH,
    UserAccessLevels.TALENT_COACH,
    UserAccessLevels.FINANCE_COACH,
    UserAccessLevels.IMPLEMENTATION_COACH,
    UserAccessLevels.TECHNICAL_COACH,
    UserAccessLevels.CUSTOMER_EXPERIENCE_COACH,
    UserAccessLevels.CONNECTIONS_COACH,
    UserAccessLevels.ACCOUNTING_COACH,
    UserAccessLevels.RECRUITING_RETENTION_COACH,
    UserAccessLevels.WORKFORCE_DEVELOPMENT_COACH,
    UserAccessLevels.INVENTORY_COACH,
    UserAccessLevels.REBATE_COACH,
    UserAccessLevels.LEADERSHIP_COACH,
    UserAccessLevels.SALES_COACH,
    UserAccessLevels.SOFTWARE_COACH,
    UserAccessLevels.ENGAGEMENT_COACH,
];
exports.nexstarBaseEmployees = [
    UserAccessLevels.STAFF,
    UserAccessLevels.EMPLOYEE_EXPERIENCE,
    UserAccessLevels.ACCOUNTING,
    UserAccessLevels.EVENT_PLANNER,
    UserAccessLevels.SALES,
];
exports.nexstarAllEmployees = [
    UserAccessLevels.ROOT_ADMINISTRATOR,
    UserAccessLevels.NEXSTAR_TRUSTED_USER,
    UserAccessLevels.COACHING_MANAGER,
    ...exports.nexstarCoaches,
    ...exports.nexstarBaseEmployees,
];
function nexstarAccessLevel(accessLevel) {
    return exports.nexstarAllEmployees.includes(accessLevel);
}
exports.nexstarAccessLevel = nexstarAccessLevel;
function isNexstarBase(accessLevel) {
    return exports.nexstarBaseEmployees.includes(accessLevel);
}
exports.isNexstarBase = isNexstarBase;
function isNexstarCoach(accessLevel) {
    return exports.nexstarCoaches.includes(accessLevel);
}
exports.isNexstarCoach = isNexstarCoach;
