import React, { ReactElement, useState } from "react";
import { useUserContext } from "../context/user/UserContext";
import {
  AccessLevelIdOrDefault,
  CRMUser,
  UserIdOrDefault,
  UserNameOrDefault,
} from "../../utils/helpers/UserHelper";
import Accordion from "../../utils/components/tailwind/Accordion";
import { NexstarTextField } from "../../utils/components/tailwind/NexstarTextField";
import Button from "../../utils/components/tailwind/Button";

function UserEmulationDropdown({ user }: { user: CRMUser }): ReactElement {
  const { emulatedUser, handleCancelEmulation, handleEmulatingUser } =
    useUserContext();

  const [userID, setUserID] = useState("");
  if (!(AccessLevelIdOrDefault(user) === 1 || UserIdOrDefault(user) === 581))
    return null;
  return (
    <Accordion
      title={
        emulatedUser ? (
          <p>{`Emulating ${UserNameOrDefault(emulatedUser)}`}</p>
        ) : (
          <p>Emulate User</p>
        )
      }
      rounded={false}
      id="userEmulation_Accordion"
    >
      <>
        {!emulatedUser ? (
          <div className="flex gap-4 items-end">
            <NexstarTextField
              id="emulationUser"
              name="emulationUser"
              label="Emulation User Id"
              value={userID}
              onChange={(event) => setUserID(event.target.value)}
              textAlign="text-center"
            />
            <Button
              id="emulateUser"
              bgColor="bg-NexstarBlue"
              onClick={() => handleEmulatingUser(userID)}
            >
              Emulate
            </Button>
          </div>
        ) : null}
        {emulatedUser ? (
          <Button id="cancelEmulation" onClick={() => handleCancelEmulation()}>
            Cancel
          </Button>
        ) : null}
      </>
    </Accordion>
  );
}

export default UserEmulationDropdown;
