"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monthlyNetIncomePercent = exports.monthlyOverheadPercentTotals = exports.monthlyManualPercentCalc = exports.monthlySeasonalityPercentCalc = exports.monthlySeasonalityCalc = exports.monthlyFlatVarianceCalc = void 0;
const monthlyFlatVarianceCalc = (overhead, companyData, month) => {
    if (!companyData[month].totalIncome)
        return 0;
    return (overhead.nextYearTotal / 12 / companyData[month].totalIncome) * 100;
};
exports.monthlyFlatVarianceCalc = monthlyFlatVarianceCalc;
const monthlySeasonalityCalc = (overhead, companyData, month) => {
    if (!companyData.companyTotals.totalIncome)
        return 0;
    return (overhead.nextYearTotal *
        (companyData[month].totalIncome / companyData.companyTotals.totalIncome));
};
exports.monthlySeasonalityCalc = monthlySeasonalityCalc;
const monthlySeasonalityPercentCalc = (overhead, companyData, month) => {
    if (!companyData[month].totalIncome)
        return 0;
    return (((0, exports.monthlySeasonalityCalc)(overhead, companyData, month) /
        companyData[month].totalIncome) *
        100);
};
exports.monthlySeasonalityPercentCalc = monthlySeasonalityPercentCalc;
const monthlyManualPercentCalc = (monthlyManual, companyData, month) => {
    if (!companyData[month].totalIncome)
        return 0;
    return (monthlyManual / companyData[month].totalIncome) * 100;
};
exports.monthlyManualPercentCalc = monthlyManualPercentCalc;
const monthlyOverheadPercentTotals = (monthlyOverheadTotal, companyData, month) => {
    if (!companyData[month].totalIncome)
        return 0;
    return (monthlyOverheadTotal / companyData[month].totalIncome) * 100;
};
exports.monthlyOverheadPercentTotals = monthlyOverheadPercentTotals;
const monthlyNetIncomePercent = (monthlyNetIncome, companyData, month) => {
    if (!companyData[month].totalIncome)
        return 0;
    return (monthlyNetIncome / companyData[month].totalIncome) * 100;
};
exports.monthlyNetIncomePercent = monthlyNetIncomePercent;
