import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AppState } from "@auth0/auth0-react/src/auth0-provider";

function Auth0ProviderWithHistory({
  children,
}: Record<string | number | symbol, React.ReactNode>): JSX.Element {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return process.env.REACT_APP_NODE_ENV !== "development" ? (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirectUri: window.location.origin,
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
        scope: `${process.env.REACT_APP_AUTH0_SCOPE}`,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  ) : (
    <div>{children}</div>
  );
}

export default Auth0ProviderWithHistory;
