"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processRSYearlyData = exports.workshopType = exports.buildRSService = exports.buildRSInstall = exports.buildRSDeptTotals = exports.buildOtherLeads = exports.buildMarketedLeads = exports.buildTechLeads = exports.buildAgreements = exports.buildSystemChecks = exports.buildDemandCalls = void 0;
const revenueSummaryServiceCalcsFromFE_1 = require("../revenueSummaryServiceCalcsFromFE");
const revenueSummaryInstallCalcsFromFE_1 = require("../revenueSummaryInstallCalcsFromFE");
const revenueSummaryTotalCalcs_1 = require("../revenueSummaryTotalCalcs");
const utils_1 = require("../../../../../utils");
const helpers_1 = require("../../../../utils/helpers");
function buildDemandCalls(preWork, workshopData, departmentId, demandCallType) {
    var _a, _b, _c, _d, _e, _f;
    let demandCalls = {};
    const conversionRate = demandCallType === "demandCalls"
        ? (_a = (0, utils_1.objectFieldValidator)(preWork, [
            departmentId,
            "nonMonthlyData",
            "serviceAvgCall",
            "convRateLastYear",
        ], true)) !== null && _a !== void 0 ? _a : 0
        : (_b = (0, utils_1.objectFieldValidator)(preWork, [
            departmentId,
            "nonMonthlyData",
            "serviceDemandSystemCheckAvgCall",
            "convRateLastYear",
        ], true)) !== null && _b !== void 0 ? _b : 0;
    const averageSale = demandCallType === "demandCalls"
        ? (_c = (0, utils_1.objectFieldValidator)(preWork, [departmentId, "nonMonthlyData", "serviceAvgCall", "avgSaleLastYear"], true)) !== null && _c !== void 0 ? _c : 0
        : (_d = (0, utils_1.objectFieldValidator)(preWork, [
            departmentId,
            "nonMonthlyData",
            "serviceDemandSystemCheckAvgCall",
            "avgSaleLastYear",
        ], true)) !== null && _d !== void 0 ? _d : 0;
    const dispatchedCalls = demandCallType === "demandCalls"
        ? (_e = (0, utils_1.objectFieldValidator)(workshopData, [departmentId, "callCount", "totalSumRequiredDemandCallCount"], true)) !== null && _e !== void 0 ? _e : 0
        : (_f = (0, utils_1.objectFieldValidator)(workshopData, [
            departmentId,
            "callCount",
            "totalSumRequiredDemandSystemCheckCallCount",
        ], true)) !== null && _f !== void 0 ? _f : 0;
    demandCalls = {
        ...demandCalls,
        previousYear: {
            dispatchedCalls: (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearDispatchedCalls)(preWork, departmentId, demandCallType),
            conversionRate,
            turnoverPercent: (0, revenueSummaryServiceCalcsFromFE_1.calcDemandPreviousYearTurnoverPercent)(preWork, departmentId, demandCallType),
            convertedCalls: (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearConvertedCalls)(preWork, departmentId, demandCallType),
            averageSale,
            demandRevenue: (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearDemandRevenue)(preWork, departmentId, demandCallType),
            growthOverLastYear: (0, revenueSummaryServiceCalcsFromFE_1.calcGrowthOverLastYear)(preWork, workshopData, departmentId, demandCallType),
        },
    };
    demandCalls = {
        ...demandCalls,
        budgetYear: {
            dispatchedCalls,
            conversionRate: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetYearConversionRate)(workshopData, departmentId, demandCallType) * 100,
            turnoverPercent: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetYearTurnoverPercent)(workshopData, departmentId, demandCallType) * 100,
            convertedCalls: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetYearConvertedCalls)(workshopData, departmentId, demandCallType),
            averageSale: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetYearAverageSale)(workshopData, departmentId, demandCallType),
            demandRevenue: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearDemandRevenue)(workshopData, departmentId, demandCallType),
            growthOverLastYear: (0, revenueSummaryServiceCalcsFromFE_1.calcGrowthOverLastYearPercent)(preWork, workshopData, departmentId, demandCallType),
        },
    };
    return demandCalls;
}
exports.buildDemandCalls = buildDemandCalls;
function buildSystemChecks(preWork, workshopData, departmentId) {
    let systemChecks = {};
    systemChecks = {
        ...systemChecks,
        previousYear: {
            dispatchedCalls: (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearSystemCheckDispatchedCalls)(preWork, departmentId),
            conversionRate: !preWork ||
                !(departmentId in preWork) ||
                !("nonMonthlyData" in preWork[departmentId]) ||
                !("serviceSystemCheck" in preWork[departmentId].nonMonthlyData)
                ? 0
                : preWork[departmentId].nonMonthlyData.serviceSystemCheck
                    .convRateLastYear || 0,
            turnoverPercent: (0, revenueSummaryServiceCalcsFromFE_1.calcSystemCheckPreviousYearTurnoverPercent)(preWork, departmentId),
            convertedCalls: (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearSystemCheckConvertedCalls)(preWork, departmentId),
            averageSale: !preWork ||
                !(departmentId in preWork) ||
                !("nonMonthlyData" in preWork[departmentId]) ||
                !("serviceSystemCheck" in preWork[departmentId].nonMonthlyData)
                ? 0
                : preWork[departmentId].nonMonthlyData.serviceSystemCheck
                    .avgSaleLastYear || 0,
            demandRevenue: (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearSystemCheckRevenue)(preWork, departmentId),
            growthOverLastYear: (0, revenueSummaryServiceCalcsFromFE_1.calcSystemCheckGrowthOverLastYear)(preWork, workshopData, departmentId),
        },
    };
    systemChecks = {
        ...systemChecks,
        budgetYear: {
            dispatchedCalls: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearSystemCheckCalls)(workshopData, departmentId),
            conversionRate: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearSystemCheckConversionRate)(workshopData, departmentId) *
                100,
            turnoverPercent: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearSystemCheckTurnoverRate)(workshopData, departmentId) *
                100,
            convertedCalls: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearSystemCheckConvertedCalls)(workshopData, departmentId),
            averageSale: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearSystemCheckAverageSale)(workshopData, departmentId),
            demandRevenue: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearSystemCheckRevenue)(workshopData, departmentId),
            growthOverLastYear: (0, revenueSummaryServiceCalcsFromFE_1.calcSystemCheckGrowthOverLastYearPercent)(preWork, workshopData, departmentId) * 100,
        },
    };
    return systemChecks;
}
exports.buildSystemChecks = buildSystemChecks;
function buildAgreements(preWork, workshopData, departmentId) {
    let agreements = {};
    agreements = {
        ...agreements,
        previousYear: {
            currentNumOfAgreements: (0, revenueSummaryServiceCalcsFromFE_1.getTotalPriorYearAgreements)(workshopData, departmentId),
            targetNumOfAgreements: null,
            increasePercent: null,
            numOfNewAgreementsToSell: null,
            recognizedAgreementRevenue: (0, revenueSummaryServiceCalcsFromFE_1.getPriorYearAgreementsRevenue)(preWork, departmentId),
            growthOverLastYear: (0, revenueSummaryServiceCalcsFromFE_1.calcAgreementGrowthOverLastYear)(preWork, workshopData, departmentId),
        },
    };
    agreements = {
        ...agreements,
        budgetYear: {
            currentNumOfAgreements: null,
            targetNumOfAgreements: (0, revenueSummaryServiceCalcsFromFE_1.getNewAndRetainedAgreements)(workshopData, departmentId),
            increasePercent: (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetYearIncreasePercentage)(workshopData, departmentId) * 100,
            numOfNewAgreementsToSell: (0, revenueSummaryServiceCalcsFromFE_1.getBudgetYearTotalNewAgreementsSold)(workshopData, departmentId),
            recognizedAgreementRevenue: (0, revenueSummaryServiceCalcsFromFE_1.getBudgetYearTotalAgreementRevenue)(workshopData, departmentId),
            growthOverLastYear: (0, revenueSummaryServiceCalcsFromFE_1.calcAgreementGrowthOverLastYearPercent)(preWork, workshopData, departmentId) * 100,
        },
    };
    return agreements;
}
exports.buildAgreements = buildAgreements;
function buildTechLeads(preWork, workshopData, departmentId, associatedId) {
    let techLeads = {};
    techLeads = {
        ...techLeads,
        previousYear: {
            numOfLeads: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearNumTechGeneratedLeads)(workshopData, departmentId),
            closeRate: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearTechGeneratedLeadsClosingRate)(preWork, departmentId),
            soldJobs: (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearTechGeneratedSoldJobs)(preWork, workshopData, departmentId),
            averageSale: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearTechGeneratedLeadAverageSale)(preWork, departmentId),
            techLeadRevenue: (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearTechGeneratedLeadRevenue)(preWork, workshopData, departmentId),
            growthOverLastYear: (0, revenueSummaryInstallCalcsFromFE_1.calcTechGeneratedGrowthOverPriorYear)(preWork, workshopData, departmentId, associatedId),
        },
    };
    techLeads = {
        ...techLeads,
        budgetYear: {
            numOfLeads: (0, revenueSummaryInstallCalcsFromFE_1.getBudgetYearNumTechGeneratedLeads)(workshopData, associatedId),
            closeRate: (0, revenueSummaryInstallCalcsFromFE_1.getBudgetYearTechGeneratedLeadsClosingRate)(workshopData, departmentId) *
                100,
            soldJobs: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearTechGeneratedSoldJobs)(workshopData, departmentId, associatedId),
            averageSale: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearTechGeneratedLeadAverageSale)(workshopData, departmentId),
            techLeadRevenue: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearTechLeadRevenue)(workshopData, departmentId, associatedId),
            growthOverLastYear: (0, revenueSummaryInstallCalcsFromFE_1.calcTechGeneratedGrowthOverPriorYearPercent)(preWork, workshopData, departmentId, associatedId) * 100,
        },
    };
    return techLeads;
}
exports.buildTechLeads = buildTechLeads;
function buildMarketedLeads(preWork, workshopData, departmentId) {
    let marketedLeads = {};
    marketedLeads = {
        ...marketedLeads,
        previousYear: {
            numOfLeads: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearNumMarketedLeads)(workshopData, departmentId),
            closeRate: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearMarketedLeadsClosingRate)(preWork, departmentId),
            soldJobs: (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearMarketedSoldJobs)(preWork, workshopData, departmentId),
            averageSale: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearMarketedLeadAverageSale)(preWork, departmentId),
            marketedLeadRevenue: (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearMarketedLeadRevenue)(preWork, workshopData, departmentId),
            growthOverLastYear: (0, revenueSummaryInstallCalcsFromFE_1.calcMarketedLeadGrowthOverLastYear)(preWork, workshopData, departmentId),
        },
    };
    marketedLeads = {
        ...marketedLeads,
        budgetYear: {
            numOfLeads: (0, revenueSummaryInstallCalcsFromFE_1.getBudgetYearNumMarketedLeads)(workshopData, departmentId),
            closeRate: (0, revenueSummaryInstallCalcsFromFE_1.getBudgetYearMarketedLeadsClosingRate)(workshopData, departmentId) * 100,
            soldJobs: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearMarketedSoldJobs)(workshopData, departmentId),
            averageSale: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearMarketedLeadAverageSale)(workshopData, departmentId),
            marketedLeadRevenue: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearMarketedLeadRevenue)(workshopData, departmentId),
            growthOverLastYear: (0, revenueSummaryInstallCalcsFromFE_1.calcMarketedLeadGrowthOverLastYearPercent)(preWork, workshopData, departmentId) * 100,
        },
    };
    return marketedLeads;
}
exports.buildMarketedLeads = buildMarketedLeads;
function buildOtherLeads(preWork, workshopData, departmentId) {
    let otherLeads = {};
    otherLeads = {
        ...otherLeads,
        previousYear: {
            numOfLeads: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearNumOtherLeads)(preWork, departmentId),
            closeRate: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearOtherLeadsClosingRate)(preWork, departmentId),
            soldJobs: (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearOtherSoldJobs)(preWork, workshopData, departmentId),
            averageSale: (0, revenueSummaryInstallCalcsFromFE_1.getPriorYearOtherLeadAverageSale)(preWork, departmentId),
            otherLeadRevenue: (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearOtherLeadRevenue)(preWork, workshopData, departmentId),
            growthOverLastYear: (0, revenueSummaryInstallCalcsFromFE_1.calcOtherLeadsGrowthOverLastYear)(preWork, workshopData, departmentId),
        },
    };
    otherLeads = {
        ...otherLeads,
        budgetYear: {
            numOfLeads: (0, revenueSummaryInstallCalcsFromFE_1.getBudgetYearNumOtherLeads)(workshopData, departmentId),
            closeRate: (0, revenueSummaryInstallCalcsFromFE_1.getBudgetYearOtherLeadsClosingRate)(workshopData, departmentId) * 100,
            soldJobs: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearOtherSoldJobs)(workshopData, departmentId),
            averageSale: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearOtherLeadAverageSale)(workshopData, departmentId),
            otherLeadRevenue: (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearOtherLeadRevenue)(workshopData, departmentId),
            growthOverLastYear: (0, revenueSummaryInstallCalcsFromFE_1.calcOtherLeadGrowthOverLastYearPercent)(preWork, workshopData, departmentId),
        },
    };
    return otherLeads;
}
exports.buildOtherLeads = buildOtherLeads;
function buildRSDeptTotals(preWork, deptData, departmentId, associatedDeptId, departmentType) {
    return {
        totalRevenue: (0, revenueSummaryTotalCalcs_1.calcTotalRevenueForDepartment)(departmentId, preWork, deptData, associatedDeptId, departmentType),
        growthOverLastYear: (0, revenueSummaryTotalCalcs_1.calcTotalGrowthOverLastYearForDepartment)(departmentId, preWork, deptData, associatedDeptId, departmentType),
        growthPercentOverLastYear: (0, revenueSummaryTotalCalcs_1.calcTotalGrowthPercentOverLastYearForDepartment)(departmentId, preWork, deptData, associatedDeptId, departmentType) * 100,
    };
}
exports.buildRSDeptTotals = buildRSDeptTotals;
function buildRSInstall(preWork, deptData, departmentId, associatedDeptId) {
    return {
        departmentId,
        departmentName: deptData[departmentId].departmentName,
        departmentType: deptData[departmentId].departmentType,
        techGeneratedLeads: buildTechLeads(preWork, deptData, departmentId, associatedDeptId),
        marketedLeads: buildMarketedLeads(preWork, deptData, departmentId),
        otherLeads: buildOtherLeads(preWork, deptData, departmentId),
        totals: buildRSDeptTotals(preWork, deptData, departmentId, associatedDeptId, deptData[departmentId].departmentType),
    };
}
exports.buildRSInstall = buildRSInstall;
function buildRSService(preWork, deptData, departmentId, associatedDeptId) {
    return {
        departmentId,
        departmentName: deptData[departmentId].departmentName,
        departmentType: deptData[departmentId].departmentType,
        demandCalls: buildDemandCalls(preWork, deptData, departmentId, "demandCalls"),
        demandSystemCheckCalls: buildDemandCalls(preWork, deptData, departmentId, "demandSystemCheckCalls"),
        systemChecks: buildSystemChecks(preWork, deptData, departmentId),
        agreements: buildAgreements(preWork, deptData, departmentId),
        totals: buildRSDeptTotals(preWork, deptData, departmentId, associatedDeptId, deptData[departmentId].departmentType),
    };
}
exports.buildRSService = buildRSService;
function workshopType(dynamoItem) {
    // creating workshopType keys based on the SK of item returned from dynamo
    if (dynamoItem.SK.split("#")[2] === "CALL_COUNT") {
        return { type: "callCount", key: "callCountTotals" };
    }
    if (dynamoItem.SK.split("#")[2] === "MAX_CAPACITY") {
        return { type: "maxCapacity", key: "maxCapacityTotals" };
    }
    if (dynamoItem.SK.split("#")[2] === "AGREEMENTS") {
        return { type: "serviceAgreements", key: "departmentServiceAgreements" };
    }
    return;
}
exports.workshopType = workshopType;
function processRSYearlyData(dynamoItems) {
    let workshopByDept = {};
    if (dynamoItems) {
        dynamoItems.map((item) => {
            // converts 'december' key to 'dec'
            item = (0, helpers_1.convertDecemberForFE)(item);
            if (item.departmentId) {
                const deptId = item.departmentId;
                const sk = item.SK.split("#")[2];
                // these are the only types that we need to run revenueSummary calcs
                if (sk === "CALL_COUNT" ||
                    sk === "MAX_CAPACITY" ||
                    sk === "AGREEMENTS") {
                    if (sk === "AGREEMENTS") {
                        Object.keys(item).forEach((key) => {
                            if (utils_1.shortMonthsArray.includes(key)) {
                                delete item[key];
                            }
                        });
                    }
                    // determines workshop type and key by the value in SK
                    const workshopKeys = workshopType(item);
                    // initializes new obj with key of deptId and necessary dept info
                    if (!(item.departmentId in workshopByDept)) {
                        workshopByDept = {
                            ...workshopByDept,
                            [deptId]: {
                                [workshopKeys.type]: sk === "AGREEMENTS" ? item : item[workshopKeys.key],
                                id: `${item.fiscalYear}#${deptId}`,
                                departmentId: deptId,
                                departmentType: item.departmentType,
                                departmentName: item.departmentName,
                                departmentTrade: item.departmentTrade,
                                fiscalYear: item.fiscalYear,
                            },
                        };
                        // else find obj by deptId and add data keyed by workshopType, i.e. 'callCount', 'maxCapacity', etc.
                    }
                    else {
                        workshopByDept[deptId] = {
                            ...workshopByDept[deptId],
                            [workshopKeys.type]: item[workshopKeys.key],
                        };
                    }
                }
            }
        });
    }
    return workshopByDept;
}
exports.processRSYearlyData = processRSYearlyData;
