import React from "react";
import UserDropdownLink from "../../components/navigation/UserDropdownLink";

function Version(): JSX.Element {
  return (
    <UserDropdownLink id="toolsVersionDisplay" disabled onClick={() => null}>
      {`Tools Version: ${process.env.REACT_APP_TOOLS_VERSION}`}
    </UserDropdownLink>
  );
}

export default Version;
