import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function DigitalMetricsIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path
        className="cls-1"
        d="m875.71,344.44h-110.76c-15.23,0-27.69,15.88-27.69,35.29v317.57c0,19.41,12.46,35.29,27.69,35.29h110.76c15.23,0,27.69-15.88,27.69-35.29v-317.57c0-19.41-12.46-35.29-27.69-35.29Z"
      />
      <path
        className="cls-1"
        d="m654.19,203.3h-110.76c-15.23,0-27.69,15.88-27.69,35.29v458.72c0,19.41,12.46,35.29,27.69,35.29h110.76c15.23,0,27.69-15.88,27.69-35.29V238.58c0-19.41-12.46-35.29-27.69-35.29Z"
      />
      <path
        className="cls-1"
        d="m432.68,450.3h-110.76c-15.23,0-27.69,15.88-27.69,35.29v211.71c0,19.41,12.46,35.29,27.69,35.29h110.76c15.23,0,27.69-15.88,27.69-35.29v-211.71c0-19.41-12.46-35.29-27.69-35.29h0Z"
      />
      <path
        className="cls-1"
        d="m504.85,911.85h190.29c8.47-36.52,35.02-63.22,66.39-63.22h339.7V121.65c0-48.53-31.15-88.22-69.23-88.22H168.23c-38.31,0-69.23,39.7-69.23,88.22v726.97h339.46c31.37,0,57.92,26.71,66.39,63.22h0Zm-313.54-158.5V180.47c0-16.17,10.15-29.41,23.08-29.41h771.46c12.7,0,23.08,13.24,23.08,29.41v572.88c0,16.17-10.38,29.41-23.08,29.41H214.39c-12.92,0-23.07-13.24-23.07-29.41h0Z"
      />
      <path
        className="cls-1"
        d="m1130.8,907.45h-369.23c-12.92,0-23.08,13.24-23.08,29.41v33.82h-276.92v-33.83c0-16.17-10.39-29.41-23.08-29.41H69.26c-12.92,0-23.08,13.24-23.08,29.41v117.63c0,64.99,41.31,117.63,92.31,117.63h923.08c50.77,0,92.31-52.64,92.31-117.63v-117.63c0-16.18-10.39-29.41-23.08-29.41h0Z"
      />
    </svg>
  );
}
