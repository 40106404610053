"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * B192
 * 'Call Count'!D173
 * Budgeted Tech Generated Leads
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getTechGeneratedLeadsRan(object, month, companyData, workingDays) {
    return null;
}
/**
 * B193
 * 'Call Count'!D181
 * Required Marketed Lead Count Based on Staffing
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getMarketingLeadsRan(object, month, companyData, workingDays) {
    return null;
}
/**
 * B194
 * 'Call Count'!D185
 * Required Other Lead Count Based on Staffing
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getOtherLeadsRan(object, month, companyData, workingDays) {
    return null;
}
/**
 * B195
 * C192+C193+C194
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcTotalLeadsRanPerMonth(object, month, companyData, workingDays) {
    return null;
}
/**
 * B197
 * 'Call Count'!D177
 * Budgeted # of Salespersons
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSalesStaffing(object, month, companyData, workingDays) {
    return null;
}
/**
 * B198
 * 'Call Count'!D168
 * Target # of Leads Ran Per Salesperson Per Day
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getLeadsPerSalesmanPerDay(object, month, companyData, workingDays) {
    return null;
}
/**
 * B199
 * 'Call Count'!D203
 * Budgeted # of Crews
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getNeededInstallationStaffing(object, month, companyData, workingDays) {
    return null;
}
/**
 * B202
 * 'Conv and Average Sale'!D118
 * Budgeted Tech Generated Leads Closing Rate
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getConversionRateOnTechLeads(object, month, companyData, workingDays) {
    return null;
}
/**
 * B203
 * 'Conv and Average Sale'!D130
 * Budgeted Tech Generated Leads Average Sale
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getAverageSaleOnTechLeads(object, month, companyData, workingDays) {
    return null;
}
/**
 * B206
 * 'Conv and Average Sale'!D122
 * Budgeted Marketed Leads Closing Rate
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getConversionRateOnMarketingLeads(object, month, companyData, workingDays) {
    return null;
}
/**
 * B207
 * 'Conv and Average Sale'!D134
 * Budgeted Marketed Leads Average Sale
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getAverageSaleOnMarketingLeads(object, month, companyData, workingDays) {
    return null;
}
/**
 * B210
 * 'Conv and Average Sale'!D126
 * Budgeted Other Leads Closing Rate
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getConversionRateOnOtherLeads(object, month, companyData, workingDays) {
    return null;
}
/**
 * B211
 * 'Conv and Average Sale'!D138
 * Budgeted Other Leads Average Sale
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getAverageSaleOnOtherLeads(object, month, companyData, workingDays) {
    return null;
}
/**
 * B214
 * 'Direct Costs'!D209
 * Sold Day Per Month Install Goal
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSoldDaysInstalled(object, month, companyData, workingDays) {
    return null;
}
/**
 * B215
 * 'Direct Costs'!C207
 * Sold Day with Profit & Commission
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSoldDaysProfitAndCommission(object, month, companyData, workingDays) {
    return null;
}
/**
 * B216
 * 'Direct Costs'!D208
 * Sold Day Profit and Commission %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSoldDaysProfitAndCommissionPercent(object, month, companyData, workingDays) {
    return null;
}
/**
 * B219
 * 1-'Direct Costs'!C186
 * Net % of revenue after Sub, Fin, ref
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getNetPercentOfRevenue(object, month, companyData, workingDays) {
    return null;
}
/**
 * B220
 * 'Direct Costs'!D187
 * Total Income
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getRevenue(object, month, companyData, workingDays) {
    return null;
}
/**
 * B221
 * 'Direct Costs'!E194
 * Budgeted Material %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getMaterial(object, month, companyData, workingDays) {
    return null;
}
/**
 * B222
 * 'Direct Costs'!E191
 * Budgeted Labor %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getLabor(object, month, companyData, workingDays) {
    return null;
}
/**
 * B223
 * 'Direct Costs'!E197
 * Budgeted Sales Expense %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSalesExpense(object, month, companyData, workingDays) {
    return null;
}
/**
 * B224
 * 'Direct Costs'!E200
 * Budgeted Other Direct %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getOther(object, month, companyData, workingDays) {
    return null;
}
/**
 * B225
 * 'Direct Costs'!E204
 * Gross Profit %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getGrossProfitPercent(object, month, companyData, workingDays) {
    return null;
}
/**
 * B226
 * 'Direct Costs'!D204
 * Gross Profit $
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getGrossProfitDollars(object, month, companyData, workingDays) {
    return null;
}
