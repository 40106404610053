import React from "react";
import { Listbox } from "@headlessui/react";
import { ManageDepartment } from "@nexstar-network/shared-utils";
import CheckIcon from "../icons/CheckIcon";

type NexstarSelectItemProps = {
  keyValue: string;
  value: string | number | ManageDepartment | Record<string, any>;
  text: string;
  dataTestId?: string;
  id: string;
  disabled?: boolean;
  onClick?: any;
};

//TODO: Move to a helper
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NexstarSelectItem({
  keyValue,
  value,
  text,
  dataTestId,
  id,
  disabled,
  onClick,
}: NexstarSelectItemProps) {
  return (
    <Listbox.Option
      onClick={onClick}
      key={`${keyValue}-item`}
      disabled={disabled}
      className={({ active }) =>
        classNames(
          active ? "text-black bg-NexstarGray" : "text-gray-900",
          "relative cursor-default select-none py-2 pl-3 pr-9",
        )
      }
      value={value}
      data-testid={dataTestId ? dataTestId : id}
      id={id}
    >
      {({ selected, active }) => (
        <>
          <div className="flex items-center">
            <span
              className={`${
                selected ? "font-semibold" : "font-normal block truncate"
              } ${disabled ? "text-red-300" : "text-black"}`}
            >
              {text}
            </span>
          </div>
          {selected && !disabled ? (
            <span
              className={classNames(
                active ? "text-black" : "text-black",
                "absolute inset-y-0 right-0 flex items-center pr-2",
              )}
            >
              <CheckIcon color="black" />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
}

export default NexstarSelectItem;
