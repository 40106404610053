import CardWrapper from "../../../utils/components/tailwind/CardWrapper";
import CardHeader from "../../../utils/components/tailwind/CardHeader";
import CardBody from "../../../utils/components/tailwind/CardBody";
import ErrorIcon from "../../../utils/components/icons/ErrorPageIcon";
import React, { useState } from "react";
import { ErrorReportDialog } from "./ErrorReportDialog";
import { CRMUser } from "../../../utils/helpers/UserHelper";
import { useAuth0 } from "@auth0/auth0-react";
import SimpleAppHeader from "../../navigation/SimpleAppHeader";

export function ErrorLandingPage({ eventId }: { eventId: any }) {
  const { user } = useAuth0();
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const selectedCompany = JSON.parse(
    window.sessionStorage.getItem("userSelectedCompany"),
  );
  return (
    <>
      <ErrorReportDialog
        open={openErrorDialog}
        setOpen={setOpenErrorDialog}
        userData={user as CRMUser}
        companyData={selectedCompany}
        eventId={eventId}
      />
      <main className="bg-NexstarSoftGray flex-grow">
        <SimpleAppHeader />
        <div className="p-6">
          <CardWrapper id="somethingWentWrong">
            <CardHeader
              title="Something went wrong."
              justify="justify-center"
              headingTextSize="text-3xl"
            />
            <CardBody padding="py-12 px-6 content-center">
              <div className="flex flex-col items-center">
                <div className="flex-row">
                  <ErrorIcon color="NexstarBlue" size="xlarge" />
                </div>
                <div className="flex-row">
                  <p className="text-center text-3xl lg:w-[700px] md:w-[500px] mt-7">
                    We apologize for the inconvenience, please send a report to
                    the nerds or return home and try again.
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-8 my-24 text-lg font-semibold text-NexstarBlue space-x-16 p-2">
                <button
                  className="hover:text-NexstarOrange underline hover:decoration-NexstarOrange"
                  id="error_return_to_home_page"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  Return to Home Page
                </button>
                <button
                  className="hover:text-NexstarOrange underline hover:decoration-NexstarOrange"
                  id="error_return_to_home_page"
                  onClick={() => setOpenErrorDialog(true)}
                >
                  Report To The Nerds
                </button>
              </div>
            </CardBody>
          </CardWrapper>
        </div>
      </main>
    </>
  );
}
