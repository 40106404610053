"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExpenseAllocationOverridesForCalculating = exports.convertExpenseAllocationOverridesForCalculating = exports.calculateStandardRateSellingPrice = exports.calculateValueRateSellingPrice = exports.calculateBreakEvenPerUnit = exports.calculateTotalAvailableWorkUnitsPerYear = exports.calculateUnbilledUnits = exports.calculateEfficiency = exports.calculateTotalOverheadCostsPerUnitByDepartment = exports.calculateTotalOverheadCostsByDepartment = exports.calculateTotalCostPerUnit = exports.convertEfficiencyToSoldHoursPerDayPerTech = exports.convertEfficiencyToSoldHoursPerWeekPerTech = exports.calculateUnBilledTimePerUnit = exports.calculatePTOCostPerUnit = exports.calculatePTOCost = exports.calculateAnnualCostPTO = exports.calculateTimeOffTotals = exports.calculateDirectLaborCostPerUnit = exports.calculateDirectLaborCost = void 0;
const utils_1 = require("../utils");
const calculateDirectLaborCost = (departmentData, rateData) => {
    var _a;
    if (departmentData.payRate === null) {
        return 0;
    }
    const taxablePay = departmentData.payRate.hourlyPayRate +
        departmentData.payRate.otherTaxablePayRate;
    if (rateData === null) {
        return taxablePay;
    }
    let dynamicCost = taxablePay;
    Object.values(rateData).forEach((taxRate) => {
        if (taxRate !== undefined) {
            // @ts-ignore
            dynamicCost += taxablePay * (taxRate / 100);
        }
    });
    return dynamicCost + ((_a = departmentData.payRate.nonTaxablePayRate) !== null && _a !== void 0 ? _a : 0);
};
exports.calculateDirectLaborCost = calculateDirectLaborCost;
/**
 *
 * @param departmentData
 * @param rateData
 */
const calculateDirectLaborCostPerUnit = (departmentData, rateData) => {
    const laborCost = (0, exports.calculateDirectLaborCost)(departmentData, rateData);
    return departmentData.methodOfPricing === "HOURLY"
        ? laborCost
        : laborCost * 8;
};
exports.calculateDirectLaborCostPerUnit = calculateDirectLaborCostPerUnit;
/**
 *
 * @param timeOffType
 * @param timeOffInfo
 */
const calculateTimeOffTotals = (timeOffType, timeOffInfo) => {
    let amount = 0;
    if (timeOffInfo === null) {
        return amount;
    }
    timeOffInfo.forEach((timeOff) => {
        if (timeOffType === "ALL") {
            amount += timeOff.amount;
        }
        else if (timeOff.category.startsWith(timeOffType)) {
            amount += timeOff.amount;
        }
    });
    return amount;
};
exports.calculateTimeOffTotals = calculateTimeOffTotals;
/**
 *
 * @param departmentData
 * @param rateData
 */
const calculateAnnualCostPTO = (departmentData, rateData) => (0, exports.calculateDirectLaborCost)(departmentData, rateData) *
    (0, exports.calculateTimeOffTotals)("PAID", departmentData.timeOff) *
    8;
exports.calculateAnnualCostPTO = calculateAnnualCostPTO;
/**
 *
 * @param departmentData
 * @param rateData
 */
const calculatePTOCost = (departmentData, rateData) => {
    const totalWorkDays = 260 - (0, exports.calculateTimeOffTotals)("ALL", departmentData.timeOff);
    const annualCost = (0, exports.calculateAnnualCostPTO)(departmentData, rateData);
    return annualCost / totalWorkDays / 8;
};
exports.calculatePTOCost = calculatePTOCost;
/**
 *
 * @param departmentData
 * @param rateData
 */
const calculatePTOCostPerUnit = (departmentData, rateData) => {
    const ptoCost = (0, exports.calculatePTOCost)(departmentData, rateData);
    return departmentData.methodOfPricing === "HOURLY" ? ptoCost : ptoCost * 8;
};
exports.calculatePTOCostPerUnit = calculatePTOCostPerUnit;
/**
 *
 * @param departmentData
 * @param rateData
 */
const calculateUnBilledTimePerUnit = (departmentData, rateData) => {
    if (departmentData.soldHourEfficiency === 0 ||
        departmentData.soldHourEfficiency === null) {
        return 0;
    }
    const efficiencyAsDecimal = departmentData.soldHourEfficiency / 100;
    const efficiencyScalar = (1 - efficiencyAsDecimal) / efficiencyAsDecimal;
    const unbilledTime = ((0, exports.calculateDirectLaborCost)(departmentData, rateData) +
        (0, exports.calculatePTOCost)(departmentData, rateData)) *
        efficiencyScalar;
    return departmentData.methodOfPricing === "HOURLY"
        ? unbilledTime
        : unbilledTime * 8;
};
exports.calculateUnBilledTimePerUnit = calculateUnBilledTimePerUnit;
/**
 *
 * @param departmentData
 */
const convertEfficiencyToSoldHoursPerWeekPerTech = (departmentData) => {
    if (departmentData.methodOfPricing === "HOURLY") {
        return (departmentData.soldHourEfficiency / 100) * 8 * 5;
    }
    return (departmentData.soldHourEfficiency / 100) * 5;
};
exports.convertEfficiencyToSoldHoursPerWeekPerTech = convertEfficiencyToSoldHoursPerWeekPerTech;
/**
 *
 * @param departmentData
 */
const convertEfficiencyToSoldHoursPerDayPerTech = (departmentData) => {
    if (departmentData.methodOfPricing === "HOURLY") {
        return (departmentData.soldHourEfficiency / 100) * 8;
    }
    return departmentData.soldHourEfficiency / 100;
};
exports.convertEfficiencyToSoldHoursPerDayPerTech = convertEfficiencyToSoldHoursPerDayPerTech;
/**
 *
 * @param departmentData
 * @param rateData
 */
const calculateTotalCostPerUnit = (departmentData, rateData) => {
    const laborCost = (0, exports.calculateDirectLaborCostPerUnit)(departmentData, rateData);
    const unBilledTime = (0, exports.calculateUnBilledTimePerUnit)(departmentData, rateData);
    const ptoCost = (0, exports.calculatePTOCostPerUnit)(departmentData, rateData);
    return laborCost + unBilledTime + ptoCost;
};
exports.calculateTotalCostPerUnit = calculateTotalCostPerUnit;
/**
 *
 * @param departmentData
 * @param rateData
 * @param overheadData
 */
const calculateTotalOverheadCostsByDepartment = (departmentData, rateData, overheadData) => {
    if (overheadData === undefined || overheadData === null) {
        return 0;
    }
    if (departmentData.expenseAllocationPercent === undefined) {
        return 0;
    }
    const expenseOverridesForCalculating = (0, exports.getExpenseAllocationOverridesForCalculating)(overheadData);
    // This will have all the overheads that are overridden for the current department
    const departmentOverheadsOverride = expenseOverridesForCalculating !== null &&
        `${departmentData.id}` in expenseOverridesForCalculating
        ? expenseOverridesForCalculating[`${departmentData.id}`]
        : null;
    let totalTaxableOverhead = 0;
    let totalNonTaxableOverhead = 0;
    overheadData.forEach((overhead) => {
        if (overhead.overheadName !== "") {
            if (!overhead.taxable) {
                if (departmentOverheadsOverride !== null &&
                    expenseOverridesForCalculating !== null &&
                    overhead.id in departmentOverheadsOverride) {
                    totalNonTaxableOverhead +=
                        overhead.totalAmount *
                            (departmentOverheadsOverride[overhead.id] / 100);
                }
                else {
                    totalNonTaxableOverhead +=
                        overhead.totalAmount *
                            (departmentData.expenseAllocationPercent / 100);
                }
            }
            else if (!(0, utils_1.empty)(departmentOverheadsOverride) &&
                !(0, utils_1.empty)(expenseOverridesForCalculating) &&
                overhead.id in departmentOverheadsOverride) {
                totalTaxableOverhead +=
                    overhead.totalAmount *
                        (departmentOverheadsOverride[overhead.id] / 100);
            }
            else {
                totalTaxableOverhead +=
                    overhead.totalAmount *
                        (departmentData.expenseAllocationPercent / 100);
            }
        }
    });
    // Now we have to figure out the tax total on the taxable rates.
    let taxCost = 0;
    if (rateData) {
        Object.entries(rateData).forEach(([taxRateKey, taxRateValue]) => {
            if (taxRateKey !== "workersCompRate" && taxRateValue !== undefined) {
                // @ts-ignore
                taxCost += totalTaxableOverhead * (taxRateValue / 100);
            }
        });
    }
    return totalTaxableOverhead + taxCost + totalNonTaxableOverhead;
};
exports.calculateTotalOverheadCostsByDepartment = calculateTotalOverheadCostsByDepartment;
/**
 *
 * @param departmentData
 * @param rateData
 * @param overheadData
 */
const calculateTotalOverheadCostsPerUnitByDepartment = (departmentData, rateData, overheadData) => {
    if (!departmentData.soldHourEfficiency || !departmentData.numberEmployees) {
        return 0;
    }
    const overheadCostsByDept = (0, exports.calculateTotalOverheadCostsByDepartment)(departmentData, rateData, overheadData);
    const availableWorkUnits = (0, exports.calculateTotalAvailableWorkUnitsPerYear)(departmentData);
    return (overheadCostsByDept /
        (availableWorkUnits *
            (departmentData.soldHourEfficiency / 100) *
            departmentData.numberEmployees));
};
exports.calculateTotalOverheadCostsPerUnitByDepartment = calculateTotalOverheadCostsPerUnitByDepartment;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const calculateEfficiency = (departmentData, calculationData) => {
    const calculateUnBilledUnits = (0, exports.calculateUnbilledUnits)(calculationData);
    const totalWorkUnitsPerYear = (0, exports.calculateTotalAvailableWorkUnitsPerYear)(departmentData);
    const totalBillableUnits = totalWorkUnitsPerYear - calculateUnBilledUnits;
    return totalBillableUnits / totalWorkUnitsPerYear;
};
exports.calculateEfficiency = calculateEfficiency;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const calculateUnbilledUnits = (calculationData) => {
    if (calculationData === null) {
        return 0;
    }
    let totalUnbilledUnits = 0;
    Object.values(calculationData).forEach((itemValue) => {
        // @ts-ignore
        totalUnbilledUnits += itemValue;
    });
    return totalUnbilledUnits;
};
exports.calculateUnbilledUnits = calculateUnbilledUnits;
const calculateTotalAvailableWorkUnitsPerYear = (departmentData) => {
    const totalWorkDays = 260 - (0, exports.calculateTimeOffTotals)("ALL", departmentData.timeOff);
    return departmentData.methodOfPricing === "HOURLY"
        ? totalWorkDays * 8
        : totalWorkDays;
};
exports.calculateTotalAvailableWorkUnitsPerYear = calculateTotalAvailableWorkUnitsPerYear;
const calculateBreakEvenPerUnit = (departmentData, rateData, overheadData) => {
    const totalCostPerUnit = (0, exports.calculateTotalCostPerUnit)(departmentData, rateData);
    const overheadPerUnitByDepartment = (0, exports.calculateTotalOverheadCostsPerUnitByDepartment)(departmentData, rateData, overheadData);
    return totalCostPerUnit + overheadPerUnitByDepartment;
};
exports.calculateBreakEvenPerUnit = calculateBreakEvenPerUnit;
const calculateValueRateSellingPrice = (departmentData, rateData, overheadData) => {
    const breakEvenPerUnit = (0, exports.calculateBreakEvenPerUnit)(departmentData, rateData, overheadData);
    const desiredNetProfit = 1 - departmentData.desiredNetProfitMargin / 100;
    return breakEvenPerUnit / desiredNetProfit;
};
exports.calculateValueRateSellingPrice = calculateValueRateSellingPrice;
// TOOL-85 (20210226 JAH): Fixing NaN on Pricing Page
const calculateStandardRateSellingPrice = (departmentData, rateData, overheadData) => (0, exports.calculateValueRateSellingPrice)(departmentData, rateData, overheadData) /
    (1 - (departmentData.sppDiscount ? departmentData.sppDiscount : 0) / 100);
exports.calculateStandardRateSellingPrice = calculateStandardRateSellingPrice;
/**
 * @deprecated
 * @param expenseOverrides
 */
const convertExpenseAllocationOverridesForCalculating = (expenseOverrides) => {
    if (expenseOverrides === null)
        return expenseOverrides;
    if (Object.keys(expenseOverrides).length === 0)
        return null;
    const pricerExpenseObject = {};
    Object.values(expenseOverrides).forEach((overrides) => {
        overrides.forEach((override) => {
            const id = override.expenseDepartmentKey.split("-")[0];
            const overheadKey = override.expenseDepartmentKey.split("-")[1];
            const objectKeyDeptName = override.label.replace(" ", "_");
            pricerExpenseObject[`${id}-${objectKeyDeptName}`] = pricerExpenseObject[`${id}-${objectKeyDeptName}`]
                ? pricerExpenseObject[`${id}-${objectKeyDeptName}`]
                : {};
            pricerExpenseObject[`${id}-${objectKeyDeptName}`][`${overheadKey}`] =
                override.expenseDepartmentOverridePercent;
        });
    });
    return pricerExpenseObject;
};
exports.convertExpenseAllocationOverridesForCalculating = convertExpenseAllocationOverridesForCalculating;
const getExpenseAllocationOverridesForCalculating = (overheads) => {
    if (overheads === null || Object.keys(overheads).length === 0)
        return null;
    const pricerExpenseObject = {};
    Object.values(overheads).forEach((overhead) => {
        if (overhead.expenseOverrides) {
            overhead.expenseOverrides.forEach((override) => {
                pricerExpenseObject[`${override.departmentId}`] = pricerExpenseObject[`${override.departmentId}`]
                    ? pricerExpenseObject[`${override.departmentId}`]
                    : {};
                pricerExpenseObject[`${override.departmentId}`][`${overhead.id}`] =
                    override.overridePercent;
            });
        }
    });
    return pricerExpenseObject;
};
exports.getExpenseAllocationOverridesForCalculating = getExpenseAllocationOverridesForCalculating;
