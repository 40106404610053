import React from "react";

interface ButtonToolTipProps {
  children: React.ReactNode;
  tooltip: string;
}

export default function ButtonToolTip({
  tooltip,
  children,
}: ButtonToolTipProps): JSX.Element {
  return (
    <div className="group relative inline-block cursor-pointer">
      {children}
      <span className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-black text-white text-[15px] font-normal leading-snug p-2 rounded absolute -top-[40px] z-50 w-max max-w-sm after:absolute after:left-1/2 after:-translate-x-1/2 after:border-8 after:border-transparent right-[-18px] after:bottom-0 after:border-t-black after:translate-y-full min-w-[90px] text-center">
        {tooltip}
      </span>
    </div>
  );
}
