import React from "react";

type LoadingBackdropProps = {
  loading: boolean;
  text?: string;
};

export function LoadingOverlay({
  loading,
  text,
}: LoadingBackdropProps): JSX.Element {
  return (
    <details
      open={loading === true || loading === null}
      className="absolute"
      style={{ fontSize: 0 }}
      aria-hidden="true"
    >
      <div className="flex fixed items-center justify-center inset-0 bg-black/50 z-[1399]">
        <div className="flex justify-center">
          <span className="circle animate-loader w-3 h-3 my-12 mx-1 bg-white rounded-full" />
          <span className="circle animate-loader w-3 h-3 my-12 mx-1 bg-white rounded-full animation-delay-200" />
          <span className="circle animate-loader w-3 h-3 my-12 mx-1 bg-white rounded-full animation-delay-400" />
        </div>
        <h2
          className="text-5xl ml-4 text-white"
          style={{ textShadow: "2px 2px 10px rgba(0,0,0,0.7)" }}
        >
          {!text ? "Loading" : text}
        </h2>
      </div>
    </details>
  );
}
