"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.round = void 0;
const dataHelpers_1 = require("./dataHelpers");
function round(value, decimal = 2) {
    if (decimal !== 0 && decimal !== 1 && decimal !== 2)
        return 0;
    if (!Number.isFinite(value))
        return 0;
    const isNegative = value.toString().startsWith("-");
    let valueToRound = value;
    if (isNegative) {
        valueToRound *= -1;
    }
    if ((0, dataHelpers_1.empty)(valueToRound))
        return 0;
    const returnValue = +`${Math.round(Number(`${valueToRound}e+${decimal}`))}e-${decimal}`;
    return isNegative ? returnValue * -1 : returnValue;
}
exports.round = round;
