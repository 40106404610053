import React, { ReactElement } from "react";

type NexstarLogoProps = {
  fill?: string;
  width?: string;
  height?: string;
};

export default function NexstarLogo({
  fill = "#ffffff",
  width = "w-[100px]",
  height = "h-[65px]",
}: NexstarLogoProps): ReactElement {
  return (
    <div className={`flex justify-center items-center ${width} ${height}`}>
      <svg
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 144.41 75.46"
      >
        <path d="M55.39,72.5c-6.11-3.56-7.02-14-3.1-25.77l5.05,6.49h5.5l-8.76-11.17c.99-2.3,2.16-4.63,3.5-6.94,3.54-6.08,7.8-11.17,12.13-14.88-6.39,2.89-12.95,9.04-17.8,17.35-.19.33-.38.66-.56.99l-2.4-3.05h-5.39l5.68,7.29c-5.87,13.28-4.85,26.5,2.98,31.05,8.28,4.84,21.27-1.79,30.03-14.91-8.9,11.58-20.03,17.52-26.87,13.54Z" />
        <polygon points="0 35.39 2.95 35.39 17.87 47.07 17.87 35.39 21.75 35.39 21.75 52.98 18.79 52.98 3.92 41.13 3.92 52.98 0 52.98 0 35.39 0 35.39" />
        <polygon points="23.33 35.39 42.64 35.39 42.64 38.52 27.63 38.52 27.63 42.33 36.11 42.33 36.11 45.39 27.63 45.39 27.63 49.72 42.88 49.72 42.88 52.98 23.33 52.98 23.33 35.39 23.33 35.39" />
        <path d="M67.17,38.55v3.71h11.44c3.5,0,4.57.82,4.57,4.16v2.41c0,3.3-1.07,4.16-4.57,4.16h-11.44c-3.5,0-4.6-.86-4.6-4.16v-.48l3.81-.72v2.1h12.92v-4.05h-11.37c-3.47,0-4.64-.82-4.64-4.16v-1.96c0-3.33,1.17-4.16,4.64-4.16h10.45c3.23,0,4.6.76,4.6,3.85v.41l-3.71.79v-1.89h-12.09Z" />
        <polygon points="93.7 52.98 89.4 52.98 89.4 38.55 83.8 38.55 83.8 35.39 101.26 35.39 101.26 38.55 93.7 38.55 93.7 52.98 93.7 52.98" />
        <path d="M103.18,45.9l3.95-7.32,4.12,7.32h-8.07ZM94.87,52.98h4.3l2.2-3.85h11.65l2.2,3.85h4.81l-10.58-17.59h-4.12l-10.45,17.59h0Z" />
        <path d="M135.24,38.45c1.27,0,1.96.24,1.96,1.41v2.03c0,1.17-.69,1.41-1.96,1.41h-9.79v-4.84h9.79ZM121.22,52.98h4.23v-6.63h4.05l7.35,6.63h6.15l-8.35-6.63h2.41c2.82,0,4.12-.96,4.12-3.37v-4.16c0-2.47-1.31-3.44-4.12-3.44h-15.84v17.59h0Z" />
        <path d="M85.25,59.41h1.82v1h.03c.58-.82,1.41-1.17,2.41-1.17,1.75,0,2.51,1.24,2.51,2.89v4.67h-1.92v-3.95c0-.89,0-2.1-1.24-2.1-1.37,0-1.68,1.48-1.68,2.44v3.61h-1.92v-7.39h0Z" />
        <path d="M95.73,62.4c.07-1,.62-1.75,1.68-1.75s1.51.79,1.58,1.75h-3.26ZM100.26,64.8c-.72.41-1.51.76-2.44.76-1.2,0-2.03-.69-2.1-1.86h5.09c0-2.58-.79-4.47-3.5-4.47-2.3,0-3.44,1.79-3.44,3.92,0,2.41,1.44,3.81,3.85,3.81,1.03,0,1.86-.21,2.54-.58v-1.58h0Z" />
        <path d="M103.29,60.82h-1.41v-1.41h1.41v-1.48l1.92-.62v2.1h1.72v1.41h-1.72v3.44c0,.62.17,1.24.93,1.24.34,0,.65-.1.86-.21l.07,1.51c-.41.1-.86.17-1.44.17-1.48,0-2.34-.93-2.34-2.41v-3.75h0Z" />
        <polygon points="107.75 59.41 109.81 59.41 111.43 64.87 111.46 64.87 112.94 59.41 115.17 59.41 116.79 64.87 116.82 64.87 118.33 59.41 120.19 59.41 117.92 66.8 115.65 66.8 114.04 61.16 114.04 61.16 112.46 66.8 110.26 66.8 107.75 59.41 107.75 59.41" />
        <path d="M123.21,62.91c0-1.1.62-2.16,1.86-2.16s1.86,1.03,1.86,2.16c0,1.24-.38,2.58-1.86,2.58s-1.86-1.34-1.86-2.58h0ZM121.22,63.15c0,2.13,1.41,3.81,3.85,3.81s3.85-1.68,3.85-3.81c0-2.44-1.65-3.92-3.85-3.92s-3.85,1.48-3.85,3.92h0Z" />
        <path d="M130.84,59.41h1.68v1.68h.03c.1-.69.89-1.86,2.03-1.86.17,0,.38,0,.58.07v1.92c-.17-.1-.52-.14-.86-.14-1.55,0-1.55,1.92-1.55,2.99v2.71h-1.92v-7.39h0Z" />
        <polygon points="136.75 56.04 138.68 56.04 138.68 62.36 138.71 62.36 141.01 59.41 143.25 59.41 140.5 62.71 143.59 66.8 141.18 66.8 138.71 63.22 138.68 63.22 138.68 66.8 136.75 66.8 136.75 56.04 136.75 56.04" />
        <path d="M72.29,19.04c1.82-1,3.64-1.89,5.5-2.68,5.53-2.27,10.86-3.37,14.64-2.78.93.17,1.72.41,2.34.72l3.33-1.58c-.72-.31-1.55-.55-2.51-.72-.17-.03-.38-.07-.55-.07l-2.06-.14c-5.15-.07-11.89,1.89-18.31,5.19,4.3-2.44,8.73-4.81,12.92-6.01h0l2.85-10.96-10.51,10.62-11.79.17,5.05,7.32-.89.93h0Z" />
        <path d="M83.22,16.01c-4.74,1.58-9.79,4.3-13.92,7.73-2.3,1.92-4.05,3.85-5.19,5.63l12.3-5.77,5.67,6.84,3.2-11.13,8.76-4.36c-2.68-.79-6.56-.34-10.82,1.06h0Z" />
        <path d="M144.41,36.13c0,.43-.34.77-.78.77s-.79-.34-.79-.77.35-.77.79-.77.78.34.78.77ZM143.03,36.13c0,.34.25.61.6.61s.58-.27.58-.61-.25-.62-.59-.62-.59.28-.59.61ZM143.5,36.53h-.18v-.77c.07-.01.17-.02.29-.02.15,0,.21.02.27.06.04.03.07.09.07.17,0,.08-.07.15-.16.18h0c.07.04.12.09.14.2.02.12.04.16.06.19h-.19s-.04-.1-.06-.19c-.01-.08-.06-.12-.16-.12h-.08v.31ZM143.5,36.1h.08c.1,0,.18-.03.18-.11,0-.07-.05-.12-.16-.12-.05,0-.08,0-.1,0v.22Z" />
      </svg>
    </div>
  );
}
