"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.yearlyAverage = exports.threeMonthAverage = exports.calculateAverageByMonthlyData = exports.sumOfValues = exports.calculateMonthlyDisplayData = exports.calculateTotalRevenueSummary = exports.calculateRevenueSummary = exports.calculateCallsPerTechPerDay = exports.calculateNumOfJobsPerCrewPerDay = exports.calculateNumOfLeadsPerSalesmanPerDay = exports.calculateTotalNumOfLeads = exports.calculateCallCountSeasonalityPercentage = exports.calculateYearTotalCallCount = exports.calculateMonthlyTotalCallCount = void 0;
const utils_1 = require("../../../utils");
const helpers_1 = require("../../utils/helpers");
//This is calculating avgCallCount
function calculateMonthlyTotalCallCount(callCountObj) {
    if (callCountObj) {
        const callCountByYearArr = Object.values(callCountObj);
        let avgInstallCallCount = null;
        let countForAvg = 0;
        for (let i = 0; i < callCountByYearArr.length; i++) {
            const currentCallCount = callCountByYearArr[i];
            // KRJ: Added null check so zero for a month is valid and then converted return to be an average.
            if (currentCallCount >= 0 && currentCallCount !== null) {
                avgInstallCallCount += currentCallCount;
                countForAvg += 1;
            }
        }
        if (avgInstallCallCount > 0)
            return avgInstallCallCount / countForAvg;
    }
    return null;
}
exports.calculateMonthlyTotalCallCount = calculateMonthlyTotalCallCount;
const calculateYearTotalCallCount = (yearlyData, callCountString) => {
    let yearTotalCallCount = null;
    const yearlyDataArr = Object.values(yearlyData);
    for (let i = 0; i < yearlyDataArr.length; i++) {
        if (yearlyDataArr[i] === null)
            continue;
        const totalMonthlyCallCount = calculateMonthlyTotalCallCount(yearlyDataArr[i][callCountString]);
        if (totalMonthlyCallCount !== undefined || totalMonthlyCallCount !== null) {
            yearTotalCallCount += totalMonthlyCallCount;
        }
    }
    return yearTotalCallCount;
};
exports.calculateYearTotalCallCount = calculateYearTotalCallCount;
const calculateCallCountSeasonalityPercentage = (yearlyData, callCountObj, callCountKey) => {
    //callCountObj is the last 4 years of call data by month (ie. installJobs, demandCalls, systemCheckCalls)
    if (callCountObj) {
        const seasonalityPercentage = (0, helpers_1.getSeasonalityPercentage)(callCountObj, callCountKey);
        if (!(0, utils_1.empty)(seasonalityPercentage) && seasonalityPercentage !== 0)
            return seasonalityPercentage;
        const keysToSum = [`${callCountKey}`];
        if (callCountKey === "demandCalls") {
            keysToSum.push("demandSystemCheckCalls");
        }
        let yearTotalCallCount = 0;
        let liveCalculatedAvgCallCount = 0;
        keysToSum.forEach((key) => {
            if (callCountObj[key] && yearlyData) {
                const previousYears = callCountObj[key];
                //if any year is missing return back null
                if (previousYears.yearMinusOne) {
                    liveCalculatedAvgCallCount +=
                        calculateMonthlyTotalCallCount(previousYears);
                    yearTotalCallCount += (0, exports.calculateYearTotalCallCount)(yearlyData, key);
                }
            }
        });
        if (liveCalculatedAvgCallCount > 0 && yearTotalCallCount > 0) {
            return liveCalculatedAvgCallCount / yearTotalCallCount;
        }
    }
    return null;
};
exports.calculateCallCountSeasonalityPercentage = calculateCallCountSeasonalityPercentage;
const calculateTotalNumOfLeads = (singleMonthData) => {
    if (singleMonthData) {
        const { numOfTechGeneratedLeadsRan, numOfMarketedLeadsRan, numOfOtherLeadsRan, } = singleMonthData;
        if (numOfTechGeneratedLeadsRan ||
            numOfMarketedLeadsRan ||
            numOfOtherLeadsRan) {
            const totalNumOfLeadsRan = (numOfTechGeneratedLeadsRan || 0) +
                (numOfMarketedLeadsRan || 0) +
                (numOfOtherLeadsRan || 0);
            return totalNumOfLeadsRan;
        }
    }
    return null;
};
exports.calculateTotalNumOfLeads = calculateTotalNumOfLeads;
const calculateNumOfLeadsPerSalesmanPerDay = (singleMonthData, workingDays) => {
    if (singleMonthData && workingDays) {
        const { numOfSalesman } = singleMonthData;
        const liveCalculatedTotalNumOfLeadsRan = (0, exports.calculateTotalNumOfLeads)(singleMonthData);
        let numOfLeadsPerSalesmanPerDay = null;
        if (numOfSalesman &&
            liveCalculatedTotalNumOfLeadsRan &&
            liveCalculatedTotalNumOfLeadsRan > 0) {
            numOfLeadsPerSalesmanPerDay =
                liveCalculatedTotalNumOfLeadsRan / numOfSalesman / workingDays;
            if (numOfLeadsPerSalesmanPerDay > 0)
                return numOfLeadsPerSalesmanPerDay;
        }
    }
    return null;
};
exports.calculateNumOfLeadsPerSalesmanPerDay = calculateNumOfLeadsPerSalesmanPerDay;
const calculateNumOfJobsPerCrewPerDay = (singleMonthData, workingDays) => {
    if (singleMonthData && workingDays) {
        const { numOfSoldJobs, numOfInstallCrews } = singleMonthData;
        const numOfJobsPerCrewPerDay = numOfSoldJobs / numOfInstallCrews / workingDays;
        if (numOfJobsPerCrewPerDay > 0 && numOfJobsPerCrewPerDay < Infinity)
            return numOfJobsPerCrewPerDay;
    }
    return null;
};
exports.calculateNumOfJobsPerCrewPerDay = calculateNumOfJobsPerCrewPerDay;
//Demand specific calculation
const calculateCallsPerTechPerDay = (singleMonthData, workingDays) => {
    var _a;
    if (singleMonthData && workingDays) {
        const { demandCalls, systemCheckCalls, numOfAllTechsRunningCalls } = singleMonthData;
        if (demandCalls && systemCheckCalls && numOfAllTechsRunningCalls) {
            // TP-1799: adding in new call type to this.
            const numberDemandSystemCheckCalls = (_a = (0, utils_1.objectFieldValidator)(singleMonthData, ["demandSystemCheckCalls", "yearMinusOne"], true)) !== null && _a !== void 0 ? _a : 0;
            const callsPerTechPerDay = (demandCalls.yearMinusOne +
                systemCheckCalls.yearMinusOne +
                numberDemandSystemCheckCalls) /
                numOfAllTechsRunningCalls /
                workingDays;
            if (callsPerTechPerDay > 0)
                return callsPerTechPerDay;
        }
    }
    return null;
};
exports.calculateCallsPerTechPerDay = calculateCallsPerTechPerDay;
const calculateRevenueSummary = (callCount, conversionRate, averageSale) => {
    if (callCount && conversionRate && averageSale) {
        const revenueSummary = callCount * (conversionRate / 100) * averageSale;
        if (revenueSummary > 0)
            return revenueSummary;
    }
    return null;
};
exports.calculateRevenueSummary = calculateRevenueSummary;
const calculateTotalRevenueSummary = (singleMonthData, departmentType) => {
    var _a, _b, _c, _d, _e, _f;
    if (singleMonthData &&
        departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
        const { demandCallsConversionRate, demandCallsAverageSale, demandSystemCheckCallsConversionRate, demandSystemCheckCallsAverageSale, systemCheckCallsConversionRate, systemCheckCallsAverageSale, } = singleMonthData;
        const totalRevenueSummary = ((_a = (0, exports.calculateRevenueSummary)(singleMonthData.demandCalls.yearMinusOne, demandCallsConversionRate, demandCallsAverageSale)) !== null && _a !== void 0 ? _a : 0) +
            ((_b = (0, exports.calculateRevenueSummary)(singleMonthData.demandSystemCheckCalls.yearMinusOne, demandSystemCheckCallsConversionRate, demandSystemCheckCallsAverageSale)) !== null && _b !== void 0 ? _b : 0) +
            ((_c = (0, exports.calculateRevenueSummary)(singleMonthData.systemCheckCalls.yearMinusOne, systemCheckCallsConversionRate, systemCheckCallsAverageSale)) !== null && _c !== void 0 ? _c : 0);
        if (totalRevenueSummary > 0)
            return totalRevenueSummary;
    }
    if (singleMonthData &&
        departmentType === utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT) {
        const { numOfTechGeneratedLeadsRan, techGeneratedLeadsConversionRate, techGeneratedLeadsAverageSale, numOfMarketedLeadsRan, marketedLeadsConversionRate, marketedLeadsAverageSale, numOfOtherLeadsRan, otherLeadsConversionRate, otherLeadsAverageSale, } = singleMonthData;
        const totalRevenueSummary = ((_d = (0, exports.calculateRevenueSummary)(numOfTechGeneratedLeadsRan, techGeneratedLeadsConversionRate, techGeneratedLeadsAverageSale)) !== null && _d !== void 0 ? _d : 0) +
            ((_e = (0, exports.calculateRevenueSummary)(numOfMarketedLeadsRan, marketedLeadsConversionRate, marketedLeadsAverageSale)) !== null && _e !== void 0 ? _e : 0) +
            ((_f = (0, exports.calculateRevenueSummary)(numOfOtherLeadsRan, otherLeadsConversionRate, otherLeadsAverageSale)) !== null && _f !== void 0 ? _f : 0);
        if (totalRevenueSummary > 0)
            return totalRevenueSummary;
    }
    return null;
};
exports.calculateTotalRevenueSummary = calculateTotalRevenueSummary;
const calculateMonthlyDisplayData = (key, yearlyData, selectedMonth, workingDays, departmentType) => {
    if (yearlyData === null ||
        !(selectedMonth in yearlyData) ||
        !yearlyData[selectedMonth])
        return null;
    const singleMonthData = yearlyData[selectedMonth];
    let returnValue;
    switch (key) {
        //install
        case "avgInstallCallCount":
            returnValue = calculateMonthlyTotalCallCount(singleMonthData.installJobs);
            break;
        case "installCallCountSeasonalityPercentage":
            returnValue = (0, exports.calculateCallCountSeasonalityPercentage)(yearlyData, singleMonthData, "installJobs");
            break;
        case "totalNumOfInstallLeadsRan":
            returnValue = (0, exports.calculateTotalNumOfLeads)(singleMonthData);
            break;
        case "numOfLeadsPerSalesmanPerDay":
            returnValue = (0, exports.calculateNumOfLeadsPerSalesmanPerDay)(singleMonthData, workingDays);
            break;
        case "numOfJobsPerCrewPerDay":
            returnValue = (0, exports.calculateNumOfJobsPerCrewPerDay)(singleMonthData, workingDays);
            break;
        case "techGeneratedLeadsConversionRate":
            returnValue = singleMonthData.techGeneratedLeadsConversionRate;
            break;
        case "techGeneratedLeadsAverageSale":
            returnValue = singleMonthData.techGeneratedLeadsAverageSale;
            break;
        case "techGeneratedLeadsRevenueSummary":
            returnValue = (0, exports.calculateRevenueSummary)(singleMonthData.numOfTechGeneratedLeadsRan, singleMonthData.techGeneratedLeadsConversionRate, singleMonthData.techGeneratedLeadsAverageSale);
            break;
        case "marketedLeadsConversionRate":
            returnValue = singleMonthData.marketedLeadsConversionRate;
            break;
        case "marketedLeadsAverageSale":
            returnValue = singleMonthData.marketedLeadsAverageSale;
            break;
        case "marketedLeadsRevenueSummary":
            returnValue = (0, exports.calculateRevenueSummary)(singleMonthData.numOfMarketedLeadsRan, singleMonthData.marketedLeadsConversionRate, singleMonthData.marketedLeadsAverageSale);
            break;
        case "otherLeadsConversionRate":
            returnValue = singleMonthData.otherLeadsConversionRate;
            break;
        case "otherLeadsAverageSale":
            returnValue = singleMonthData.otherLeadsAverageSale;
            break;
        case "otherLeadsRevenueSummary":
            returnValue = (0, exports.calculateRevenueSummary)(singleMonthData.numOfOtherLeadsRan, singleMonthData.otherLeadsConversionRate, singleMonthData.otherLeadsAverageSale);
            break;
        //serviceDemand
        case "avgDemandCallCount":
            returnValue = calculateMonthlyTotalCallCount(singleMonthData.demandCalls);
            break;
        case "avgDemandSystemCheckCallCount":
            returnValue = calculateMonthlyTotalCallCount(singleMonthData.demandSystemCheckCalls);
            break;
        case "demandCallCountSeasonalityPercentage":
            returnValue = (0, exports.calculateCallCountSeasonalityPercentage)(yearlyData, singleMonthData, "demandCalls");
            break;
        case "callsPerTechPerDay":
            returnValue = (0, exports.calculateCallsPerTechPerDay)(singleMonthData, workingDays);
            break;
        case "demandCallsConversionRate":
            returnValue = singleMonthData.demandCallsConversionRate;
            break;
        case "demandSystemCheckCallsConversionRate":
            returnValue = singleMonthData.demandSystemCheckCallsConversionRate;
            break;
        case "demandCallsAverageSale":
            returnValue = singleMonthData.demandCallsAverageSale;
            break;
        case "demandSystemCheckCallsAverageSale":
            returnValue = singleMonthData.demandSystemCheckCallsAverageSale;
            break;
        case "demandCallsRevenueSummary":
            returnValue = (0, exports.calculateRevenueSummary)(singleMonthData.demandCalls.yearMinusOne, singleMonthData.demandCallsConversionRate, singleMonthData.demandCallsAverageSale);
            break;
        case "demandSystemCheckCallsRevenueSummary":
            returnValue = (0, exports.calculateRevenueSummary)(singleMonthData.demandSystemCheckCalls.yearMinusOne, singleMonthData.demandSystemCheckCallsConversionRate, singleMonthData.demandSystemCheckCallsAverageSale);
            break;
        //serviceSystem
        case "avgSystemCheckCallCount":
            returnValue = calculateMonthlyTotalCallCount(singleMonthData.systemCheckCalls);
            break;
        case "systemCheckCallCountSeasonalityPercentage":
            returnValue = (0, exports.calculateCallCountSeasonalityPercentage)(yearlyData, singleMonthData, "systemCheckCalls");
            break;
        case "systemCheckCallsConversionRate":
            returnValue = singleMonthData.systemCheckCallsConversionRate;
            break;
        case "systemCheckCallsAverageSale":
            returnValue = singleMonthData.systemCheckCallsAverageSale;
            break;
        case "systemCheckCallsRevenueSummary":
            returnValue = (0, exports.calculateRevenueSummary)(singleMonthData.systemCheckCalls.yearMinusOne, singleMonthData.systemCheckCallsConversionRate, singleMonthData.systemCheckCallsAverageSale);
            break;
        case "totalRevenueSummary":
            returnValue = (0, exports.calculateTotalRevenueSummary)(singleMonthData, departmentType);
            break;
        default:
            returnValue = singleMonthData[key];
            break;
    }
    return returnValue;
};
exports.calculateMonthlyDisplayData = calculateMonthlyDisplayData;
const sumOfValues = (array, key) => array.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
exports.sumOfValues = sumOfValues;
/**
 *
 * @param dataForTimeSpan
 * @param key1
 * @param key2 if the obj is not nested pass an empty string
 * @param key3
 * @param percent
 * Takes an array of prework monthly data: [{month of data}, {month of data},...]
 * month[key1][key2] is used to access nested yearMinusX data
 * ex. monthData["demandCalls"]["yearMinusOne"] to build a total across all months
 * key3 is the value for that month that you are calculating the avg for
 * (in the example above it could be month["demandCallsConversionRate"]
 * percent is a bool, pass true if key3 references a percentage to adjust rounding and return value
 */
const calculateAverageByMonthlyData = (dataForTimeSpan, key1, key2, key3, percent) => {
    const modifier = percent ? 100 : 1;
    let totalForAverage = 0;
    let numberToAverage = 0;
    dataForTimeSpan.forEach((month) => {
        const currentValue = key2 ? month[key1][key2] : month[key1];
        totalForAverage += currentValue;
        numberToAverage += (currentValue * month[key3]) / modifier;
    });
    if (totalForAverage <= 0)
        return 0;
    return (0, utils_1.round)((numberToAverage / totalForAverage) * modifier, percent ? 1 : 2);
};
exports.calculateAverageByMonthlyData = calculateAverageByMonthlyData;
const threeMonthAverage = (value) => value / 3;
exports.threeMonthAverage = threeMonthAverage;
const yearlyAverage = (value) => value / 12;
exports.yearlyAverage = yearlyAverage;
