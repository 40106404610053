import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import ChevronUpDownIcon from "../icons/ChevronUpDownIcon";
import NexstarTooltip from "./NexstarTooltip";

type SelectMenuProps = {
  id: string;
  dataTestId?: string;
  defaultText;
  value: string | boolean | number;
  options: Record<string, any>[];
  onChange?: any;
  menuLabel?: string;
  maxWidth?: string;
  children: JSX.Element | JSX.Element[];
  toolTipText?: string;
  disabled?: boolean;
  textColor?: string;
  left?: boolean;
  error?: boolean;
  helperText?: string | boolean;
  required?: boolean;
  below?: boolean;
};

export default function NexstarSelectMenu({
  id,
  dataTestId,
  defaultText,
  value,
  options,
  onChange,
  menuLabel,
  maxWidth = "max-w-none",
  children,
  toolTipText,
  disabled,
  textColor = "text-NexstarBlue",
  left = false,
  error = false,
  helperText,
  required,
  below,
}: SelectMenuProps): JSX.Element {
  function getName(nameValue): string | JSX.Element {
    let displayName = null;
    if (!nameValue && required) {
      return (
        <p>
          <span className="text-gray-500">{defaultText}</span>
          <span className="text-[13px] text-gray-500"> * Required</span>
        </p>
      );
    }
    if (!nameValue) {
      return defaultText;
    }
    options.forEach((reason) => {
      if (reason.value === nameValue) {
        displayName = reason.text;
        return displayName;
      }
    });
    return displayName;
  }

  function getValue(displayValue) {
    let returnValue = null;
    if (!displayValue) {
      return null;
    }
    options.forEach((reason) => {
      if (reason.value === displayValue) {
        returnValue = displayValue;
      }
    });
    return returnValue;
  }
  return (
    <div
      className={`flex ${
        left ? "flex-row gap-4 items-center" : "flex-col"
      } w-full ${maxWidth}`}
    >
      <Listbox value={getValue(value)} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <>
            <div className="flex gap-2 items-center">
              <Listbox.Label
                className={`text-left block text-sm font-bold uppercase ${
                  disabled ? "text-NexstarGray-dark" : textColor
                }`}
              >
                {`${menuLabel}${required ? " *" : ""}`}
              </Listbox.Label>
              {toolTipText ? <NexstarTooltip content={toolTipText} /> : null}
            </div>
            <div
              className={`relative mt-1 flex flex-grow items-center justify-center`}
            >
              <Listbox.Button
                id={id}
                data-testid={dataTestId ? dataTestId : id}
                className={`w-full content-center relative cursor-default border ${
                  disabled ? "border-NexstarGray" : "border-NexstarGray-dark"
                } focus:ring-1 focus:ring-NexstarBlue-light focus:border-NexstarBlue-light h-[42px] ${
                  error
                    ? "bg-red-100 border-NexstarSienna-dark focus:bg-white"
                    : "border-NexstarGray-dark"
                } py-2 pl-2 pr-8 text-left focus:outline-none text-md ${
                  !value ? "bg-NexstarSoftGray" : "bg-white"
                } `}
              >
                <span className={`flex items-center`}>
                  <span
                    className={`${
                      disabled && "text-NexstarGray-dark"
                    } block truncate`}
                  >
                    {getName(value)}
                  </span>
                </span>
                <span
                  className={`pointer-events-none absolute w-[24px] inset-y-0 right-0 flex items-center justify-center bg-NexstarGray`}
                >
                  <ChevronUpDownIcon color="gray" size="xsmall" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={`absolute ${
                    below ? "top-[42px]" : null
                  } z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                  {children}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error ? (
        <p className="text-xs text-NexstarSienna-dark mt-1 relative">
          {helperText}
        </p>
      ) : null}
    </div>
  );
}
