"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companyDataTestObj = exports.currentYearNullTestObj = exports.workshopOverheadNULLTestObj = exports.workshopFULLOverheadTestObj = exports.currentYearWorkshopTest = exports.workshopOverheadRequiredTestObj = exports.workshopOverheadTestObj = exports.serviceAgreementsObject = exports.serviceAgreementsNULLObject = exports.preWorkEmptyObject = exports.electricalPreWorkObject = exports.plumbingPreWorkObject = exports.hvacPreWorkObject = exports.preWorkNullObject = void 0;
const utils_1 = require("../../../utils");
const serviceAgreementsObject = {
    updatedAt: "now",
    updatedBy: "me",
    createdAt: "me",
    areAgreementsRecognizedWhenVisitsRan: false,
    annualPriceOfAgreement: 330.0,
    departmentServiceAgreements: [
        {
            departmentId: "1",
            departmentName: "1",
            departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
            departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
            fiscalYear: 2022,
            monthly: {
                numOfAgreementsStartOfYear: null,
                projectedNumOfNewAgreements: null,
                projectedNumOfAgreementsEndOfYear: null,
                retentionRateGoalNextYear: null,
                goalForNewAgreementsToAdd: null,
            },
            annual: {
                numOfAgreementsStartOfYear: null,
                projectedNumOfNewAgreements: null,
                projectedNumOfAgreementsEndOfYear: null,
                retentionRateGoalNextYear: null,
                goalForNewAgreementsToAdd: null,
            },
            multiTradeSalesDistribution: 36,
            numOfVisits: 2,
            newRunRate: 76,
            visitsAtTimeOfSale: false,
            revenueRecognizedPerSystemCheckVisit: 82.5,
            totalPriorYearAgreements: null,
            totalNewAndRetainedAgreementsForDepartment: null,
            totalAgreementRevenue: null,
        },
        {
            departmentId: "2",
            departmentName: "2",
            departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
            departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_PLUMBING,
            fiscalYear: 2022,
            monthly: {
                numOfAgreementsStartOfYear: null,
                projectedNumOfNewAgreements: null,
                projectedNumOfAgreementsEndOfYear: null,
                retentionRateGoalNextYear: null,
                goalForNewAgreementsToAdd: null,
            },
            annual: {
                numOfAgreementsStartOfYear: null,
                projectedNumOfNewAgreements: null,
                projectedNumOfAgreementsEndOfYear: null,
                retentionRateGoalNextYear: null,
                goalForNewAgreementsToAdd: null,
            },
            multiTradeSalesDistribution: 42,
            numOfVisits: 1,
            newRunRate: 15,
            visitsAtTimeOfSale: false,
            revenueRecognizedPerSystemCheckVisit: 82.5,
            totalPriorYearAgreements: null,
            totalNewAndRetainedAgreementsForDepartment: null,
            totalAgreementRevenue: null,
        },
        {
            departmentId: "3",
            departmentName: "3",
            departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
            departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_ELECTRICAL,
            fiscalYear: 2022,
            monthly: {
                numOfAgreementsStartOfYear: null,
                projectedNumOfNewAgreements: null,
                projectedNumOfAgreementsEndOfYear: null,
                retentionRateGoalNextYear: null,
                goalForNewAgreementsToAdd: null,
            },
            annual: {
                numOfAgreementsStartOfYear: null,
                projectedNumOfNewAgreements: null,
                projectedNumOfAgreementsEndOfYear: null,
                retentionRateGoalNextYear: null,
                goalForNewAgreementsToAdd: null,
            },
            multiTradeSalesDistribution: 23,
            numOfVisits: 1,
            newRunRate: 14,
            visitsAtTimeOfSale: false,
            revenueRecognizedPerSystemCheckVisit: 82.5,
            totalPriorYearAgreements: null,
            totalNewAndRetainedAgreementsForDepartment: null,
            totalAgreementRevenue: null,
        },
    ],
    multiTradeAgreements: {
        monthly: {
            numOfAgreementsStartOfYear: null,
            projectedNumOfNewAgreements: null,
            projectedNumOfAgreementsEndOfYear: null,
            retentionRateGoalNextYear: null,
            goalForNewAgreementsToAdd: null,
        },
        annual: {
            numOfAgreementsStartOfYear: 1043,
            projectedNumOfNewAgreements: 1129,
            projectedNumOfAgreementsEndOfYear: 1680,
            retentionRateGoalNextYear: 70,
            goalForNewAgreementsToAdd: 1500,
        },
    },
};
exports.serviceAgreementsObject = serviceAgreementsObject;
const serviceAgreementsNULLObject = {
    areAgreementsFromOtherDeptsUsed: null,
    areAgreementsRecognizedWhenVisitsRan: null,
    annualPriceOfAgreement: null,
    departmentServiceAgreements: [
        {
            departmentId: "1",
            departmentName: "1",
            departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
            departmentTrade: "",
            fiscalYear: 2022,
            monthly: {
                numOfAgreementsStartOfYear: null,
                projectedNumOfNewAgreements: null,
                projectedNumOfAgreementsEndOfYear: null,
                retentionRateGoalNextYear: null,
                goalForNewAgreementsToAdd: null,
            },
            annual: {
                numOfAgreementsStartOfYear: null,
                projectedNumOfNewAgreements: null,
                projectedNumOfAgreementsEndOfYear: null,
                retentionRateGoalNextYear: null,
                goalForNewAgreementsToAdd: null,
            },
            multiTradeSalesDistribution: null,
            numOfVisits: null,
            newRunRate: null,
            visitsAtTimeOfSale: null,
            revenueRecognizedPerSystemCheckVisit: null,
            totalPriorYearAgreements: null,
            totalNewAndRetainedAgreementsForDepartment: null,
            totalAgreementRevenue: null,
        },
    ],
    multiTradeAgreements: {
        monthly: {
            numOfAgreementsStartOfYear: null,
            projectedNumOfNewAgreements: null,
            projectedNumOfAgreementsEndOfYear: null,
            retentionRateGoalNextYear: null,
            goalForNewAgreementsToAdd: null,
        },
        annual: {
            numOfAgreementsStartOfYear: null,
            projectedNumOfNewAgreements: null,
            projectedNumOfAgreementsEndOfYear: null,
            retentionRateGoalNextYear: null,
            goalForNewAgreementsToAdd: null,
        },
    },
};
exports.serviceAgreementsNULLObject = serviceAgreementsNULLObject;
const preWorkNullObject = {
    departmentTrade: null,
    departmentType: null,
    departmentId: null,
    nonMonthlyData: {
        serviceSystemCheck: {
            runRateVisits: null,
            agreementsSold: null,
        },
    },
    monthlyData: {
        jan: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        feb: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        mar: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        apr: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        may: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        jun: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        jul: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        aug: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        sep: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        oct: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        nov: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
        dec: {
            demandCallCountSeasonalityPercentage: null,
            systemCheckCallCountSeasonalityPercentage: null,
        },
    },
};
exports.preWorkNullObject = preWorkNullObject;
const hvacPreWorkObject = {
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentId: "1",
    nonMonthlyData: {
        serviceSystemCheck: {
            runRateVisits: 0.74,
            agreementsSold: 406,
        },
    },
    monthlyData: {
        jan: {
            demandCallCountSeasonalityPercentage: 0.0720939300723539,
            systemCheckCallCountSeasonalityPercentage: 0.091,
        },
        feb: {
            demandCallCountSeasonalityPercentage: 0.0509509986569039,
            systemCheckCallCountSeasonalityPercentage: 0.075,
        },
        mar: {
            demandCallCountSeasonalityPercentage: 0.0455786144447814,
            systemCheckCallCountSeasonalityPercentage: 0.12,
        },
        apr: {
            demandCallCountSeasonalityPercentage: 0.046791733460422,
            systemCheckCallCountSeasonalityPercentage: 0.1,
        },
        may: {
            demandCallCountSeasonalityPercentage: 0.091503834322603,
            systemCheckCallCountSeasonalityPercentage: 0.106,
        },
        jun: {
            demandCallCountSeasonalityPercentage: 0.109873922273732,
            systemCheckCallCountSeasonalityPercentage: 0.0741587582034743,
        },
        jul: {
            demandCallCountSeasonalityPercentage: 0.121485204280577,
            systemCheckCallCountSeasonalityPercentage: 0.049,
        },
        aug: {
            demandCallCountSeasonalityPercentage: 0.0953164940860448,
            systemCheckCallCountSeasonalityPercentage: 0.049,
        },
        sep: {
            demandCallCountSeasonalityPercentage: 0.0892508990078419,
            systemCheckCallCountSeasonalityPercentage: 0.062,
        },
        oct: {
            demandCallCountSeasonalityPercentage: 0.0939300723538841,
            systemCheckCallCountSeasonalityPercentage: 0.086,
        },
        nov: {
            demandCallCountSeasonalityPercentage: 0.102075300030328,
            systemCheckCallCountSeasonalityPercentage: 0.083,
        },
        dec: {
            demandCallCountSeasonalityPercentage: 0.0811489970105281,
            systemCheckCallCountSeasonalityPercentage: 0.1008,
        },
    },
};
exports.hvacPreWorkObject = hvacPreWorkObject;
const plumbingPreWorkObject = {
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_PLUMBING,
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentId: "2",
    nonMonthlyData: {
        serviceSystemCheck: {
            runRateVisits: 0.74,
            agreementsSold: 474,
        },
    },
    monthlyData: {
        jan: {
            demandCallCountSeasonalityPercentage: 0.0842484645706019,
            systemCheckCallCountSeasonalityPercentage: 0.129837702871411,
        },
        feb: {
            demandCallCountSeasonalityPercentage: 0.0697801578123716,
            systemCheckCallCountSeasonalityPercentage: 0.102372034956305,
        },
        mar: {
            demandCallCountSeasonalityPercentage: 0.0802295053467772,
            systemCheckCallCountSeasonalityPercentage: 0.122347066167291,
        },
        apr: {
            demandCallCountSeasonalityPercentage: 0.0733415112532159,
            systemCheckCallCountSeasonalityPercentage: 0.103620474406991,
        },
        may: {
            demandCallCountSeasonalityPercentage: 0.0899942229134672,
            systemCheckCallCountSeasonalityPercentage: 0.0511860174781523,
        },
        jun: {
            demandCallCountSeasonalityPercentage: 0.0830599441925209,
            systemCheckCallCountSeasonalityPercentage: 0.0599250936329588,
        },
        jul: {
            demandCallCountSeasonalityPercentage: 0.0872504625517353,
            systemCheckCallCountSeasonalityPercentage: 0.0561797752808989,
        },
        aug: {
            demandCallCountSeasonalityPercentage: 0.0797273745172877,
            systemCheckCallCountSeasonalityPercentage: 0.0948813982521848,
        },
        sep: {
            demandCallCountSeasonalityPercentage: 0.0804574858254668,
            systemCheckCallCountSeasonalityPercentage: 0.0699126092384519,
        },
        oct: {
            demandCallCountSeasonalityPercentage: 0.0966955101754037,
            systemCheckCallCountSeasonalityPercentage: 0.0786516853932584,
        },
        nov: {
            demandCallCountSeasonalityPercentage: 0.0866608116995542,
            systemCheckCallCountSeasonalityPercentage: 0.0848938826466916,
        },
        dec: {
            demandCallCountSeasonalityPercentage: 0.0885545491415976,
            systemCheckCallCountSeasonalityPercentage: 0.0461922596754057,
        },
    },
};
exports.plumbingPreWorkObject = plumbingPreWorkObject;
const electricalPreWorkObject = {
    departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_ELECTRICAL,
    departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
    departmentId: "3",
    nonMonthlyData: {
        serviceSystemCheck: {
            runRateVisits: 0.74,
            agreementsSold: 257,
        },
    },
    monthlyData: {
        jan: {
            demandCallCountSeasonalityPercentage: 0.0667911612413879,
            systemCheckCallCountSeasonalityPercentage: 0.0878962536023055,
        },
        feb: {
            demandCallCountSeasonalityPercentage: 0.0621071747865066,
            systemCheckCallCountSeasonalityPercentage: 0.135446685878963,
        },
        mar: {
            demandCallCountSeasonalityPercentage: 0.0785462800485642,
            systemCheckCallCountSeasonalityPercentage: 0.132564841498559,
        },
        apr: {
            demandCallCountSeasonalityPercentage: 0.068126171786106,
            systemCheckCallCountSeasonalityPercentage: 0.102305475504323,
        },
        may: {
            demandCallCountSeasonalityPercentage: 0.0862744194226377,
            systemCheckCallCountSeasonalityPercentage: 0.0561959654178674,
        },
        jun: {
            demandCallCountSeasonalityPercentage: 0.0855245929167608,
            systemCheckCallCountSeasonalityPercentage: 0.0706051873198847,
        },
        jul: {
            demandCallCountSeasonalityPercentage: 0.0983914390880198,
            systemCheckCallCountSeasonalityPercentage: 0.0677233429394813,
        },
        aug: {
            demandCallCountSeasonalityPercentage: 0.0848517535818089,
            systemCheckCallCountSeasonalityPercentage: 0.0677233429394813,
        },
        sep: {
            demandCallCountSeasonalityPercentage: 0.0835167430370909,
            systemCheckCallCountSeasonalityPercentage: 0.037463976945245,
        },
        oct: {
            demandCallCountSeasonalityPercentage: 0.102550920712918,
            systemCheckCallCountSeasonalityPercentage: 0.0878962536023055,
        },
        nov: {
            demandCallCountSeasonalityPercentage: 0.0944205862243645,
            systemCheckCallCountSeasonalityPercentage: 0.102305475504323,
        },
        dec: {
            demandCallCountSeasonalityPercentage: 0.088898757153835,
            systemCheckCallCountSeasonalityPercentage: 0.0518731988472622,
        },
    },
};
exports.electricalPreWorkObject = electricalPreWorkObject;
const preWorkEmptyObject = {
    jan: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    feb: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    mar: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    apr: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    may: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    jun: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    jul: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    aug: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    sep: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    oct: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    nov: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
    dec: {
        demandCallCountSeasonalityPercentage: 0,
        systemCheckCallCountSeasonalityPercentage: 0,
    },
};
exports.preWorkEmptyObject = preWorkEmptyObject;
const workshopFULLOverheadTestObj = {
    id: "test",
    memberId: "tester",
    fiscalYear: 2022,
    accountNumber: "808",
    overheadName: "Tester123",
    big5: "Vehicle",
    currentYTD: 40526,
    projectedTotal: 44210,
    expensePercent: 0.004979493,
    nextYearIncPercent: 0.5,
    nextYearIncAmount: 1000,
    nextYearTotal: 44210,
    plannedPercent: 0,
    budgetMethod: "Flat",
    overheadOrder: 1,
};
exports.workshopFULLOverheadTestObj = workshopFULLOverheadTestObj;
const workshopOverheadRequiredTestObj = {
    accountNumber: "",
    big5: "",
    budgetMethod: "FLAT",
    currentYTD: null,
    expensePercent: 0,
    fiscalYear: 2022,
    id: "OVERHEAD#2022#01G7PVSZF6PCTCYBY03QWG1527",
    memberId: "404",
    monthlyVariance: null,
    nextYearIncAmount: null,
    nextYearIncPercent: 1,
    nextYearTotal: 0,
    overheadName: "Overhead",
    overheadOrder: 0,
    projectedTotal: null,
};
exports.workshopOverheadRequiredTestObj = workshopOverheadRequiredTestObj;
const workshopOverheadTestObj = {
    id: "test",
    memberId: "tester",
    fiscalYear: 2022,
    accountNumber: "808",
    overheadName: "Tester123",
    big5: "Vehicle",
    currentYTD: 40526,
    projectedTotal: 44210,
    expensePercent: 0.005,
    nextYearIncPercent: null,
    nextYearIncAmount: null,
    nextYearTotal: 44210,
    plannedPercent: 0,
    budgetMethod: "Flat",
    overheadOrder: 1,
};
exports.workshopOverheadTestObj = workshopOverheadTestObj;
const currentYearWorkshopTest = {
    id: "test",
    memberId: "tester",
    fiscalYear: 2022,
    monthsInCurrentYear: 11,
    currentYearRevenue: 8138579,
    currentYearProjectedRev: 8878450,
    // budgetedRevenue: number;
    overheads: [
        {
            id: "test",
            memberId: "tester",
            fiscalYear: 2022,
            accountNumber: "808",
            overheadName: "Tester123",
            big5: "Vehicle",
            currentYTD: 40526,
            projectedTotal: 44210,
            expensePercent: 0.005,
            nextYearIncPercent: null,
            nextYearIncAmount: null,
            nextYearTotal: 44210,
            plannedPercent: 0,
            budgetMethod: "Flat",
            overheadOrder: 1,
        },
    ],
};
exports.currentYearWorkshopTest = currentYearWorkshopTest;
const workshopOverheadNULLTestObj = {
    id: "test",
    memberId: "tester",
    fiscalYear: null,
    accountNumber: null,
    overheadName: "Tester123",
    big5: "Vehicle",
    currentYTD: null,
    projectedTotal: null,
    expensePercent: null,
    nextYearIncPercent: null,
    nextYearIncAmount: null,
    nextYearTotal: null,
    plannedPercent: null,
    budgetMethod: "Flat",
    overheadOrder: 1,
};
exports.workshopOverheadNULLTestObj = workshopOverheadNULLTestObj;
const currentYearNullTestObj = {
    id: "test",
    memberId: "tester",
    fiscalYear: null,
    monthsInCurrentYear: null,
    currentYearRevenue: null,
    currentYearProjectedRev: null,
    // budgetedRevenue: number;
    overheads: [
        {
            id: "test",
            memberId: "tester",
            fiscalYear: null,
            accountNumber: null,
            overheadName: "Tester123",
            big5: "Vehicle",
            currentYTD: null,
            projectedTotal: null,
            expensePercent: null,
            nextYearIncPercent: null,
            nextYearIncAmount: null,
            nextYearTotal: null,
            plannedPercent: null,
            budgetMethod: "Flat",
            overheadOrder: 1,
        },
    ],
};
exports.currentYearNullTestObj = currentYearNullTestObj;
const companyDataTestObj = {
    memberId: "MEMBER#372",
    id: "MANAGE#COMPANY#2022#01FRGBQNGF0C2HMV1GXRY4WWXB",
    fiscalYearEnd: "December",
    fiscalYear: 2022,
    nonWorkingDays: [
        {
            label: "Juneteenth",
            date: "2021-06-19T00:00:00.000-05:00",
        },
        {
            label: "Independence Day",
            date: "2021-07-04T00:00:00.000-05:00",
        },
        {
            label: "Labor Day",
            date: "2021-09-06T00:00:00.000-05:00",
        },
        {
            label: "Columbus Day",
            date: "2021-10-11T00:00:00.000-05:00",
        },
        {
            label: "Veterans Day",
            date: "2021-11-11T00:00:00.000-06:00",
        },
        {
            label: "Thanksgiving Day",
            date: "2021-11-25T00:00:00.000-06:00",
        },
        {
            label: "New Year's Day",
            date: "2022-01-01",
        },
        {
            label: "Martin Luther King Jr. Day",
            date: "2022-01-17",
        },
        {
            label: "Washington's Birthday",
            date: "2022-02-21",
        },
        {
            label: "new non working day",
            date: "2022-03-30",
        },
        {
            label: "My birthday",
            date: "2022-10-03T00:00:00.000-05:00",
        },
        {
            label: "Christmas Day",
            date: "2022-12-25T00:00:00.000-06:00",
        },
    ],
    weeklyWorkdays: {
        mon: false,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true,
        sun: false,
    },
    createdBy: "me",
    updatedBy: "me",
};
exports.companyDataTestObj = companyDataTestObj;
