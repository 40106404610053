"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSeasonalityPercentage = exports.getSumOfAllNumericObjectValues = exports.processDBItemForFE = exports.convertDecemberToDynamo = exports.convertDecemberForFE = void 0;
const utils_1 = require("../../utils");
function convertDecemberForFE(obj) {
    if ("december" in obj) {
        obj.dec = obj.december;
        delete obj.december;
    }
    return obj;
}
exports.convertDecemberForFE = convertDecemberForFE;
function convertDecemberToDynamo(obj) {
    if ("dec" in obj) {
        obj.december = obj.dec;
        delete obj.dec;
    }
    return obj;
}
exports.convertDecemberToDynamo = convertDecemberToDynamo;
function processDBItemForFE(dynamoMCData) {
    const dataForFE = { ...dynamoMCData };
    dataForFE.memberId = dynamoMCData.PK;
    dataForFE.id = dynamoMCData.SK;
    delete dataForFE.PK;
    delete dataForFE.SK;
    delete dataForFE.LSI1SK;
    delete dataForFE.LSI2SK;
    return convertDecemberForFE(dataForFE);
}
exports.processDBItemForFE = processDBItemForFE;
function addObjValues(objectToProcess, objectToReturn) {
    if (objectToProcess === null || objectToProcess === undefined)
        return null;
    Object.entries(objectToProcess).forEach(([key, value]) => {
        if (typeof value === "number") {
            objectToReturn = {
                ...objectToReturn,
                [key]: !objectToReturn[key] ? value : objectToReturn[key] + value,
            };
        }
        if (value === null) {
            // TP-1120 JAH: Need to not overwrite the existing when null.
            if (!(key in objectToReturn) || !objectToReturn[key]) {
                objectToReturn = { ...objectToReturn, [key]: null };
            }
        }
        else if (typeof value === "object") {
            if (!(key in objectToReturn)) {
                objectToReturn = { ...objectToReturn, [key]: {} };
            }
            objectToReturn = {
                ...objectToReturn,
                [key]: addObjValues(value, objectToReturn[key]),
            };
        }
    });
    return objectToReturn;
}
/**
 * WARNING!!!!!!!!!!!!!!!
 *
 * You need to be careful with this, you need to make sure to only grab the data you want and know is summed
 *
 * @param items
 */
function getSumOfAllNumericObjectValues(items) {
    if ((items === null || items === void 0 ? void 0 : items.length) === 0)
        return null;
    let returnObject = {};
    items === null || items === void 0 ? void 0 : items.forEach((item) => {
        returnObject = { ...addObjValues(item, returnObject) };
    });
    return returnObject;
}
exports.getSumOfAllNumericObjectValues = getSumOfAllNumericObjectValues;
//Function to take a call type and return correct seasonality percentage
function getSeasonalityPercentage(dataObj, callType) {
    let callName;
    switch (callType) {
        case "installJobs":
            callName = "installCallCountSeasonalityPercentage";
            break;
        case "demandCalls":
            callName = "demandCallCountSeasonalityPercentage";
            break;
        case "systemCheckCalls":
            callName = "systemCheckCallCountSeasonalityPercentage";
    }
    let value = (0, utils_1.objectFieldValidator)(dataObj, [`${callName}` + "Override"], true);
    if (!(0, utils_1.empty)(value))
        return value;
    value = (0, utils_1.objectFieldValidator)(dataObj, [`${callName}`], true);
    if (!(0, utils_1.empty)(value))
        return value;
    return 0;
}
exports.getSeasonalityPercentage = getSeasonalityPercentage;
