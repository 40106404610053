import TagManager from "react-gtm-module";

const InitializeGoogleAnalytics = () => {
  // Initialize GA4 - Add your measurement ID

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
  };

  TagManager.initialize(tagManagerArgs);
};

export { InitializeGoogleAnalytics };
