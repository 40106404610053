"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.yearlyMaxCapacityDataInit = exports.maxCapacityDataInit = void 0;
exports.maxCapacityDataInit = {
    budgetYearPercOfSystemCheckOpportunity: null,
    budgetYearPercOfDemandCallsOpportunity: null,
    // not using opportunity
    budgetYearPercOfSystemCheckTurnover: null,
    budgetYearPercOfDemandCallsTurnover: null,
    numOfSystemTurnovers: null,
    numOfDemandTurnovers: null,
    monthlyTurnovers: null,
    budgetYearPercOfDemandSystemCheckCallsOpportunity: null,
    budgetYearPercOfDemandSystemCheckCallsTurnover: null,
    numOfDemandSystemCheckTurnovers: null,
};
exports.yearlyMaxCapacityDataInit = {
    jan: exports.maxCapacityDataInit,
    feb: exports.maxCapacityDataInit,
    mar: exports.maxCapacityDataInit,
    apr: exports.maxCapacityDataInit,
    may: exports.maxCapacityDataInit,
    jun: exports.maxCapacityDataInit,
    jul: exports.maxCapacityDataInit,
    aug: exports.maxCapacityDataInit,
    sep: exports.maxCapacityDataInit,
    oct: exports.maxCapacityDataInit,
    nov: exports.maxCapacityDataInit,
    dec: exports.maxCapacityDataInit,
    maxCapacityTotals: {
        totalSystemTurnovers: null,
        totalDemandTurnovers: null,
        totalTurnovers: null,
        totalDemandSystemCheckTurnovers: null,
    },
    useOpportunity: false,
};
const yearlyObj = {
    jan: exports.maxCapacityDataInit,
    feb: exports.maxCapacityDataInit,
    mar: exports.maxCapacityDataInit,
    apr: exports.maxCapacityDataInit,
    may: exports.maxCapacityDataInit,
    jun: exports.maxCapacityDataInit,
    jul: exports.maxCapacityDataInit,
    aug: exports.maxCapacityDataInit,
    sep: exports.maxCapacityDataInit,
    oct: exports.maxCapacityDataInit,
    nov: exports.maxCapacityDataInit,
    dec: exports.maxCapacityDataInit,
};
