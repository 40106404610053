import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function CompanyManagementIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m1050 712.5h-75v-112.5c0-20.699-16.801-37.5-37.5-37.5h-300v-75h75c20.699 0 37.5-16.801 37.5-37.5v-225c0-20.699-16.801-37.5-37.5-37.5h-225c-20.699 0-37.5 16.801-37.5 37.5v225c0 20.699 16.801 37.5 37.5 37.5h75v75h-300c-20.699 0-37.5 16.801-37.5 37.5v112.5h-75c-20.699 0-37.5 16.801-37.5 37.5v225c0 20.699 16.801 37.5 37.5 37.5h225c20.699 0 37.5-16.801 37.5-37.5v-225c0-20.699-16.801-37.5-37.5-37.5h-75v-75h262.5v75h-75c-20.699 0-37.5 16.801-37.5 37.5v225c0 20.699 16.801 37.5 37.5 37.5h225c20.699 0 37.5-16.801 37.5-37.5v-225c0-20.699-16.801-37.5-37.5-37.5h-75v-75h262.5v75h-75c-20.699 0-37.5 16.801-37.5 37.5v225c0 20.699 16.801 37.5 37.5 37.5h225c20.699 0 37.5-16.801 37.5-37.5v-225c0-20.699-16.801-37.5-37.5-37.5z" />
    </svg>
  );
}
