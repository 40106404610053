"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newServiceAgreementObject = void 0;
exports.newServiceAgreementObject = {
    SK: "MAIN#2023#AGREEMENTS#WORKSHOP#LATEST",
    PK: "MEMBER#6982663160",
    LSI1SK: "WORKSHOP#AGREEMENTS#2023#LATEST",
    LSI2SK: "WORKSHOP#2023#AGREEMENTS#LATEST",
    fiscalYear: 2023,
    areAgreementsFromOtherDeptsUsed: null,
    areAgreementsRecognizedWhenVisitsRan: false,
    annualPriceOfAgreement: 180,
    multiTradeAgreements: {
        monthly: {
            numOfAgreementsStartOfYear: null,
            projectedNumOfNewAgreements: null,
            projectedNumOfAgreementsEndOfYear: null,
            retentionRateGoalNextYear: null,
            goalForNewAgreementsToAdd: null,
        },
        annual: {
            numOfAgreementsStartOfYear: null,
            projectedNumOfNewAgreements: null,
            projectedNumOfAgreementsEndOfYear: null,
            retentionRateGoalNextYear: null,
            goalForNewAgreementsToAdd: null,
        },
    },
};
