"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objToSaveFromFE = exports.directCostsExpectedOutput = void 0;
const utils_1 = require("../../../utils");
exports.directCostsExpectedOutput = {
    jan: {
        budgetedLaborAmount: 37915.46051992045,
        budgetedLaborPerInput: 45,
        budgetedMaterialAmount: 13481.05262930505,
        budgetedMaterialPerInput: 16,
        budgetedOtherDirectAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedSalesExpPercInput: 0,
        demandAndSystemCheckSales: 67590.17511231393,
        financeFeeAmount: 135.18035022462786,
        grossIncome: null,
        grossProfit: 32860.06578393106,
        refundAmount: 473.13122578619743,
        soldDayPerMonthInstallGoal: null,
        soldHourPerMonthGoal: 0,
        sppRevenue: 17274.715396853455,
        subcontractorAmount: 0,
        totalIncome: 84256.57893315656,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalDirectCost: null,
        reductions: null,
    },
    feb: {
        budgetedLaborAmount: 36633.24381792242,
        budgetedLaborPerInput: 40,
        budgetedMaterialAmount: 14653.297527168967,
        budgetedMaterialPerInput: 16,
        budgetedOtherDirectAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedSalesExpPercInput: 0,
        demandAndSystemCheckSales: 73901.60653856563,
        financeFeeAmount: 147.80321307713126,
        grossIncome: null,
        grossProfit: 40296.56819971465,
        refundAmount: 517.3112457699593,
        soldDayPerMonthInstallGoal: null,
        soldHourPerMonthGoal: 0,
        sppRevenue: 18346.617465087504,
        subcontractorAmount: 0,
        totalIncome: 91583.10954480604,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalDirectCost: null,
        reductions: null,
    },
    mar: {
        demandAndSystemCheckSales: 61782.78828903925,
        sppRevenue: 19305.495845854693,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 432.4795180232747,
        financeFeeAmount: 123.5655765780785,
        budgetedLaborPerInput: 45,
        budgetedLaborAmount: 36239.50756813166,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 12885.158246446814,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 31407.573225714106,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        totalIncome: 80532.23904029258,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalDirectCost: null,
        reductions: null,
    },
    apr: {
        demandAndSystemCheckSales: 44242.081765899864,
        sppRevenue: 20289.89570443698,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 309.69457236129904,
        financeFeeAmount: 88.48416353179972,
        budgetedLaborPerInput: 40,
        budgetedLaborAmount: 25653.5194937775,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 10261.407797511,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 28218.87144315525,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        totalIncome: 64133.79873444375,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalDirectCost: null,
        reductions: null,
    },
    may: null,
    jun: null,
    jul: null,
    aug: null,
    sep: null,
    oct: null,
    nov: null,
    dec: null,
};
const directCostsTester = {
    jan: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: null,
        financeFeeAmount: null,
        budgetedLaborAmount: null,
        budgetedMaterialAmount: null,
        budgetedOtherDirectAmount: 0,
        budgetedSalesExpAmount: 0,
        grossProfit: null,
        soldHourPerMonthGoal: null,
        soldDayPerMonthInstallGoal: null,
        budgetedLaborPerInput: 45,
        budgetedMaterialPerInput: 16,
        budgetedSalesExpPercInput: 0,
        budgetedOtherDirectPercInput: 0,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    feb: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: null,
        financeFeeAmount: null,
        budgetedLaborAmount: null,
        budgetedMaterialAmount: null,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: null,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        budgetedLaborPerInput: 40,
        budgetedMaterialPerInput: 16,
        budgetedSalesExpPercInput: 0,
        budgetedOtherDirectPercInput: 0,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    mar: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: null,
        financeFeeAmount: null,
        budgetedLaborPerInput: 45,
        budgetedLaborAmount: null,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: null,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: null,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    apr: {
        demandAndSystemCheckSales: null,
        sppRevenue: null,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: null,
        financeFeeAmount: null,
        budgetedLaborPerInput: 40,
        budgetedLaborAmount: null,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: null,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: null,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    may: {
        demandAndSystemCheckSales: 95119.84597428958,
        sppRevenue: 22500.480935875217,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 665.8389218200269,
        financeFeeAmount: 190.23969194857915,
        budgetedLaborPerInput: 45,
        budgetedLaborAmount: 52543.911733378285,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 18682.27972742339,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 45538.056835594514,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    jun: {
        demandAndSystemCheckSales: 128409.13179296348,
        sppRevenue: 24766.7287694974,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 898.8639225507443,
        financeFeeAmount: 256.81826358592696,
        budgetedLaborPerInput: 40,
        budgetedLaborAmount: 60808.07135052969,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 24323.228540211876,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 66888.87848558265,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    jul: {
        demandAndSystemCheckSales: 89752.45468531144,
        sppRevenue: 27272.469670710572,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 628.26718279718,
        financeFeeAmount: 179.5049093706229,
        budgetedLaborPerInput: 40,
        budgetedLaborAmount: 46486.86090554169,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 18594.744362216676,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 51135.54699609586,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    aug: {
        demandAndSystemCheckSales: 100675.41661577448,
        sppRevenue: 29238.4575389948,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 704.7279163104213,
        financeFeeAmount: 201.35083323154896,
        budgetedLaborPerInput: 40,
        budgetedLaborAmount: 51603.11816209093,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 20641.24726483637,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 56763.42997830002,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    sep: {
        demandAndSystemCheckSales: 65091.656172070085,
        sppRevenue: 31079.337088388216,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 455.64159320449056,
        financeFeeAmount: 130.18331234414018,
        budgetedLaborPerInput: 40,
        budgetedLaborAmount: 38234.06734196387,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 15293.626936785546,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 42057.474076160244,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    oct: {
        demandAndSystemCheckSales: 49117.78810010901,
        sppRevenue: 33016.7287694974,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 343.82451670076307,
        financeFeeAmount: 98.23557620021803,
        budgetedLaborPerInput: 50,
        budgetedLaborAmount: 40846.22838835272,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 13070.793084272871,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 27775.435304079845,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    nov: {
        demandAndSystemCheckSales: 88873.43912135475,
        sppRevenue: 35122.123050259965,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 622.1140738494831,
        financeFeeAmount: 177.7468782427095,
        budgetedLaborPerInput: 45,
        budgetedLaborAmount: 55438.06554878514,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 19711.312195123606,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 48046.32347561378,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    dec: {
        demandAndSystemCheckSales: 82524.79231107076,
        sppRevenue: 36795,
        grossIncome: null,
        subcontractorAmount: 0,
        refundAmount: 577.6735461774953,
        financeFeeAmount: 165.04958462214154,
        budgetedLaborPerInput: 45,
        budgetedLaborAmount: 53359.68113112201,
        budgetedMaterialPerInput: 16,
        budgetedMaterialAmount: 18972.33106884338,
        budgetedSalesExpPercInput: 0,
        budgetedSalesExpAmount: 0,
        budgetedOtherDirectPercInput: 0,
        budgetedOtherDirectAmount: 0,
        grossProfit: 46245.05698030573,
        soldHourPerMonthGoal: 0,
        soldDayPerMonthInstallGoal: null,
        demandCallSales: null,
        demandSystemCheckCallSales: null,
        systemCheckCallSales: null,
        totalIncome: null,
        totalDirectCost: null,
        reductions: null,
    },
    directCostsYearly: {
        subcontractorPercInput: 0,
        refundPercInput: 0.7,
        financeFeePercInput: 0.2,
        soldBreakEvenFromPPInput: 0,
        profitPercOnMaterialsInput: 0,
        soldProfitAndCommissionInput: null,
        desiredProfitPlusCommissionInput: null,
        pricerId: null,
        pricerDepartmentId: null,
    },
};
exports.objToSaveFromFE = {
    maxCapacity: null,
    serviceAgreementsMain: {
        areAgreementsFromOtherDeptsUsed: null,
        areAgreementsRecognizedWhenVisitsRan: null,
        visitsAtTimeOfSale: null,
    },
    serviceAgreements: {
        jan: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 14979.016964025795,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 9610.100135317998,
            totalRevenueMonthlyRecognition: 17274.715396853455,
        },
        feb: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 10145.779640250754,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 4936.335588633288,
            totalRevenueMonthlyRecognition: 18346.617465087504,
        },
        mar: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 20414.4114474433,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 15754.26251691475,
            totalRevenueMonthlyRecognition: 19305.495845854693,
        },
        apr: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 32721.74217603874,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 27937.558863328824,
            totalRevenueMonthlyRecognition: 20289.89570443698,
        },
        may: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 26107.76873228541,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 16752.03247631935,
            totalRevenueMonthlyRecognition: 22214.944316775676,
        },
        jun: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 25622.864284923202,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 14388.893098782137,
            totalRevenueMonthlyRecognition: 24526.461021742973,
        },
        jul: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 26945.754558274297,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 14524.597142682991,
            totalRevenueMonthlyRecognition: 27082.254728654763,
        },
        aug: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 22173.02139584314,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 12427.462795878495,
            totalRevenueMonthlyRecognition: 29087.513699840903,
        },
        sep: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 38329.92425937227,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 29204.53757031446,
            totalRevenueMonthlyRecognition: 30965.165281951562,
        },
        oct: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 48672.64118487181,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 39068.83616454302,
            totalRevenueMonthlyRecognition: 32941.25684992045,
        },
        nov: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 39951.83144090083,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 29515.224140211423,
            totalRevenueMonthlyRecognition: 35088.706911790694,
        },
        dec: {
            totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: 38351.5100462283,
            totalRevenueTimeOfVisit_Visit6MonthFromSale: 30058.92563753111,
            totalRevenueMonthlyRecognition: 36795,
        },
    },
    directCosts: directCostsTester,
    callCount: {
        jan: {
            monthlyTotalRevenueCalc: 67590.17511231393,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        feb: {
            monthlyTotalRevenueCalc: 73901.60653856563,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        mar: {
            monthlyTotalRevenueCalc: 61782.78828903925,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        apr: {
            monthlyTotalRevenueCalc: 44242.081765899864,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        may: {
            monthlyTotalRevenueCalc: 95119.84597428958,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        jun: {
            monthlyTotalRevenueCalc: 128409.13179296348,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        jul: {
            monthlyTotalRevenueCalc: 90110.28846799319,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        aug: {
            monthlyTotalRevenueCalc: 100981.58455817596,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        sep: {
            monthlyTotalRevenueCalc: 65811.15083671358,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        oct: {
            monthlyTotalRevenueCalc: 50119.994928576954,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        nov: {
            monthlyTotalRevenueCalc: 89630.57350469037,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        dec: {
            monthlyTotalRevenueCalc: 83235.64641136039,
            targetNumOfCallsRanPerTech: null,
            percentOfDemandCallsThatAreDemandSystemCheck: null,
            budgetedNumOfTechs: null,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        id: "",
        memberId: "",
        fiscalYear: 0,
        departmentId: "",
        departmentName: "",
        departmentType: utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT,
        departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
        callCountTotals: {
            totalSumPriorYearAgreementSystemChecks: 0,
            totalSumBudgetedSystemCheckCallCount: 0,
            totalSumPriorYearDemandCallCount: 0,
            totalSumPriorYearDemandSystemCheckCallCount: 0,
            totalSumPriorYearTechGeneratedLeads: 0,
            totalSumBudgetedTechGeneratedLeads: 0,
            totalSumPriorYearMarketedLeadCount: 0,
            totalSumPriorYearOtherLeadCount: 0,
            totalSumRequiredDemandCallCount: 0,
            totalSumRequiredDemandSystemCheckCallCount: 0,
            totalSumMarketedLeadCount: 0,
            totalConvertedTechLeadCount: 0,
            totalSumOtherLeadCount: 0,
            totalTechLeadRevenue: 0,
            totalConvertedMarketedLeadCount: 0,
            totalMarketedLeadRevenue: 0,
            totalConvertedOtherLeadCount: 0,
            totalOtherLeadRevenue: 0,
            totalDemandConvertedCalls: 0,
            totalDemandSystemCheckConvertedCalls: 0,
            totalDemandRevenue: 0,
            totalDemandSystemCheckRevenue: 0,
            totalSystemCheckConvertedCalls: 0,
            totalSystemCheckRevenue: 0,
        },
    },
};
