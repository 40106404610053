import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import {
  CompanyIdOrDefault,
  CompanyNameOrDefault,
  CRMUser,
  UserIdOrDefault,
  UserNameOrDefault,
} from "../../utils/helpers/UserHelper";
import { LoadingOverlay } from "../../utils/components/tailwind/LoadingOverlay";

type Auth0WrapperProps = {
  children: any;
};
function Auth0Wrapper({ children }: Auth0WrapperProps): JSX.Element {
  const { isAuthenticated, isLoading, error, user, loginWithRedirect } =
    useAuth0();

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV !== "development") {
      (async function login() {
        if (!isLoading && !user) {
          await loginWithRedirect({
            appState: {
              returnTo: `${window.location.pathname}`,
            },
          });
        } else if (!isLoading && user) {
          Sentry.setUser({
            email: user.email,
            id: UserIdOrDefault(user as CRMUser).toString(),
            username: `${UserNameOrDefault(
              user as CRMUser,
            )} - ${CompanyNameOrDefault(
              user as CRMUser,
            )} - ${CompanyIdOrDefault(user as CRMUser)}`,
          });
        }
      })();
    }
  }, [isLoading]);
  if (process.env.REACT_APP_NODE_ENV !== "development") {
    if (isLoading) {
      return (
        <LoadingOverlay
          loading
          text={
            process.env.REACT_APP_NODE_ENV !== "development"
              ? "Loading Nexstar Tools"
              : "Loading Auth0Wrapper"
          }
        />
      );
    }
    if (error) {
      return (
        <div>
          Oops...
          {error.message}
        </div>
      );
    }

    if (isAuthenticated) return <div>{children}</div>;
    return null;
  }
  return <div>{children}</div>;
}

export default Auth0Wrapper;
