import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import CompanySelector from "./CompanySelector";
import {
  userCanAccessFinancials,
  CRMUser,
  isNexstarEmployee,
  noUserInProd,
  primaryCompany,
} from "../../utils/helpers/UserHelper";
import { useUserContext } from "../context/user/UserContext";
import AuthenticationButton from "../auth/Auth0Authentication";
import DesktopNav from "./components/DesktopNav";
import UserDropdownMenu from "./UserDropdownMenu";
import UserEmulationWarning from "../toolsadmin/UserEmulationWarning";
import AlertsDropdownMenu from "../alerts/components/AlertsDropdown";
import SideNav from "./components/SideNav";
import ApplicationsIcon from "../../utils/components/icons/ApplicationsIcon";
import { shouldDisplayTool } from "../home/utils/homePageHelpers";
import { useCompanyContext } from "../context/company/CompanyContext";
import { useNavigate } from "react-router-dom";
import { useDirtyContext } from "../context/dirty/DirtyContext";
import NexstarLogo from "../../utils/components/icons/nexstar/NexstarLogo";

export default function AppHeader() {
  const { user } = useAuth0();
  const {
    shouldDisplay,
    emulatedUser,
    selectedCompany,
    getUser,
    userToolsAccess,
    companyOptions,
  } = useUserContext();
  const isCoach = isNexstarEmployee(getUser());
  const userCompany = primaryCompany(user as CRMUser);
  const { workshopEvents } = useCompanyContext();
  const { handleOpenDirty } = useDirtyContext();
  const toolsToDisplay = shouldDisplayTool(
    userToolsAccess,
    companyOptions,
    userCanAccessFinancials(getUser()),
    getUser(),
    workshopEvents.bpw,
  );
  const [openSideNav, setOpenSideNav] = useState(false);
  const navigate = useNavigate();
  if (noUserInProd(user as CRMUser)) {
    return <AuthenticationButton />;
  }

  return (
    <>
      <div>
        <UserEmulationWarning
          emulatedUser={emulatedUser}
          isCoach={isCoach}
          selectedCompany={selectedCompany}
          primaryCompany={userCompany}
        />
      </div>
      <Popover id="NexstarHeader" className="relative bg-NexstarBlue-dark">
        <div className="pr-6">
          <div className="flex items-center justify-between py-1.5 md:space-x-2">
            <div className="flex items-center w-full justify-start">
              <button
                id="sideNavButton"
                className="group ml-2 mr-1 mt-[5px] p-3"
                onClick={() => setOpenSideNav(!openSideNav)}
              >
                <ApplicationsIcon className="group-hover:fill-NexstarOrange transition" />
              </button>
              <div className="flex justify-start ml-1 mr-4">
                {/*<a href="/" id="logo" data-testid="logo">*/}
                <button
                  id="logo"
                  onClick={() => handleOpenDirty(() => navigate("/"))}
                >
                  <NexstarLogo />
                </button>
                {/*</a>*/}
              </div>
              <DesktopNav
                toolsToDisplay={Object.keys(toolsToDisplay)}
                toolsAccess={userToolsAccess}
              />
            </div>
            <div className="flex items-center justify-end">
              {/*// TP-690 JAH: Updating the messaging for the new CRM connections*/}
              {shouldDisplay ? <CompanySelector /> : null}
              <div className="mt-5">
                <AlertsDropdownMenu />
              </div>
              <UserDropdownMenu user={user as CRMUser} />
            </div>
          </div>
        </div>
      </Popover>
      <SideNav
        open={openSideNav}
        setOpen={setOpenSideNav}
        isCoach={isCoach}
        toolsToDisplay={toolsToDisplay}
      />
    </>
  );
}
