import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import CloseIcon from "../icons/CloseIcon";

type NexstarModalProps = {
  children: React.ReactNode;
  dataTestId?: string;
  open: boolean;
  onClose: () => void;
  maxWidth?: string;
  id: string;
  warningText?: string;
  disableClose?: boolean;
};

export default function NexstarModal({
  children,
  dataTestId,
  open,
  onClose,
  maxWidth = "max-w-7xl",
  id,
  disableClose = false,
}: NexstarModalProps) {
  const onClosure = () => {
    return onClose();
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" id={id} className="relative z-[1300]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto" id="root-modal">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              data-testid={dataTestId ? dataTestId : id}
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-tr-xl rounded-bl-xl bg-white text-left shadow-xl transition-all sm:my-8 w-[calc(100%-2rem)] ${maxWidth}`}
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  {disableClose ? null : (
                    <button
                      type="button"
                      className="rounded-full bg-white text-gray-400 hover:text-gray-500 p-0.5 focus:outline-none focus:ring-2 focus:ring-NexstarOrange focus:ring-offset-2"
                      onClick={onClosure}
                      id="close_modal"
                    >
                      <span className="sr-only">Close</span>
                      <CloseIcon color="gray" aria-hidden="true" />
                    </button>
                  )}
                </div>
                <div className="block">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
