import React from "react";

type LoadingCardProps = {
  headerDisplay?: boolean;
};

export default function LoadingCard({
  headerDisplay = true,
}: LoadingCardProps): JSX.Element {
  return (
    <div
      aria-live="polite"
      aria-busy="true"
      className={`w-full overflow-hidden ${
        headerDisplay
          ? "shadow-md rounded-tr-xl rounded-bl-xl border border-gray-200 mt-2"
          : null
      }`}
    >
      <div
        className={`${
          headerDisplay ? "flex" : "hidden"
        } justify-between bg-NexstarBlue p-6`}
      >
        <div className="h-6 w-6/12 bg-gray-300 rounded-full animate-pulse" />
        <div className="h-6 w-2/12 bg-gray-300 rounded-full animate-pulse" />
      </div>
      <div className="w-full grid grid-cols-2 gap-6 px-6 py-10">
        <div>
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full animate-pulse" />
        </div>
        <div>
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
          <div className="h-4 w-6/12 bg-gray-300 rounded-full animate-pulse" />
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
}
