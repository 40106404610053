"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setMonthToMonthValues = exports.calcAnnualPriceOfSPP = exports.calcRevenueRecognizedPerSystemCheckVisit = exports.calcNewAgreementsSoldByTrade = exports.calcMultiTradeSoldByTrade = exports.calcPriorYearSalesDistributionByDept = exports.calcGrandTotalAgreementsNextYear = exports.calcTotalAgreementsNextYear = exports.calcPotentialNewAgreements = exports.calcNextYearRetentionRate = exports.calcCurrentNetGrowthRate = exports.calcCurrentSalesGrowthRate = exports.calcCurrentRetentionRate = exports.calcProjectedNumOfAgreementsLoss = void 0;
const bpwCalculationHelpers_1 = require("../bpwCalculationHelpers");
const backendServiceAgreementCalculations_1 = require("./backendServiceAgreementCalculations");
const utils_1 = require("../../../../utils");
/**
 * Projected Number of Agreements You Will LOSE this Year
 *
 * I5
 * =G5-C5-E5
 *
 * FRONTEND CALC
 * @param obj pass in monthToMonth or annualPay obj from currentServiceAgreements
 * @param agreementTimeframe
 */
function calcProjectedNumOfAgreementsLoss(obj, agreementTimeframe) {
    if (!obj || !agreementTimeframe)
        return 0;
    const { numOfAgreementsStartOfYear, projectedNumOfNewAgreements, projectedNumOfAgreementsEndOfYear, } = obj[agreementTimeframe.toLowerCase()] || {};
    if (numOfAgreementsStartOfYear ||
        projectedNumOfNewAgreements ||
        projectedNumOfAgreementsEndOfYear) {
        return ((projectedNumOfAgreementsEndOfYear || 0) -
            (numOfAgreementsStartOfYear || 0) -
            (projectedNumOfNewAgreements || 0));
    }
    return 0;
}
exports.calcProjectedNumOfAgreementsLoss = calcProjectedNumOfAgreementsLoss;
//pass in monthToMonth or annualPay obj from currentServiceAgreements
/**
 * C28
 * FRONTEND CALC
 * @param obj
 * @param agreementTimeframe
 */
function calcCurrentRetentionRate(obj, agreementTimeframe) {
    if (!obj || !agreementTimeframe)
        return 0;
    // this means that we need to calc the multi trade
    const { numOfAgreementsStartOfYear } = obj[agreementTimeframe.toLowerCase()] || {};
    const projectedNumOfAgreementsWillLoseThisYear = calcProjectedNumOfAgreementsLoss(obj, agreementTimeframe);
    if (!numOfAgreementsStartOfYear)
        return 0;
    if (projectedNumOfAgreementsWillLoseThisYear &&
        numOfAgreementsStartOfYear > 0) {
        return ((numOfAgreementsStartOfYear + projectedNumOfAgreementsWillLoseThisYear) /
            numOfAgreementsStartOfYear);
    }
    return 0;
}
exports.calcCurrentRetentionRate = calcCurrentRetentionRate;
//pass in monthToMonth or annualPay obj from currentServiceAgreements
/**
 * F:G28
 *
 * FRONTEND CALC
 * @param obj
 * @param agreementTimeframe
 */
function calcCurrentSalesGrowthRate(obj, agreementTimeframe) {
    if (!obj || !agreementTimeframe)
        return 0;
    const { numOfAgreementsStartOfYear, projectedNumOfNewAgreements } = obj[agreementTimeframe.toLowerCase()] || {};
    if (!numOfAgreementsStartOfYear || !projectedNumOfNewAgreements)
        return 0;
    if (numOfAgreementsStartOfYear > 0) {
        return projectedNumOfNewAgreements / numOfAgreementsStartOfYear;
    }
    return 0;
}
exports.calcCurrentSalesGrowthRate = calcCurrentSalesGrowthRate;
//pass in monthToMonth or annualPay obj from currentServiceAgreements
/**
 * I:J28
 *
 * FRONTEND CALC
 * @param obj
 * @param agreementTimeframe
 */
function calcCurrentNetGrowthRate(obj, agreementTimeframe) {
    if (!obj || !agreementTimeframe)
        return 0;
    const { numOfAgreementsStartOfYear, projectedNumOfAgreementsEndOfYear } = obj[agreementTimeframe.toLowerCase()] || {};
    if (!numOfAgreementsStartOfYear || !projectedNumOfAgreementsEndOfYear)
        return 0;
    if (numOfAgreementsStartOfYear > 0) {
        return ((projectedNumOfAgreementsEndOfYear - numOfAgreementsStartOfYear) /
            numOfAgreementsStartOfYear);
    }
    return 0;
}
exports.calcCurrentNetGrowthRate = calcCurrentNetGrowthRate;
//***End Current Service Agreements***\\
//***Service Agreements Added Next Year***\\
//pass in monthToMonth or annualPay obj from currentServiceAgreements
/**
 * C54
 *
 * FRONTEND CALC
 * @param obj
 * @param agreementTimeframe
 */
function calcNextYearRetentionRate(obj, agreementTimeframe) {
    if (!obj || !agreementTimeframe)
        return 0;
    // @ts-ignore
    const { retentionRateGoalNextYear, projectedNumOfAgreementsEndOfYear } = obj[agreementTimeframe.toLowerCase()] || {};
    if (!retentionRateGoalNextYear || !projectedNumOfAgreementsEndOfYear)
        return 0;
    if (projectedNumOfAgreementsEndOfYear > 0) {
        return (projectedNumOfAgreementsEndOfYear * (retentionRateGoalNextYear / 100));
    }
    return 0;
}
exports.calcNextYearRetentionRate = calcNextYearRetentionRate;
//pass in monthToMonth or annualPay obj from currentServiceAgreements
/**
 * E54
 *
 * FRONTEND CALC
 *
 * OLD calcNextYearGrowthRate
 * @param obj
 * @param agreementTimeframe
 */
function calcPotentialNewAgreements(obj, agreementTimeframe) {
    if (!obj || !agreementTimeframe)
        return 0;
    const { projectedNumOfAgreementsEndOfYear } = obj[agreementTimeframe.toLowerCase()] || {};
    const currentGrowthRate = calcCurrentSalesGrowthRate(obj, agreementTimeframe);
    if (!projectedNumOfAgreementsEndOfYear || !currentGrowthRate)
        return 0;
    if (projectedNumOfAgreementsEndOfYear > 0) {
        return projectedNumOfAgreementsEndOfYear * currentGrowthRate;
    }
    return 0;
}
exports.calcPotentialNewAgreements = calcPotentialNewAgreements;
//pass in the entire serviceAgreements obj and duration key
/**
 * I54
 *
 * FRONTEND CALC
 * @param obj
 * @param agreementTimeframe
 */
function calcTotalAgreementsNextYear(obj, agreementTimeframe) {
    if (!obj || !agreementTimeframe)
        return 0;
    // @ts-ignore
    const { goalForNewAgreementsToAdd } = obj[agreementTimeframe.toLowerCase()] || {};
    const retentionRate = calcNextYearRetentionRate(obj, agreementTimeframe);
    if (goalForNewAgreementsToAdd || goalForNewAgreementsToAdd === 0) {
        return goalForNewAgreementsToAdd + retentionRate;
    }
    return retentionRate;
}
exports.calcTotalAgreementsNextYear = calcTotalAgreementsNextYear;
//pass in the entire serviceAgreements obj
/**
 * I74
 *
 * FRONTEND CALC
 * @param obj
 */
function calcGrandTotalAgreementsNextYear(obj) {
    if (obj === null)
        return 0;
    let grandTotal = 0;
    obj.departmentServiceAgreements.forEach((dept) => {
        // TODO: figure out the correct way to pass in not the 'as any' that I am doing
        const tradeData = dept;
        grandTotal += calcTotalAgreementsNextYear(tradeData, "MONTHLY");
        grandTotal += calcTotalAgreementsNextYear(tradeData, "ANNUAL");
    });
    grandTotal += calcTotalAgreementsNextYear(obj.multiTradeAgreements, "MONTHLY");
    grandTotal += calcTotalAgreementsNextYear(obj.multiTradeAgreements, "ANNUAL");
    return grandTotal;
}
exports.calcGrandTotalAgreementsNextYear = calcGrandTotalAgreementsNextYear;
//***End Service Agreements Added Next Year***\\
//***New Agreement Projected Sales Distribution***\\
/**
 * C83
 *
 * FRONTEND CALC
 * OLD calcPriorYearSalesDistribution
 * @param preworkData
 * @param departmentId
 */
function calcPriorYearSalesDistributionByDept(preworkData, departmentId) {
    if (preworkData === null)
        return 0;
    //need data from hvac serv-seasonality
    let selectedTradeNumberOfAgreementsSoldPriorYear = 0;
    let totalNumberOfAgreementsSoldPriorYear = 0;
    preworkData === null || preworkData === void 0 ? void 0 : preworkData.forEach((pwd) => {
        if (pwd.departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
            if (pwd.departmentId === departmentId) {
                selectedTradeNumberOfAgreementsSoldPriorYear =
                    pwd.nonMonthlyData.serviceSystemCheck.agreementsSold;
            }
            totalNumberOfAgreementsSoldPriorYear +=
                pwd.nonMonthlyData.serviceSystemCheck.agreementsSold;
        }
    });
    if (!totalNumberOfAgreementsSoldPriorYear ||
        !selectedTradeNumberOfAgreementsSoldPriorYear)
        return 0;
    return ((selectedTradeNumberOfAgreementsSoldPriorYear || 0) /
        (totalNumberOfAgreementsSoldPriorYear || 0));
}
exports.calcPriorYearSalesDistributionByDept = calcPriorYearSalesDistributionByDept;
/**
 * G83
 *
 * @param mainObject
 * @param tradeObject
 */
function calcMultiTradeSoldByTrade(mainObject, tradeObject) {
    if (!mainObject || !tradeObject)
        return 0;
    let goalForNewAgreementsToAdd = 0;
    // this means that we need to calc the multi trade
    if (mainObject.multiTradeAgreements &&
        tradeObject.multiTradeSalesDistribution) {
        goalForNewAgreementsToAdd +=
            mainObject.multiTradeAgreements.monthly.goalForNewAgreementsToAdd;
        goalForNewAgreementsToAdd +=
            mainObject.multiTradeAgreements.annual.goalForNewAgreementsToAdd;
        return (goalForNewAgreementsToAdd *
            (tradeObject.multiTradeSalesDistribution / 100));
    }
    return 0;
}
exports.calcMultiTradeSoldByTrade = calcMultiTradeSoldByTrade;
/**
 * I83 Also I145
 *
 * FRONTEND CALC
 * @param obj
 * @param tradeObject
 */
function calcNewAgreementsSoldByTrade(obj, tradeObject) {
    if (!obj || !tradeObject)
        return 0;
    let goalForNewAgreementsToAdd = 0;
    // this means that we need to calc the multi trade
    goalForNewAgreementsToAdd += tradeObject.monthly.goalForNewAgreementsToAdd
        ? tradeObject.monthly.goalForNewAgreementsToAdd
        : 0;
    goalForNewAgreementsToAdd += tradeObject.annual.goalForNewAgreementsToAdd
        ? tradeObject.annual.goalForNewAgreementsToAdd
        : 0;
    return (goalForNewAgreementsToAdd + calcMultiTradeSoldByTrade(obj, tradeObject));
}
exports.calcNewAgreementsSoldByTrade = calcNewAgreementsSoldByTrade;
/**
 * C121
 *
 * @param obj
 * @param departmentId
 */
function calcRevenueRecognizedPerSystemCheckVisit(obj, departmentId) {
    if (!obj || !obj.departmentServiceAgreements)
        return 0;
    let totalNumberOfVisits = 0;
    let deptNumOfVisits = null;
    obj.departmentServiceAgreements.forEach((dept) => {
        const deptData = dept;
        // TP-756 MH: we want to return 0 if given departments numOfVisits is 0
        if (departmentId === deptData.departmentId && !deptData.numOfVisits)
            deptNumOfVisits = 0;
        totalNumberOfVisits += deptData.numOfVisits;
    });
    if (totalNumberOfVisits === 0)
        return 0;
    const { annualPriceOfAgreement } = obj || {};
    return deptNumOfVisits === 0
        ? 0
        : annualPriceOfAgreement / totalNumberOfVisits;
}
exports.calcRevenueRecognizedPerSystemCheckVisit = calcRevenueRecognizedPerSystemCheckVisit;
function calcAnnualPriceOfSPP(obj) {
    if (!obj || !obj.departmentServiceAgreements)
        return 0;
    let annualPrice = 0;
    obj.departmentServiceAgreements.forEach((dept) => {
        var _a, _b;
        const deptData = dept;
        annualPrice +=
            ((_a = deptData.numOfVisits) !== null && _a !== void 0 ? _a : 0) *
                ((_b = deptData.revenueRecognizedPerSystemCheckVisit) !== null && _b !== void 0 ? _b : 0);
    });
    return annualPrice;
}
exports.calcAnnualPriceOfSPP = calcAnnualPriceOfSPP;
// TODO: THIS NEEDS TO BE TESTED IN SOME WAY.
function setMonthToMonthValues(fullObj, tradeData, preWork, companyData) {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    Object.keys(tradeData).forEach((departmentId) => {
        const preWorkObj = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWork, departmentId);
        if (preWorkObj && departmentId in tradeData) {
            months.forEach((month) => {
                if (!(month in tradeData[departmentId])) {
                    //TP-1435 MH: replace tradeKey with departmentId
                    // @ts-ignore
                    tradeData[departmentId][month] = {};
                }
                // TODO: Look into this cause something seems off.
                tradeData[departmentId][month] = {
                    totalVisitsBasedOnTimeOfSaleCalculation: (0, backendServiceAgreementCalculations_1.calcTotalVisitsBasedOnTimeOfSale)(preWork, fullObj, companyData, preWorkObj.departmentTrade, departmentId, month),
                    totalVisitIf6MonthFromSale: (0, backendServiceAgreementCalculations_1.calcMonthlyTotalVisitsIfSixMonthsFromSale)(preWork, fullObj, companyData, departmentId, month),
                    totalRevenueMonthlyRecognition: (0, backendServiceAgreementCalculations_1.calcTotalRevenueMonthlyRecognition)(preWork, fullObj, companyData, departmentId, month),
                    totalRevenueTimeOfVisit_VisitPerformedTimeOfSale: (0, backendServiceAgreementCalculations_1.calcTotalRevenueTimeOfVisitPerformedTimeOfSale)(preWork, fullObj, companyData, preWorkObj.departmentTrade, departmentId, month),
                    totalRevenueTimeOfVisit_Visit6MonthFromSale: (0, backendServiceAgreementCalculations_1.calcTotalRevenueTimeOfVisitSixMonthsFromSale)(preWork, fullObj, companyData, departmentId, month),
                    retainedAgreementsVisits: (0, backendServiceAgreementCalculations_1.calcTotalRetainedAgreementsVisitsByMonth)(preWork, fullObj, departmentId, month),
                };
            });
        }
    });
    return tradeData;
}
exports.setMonthToMonthValues = setMonthToMonthValues;
