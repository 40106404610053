"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcTotalNewAndRetainedAgreementsForDepartment = exports.calcTotalPriorYearAgreements = exports.calcNumCallsRanOnNewAgreements = exports.calcTotalAgreementRevenueByTrade = exports.calcAllocatedRevenueForNewAgreements = exports.calcAllocatedRevenueForRetainedAgreements = exports.calcTotalRevenueTimeOfVisitSixMonthsFromSale = exports.calcTotalRevenueTimeOfVisitPerformedTimeOfSale = exports.calcTotalRevenueMonthlyRecognition = exports.calcMonthlyTotalVisitsIfSixMonthsFromSale = exports.calcSumRevenueWhenVisitRunSixMonthFromSale = exports.calcRevenueWhenVisitRunSixMonthsFromSale = exports.calcSumRevenueWhenVisitRunTimeOfSale = exports.calcMonthlyRevenueWhenVisitRunTimeOfSale = exports.calcSumRevenueMonthlyRecognition = exports.calcRevenueMonthlyRecognition = exports.calcNewMultiTradeAgreementDistributionForOtherTrades = exports.calcTotalRevenueTimeOfVisitRecognition = exports.calcRevenueTimeOfVisitRecognitionByMonth = exports.calcTotalRevenueRetainedRecognition = exports.calcRevenueRetainedMonthlyRecognition = exports.calcTotal6MonthsAndOther = exports.calcTotalTimeOfSaleAndOther = exports.calcTotalVisitsBasedOnTimeOfSale = exports.calcTotalRetainedAgreementsVisitsByMonth = exports.calcNumOfCallsRanOnRetainedAgreements = exports.calcTotalRetainedAgreements = exports.calcInspectionTimeOfSaleLast6Months = exports.calcInspection6MonthsFromSaleOtherTrade = exports.calcInspection6MonthsFromSale = exports.calcSumOfSystemCheckSeasonalityLast6Months = exports.calcSumOfNewAgreementDistributionForOtherTradesFirstSixMonths = exports.calcSumOfNewAgreementDistributionFirstSixMonths = exports.calcInspectionTimeOfSale = exports.calcNewMultiTradeAgreementDistributionForMonth = exports.calcNewAgreementDistributionForMonth = void 0;
const bpwCalculationHelpers_1 = require("../bpwCalculationHelpers");
const serviceAgreementCalcsFromFE_1 = require("./serviceAgreementCalcsFromFE");
const utils_1 = require("../../../../utils");
const helpers_1 = require("../../../utils/helpers");
/**
 * L145 (the whole row)
 * I145 * Demand Seasonality
 * I83
 * G83+G54+G56
 * Multi Trade Agreements Sold + Goal New Agreements Monthly + Goal New Agreements Annual
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param departmentId
 * @param month
 */
function calcNewAgreementDistributionForMonth(preWorkData, serviceAgreementObject, departmentId, month) {
    if (!preWorkData)
        return 0;
    //TODO: Optimize later depending on how run, Pass in the new agreements val so it isn't re calced all the time
    const tradeObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (!tradeObject)
        return 0;
    const newAgreementDistributionByDept = (0, serviceAgreementCalcsFromFE_1.calcNewAgreementsSoldByTrade)(serviceAgreementObject, tradeObject);
    const preWorkObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWorkData, departmentId);
    if (preWorkObject === null)
        return 0;
    if (!preWorkObject.monthlyData[month])
        return 0;
    //TODO fix with getter
    // const newAgreementDistributionByDept = calcNewAgreementDistributionByDept(fullObj, deptType);
    return ((newAgreementDistributionByDept || 0) *
        (0, helpers_1.getSeasonalityPercentage)(preWorkObject.monthlyData[month], "demandCalls"));
}
exports.calcNewAgreementDistributionForMonth = calcNewAgreementDistributionForMonth;
/**
 * L146
 * I146*L144
 * G83*L144
 * Multi Trade Agreements Sold
 */
function calcNewMultiTradeAgreementDistributionForMonth(preWorkData, serviceAgreementObject, departmentId, month) {
    if (!preWorkData)
        return 0;
    //TODO: Optimize later depending on how run, Pass in the new agreements val so it isn't re calced all the time
    const tradeObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (!tradeObject)
        return 0;
    const newAgreementDistributionByDept = (0, serviceAgreementCalcsFromFE_1.calcMultiTradeSoldByTrade)(serviceAgreementObject, tradeObject);
    const preWorkObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWorkData, departmentId);
    if (preWorkObject === null)
        return 0;
    // const newAgreementDistributionByDept = calcNewAgreementDistributionByDept(fullObj, deptType);
    return ((newAgreementDistributionByDept || 0) *
        (0, helpers_1.getSeasonalityPercentage)(preWorkObject.monthlyData[month], "demandCalls"));
}
exports.calcNewMultiTradeAgreementDistributionForMonth = calcNewMultiTradeAgreementDistributionForMonth;
/**
 * L147 (whole row, optionally)
 * L145 * 0.81
 *
 * Calc for Inspection Time Of Sale (Using fixed 81% Run Rate): (L91) => Backend Calculations on Excel Spreadsheet
 * @param preWorkData
 * @param serviceAgreementObject
 * @param departmentId
 * @param month
 */
function calcInspectionTimeOfSale(preWorkData, serviceAgreementObject, departmentId, month) {
    const newAgreementDistributionByDept = calcNewAgreementDistributionForMonth(preWorkData, serviceAgreementObject, departmentId, month);
    if (newAgreementDistributionByDept !== null &&
        newAgreementDistributionByDept !== undefined) {
        return newAgreementDistributionByDept * 0.81;
    }
    return 0;
}
exports.calcInspectionTimeOfSale = calcInspectionTimeOfSale;
/**
 * V150
 * =SUM(L145:V145)
 *
 * Agreements Sold During 1st 6 Months (This Trade Only)
 *
 * Sum of New Agreements Distribution for first 6 months: (V150) on Excel Spreadsheet
 * TODO: figure out what to do for months with weird Fiscal Years
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 */
function calcSumOfNewAgreementDistributionFirstSixMonths(preWorkData, serviceAgreementObject, companyData, departmentId) {
    if (preWorkData && serviceAgreementObject) {
        const first6Months = (0, utils_1.createFirstXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
        let sumOfNewAgreements = 0;
        first6Months.forEach((month) => {
            const newAgreementDistribution = calcNewAgreementDistributionForMonth(preWorkData, serviceAgreementObject, departmentId, month);
            sumOfNewAgreements += newAgreementDistribution || 0;
        });
        return sumOfNewAgreements;
    }
    return 0;
}
exports.calcSumOfNewAgreementDistributionFirstSixMonths = calcSumOfNewAgreementDistributionFirstSixMonths;
/**
 * V151
 * =SUM(L173:V173,L200:V200,L227:V227)
 *
 * Agreements Sold During 1st 6 Months (Other Trades Only)
 *
 * Sum of New Agreements Distribution for departments not passed in: (V95) on Excel Spreadsheet
 * TODO: Ask Scott and Jodi how often "Sold in other departments" will be "YES" !!!!
 *
 * @returns sumOfNewAgreementsForOtherDepts
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 */
function calcSumOfNewAgreementDistributionForOtherTradesFirstSixMonths(preWorkData, serviceAgreementObject, companyData, departmentId) {
    if (!preWorkData ||
        !serviceAgreementObject ||
        !serviceAgreementObject.departmentServiceAgreements)
        return 0;
    const first6Months = (0, utils_1.createFirstXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let sumOfNewAgreementsForOtherTrades = 0;
    for (let i = 0; i < serviceAgreementObject.departmentServiceAgreements.length; i++) {
        if (serviceAgreementObject.departmentServiceAgreements[i].departmentId ===
            departmentId)
            continue;
        let sumOfNewAgreements = 0;
        first6Months.forEach((month) => {
            const newAgreementDistribution = calcNewMultiTradeAgreementDistributionForMonth(preWorkData, serviceAgreementObject, serviceAgreementObject.departmentServiceAgreements[i].departmentId, month);
            sumOfNewAgreements += newAgreementDistribution || 0;
        });
        sumOfNewAgreementsForOtherTrades += sumOfNewAgreements;
    }
    return sumOfNewAgreementsForOtherTrades;
}
exports.calcSumOfNewAgreementDistributionForOtherTradesFirstSixMonths = calcSumOfNewAgreementDistributionForOtherTradesFirstSixMonths;
/**
 * V152
 * =SUM(X158:AH158)
 * Sum of Sys Check Seasonality for Last 6 Mo Of Year
 *
 * Sum of Historic System Check Seasonality for last 6 months of previous year: (V96) on Excel Spreadsheet
 * TODO: figure out what to do for months with weird Fiscal Years
 * @returns sumOfSystemCheckSeasonality(last 6 months)
 */
function calcSumOfSystemCheckSeasonalityLast6Months(preWorkData, departmentId, companyData) {
    const last6Months = (0, utils_1.createLastXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    const preWorkObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWorkData, departmentId);
    if (preWorkObject === null)
        return 0;
    let sumOfSystemCheckSeasonality = 0;
    last6Months.forEach((month) => {
        const currrentSeasonality = (0, helpers_1.getSeasonalityPercentage)(preWorkObject.monthlyData[month], "systemCheckCalls");
        if (!(0, utils_1.empty)(currrentSeasonality) !== null) {
            sumOfSystemCheckSeasonality += currrentSeasonality;
        }
        else
            return 0;
    });
    return sumOfSystemCheckSeasonality;
}
exports.calcSumOfSystemCheckSeasonalityLast6Months = calcSumOfSystemCheckSeasonalityLast6Months;
/**
 * L148
 * V150*D98*X158/V152
 * D98 = new Run Rate
 *
 * Inspection 6 months from sale (using Retained Run Rate): (X148) => on Backend Calculations in Excel Spreadsheet
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 * @param month
 * @returns inspection6MonthsFromSale
 */
function calcInspection6MonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const first6months = (0, utils_1.createFirstXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    if (first6months.includes(month))
        return 0;
    const preWorkObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWorkData, departmentId);
    const departmentObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (preWorkObject === null || departmentObject === null)
        return 0;
    const preWork = preWorkObject;
    const deptObj = departmentObject;
    const seasonalityForCalc = (0, helpers_1.getSeasonalityPercentage)(preWork.monthlyData[month], "systemCheckCalls");
    if (!preWork.monthlyData[month])
        return 0;
    if (deptObj.newRunRate !== null && seasonalityForCalc !== null) {
        const v150 = calcSumOfNewAgreementDistributionFirstSixMonths(preWorkData, serviceAgreementObject, companyData, departmentId);
        const v152 = calcSumOfSystemCheckSeasonalityLast6Months(preWorkData, departmentId, companyData);
        if (!v152)
            return 0;
        return (v150 * seasonalityForCalc * (deptObj.newRunRate / 100)) / v152;
    }
    return 0;
}
exports.calcInspection6MonthsFromSale = calcInspection6MonthsFromSale;
/**
 * L149 (only returns value last 6 months)
 * V151*D98*X158/V152
 * D98 = new Run Rate
 *
 * Inspection 6 months from sale (using Retained Run Rate): (X148) => on Backend Calculations in Excel Spreadsheet
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 * @param month
 * @returns inspection6MonthsFromSale
 */
function calcInspection6MonthsFromSaleOtherTrade(preWorkData, serviceAgreementObject, companyData, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const first6months = (0, utils_1.createFirstXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    if (first6months.includes(month))
        return 0;
    const preWorkObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWorkData, departmentId);
    const departmentObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (preWorkObject === null || departmentObject === null)
        return 0;
    const preWork = preWorkObject;
    const deptObj = departmentObject;
    if (!preWork.monthlyData[month])
        return 0;
    const calcSeasonality = (0, helpers_1.getSeasonalityPercentage)(preWork.monthlyData[month], "systemCheckCalls");
    if (deptObj.newRunRate !== null && calcSeasonality !== null) {
        const a = calcSumOfNewAgreementDistributionForOtherTradesFirstSixMonths(preWorkData, serviceAgreementObject, companyData, departmentId);
        const b = calcSumOfSystemCheckSeasonalityLast6Months(preWorkData, departmentId, companyData);
        if (!b)
            return 0;
        return (a * calcSeasonality * (deptObj.newRunRate / 100)) / b;
    }
    return 0;
}
exports.calcInspection6MonthsFromSaleOtherTrade = calcInspection6MonthsFromSaleOtherTrade;
/**
 * X147
 * if numOfVisit === 2 (X145*0.81)+(V150*D98*X158/V152)
 * else (X145*0.81)
 *
 * TODO: Talk to S&J about this calc
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param agreementTrade
 * @param departmentId
 * @param month
 */
function calcInspectionTimeOfSaleLast6Months(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const first6months = (0, utils_1.createFirstXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    if (first6months.includes(month))
        return 0;
    const preWorkObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWorkData, departmentId);
    const departmentObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (preWorkObject === null || departmentObject === null)
        return 0;
    const preWork = preWorkObject;
    const deptObj = departmentObject;
    if (preWork && serviceAgreementObject && deptObj) {
        if (agreementTrade === utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC &&
            deptObj.numOfVisits === 2) {
            const calcSumSystemCheck = calcSumOfSystemCheckSeasonalityLast6Months(preWorkData, departmentId, companyData);
            if (!calcSumSystemCheck)
                return 0;
            return (calcInspectionTimeOfSale(preWorkData, serviceAgreementObject, departmentId, month) +
                (calcSumOfNewAgreementDistributionFirstSixMonths(preWorkData, serviceAgreementObject, companyData, departmentId) *
                    (deptObj.newRunRate / 100) *
                    (0, helpers_1.getSeasonalityPercentage)(preWork.monthlyData[month], "systemCheckCalls")) /
                    calcSumSystemCheck);
        }
        return calcInspectionTimeOfSale(preWorkData, serviceAgreementObject, departmentId, month);
    }
    return 0;
}
exports.calcInspectionTimeOfSaleLast6Months = calcInspectionTimeOfSaleLast6Months;
//pass in the entire serviceAgreements obj and deptType
/**
 *
 * @param serviceAgreementObject
 * @param tradeObject
 */
function calcTotalRetainedAgreements(serviceAgreementObject, tradeObject) {
    if (!tradeObject)
        return 0;
    return ((0, serviceAgreementCalcsFromFE_1.calcNextYearRetentionRate)(tradeObject, "ANNUAL") +
        (0, serviceAgreementCalcsFromFE_1.calcNextYearRetentionRate)(tradeObject, "MONTHLY") +
        (0, serviceAgreementCalcsFromFE_1.calcNextYearRetentionRate)(serviceAgreementObject.multiTradeAgreements, "ANNUAL") +
        (0, serviceAgreementCalcsFromFE_1.calcNextYearRetentionRate)(serviceAgreementObject.multiTradeAgreements, "MONTHLY"));
}
exports.calcTotalRetainedAgreements = calcTotalRetainedAgreements;
/**
 * F98
 * =(C54+C56+C70+C72)*B98*D98
 *
 * BACKEND CALC
 * @param serviceAgreementObject
 * @param tradeObject
 */
function calcNumOfCallsRanOnRetainedAgreements(serviceAgreementObject, tradeObject) {
    if (!serviceAgreementObject || !tradeObject)
        return 0;
    // Need to add multitrade and specified to get right value.
    const totalRetainedAgreements = calcTotalRetainedAgreements(serviceAgreementObject, tradeObject);
    const { numOfVisits, newRunRate } = tradeObject;
    if (totalRetainedAgreements && numOfVisits && newRunRate) {
        return totalRetainedAgreements * numOfVisits * (newRunRate / 100);
    }
    return 0;
}
exports.calcNumOfCallsRanOnRetainedAgreements = calcNumOfCallsRanOnRetainedAgreements;
/**
 * L159
 * =$J159*L158
 *
 * BACKEND CALC
 * @param preWorkData
 * @param serviceAgreementObject
 * @param departmentId
 * @param month
 */
function calcTotalRetainedAgreementsVisitsByMonth(preWorkData, serviceAgreementObject, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const preWorkObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWorkData, departmentId);
    const departmentObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (preWorkObject === null || departmentObject === null)
        return 0;
    const preWork = preWorkObject;
    const deptObj = departmentObject;
    if (!preWork.monthlyData[month])
        return 0;
    const seasonalityForCalc = (0, helpers_1.getSeasonalityPercentage)(preWork.monthlyData[month], "systemCheckCalls");
    // @ts-ignore
    if (seasonalityForCalc && serviceAgreementObject && deptObj) {
        const newCallRetainedAgreements = calcNumOfCallsRanOnRetainedAgreements(serviceAgreementObject, deptObj);
        return newCallRetainedAgreements * seasonalityForCalc;
    }
    return 0;
}
exports.calcTotalRetainedAgreementsVisitsByMonth = calcTotalRetainedAgreementsVisitsByMonth;
/**
 * L163
 * =L149+L159
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param agreementTrade
 * @param departmentId
 * @param month
 */
function calcTotalVisitsBasedOnTimeOfSale(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId, month) {
    // TP-565: creates array of last 6 months in fiscalYear
    const last6Months = (0, utils_1.createLastXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    if (!last6Months.includes(month)) {
        return (calcInspection6MonthsFromSaleOtherTrade(preWorkData, serviceAgreementObject, companyData, departmentId, month) +
            calcTotalRetainedAgreementsVisitsByMonth(preWorkData, serviceAgreementObject, departmentId, month));
    }
    const preWorkObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(preWorkData, departmentId);
    const departmentObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (preWorkObject === null || departmentObject === null)
        return 0;
    const preWork = preWorkObject;
    const deptObj = departmentObject;
    if (preWork && serviceAgreementObject && deptObj) {
        if (agreementTrade === utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC &&
            deptObj.numOfVisits === 2) {
            const l148 = calcInspection6MonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month);
            const l149 = calcInspection6MonthsFromSaleOtherTrade(preWorkData, serviceAgreementObject, companyData, departmentId, month);
            const l159 = calcTotalRetainedAgreementsVisitsByMonth(preWorkData, serviceAgreementObject, departmentId, month);
            return l148 + l149 + l159;
        }
        const a = calcInspection6MonthsFromSaleOtherTrade(preWorkData, serviceAgreementObject, companyData, departmentId, month);
        const b = calcTotalRetainedAgreementsVisitsByMonth(preWorkData, serviceAgreementObject, departmentId, month);
        return a + b;
    }
}
exports.calcTotalVisitsBasedOnTimeOfSale = calcTotalVisitsBasedOnTimeOfSale;
/**
 * AK163
 * =SUM(L163:AH163)
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param agreementTrade
 * @param departmentId
 */
function calcTotalTimeOfSaleAndOther(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId) {
    if (!preWorkData || !serviceAgreementObject || !departmentId)
        return 0;
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalVisits = 0;
    months.forEach((month) => {
        const a = calcTotalVisitsBasedOnTimeOfSale(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId, month);
        totalVisits += a;
    });
    return totalVisits;
}
exports.calcTotalTimeOfSaleAndOther = calcTotalTimeOfSaleAndOther;
/**
 * AM164
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 */
function calcTotal6MonthsAndOther(preWorkData, serviceAgreementObject, companyData, departmentId) {
    if (!preWorkData || !serviceAgreementObject || !departmentId)
        return 0;
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let sum148 = 0;
    let sum149 = 0;
    let sum159 = 0;
    months.forEach((month) => {
        const a = calcInspection6MonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month);
        const b = calcInspection6MonthsFromSaleOtherTrade(preWorkData, serviceAgreementObject, companyData, departmentId, month);
        const c = calcTotalRetainedAgreementsVisitsByMonth(preWorkData, serviceAgreementObject, departmentId, month);
        sum148 += a;
        sum149 += b;
        sum159 += c;
    });
    return sum148 + sum149 + sum159;
}
exports.calcTotal6MonthsAndOther = calcTotal6MonthsAndOther;
/**
 * L160
 * =($C$54+$C$56+$C$70+$C$72)*$B$98*$C$119*$L$141
 *
 * BACKEND CALC
 * @param serviceAgreementObject
 * @param departmentId
 */
function calcRevenueRetainedMonthlyRecognition(serviceAgreementObject, departmentId) {
    if (!serviceAgreementObject || !departmentId)
        return 0;
    const departmentObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (departmentObject === null)
        return 0;
    const deptObj = departmentObject;
    const totalRetainedAgreements = calcTotalRetainedAgreements(serviceAgreementObject, deptObj);
    let revenueRecognizedPerSystemCheckVisit = 0;
    for (let i = 0; i < serviceAgreementObject.departmentServiceAgreements.length; i++) {
        if (departmentId ===
            serviceAgreementObject.departmentServiceAgreements[i].departmentId) {
            revenueRecognizedPerSystemCheckVisit = !(0, utils_1.empty)(serviceAgreementObject.departmentServiceAgreements[i]
                .revenueRecognizedPerSystemCheckVisit)
                ? serviceAgreementObject.departmentServiceAgreements[i]
                    .revenueRecognizedPerSystemCheckVisit
                : (0, serviceAgreementCalcsFromFE_1.calcRevenueRecognizedPerSystemCheckVisit)(serviceAgreementObject, departmentId);
        }
    }
    const { numOfVisits } = deptObj;
    if (numOfVisits && revenueRecognizedPerSystemCheckVisit) {
        return (totalRetainedAgreements *
            numOfVisits *
            revenueRecognizedPerSystemCheckVisit *
            (1 / 12));
    }
    return 0;
}
exports.calcRevenueRetainedMonthlyRecognition = calcRevenueRetainedMonthlyRecognition;
/**
 * AI104
 *
 * AI160
 *
 * BACKEND CALC
 * @param serviceAgreementObject
 * @param departmentId
 */
function calcTotalRevenueRetainedRecognition(serviceAgreementObject, departmentId) {
    const monthData = calcRevenueRetainedMonthlyRecognition(serviceAgreementObject, departmentId);
    return monthData !== null ? monthData * 12 : null;
}
exports.calcTotalRevenueRetainedRecognition = calcTotalRevenueRetainedRecognition;
/**
 * L161
 * =L159*$C$119
 * BACKEND CALC
 * @param preWorkData
 * @param serviceAgreementObject
 * @param departmentId
 * @param month
 */
function calcRevenueTimeOfVisitRecognitionByMonth(preWorkData, serviceAgreementObject, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId)
        return 0;
    let revenueRecognizedPerSystemCheckVisit = 0;
    for (let i = 0; i < serviceAgreementObject.departmentServiceAgreements.length; i++) {
        if (departmentId ===
            serviceAgreementObject.departmentServiceAgreements[i].departmentId) {
            revenueRecognizedPerSystemCheckVisit = !(0, utils_1.empty)(serviceAgreementObject.departmentServiceAgreements[i]
                .revenueRecognizedPerSystemCheckVisit)
                ? serviceAgreementObject.departmentServiceAgreements[i]
                    .revenueRecognizedPerSystemCheckVisit
                : (0, serviceAgreementCalcsFromFE_1.calcRevenueRecognizedPerSystemCheckVisit)(serviceAgreementObject, departmentId);
        }
    }
    return (calcTotalRetainedAgreementsVisitsByMonth(preWorkData, serviceAgreementObject, departmentId, month) * revenueRecognizedPerSystemCheckVisit);
}
exports.calcRevenueTimeOfVisitRecognitionByMonth = calcRevenueTimeOfVisitRecognitionByMonth;
/**
 * AI161
 *
 * This will be passed in the correct info based on the dept
 * BACKEND CALC
 * @param serviceAgreementObject
 * @param preWorkData
 * @param companyData
 * @param departmentId
 */
function calcTotalRevenueTimeOfVisitRecognition(preWorkData, serviceAgreementObject, companyData, departmentId) {
    if (!preWorkData || !serviceAgreementObject || !departmentId)
        return 0;
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalRevenue = 0;
    months.forEach((month) => {
        totalRevenue += calcRevenueTimeOfVisitRecognitionByMonth(preWorkData, serviceAgreementObject, departmentId, month);
    });
    return totalRevenue;
}
exports.calcTotalRevenueTimeOfVisitRecognition = calcTotalRevenueTimeOfVisitRecognition;
// END OF TOTAL CALCS
/**
 * Helper calc for L98
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param departmentId
 * @param month
 */
function calcNewMultiTradeAgreementDistributionForOtherTrades(preWorkData, serviceAgreementObject, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    let newAgreementsForOtherTrades = 0;
    for (let i = 0; i < serviceAgreementObject.departmentServiceAgreements.length; i++) {
        if (serviceAgreementObject.departmentServiceAgreements[i].departmentId ===
            departmentId)
            continue;
        newAgreementsForOtherTrades +=
            calcNewMultiTradeAgreementDistributionForMonth(preWorkData, serviceAgreementObject, serviceAgreementObject.departmentServiceAgreements[i].departmentId, month);
    }
    return newAgreementsForOtherTrades;
}
exports.calcNewMultiTradeAgreementDistributionForOtherTrades = calcNewMultiTradeAgreementDistributionForOtherTrades;
/**
 * L154
 * =(L145+L227+L200+L173)*B98*C119*L141
 * (new agreement distro + multitrade for others) * num of visits * rev per visit * (1 / 12)
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 * @param month
 */
function calcRevenueMonthlyRecognition(preWorkData, serviceAgreementObject, companyData, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    const departmentObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (departmentObject === null)
        return 0;
    const deptObj = departmentObject;
    if (!deptObj.numOfVisits)
        return 0;
    let thisMonthRevenueRecognition = 0;
    const monthIndex = months.findIndex((x) => x === month);
    let revenueRecognizedPerSystemCheckVisit = 0;
    for (let i = 0; i < serviceAgreementObject.departmentServiceAgreements.length; i++) {
        if (departmentId ===
            serviceAgreementObject.departmentServiceAgreements[i].departmentId) {
            revenueRecognizedPerSystemCheckVisit = !(0, utils_1.empty)(serviceAgreementObject.departmentServiceAgreements[i]
                .revenueRecognizedPerSystemCheckVisit)
                ? serviceAgreementObject.departmentServiceAgreements[i]
                    .revenueRecognizedPerSystemCheckVisit
                : (0, serviceAgreementCalcsFromFE_1.calcRevenueRecognizedPerSystemCheckVisit)(serviceAgreementObject, departmentId);
        }
    }
    for (let i = 0; i <= monthIndex; i++) {
        const l145 = calcNewAgreementDistributionForMonth(preWorkData, serviceAgreementObject, departmentId, months[i]);
        const l154helper = calcNewMultiTradeAgreementDistributionForOtherTrades(preWorkData, serviceAgreementObject, departmentId, months[i]);
        thisMonthRevenueRecognition +=
            (l145 + l154helper) *
                deptObj.numOfVisits *
                revenueRecognizedPerSystemCheckVisit *
                (1 / 12);
    }
    return thisMonthRevenueRecognition;
}
exports.calcRevenueMonthlyRecognition = calcRevenueMonthlyRecognition;
/**
 * AI154
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 */
function calcSumRevenueMonthlyRecognition(preWorkData, serviceAgreementObject, companyData, departmentId) {
    if (!preWorkData || !serviceAgreementObject || !departmentId)
        return 0;
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalRevenueMonthlyRecognition = 0;
    months.forEach((month) => {
        totalRevenueMonthlyRecognition += calcRevenueMonthlyRecognition(preWorkData, serviceAgreementObject, companyData, departmentId, month);
    });
    return totalRevenueMonthlyRecognition;
}
exports.calcSumRevenueMonthlyRecognition = calcSumRevenueMonthlyRecognition;
/**
 * L155
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param agreementTrade
 * @param departmentId
 * @param month
 *
 */
function calcMonthlyRevenueWhenVisitRunTimeOfSale(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const last6Months = (0, utils_1.createLastXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    // this is the check in the doc comment
    // setting to 0 if agreements from other Depts are not used so calculation below works
    // calc at L149
    const calcInspectionFromOtherTrade = calcInspection6MonthsFromSaleOtherTrade(preWorkData, serviceAgreementObject, companyData, departmentId, month);
    let revenueRecognizedPerSystemCheckVisit = 0;
    for (let i = 0; i < serviceAgreementObject.departmentServiceAgreements.length; i++) {
        if (departmentId ===
            serviceAgreementObject.departmentServiceAgreements[i].departmentId) {
            revenueRecognizedPerSystemCheckVisit = !(0, utils_1.empty)(serviceAgreementObject.departmentServiceAgreements[i]
                .revenueRecognizedPerSystemCheckVisit)
                ? serviceAgreementObject.departmentServiceAgreements[i]
                    .revenueRecognizedPerSystemCheckVisit
                : (0, serviceAgreementCalcsFromFE_1.calcRevenueRecognizedPerSystemCheckVisit)(serviceAgreementObject, departmentId);
        }
    }
    if (last6Months.includes(month)) {
        // calc for last 6 months
        // X147 (last 6 months of 147)
        return ((calcInspectionTimeOfSaleLast6Months(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId, month) +
            calcInspectionFromOtherTrade) *
            revenueRecognizedPerSystemCheckVisit);
    }
    // calc for first 6
    return ((calcInspectionTimeOfSale(preWorkData, serviceAgreementObject, departmentId, month) +
        calcInspectionFromOtherTrade) *
        revenueRecognizedPerSystemCheckVisit);
}
exports.calcMonthlyRevenueWhenVisitRunTimeOfSale = calcMonthlyRevenueWhenVisitRunTimeOfSale;
/**
 * AI155
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param agreementTrade
 * @param departmentId
 */
function calcSumRevenueWhenVisitRunTimeOfSale(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId) {
    if (!preWorkData || !serviceAgreementObject || !departmentId)
        return 0;
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalRevenueWhenVisitRunTimeOfSale = 0;
    months.forEach((month) => {
        totalRevenueWhenVisitRunTimeOfSale +=
            calcMonthlyRevenueWhenVisitRunTimeOfSale(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId, month);
    });
    return totalRevenueWhenVisitRunTimeOfSale;
}
exports.calcSumRevenueWhenVisitRunTimeOfSale = calcSumRevenueWhenVisitRunTimeOfSale;
/**
 * L156
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 * @param month
 *
 */
function calcRevenueWhenVisitRunSixMonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const first6months = (0, utils_1.createFirstXMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    if (first6months.includes(month))
        return 0;
    let revenueRecognizedPerSystemCheckVisit = 0;
    for (let i = 0; i < serviceAgreementObject.departmentServiceAgreements.length; i++) {
        if (departmentId ===
            serviceAgreementObject.departmentServiceAgreements[i].departmentId) {
            revenueRecognizedPerSystemCheckVisit = !(0, utils_1.empty)(serviceAgreementObject.departmentServiceAgreements[i]
                .revenueRecognizedPerSystemCheckVisit)
                ? serviceAgreementObject.departmentServiceAgreements[i]
                    .revenueRecognizedPerSystemCheckVisit
                : (0, serviceAgreementCalcsFromFE_1.calcRevenueRecognizedPerSystemCheckVisit)(serviceAgreementObject, departmentId);
        }
    }
    return ((calcInspection6MonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month) +
        calcInspection6MonthsFromSaleOtherTrade(preWorkData, serviceAgreementObject, companyData, departmentId, month)) *
        revenueRecognizedPerSystemCheckVisit);
}
exports.calcRevenueWhenVisitRunSixMonthsFromSale = calcRevenueWhenVisitRunSixMonthsFromSale;
/**
 * AI156
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 */
function calcSumRevenueWhenVisitRunSixMonthFromSale(preWorkData, serviceAgreementObject, companyData, departmentId) {
    if (!preWorkData || !serviceAgreementObject || !departmentId)
        return 0;
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    let totalRevenueWhenVisitRunTimeOfSale = 0;
    months.forEach((month) => {
        totalRevenueWhenVisitRunTimeOfSale +=
            calcRevenueWhenVisitRunSixMonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month);
    });
    return totalRevenueWhenVisitRunTimeOfSale;
}
exports.calcSumRevenueWhenVisitRunSixMonthFromSale = calcSumRevenueWhenVisitRunSixMonthFromSale;
/** *
 * L164
 * =L148+L149+L159
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 * @param month
 */
function calcMonthlyTotalVisitsIfSixMonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const l148 = calcInspection6MonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month);
    const l149 = calcInspection6MonthsFromSaleOtherTrade(preWorkData, serviceAgreementObject, companyData, departmentId, month);
    const l159 = calcTotalRetainedAgreementsVisitsByMonth(preWorkData, serviceAgreementObject, departmentId, month);
    return l148 + l149 + l159;
}
exports.calcMonthlyTotalVisitsIfSixMonthsFromSale = calcMonthlyTotalVisitsIfSixMonthsFromSale;
/**
 * L166
 * =L160+L154
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 * @param month
 */
function calcTotalRevenueMonthlyRecognition(preWorkData, serviceAgreementObject, companyData, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const l160 = calcRevenueRetainedMonthlyRecognition(serviceAgreementObject, departmentId);
    const l154 = calcRevenueMonthlyRecognition(preWorkData, serviceAgreementObject, companyData, departmentId, month);
    return l160 + l154;
}
exports.calcTotalRevenueMonthlyRecognition = calcTotalRevenueMonthlyRecognition;
/**
 * L167
 * =L161+L155
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param agreementTrade
 * @param departmentId
 * @param month
 */
function calcTotalRevenueTimeOfVisitPerformedTimeOfSale(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const l161 = calcRevenueTimeOfVisitRecognitionByMonth(preWorkData, serviceAgreementObject, departmentId, month);
    const l155 = calcMonthlyRevenueWhenVisitRunTimeOfSale(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId, month);
    return l161 + l155;
}
exports.calcTotalRevenueTimeOfVisitPerformedTimeOfSale = calcTotalRevenueTimeOfVisitPerformedTimeOfSale;
/**
 * L168
 * =L161+L156
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 * @param month
 */
function calcTotalRevenueTimeOfVisitSixMonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month) {
    if (!preWorkData || !serviceAgreementObject || !departmentId || !month)
        return 0;
    const l161 = calcRevenueTimeOfVisitRecognitionByMonth(preWorkData, serviceAgreementObject, departmentId, month);
    const l156 = calcRevenueWhenVisitRunSixMonthsFromSale(preWorkData, serviceAgreementObject, companyData, departmentId, month);
    return l161 + l156;
}
exports.calcTotalRevenueTimeOfVisitSixMonthsFromSale = calcTotalRevenueTimeOfVisitSixMonthsFromSale;
/**
 * E119
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param departmentId
 */
function calcAllocatedRevenueForRetainedAgreements(preWorkData, serviceAgreementObject, companyData, departmentId) {
    return serviceAgreementObject.areAgreementsRecognizedWhenVisitsRan
        ? calcTotalRevenueTimeOfVisitRecognition(preWorkData, serviceAgreementObject, companyData, departmentId)
        : calcTotalRevenueRetainedRecognition(serviceAgreementObject, departmentId);
}
exports.calcAllocatedRevenueForRetainedAgreements = calcAllocatedRevenueForRetainedAgreements;
/**
 * G119
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param agreementTrade
 * @param departmentId
 */
function calcAllocatedRevenueForNewAgreements(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId) {
    if (!serviceAgreementObject.areAgreementsRecognizedWhenVisitsRan) {
        return calcSumRevenueMonthlyRecognition(preWorkData, serviceAgreementObject, companyData, departmentId);
    }
    const tradeData = (0, bpwCalculationHelpers_1.findDepartmentsById)(serviceAgreementObject.departmentServiceAgreements, departmentId);
    if (!tradeData.visitsAtTimeOfSale) {
        return calcSumRevenueWhenVisitRunSixMonthFromSale(preWorkData, serviceAgreementObject, companyData, departmentId);
    }
    return calcSumRevenueWhenVisitRunTimeOfSale(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId);
}
exports.calcAllocatedRevenueForNewAgreements = calcAllocatedRevenueForNewAgreements;
/**
 * I119
 *
 * @param preWorkData
 * @param serviceAgreementObject
 * @param companyData
 * @param agreementTrade
 * @param departmentId
 */
function calcTotalAgreementRevenueByTrade(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId) {
    return (calcAllocatedRevenueForRetainedAgreements(preWorkData, serviceAgreementObject, companyData, departmentId) +
        calcAllocatedRevenueForNewAgreements(preWorkData, serviceAgreementObject, companyData, agreementTrade, departmentId));
}
exports.calcTotalAgreementRevenueByTrade = calcTotalAgreementRevenueByTrade;
/**
 * I98
 *
 */
function calcNumCallsRanOnNewAgreements(preWorkData, mainObject, companyData, allTradeData, currentTradeObject, agreementTrade, departmentId) {
    const updatedMainObject = {
        ...mainObject,
        departmentServiceAgreements: Object.values({
            ...allTradeData,
            [`${departmentId}`]: currentTradeObject,
        }),
    };
    const a = calcTotalTimeOfSaleAndOther(preWorkData, updatedMainObject, companyData, agreementTrade, departmentId);
    const b = calcNumOfCallsRanOnRetainedAgreements(updatedMainObject, currentTradeObject);
    const c = calcTotal6MonthsAndOther(preWorkData, updatedMainObject, companyData, departmentId);
    if (currentTradeObject.visitsAtTimeOfSale) {
        return a === 0 ? 0 : a - b;
    }
    return c === 0 ? 0 : c - b;
}
exports.calcNumCallsRanOnNewAgreements = calcNumCallsRanOnNewAgreements;
/**
 * L151
 * =IF(OR(C83="No Data",C83=0),(G21+G23)*E83+G5+G7,(G21+G23)*C83+G5+G7)
 */
function calcTotalPriorYearAgreements(preWorkData, mainObject, departmentId) {
    const tradeObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(mainObject.departmentServiceAgreements, departmentId);
    const g21MultiTradeMonthly = mainObject.multiTradeAgreements.monthly.projectedNumOfAgreementsEndOfYear;
    const g23MultiTradeYearly = mainObject.multiTradeAgreements.annual.projectedNumOfAgreementsEndOfYear;
    const e83DepartmentMultiTradeDistribution = !tradeObject
        ? 0
        : tradeObject.multiTradeSalesDistribution;
    const g5DepartmentMonthly = !tradeObject
        ? 0
        : tradeObject.monthly.projectedNumOfAgreementsEndOfYear;
    const g7DepartmentYearly = !tradeObject
        ? 0
        : tradeObject.annual.projectedNumOfAgreementsEndOfYear;
    const c83PriorYearSalesDistribution = (0, serviceAgreementCalcsFromFE_1.calcPriorYearSalesDistributionByDept)(preWorkData, departmentId);
    if (c83PriorYearSalesDistribution === 0) {
        return ((g21MultiTradeMonthly + g23MultiTradeYearly) *
            (e83DepartmentMultiTradeDistribution / 100) +
            g5DepartmentMonthly +
            g7DepartmentYearly);
    }
    return ((g21MultiTradeMonthly + g23MultiTradeYearly) *
        c83PriorYearSalesDistribution +
        g5DepartmentMonthly +
        g7DepartmentYearly);
}
exports.calcTotalPriorYearAgreements = calcTotalPriorYearAgreements;
/**
 * L152
 * =IF(OR(C83="No Data",C83=0),(C70+C72)*E83+I54+I56+G83,(C70+C72)*C83+G83+I54+I56)
 */
function calcTotalNewAndRetainedAgreementsForDepartment(preWorkData, mainObject, departmentId) {
    const tradeObject = (0, bpwCalculationHelpers_1.findDepartmentsById)(mainObject.departmentServiceAgreements, departmentId);
    const e83DepartmentMultiTradeDistribution = !tradeObject
        ? 0
        : tradeObject.multiTradeSalesDistribution;
    const c83PriorYearSalesDistribution = (0, serviceAgreementCalcsFromFE_1.calcPriorYearSalesDistributionByDept)(preWorkData, departmentId);
    const c70MultiTradeMonthly = (0, serviceAgreementCalcsFromFE_1.calcNextYearRetentionRate)(mainObject.multiTradeAgreements, "MONTHLY");
    const c72MultiTradeYearly = (0, serviceAgreementCalcsFromFE_1.calcNextYearRetentionRate)(mainObject.multiTradeAgreements, "ANNUAL");
    const i54TradeTotalAgreementNextYearMonthly = (0, serviceAgreementCalcsFromFE_1.calcTotalAgreementsNextYear)(tradeObject !== null && tradeObject !== void 0 ? tradeObject : null, "MONTHLY");
    const i56TradeTotalAgreementNextYearAnnual = (0, serviceAgreementCalcsFromFE_1.calcTotalAgreementsNextYear)(tradeObject !== null && tradeObject !== void 0 ? tradeObject : null, "ANNUAL");
    const g83MultiTradeAgreemtnesSold = (0, serviceAgreementCalcsFromFE_1.calcMultiTradeSoldByTrade)(mainObject, tradeObject !== null && tradeObject !== void 0 ? tradeObject : null);
    if (c83PriorYearSalesDistribution === 0) {
        return ((c70MultiTradeMonthly + c72MultiTradeYearly) *
            (e83DepartmentMultiTradeDistribution / 100) +
            i54TradeTotalAgreementNextYearMonthly +
            i56TradeTotalAgreementNextYearAnnual +
            g83MultiTradeAgreemtnesSold);
    }
    return ((c70MultiTradeMonthly + c72MultiTradeYearly) *
        c83PriorYearSalesDistribution +
        i54TradeTotalAgreementNextYearMonthly +
        i56TradeTotalAgreementNextYearAnnual +
        g83MultiTradeAgreemtnesSold);
}
exports.calcTotalNewAndRetainedAgreementsForDepartment = calcTotalNewAndRetainedAgreementsForDepartment;
