import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function CloseIcon({
  size = "small",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m451.81,110.38l-147.61,147.61,147.61,147.61c6.64,6.4,10.41,15.2,10.5,24.43.08,9.22-3.55,18.09-10.07,24.62-6.52,6.52-15.39,10.15-24.62,10.07-9.22-.08-18.03-3.86-24.43-10.5l-147.61-147.61-147.61,147.61c-8.73,8.44-21.27,11.63-32.98,8.43-11.71-3.21-20.86-12.36-24.07-24.07-3.2-11.71,0-24.25,8.43-32.98l147.61-147.61L59.35,110.37c-8.44-8.73-11.63-21.27-8.43-32.98,3.21-11.71,12.36-20.86,24.07-24.07,11.71-3.2,24.25,0,32.98,8.43l147.61,147.61L403.19,61.76c6.4-6.64,15.2-10.41,24.43-10.5,9.22-.08,18.09,3.55,24.62,10.07,6.52,6.52,10.15,15.39,10.07,24.62-.08,9.22-3.86,18.03-10.5,24.43h0Z" />
    </svg>
  );
}
