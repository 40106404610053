import React, { Dispatch, ReactElement, SetStateAction } from "react";
import NexstarModal from "./NexstarModal";
import CardHeader from "./CardHeader";
import Container from "./Container";
import Button from "./Button";
import { overflowFix } from "../../helpers/HelperFunctions";

type DirtyProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setDirty: Dispatch<SetStateAction<boolean>>;
  handleDirtySave: () => void;
  dirtyMessage: string;
};
//TODO udpate for new save handling option
/**
 * KRJ: HandleNav is to supply a function managing navigation after the dialog is closed.
 * Navigation after save should be handled in whatever is being supplied as handleDirtySave to preserve on success or error handling
 * @param open
 * @param setOpen
 * @param setDirty
 * @param handleDirtySave
 * @param dirtyMessage
 * @constructor
 */
export function DirtyDialog({
  open,
  setOpen,
  setDirty,
  handleDirtySave,
  dirtyMessage,
}: DirtyProps): ReactElement {
  const handleClose = () => {
    setOpen(false);
    overflowFix();
  };
  const handleCancel = () => {
    setDirty(false);
    handleClose();
  };
  //TP-1619 KRJ: Save handler is fed a save, navigation is handled in context on switch of dirty from true to false
  const handleSave = () => {
    handleDirtySave();
    handleClose();
  };
  //TODO KRJ: we can add custom messaging here if we want, there is a message setter in the dirty context that could be used
  return (
    <NexstarModal
      id="dirtyDialog"
      open={open}
      onClose={handleClose}
      maxWidth="max-w-3xl"
    >
      <CardHeader
        title="Unsaved Data"
        headingTextSize="text-xl"
        id="dirty_data_dialog"
      />
      <Container padding="px-4 py-6" justifyContent="justify-center">
        <div className="text-xl">{dirtyMessage}</div>
        <div className="text-lg text-NexstarSienna">
          Leaving the current page may delete changes
        </div>
        <Container padding="px-4 py-2" justifyContent="justify-center">
          <Button
            id="do_not_save_dirty_btn"
            onClick={handleCancel}
            width="w-24"
          >
            No
          </Button>
          <Button id="dirty_save_btn" onClick={handleSave} width="w-24">
            Save
          </Button>
        </Container>
      </Container>
    </NexstarModal>
  );
}
