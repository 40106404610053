import React, { Dispatch, SetStateAction } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { MemberType } from "@nexstar-network/shared-utils";
import {
  CompanyIdOrDefault,
  UserIdOrDefault,
} from "../../../utils/helpers/UserHelper";
import { addMessage } from "../../../utils/helpers/MessagingHelper";
import NexstarModal from "../../../utils/components/tailwind/NexstarModal";
import CardHeader from "../../../utils/components/tailwind/CardHeader";
import { NexstarTextField } from "../../../utils/components/tailwind/NexstarTextField";
import NexstarSelectItem from "../../../utils/components/tailwind/NexstarSelectItem";
import NexstarSelectMenu from "../../../utils/components/tailwind/NexstarSelectMenu";
import Button from "../../../utils/components/tailwind/Button";

type AddCompanyDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  user: any;
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>;
  endpoint: string;
  companyOptions: Array<MemberType>;
  setCompanyOptions: Dispatch<SetStateAction<Array<MemberType>>>;
  newCompanyData: MemberType;
  setNewCompanyData: Dispatch<SetStateAction<MemberType>>;
};

function AddCompanyDialog({
  open,
  setOpen,
  user,
  getAccessTokenSilently,
  endpoint,
  companyOptions,
  setCompanyOptions,
  newCompanyData,
  setNewCompanyData,
}: AddCompanyDialogProps): JSX.Element {
  // TP-671 JAH: Updating to new Toast Package.
  const { enqueueSnackbar } = useSnackbar();
  const countryList = [
    {
      key: "US",
      value: "United States",
    },
    {
      key: "CA",
      value: "Canada",
    },
    {
      key: "CY",
      value: "Cayman Islands",
    },
    {
      key: "AUS",
      value: "Australia",
    },
  ];

  async function saveData(event) {
    event.preventDefault();
    const accessToken =
      process.env.REACT_APP_NODE_ENV !== "development"
        ? await getAccessTokenSilently({
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
            scope: "openid profile email",
            detailedResponse: false,
          })
        : process.env.REACT_APP_TEST_API_AUTH_KEY;

    // Need to sanitize data
    const dataToSave: MemberType = {
      coachId: UserIdOrDefault(user).toString(),
      memberId: CompanyIdOrDefault(user),
      companyId: null,
      companyName: newCompanyData.companyName,
      companyCountry: newCompanyData.companyCountry,
      companyCity: newCompanyData.companyCity,
      companyState: newCompanyData.companyState,
      companyZipCode: newCompanyData.companyZipCode,
      companyLatitude: null,
      companyLongitude: null,
    };
    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        url: endpoint,
        method: "post",
        data: {
          query: `mutation($coachesCompanyInput: CreateCoachCompany!){
            saveCoachesCompany(coachesCompanyInput: $coachesCompanyInput){
              id
              coachId
              companyName
              companyCountry
              companyCity
              companyState
              companyZipCode
              errorCode
              errorMessage
            }
          }`,
          variables: { coachesCompanyInput: { ...dataToSave } },
        },
      });
      if (response.data.errors) throw Error(response.data.errors[0].message);
      if (response.data.data.saveCoachesCompany.errorCode)
        throw Error(
          `${response.data.data.saveCoachesCompany.errorCode} - ${response.data.data.saveCoachesCompany.errorMessage}`,
        );
      delete response.data.data.saveCoachesCompany.errorCode;
      delete response.data.data.saveCoachesCompany.errorMessage;
      let processedData = {
        ...response.data.data.saveCoachesCompany,
        companyId: parseInt(
          response.data.data.saveCoachesCompany.id.split("#").pop(),
          10,
        ),
      };
      processedData = {
        ...processedData,
        city: response.data.data.saveCoachesCompany.companyCity,
      };
      processedData = {
        ...processedData,
        state: response.data.data.saveCoachesCompany.companyState,
      };
      processedData = {
        ...processedData,
        companyZipCode: response.data.data.saveCoachesCompany.companyZipCode,
      };
      processedData = {
        ...processedData,
        companyLatitude: response.data.data.saveCoachesCompany.companyLatitude,
      };
      processedData = {
        ...processedData,
        companyLongitude:
          response.data.data.saveCoachesCompany.companyLongitude,
      };
      companyOptions.push(processedData);
      setCompanyOptions(companyOptions);
      setNewCompanyData({
        coachId: null,
        companyId: null,
        companyLatitude: null,
        companyLongitude: null,
        id: null,
        memberId: null,
        companyName: "",
        companyCountry: "",
        companyState: "",
        companyCity: "",
        companyZipCode: "",
      });
      setOpen(false);
      addMessage("SUCCESSFUL_REQUEST", "success", enqueueSnackbar);
    } catch (e) {
      addMessage("ERROR_CUSTOM_COMPANY", "error", enqueueSnackbar);
    }
  }

  return (
    <NexstarModal
      id="addCompanyModal"
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="max-w-[400px]"
    >
      <CardHeader title="Create A Company" />
      <div className="p-6">
        <div className="mb-2">
          <NexstarTextField
            id="companyName"
            name="companyName"
            label="Company Name"
            value={newCompanyData.companyName}
            onChange={(e) =>
              setNewCompanyData({
                ...newCompanyData,
                companyName: e.target.value,
              })
            }
          />
          <p className="text-xs mt-1 text-gray-500">
            This is the title of your test company
          </p>
        </div>
        <div className="mb-4">
          <NexstarSelectMenu
            defaultText="Select a country"
            id="countrySelect"
            menuLabel="Select A Country"
            value={newCompanyData.companyCountry}
            onChange={(e) =>
              setNewCompanyData({
                ...newCompanyData,
                companyCountry: e.target.value,
              })
            }
            options={countryList.map((existing) => ({
              text: existing.value,
              value: existing.value,
            }))}
          >
            {countryList.map((existing) => (
              <NexstarSelectItem
                id={existing.key}
                key={existing.key}
                keyValue={existing.key}
                value={{
                  target: { name: "countrySelect", value: existing.key },
                }}
                text={existing.value}
              />
            ))}
          </NexstarSelectMenu>
        </div>
        <div className="mb-4">
          <NexstarTextField
            id="state"
            name="state"
            label="Company State"
            value={newCompanyData.companyState}
            onChange={(e) =>
              setNewCompanyData({
                ...newCompanyData,
                companyState: e.target.value,
              })
            }
          />
          <p className="text-xs mt-1 text-gray-500">
            This is the 2 character shortcode of the state
          </p>
        </div>
        <div className="mb-4">
          <NexstarTextField
            id="city"
            name="city"
            label="City"
            value={newCompanyData.companyCity}
            onChange={(e) =>
              setNewCompanyData({
                ...newCompanyData,
                companyCity: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-4">
          <NexstarTextField
            id="companyZipCode"
            name="companyZipCode"
            label="Zip Code"
            value={newCompanyData.companyZipCode}
            onChange={(e) =>
              setNewCompanyData({
                ...newCompanyData,
                companyZipCode: e.target.value,
              })
            }
          />
        </div>
        <div className="flex justify-center mt-6">
          <Button
            id="addNewCompany"
            onClick={(e) => {
              saveData(e);
            }}
            width="w-28"
          >
            Add
          </Button>
        </div>
      </div>
    </NexstarModal>
  );
}

export default AddCompanyDialog;
