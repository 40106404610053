"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companyObjV2 = exports.workshopTestV2 = void 0;
const utils_1 = require("../../../utils");
exports.workshopTestV2 = {
    serviceAgreementsMain: {
        areAgreementsFromOtherDeptsUsed: null,
        areAgreementsRecognizedWhenVisitsRan: null,
    },
    serviceAgreements: {
        jan: {
            totalVisitsBasedOnTimeOfSaleCalculation: 116.48606224627876,
            totalVisitIf6MonthFromSale: 116.48606224627876,
            retainedAgreementsVisits: 116.48606224627876,
        },
        feb: {
            totalVisitsBasedOnTimeOfSaleCalculation: 59.834370771312585,
            totalVisitIf6MonthFromSale: 59.834370771312585,
            retainedAgreementsVisits: 59.834370771312585,
        },
        mar: {
            totalVisitsBasedOnTimeOfSaleCalculation: 190.96075778078486,
            totalVisitIf6MonthFromSale: 190.96075778078486,
            retainedAgreementsVisits: 190.96075778078486,
        },
        apr: {
            totalVisitsBasedOnTimeOfSaleCalculation: 338.6370771312585,
            totalVisitIf6MonthFromSale: 338.6370771312585,
            retainedAgreementsVisits: 338.6370771312585,
        },
        may: {
            totalVisitsBasedOnTimeOfSaleCalculation: 203.0549391069012,
            totalVisitIf6MonthFromSale: 203.0549391069012,
            retainedAgreementsVisits: 203.0549391069012,
        },
        jun: {
            totalVisitsBasedOnTimeOfSaleCalculation: 174.41082543978348,
            totalVisitIf6MonthFromSale: 174.41082543978348,
            retainedAgreementsVisits: 174.41082543978348,
        },
        jul: {
            totalVisitsBasedOnTimeOfSaleCalculation: 176.055722941612,
            totalVisitIf6MonthFromSale: 176.055722941612,
            retainedAgreementsVisits: 119.0322056833559,
        },
        aug: {
            totalVisitsBasedOnTimeOfSaleCalculation: 150.63591267731508,
            totalVisitIf6MonthFromSale: 150.63591267731508,
            retainedAgreementsVisits: 101.84573748308526,
        },
        sep: {
            totalVisitsBasedOnTimeOfSaleCalculation: 353.9943947916904,
            totalVisitIf6MonthFromSale: 353.9943947916904,
            retainedAgreementsVisits: 239.33748308525034,
        },
        oct: {
            totalVisitsBasedOnTimeOfSaleCalculation: 473.5616504793093,
            totalVisitIf6MonthFromSale: 473.5616504793093,
            retainedAgreementsVisits: 320.1775372124493,
        },
        nov: {
            totalVisitsBasedOnTimeOfSaleCalculation: 357.7602926086233,
            totalVisitIf6MonthFromSale: 357.7602926086233,
            retainedAgreementsVisits: 241.88362652232746,
        },
        dec: {
            totalVisitsBasedOnTimeOfSaleCalculation: 364.3506137882558,
            totalVisitIf6MonthFromSale: 364.3506137882558,
            retainedAgreementsVisits: 246.33937753721247,
        },
    },
    maxCapacity: {
        jan: {
            budgetYearPercOfSystemCheckOpportunity: 40,
            budgetYearPercOfDemandCallsOpportunity: 52.5,
            budgetYearPercOfSystemCheckTurnover: 25,
            budgetYearPercOfDemandCallsTurnover: 48,
            numOfSystemTurnovers: 11.648606224627876,
            numOfDemandTurnovers: 49.760912313937744,
            monthlyTurnovers: 61.40951853856562,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 52.5,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 48,
            numOfDemandSystemCheckTurnovers: 49.760912313937744,
        },
        feb: {
            budgetYearPercOfSystemCheckOpportunity: 44.2,
            budgetYearPercOfDemandCallsOpportunity: 46.2,
            budgetYearPercOfSystemCheckTurnover: 26,
            budgetYearPercOfDemandCallsTurnover: 48,
            numOfSystemTurnovers: 6.8761658890392425,
            numOfDemandTurnovers: 49.72205793775371,
            monthlyTurnovers: 56.59822382679295,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 46.2,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 48,
            numOfDemandSystemCheckTurnovers: 49.72205793775371,
        },
        mar: {
            budgetYearPercOfSystemCheckOpportunity: 48,
            budgetYearPercOfDemandCallsOpportunity: 52.9,
            budgetYearPercOfSystemCheckTurnover: 27,
            budgetYearPercOfDemandCallsTurnover: 50,
            numOfSystemTurnovers: 24.74851420838972,
            numOfDemandTurnovers: 40.043777066982415,
            monthlyTurnovers: 64.79229127537214,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 52.9,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 50,
            numOfDemandSystemCheckTurnovers: 40.043777066982415,
        },
        apr: {
            budgetYearPercOfSystemCheckOpportunity: 40,
            budgetYearPercOfDemandCallsOpportunity: 42.5,
            budgetYearPercOfSystemCheckTurnover: 25,
            budgetYearPercOfDemandCallsTurnover: 47,
            numOfSystemTurnovers: 33.86370771312585,
            numOfDemandTurnovers: 2.4694938430311186,
            monthlyTurnovers: 36.333201556156965,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 42.5,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 47,
            numOfDemandSystemCheckTurnovers: 2.4694938430311186,
        },
        may: {
            budgetYearPercOfDemandCallsOpportunity: 46.3,
            budgetYearPercOfDemandCallsTurnover: 48,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 46.3,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 48,
            budgetYearPercOfSystemCheckOpportunity: 43.3,
            budgetYearPercOfSystemCheckTurnover: 26,
            monthlyTurnovers: 81.6301953775372,
            numOfDemandSystemCheckTurnovers: 58.77027033288227,
            numOfDemandTurnovers: 58.77027033288227,
            numOfSystemTurnovers: 22.85992504465494,
        },
        jun: {
            budgetYearPercOfSystemCheckOpportunity: 48.3,
            budgetYearPercOfDemandCallsOpportunity: 43.8,
            budgetYearPercOfSystemCheckTurnover: 30,
            budgetYearPercOfDemandCallsTurnover: 50,
            numOfSystemTurnovers: 25.272128606224623,
            numOfDemandTurnovers: 73.99663422868743,
            monthlyTurnovers: 99.26876283491205,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 43.8,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 50,
            numOfDemandSystemCheckTurnovers: 73.996634228687,
        },
        jul: {
            budgetYearPercOfSystemCheckOpportunity: 40,
            budgetYearPercOfDemandCallsOpportunity: 56.1,
            budgetYearPercOfSystemCheckTurnover: 28,
            budgetYearPercOfDemandCallsTurnover: 60,
            numOfSystemTurnovers: 19.71824096946055,
            numOfDemandTurnovers: 74.06690365785342,
            monthlyTurnovers: 93.78514462731397,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 56.1,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 60,
            numOfDemandSystemCheckTurnovers: 74.06690365785342,
        },
        aug: {
            budgetYearPercOfSystemCheckOpportunity: 42.6,
            budgetYearPercOfDemandCallsOpportunity: 47.5,
            budgetYearPercOfSystemCheckTurnover: 30,
            budgetYearPercOfDemandCallsTurnover: 55,
            numOfSystemTurnovers: 19.251269640160867,
            numOfDemandTurnovers: 68.41329906305143,
            monthlyTurnovers: 87.6645687032123,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 47.5,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 55,
            numOfDemandSystemCheckTurnovers: 68.41329906305143,
        },
        sep: {
            budgetYearPercOfSystemCheckOpportunity: 46.9,
            budgetYearPercOfDemandCallsOpportunity: 42,
            budgetYearPercOfSystemCheckTurnover: 28,
            budgetYearPercOfDemandCallsTurnover: 47,
            numOfSystemTurnovers: 46.48654392404479,
            numOfDemandTurnovers: 13.009766468120318,
            monthlyTurnovers: 59.49631039216511,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 42,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 47,
            numOfDemandSystemCheckTurnovers: 13.009766468120318,
        },
        oct: {
            budgetYearPercOfSystemCheckOpportunity: 43.7,
            budgetYearPercOfDemandCallsOpportunity: 43.9,
            budgetYearPercOfSystemCheckTurnover: 27,
            budgetYearPercOfDemandCallsTurnover: 47,
            numOfSystemTurnovers: 55.87553914005372,
            numOfDemandTurnovers: -0.8473251933958955,
            monthlyTurnovers: 55.02821394665782,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 43.9,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 47,
            numOfDemandSystemCheckTurnovers: -0.8473251933958955,
        },
        nov: {
            budgetYearPercOfSystemCheckOpportunity: 47,
            budgetYearPercOfDemandCallsOpportunity: 45.1,
            budgetYearPercOfSystemCheckTurnover: 28,
            budgetYearPercOfDemandCallsTurnover: 48,
            numOfSystemTurnovers: 47.08125450729482,
            numOfDemandTurnovers: 32.680839856085235,
            monthlyTurnovers: 79.76209436338006,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 45.1,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 48,
            numOfDemandSystemCheckTurnovers: 32.680839856085235,
        },
        dec: {
            budgetYearPercOfSystemCheckOpportunity: 44.9,
            budgetYearPercOfDemandCallsOpportunity: 45.4,
            budgetYearPercOfSystemCheckTurnover: 30,
            budgetYearPercOfDemandCallsTurnover: 55,
            numOfSystemTurnovers: 49.07802767727806,
            numOfDemandTurnovers: 21.51150173707252,
            monthlyTurnovers: 70.58952941435058,
            budgetYearPercOfDemandSystemCheckCallsOpportunity: 45.4,
            budgetYearPercOfDemandSystemCheckCallsTurnover: 55,
            numOfDemandSystemCheckTurnovers: 21.51150173707252,
        },
        id: "",
        memberId: "",
        fiscalYear: 0,
        departmentId: "",
        departmentName: "",
        departmentType: utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT,
        departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
        useOpportunity: false,
        maxCapacityTotals: {
            totalSystemTurnovers: 0,
            totalDemandTurnovers: 0,
            totalDemandSystemCheckTurnovers: 0,
            totalTurnovers: 0,
        },
    },
    callCount: {
        jan: {
            targetNumOfCallsRanPerTech: 2.3,
            budgetedNumOfTechs: 6.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.3,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        feb: {
            targetNumOfCallsRanPerTech: 2.3,
            budgetedNumOfTechs: 6.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.3,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        mar: {
            targetNumOfCallsRanPerTech: 2.29,
            budgetedNumOfTechs: 6.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.29,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        apr: {
            targetNumOfCallsRanPerTech: 2.34,
            budgetedNumOfTechs: 7.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.34,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        may: {
            targetNumOfCallsRanPerTech: 2.5,
            budgetedNumOfTechs: 8.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.5,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        jun: {
            targetNumOfCallsRanPerTech: 2.87,
            budgetedNumOfTechs: 8.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.87,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        jul: {
            targetNumOfCallsRanPerTech: 2.33,
            budgetedNumOfTechs: 8.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.33,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        aug: {
            targetNumOfCallsRanPerTech: 2.11,
            budgetedNumOfTechs: 8.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.11,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        sep: {
            targetNumOfCallsRanPerTech: 2.47,
            budgetedNumOfTechs: 8.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.47,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        oct: {
            targetNumOfCallsRanPerTech: 2.63,
            budgetedNumOfTechs: 8.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.63,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        nov: {
            targetNumOfCallsRanPerTech: 2.85,
            budgetedNumOfTechs: 8.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.85,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        dec: {
            targetNumOfCallsRanPerTech: 2.65,
            budgetedNumOfTechs: 8.5,
            percentOfDemandCallsThatAreDemandSystemCheck: 2.65,
            targetNumOfLeadsRanPerSalesperson: null,
            budgetedNumOfSalespersons: null,
            targetNumOfJobsPerCrew: null,
            budgetedNumOfCrews: null,
            monthlyTotalRevenueCalc: null,
            monthlyDemandCallRevenue: null,
            monthlyDemandSystemCheckCallRevenue: null,
            monthlySystemCheckCallRevenue: null,
        },
        id: "",
        memberId: "",
        fiscalYear: 0,
        departmentId: "",
        departmentName: "",
        departmentType: utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT,
        departmentTrade: utils_1.DepartmentTradeEnum.DEPARTMENT_TRADE_HVAC,
        callCountTotals: {
            totalSumPriorYearAgreementSystemChecks: 0,
            totalSumBudgetedSystemCheckCallCount: 0,
            totalSumPriorYearDemandCallCount: 0,
            totalSumPriorYearDemandSystemCheckCallCount: 0,
            totalSumPriorYearTechGeneratedLeads: 0,
            totalSumBudgetedTechGeneratedLeads: 0,
            totalSumPriorYearMarketedLeadCount: 0,
            totalSumPriorYearOtherLeadCount: 0,
            totalSumRequiredDemandCallCount: 0,
            totalSumRequiredDemandSystemCheckCallCount: 0,
            totalSumMarketedLeadCount: 0,
            totalConvertedTechLeadCount: 0,
            totalSumOtherLeadCount: 0,
            totalTechLeadRevenue: 0,
            totalConvertedMarketedLeadCount: 0,
            totalMarketedLeadRevenue: 0,
            totalConvertedOtherLeadCount: 0,
            totalOtherLeadRevenue: 0,
            totalDemandConvertedCalls: 0,
            totalDemandSystemCheckConvertedCalls: 0,
            totalDemandRevenue: 0,
            totalDemandSystemCheckRevenue: 0,
            totalSystemCheckConvertedCalls: 0,
            totalSystemCheckRevenue: 0,
        },
    },
};
exports.companyObjV2 = {
    memberId: "MEMBER#226",
    id: "MANAGE#COMPANY#2023#01G42ZVJD5SR9BFQH47VZDTKTE",
    fiscalYearEnd: "December",
    fiscalYear: "2023",
    nonWorkingDays: [
        {
            label: "New Year's Day",
            date: "2023-01-01",
        },
        {
            label: "Martin Luther King Jr. Day",
            date: "2023-01-16",
        },
        {
            label: "Washington's Birthday",
            date: "2023-02-20",
        },
        {
            label: "Memorial Day",
            date: "2023-05-29",
        },
        {
            label: "Juneteenth",
            date: "2023-06-19",
        },
        {
            label: "Independence Day",
            date: "2023-07-04",
        },
        {
            label: "Labor Day",
            date: "2023-09-04",
        },
        {
            label: "Columbus Day",
            date: "2023-10-09",
        },
        {
            label: "Veterans Day",
            date: "2023-11-11",
        },
        {
            label: "Thanksgiving Day",
            date: "2023-11-23",
        },
        {
            label: "Christmas Day",
            date: "2023-12-25",
        },
    ],
    weeklyWorkdays: {
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: false,
        sun: false,
    },
    createdBy: "GOD",
    updatedBy: "some peasant",
};
