import React from "react";
import { useCompanyContext } from "../../components/context/company/CompanyContext";
import NexstarModal from "./tailwind/NexstarModal";
import { useUserContext } from "../../components/context/user/UserContext";
import CardHeader from "./tailwind/CardHeader";
import EmailIcon from "./icons/EmailIcon";
import PhoneIcon from "./icons/PhoneIcon";
import { objectFieldValidator } from "@nexstar-network/shared-utils";

type CoachingTeamModalProps = {
  open: boolean;
  setOpen: any;
};
type CoachInformationType = {
  accessLevelID: number;
  accessLevelName: string;
  avatar: string;
  businessPhoneNumber: string;
  calendarURL: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  userID: number;
  pivot: Record<number, number>;
};

export default function CoachingTeamModal({
  open,
  setOpen,
}: CoachingTeamModalProps): JSX.Element {
  const { memberCoaches } = useCompanyContext();
  const { selectedCompany } = useUserContext();

  const formatPhoneNumber = (coach: CoachInformationType) => {
    if (objectFieldValidator(coach, ["businessPhoneNumber"])) {
      return coach.businessPhoneNumber
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "");
    } else return "-";
  };

  return (
    <NexstarModal
      id="coachingTeamModal"
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="max-w-4xl"
    >
      <CardHeader
        title={
          selectedCompany.companyName
            ? `${selectedCompany.companyName} Coaching Team`
            : "Coaching Team"
        }
      />
      <div className="p-6">
        {objectFieldValidator(memberCoaches, ["data"]) ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-6">
            {Object.values(memberCoaches.data).map(
              (coach: CoachInformationType) => {
                return (
                  <div key={coach.userID} className="col-span-1 flex flex-col">
                    <div className="w-20 h-20 mx-auto mb-2">
                      <img
                        className="w-full rounded-full object-cover shadow-md"
                        src={coach["avatar"].toString()}
                        alt={`${coach["coachTypeName"]} - ${coach["firstName"]} ${coach["lastName"]}`}
                      />
                    </div>
                    <h3 className="font-bold text-NexstarBlue text-[19px] text-center leading-tight">
                      {coach["coachTypeName"]}
                    </h3>
                    <h4 className="text-NexstarBlue text-[17px] text-center leading-tight">{`${coach["firstName"]} ${coach["lastName"]}`}</h4>
                    <div className="flex gap-2 justify-center mt-2">
                      <a href={`tel:+${formatPhoneNumber(coach)}`}>
                        <PhoneIcon color="gray" size="small" />
                      </a>
                      <a href={`mailto:${coach["email"]}`}>
                        <EmailIcon color="gray" size="small" />
                      </a>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        ) : (
          <p className="font-bold text-center text-lg">
            Error fetching your coaching team. Please try again.
          </p>
        )}
      </div>
    </NexstarModal>
  );
}
