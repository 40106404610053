import React, { ReactElement } from "react";
import { IconColors } from "../../../helpers/IconHelpers";
import { IconType } from "../../../../types/IconType";

export default function ExportIcon({
  color = "white",
  className,
}: IconType): ReactElement {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m996 780h180v288c0 28.645-11.379 56.113-31.633 76.367-20.254 20.254-47.723 31.633-76.367 31.633h-936c-28.645 0-56.113-11.379-76.367-31.633-20.254-20.254-31.633-47.723-31.633-76.367v-288h180v216h792zm-662.28-276 245.16 337.2c4.9258 6.7383 12.773 10.723 21.121 10.723s16.195-3.9844 21.121-10.723l245.16-337.2c2.7227-3.7656 3.957-8.4062 3.4688-13.027-0.49219-4.6211-2.6758-8.8984-6.1289-12.012-3.4531-3.1094-7.9336-4.8359-12.582-4.8398h-143.04v-450.12h-216v450.36h-143.04c-4.5938 0.046875-9.0156 1.7734-12.426 4.8516-3.4102 3.0781-5.5781 7.2969-6.0977 11.863-0.51953 4.5625 0.64844 9.1602 3.2812 12.926z" />
    </svg>
  );
}
