import axios, { AxiosResponse } from "axios";
import { getPHP_API_URI } from "../../../utils/helpers/ExcelHelper";

export async function getUserAlerts(
  userId: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  const alertParams = {
    uri: `${getPHP_API_URI()}tools/${userId}/alerts`,
    accessToken,
  };

  const alerts = await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}crmAPI/alerts/userAlerts`,
    method: "post",
    data: { ...alertParams },
  })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosResponse) => {
      return error;
    });
  return alerts;
}

export async function markAsRead(
  userID: number,
  alertID: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  const params = {
    uri: `${getPHP_API_URI()}tools/${userID}/alerts/${alertID}/markAsRead`,
    accessToken,
  };
  const result = await axios({
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}crmAPI/alerts/markAsRead`,
    data: params,
  })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosResponse) => {
      return error;
    });
  return result;
}
