import React, { ReactElement } from "react";
import NexstarSwoosh from "../icons/nexstar/NexstarSwoosh";

function PageNotFound(): ReactElement {
  return (
    <div className="flex flex-col justify-center py-6 px-10 max-w-5xl m-auto min-h-[calc(100vh-131px)]">
      <h1 className="sr-only">404</h1>
      <div className="flex justify-center -mt-12" aria-hidden>
        <p className="text-NexstarBlue text-[185px] md:text-[340px] font-thin leading-none">
          4
        </p>
        <div className="w-24 md:w-48 m-4">
          <NexstarSwoosh />
        </div>
        <p className="text-NexstarBlue text-[185px] md:text-[340px] font-thin leading-none -ml-8 md:-ml-14">
          4
        </p>
      </div>
      <h2 className="text-NexstarOrange text-4xl md:text-5xl text-center mb-10">
        Page Not Found
      </h2>
      <p className="text-center text-md md:text-xl">
        The resource or tool you are looking for has either never existed, been
        moved, or needs some tender loving care. If you think that this is a
        legitimate error please contact your Coach.
      </p>
    </div>
  );
}

export default PageNotFound;
