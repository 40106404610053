import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function HelpIcon({
  size = "small",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m256,29.35c-124.74,0-226.15,101.4-226.15,226.15s101.4,226.15,226.15,226.15,226.15-101.4,226.15-226.15S380.75,29.35,256,29.35h0Zm3.81,355.65c-15.23,0-27.14-12.38-27.14-27.14s12.38-27.14,27.14-27.14,27.14,12.38,27.14,27.14-12.38,27.14-27.14,27.14Zm65.7-138.54c-10.48,14.28-21.42,21.9-29.52,27.61-9.04,6.19-12.85,9.04-13.81,23.81-.48,6.67-6.19,11.43-12.38,11.43h-19.52c-7.14,0-13.33-6.19-12.85-13.81,2.86-34.28,16.19-46.66,32.85-58.09,6.67-4.76,12.85-9.04,19.04-17.62,6.19-8.57,7.14-20.47,1.9-30.47-3.33-7.14-12.85-18.57-34.75-18.57-28.57,0-37.14,18.09-39.99,31.9-.95,6.19-6.19,10.48-12.38,10.48h-20c-7.62,0-13.81-6.67-12.38-14.28,5.24-37.61,32.38-73.32,84.75-73.32,32.85,0,60.94,16.19,74.75,43.33,13.33,25.71,10.95,55.22-5.71,77.6h0Z" />
    </svg>
  );
}
