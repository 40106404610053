import React from "react";

type CardWrapperProps = {
  id: string;
  children: React.ReactNode;
  dataTestId?: string;
  height?: string;
  className?: string;
};

export default function CardWrapper({
  id,
  children,
  dataTestId,
  height = "",
  className,
}: CardWrapperProps): JSX.Element {
  return (
    <div
      className={`relative w-full overflow-clip shadow-md rounded-tr-xl rounded-bl-xl ${height} ${className}`}
      id={`${id}_cardWrapper`}
      data-testid={dataTestId ? dataTestId : `${id}_cardWrapper`}
    >
      {children}
    </div>
  );
}
