"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.soldDaysInstalled = exports.requiredOtherLeadCountBasedStaffingCalc = exports.requiredMarketLeadCountBasedOnStaffingCalc = exports.calcTotalMarketingGeneratedRevenue = exports.calcSoldDayEfficiency = exports.calcCallsPerSalesPersonPerDay = exports.calcAverageSale = exports.calcNumberJobsSoldOnMarketingLeads = exports.calcTotalOtherGeneratedRevenue = exports.calcNumberJobsSoldOnOtherLeads = exports.calcSalesLeadsRanPerMonth = exports.calcMonthlySales = exports.calcTotalTechGeneratedRevenue = exports.getNumberOfSalesStaff = exports.getConversionRateOnTechLeads = exports.getAverageSaleOnTechLeads = exports.getAverageSaleOnMarketingLeads = exports.getAverageSaleOnOtherLeads = exports.getNeededInstallationStaffing = exports.calcJobsSold = exports.calcNumberJobsSoldOnTechLeads = exports.calcConversionRate = exports.getConversionRateOnOtherLeads = exports.getConversionRateOnMarketingLeads = exports.getTechLeadsRan = exports.getMarketingLeadsRan = exports.getOtherLeadsRan = void 0;
const workshop_1 = require("../../workshop");
Object.defineProperty(exports, "requiredMarketLeadCountBasedOnStaffingCalc", { enumerable: true, get: function () { return workshop_1.requiredMarketLeadCountBasedOnStaffingCalc; } });
Object.defineProperty(exports, "requiredOtherLeadCountBasedStaffingCalc", { enumerable: true, get: function () { return workshop_1.requiredOtherLeadCountBasedStaffingCalc; } });
/**
 * C143
 * ='Call Count'!D105
 * ='Max Capacity'!D42
 *
 *
 * @param object
 * @param month
 */
function getTechLeadsRan(object, month) {
    let maxCapacity = null;
    if (object && "maxCapacity" in object)
        maxCapacity = object.maxCapacity;
    return maxCapacity && month in maxCapacity
        ? maxCapacity[month].monthlyTurnovers
        : null;
}
exports.getTechLeadsRan = getTechLeadsRan;
/**
 * C144
 * ='Conv and Average Sale'!D72
 *
 * @param object
 * @param month
 */
function getConversionRateOnTechLeads(object, month) {
    let conversionAndAverageSale = null;
    if (object && "conversionAndAverageSale" in object)
        conversionAndAverageSale = object.conversionAndAverageSale;
    return conversionAndAverageSale && month in conversionAndAverageSale
        ? conversionAndAverageSale[month].budgetTechGeneratedClosingRate
        : null;
}
exports.getConversionRateOnTechLeads = getConversionRateOnTechLeads;
/**
 * C145
 * =C143*C144
 *
 * @param object
 * @param month
 */
function calcNumberJobsSoldOnTechLeads(object, month) {
    var _a, _b;
    const techLeadsRan = (_a = getTechLeadsRan(object, month)) !== null && _a !== void 0 ? _a : 0;
    const conversionRateOnTechLeads = (_b = getConversionRateOnTechLeads(object, month) / 100) !== null && _b !== void 0 ? _b : 0;
    if (!techLeadsRan || !conversionRateOnTechLeads)
        return 0;
    return techLeadsRan * conversionRateOnTechLeads;
}
exports.calcNumberJobsSoldOnTechLeads = calcNumberJobsSoldOnTechLeads;
/**
 * C146
 * ='Conv and Average Sale'!D84
 *
 * @param object
 * @param month
 */
function getAverageSaleOnTechLeads(object, month) {
    let conversionAndAverageSale = null;
    if (object && "conversionAndAverageSale" in object)
        conversionAndAverageSale = object.conversionAndAverageSale;
    return conversionAndAverageSale && month in conversionAndAverageSale
        ? conversionAndAverageSale[month].budgetedTechGeneratedLeadsAvgSale
        : null;
}
exports.getAverageSaleOnTechLeads = getAverageSaleOnTechLeads;
/**
 * C147
 * =+C145*C146
 *
 * @param object
 * @param month
 */
function calcTotalTechGeneratedRevenue(object, month) {
    var _a, _b;
    // TP-912: This is returning NaN and it is breaking in Dynamo
    const numberJobsSoldOnTechLeads = (_a = calcNumberJobsSoldOnTechLeads(object, month)) !== null && _a !== void 0 ? _a : 0;
    const averageSaleOnTechLeads = (_b = getAverageSaleOnTechLeads(object, month)) !== null && _b !== void 0 ? _b : 0;
    return numberJobsSoldOnTechLeads * averageSaleOnTechLeads;
}
exports.calcTotalTechGeneratedRevenue = calcTotalTechGeneratedRevenue;
/**
 * C149
 * ='Call Count'!D113
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getMarketingLeadsRan(object, month, companyData, workingDays) {
    return (0, workshop_1.requiredMarketLeadCountBasedOnStaffingCalc)(object, object.preWork, companyData, workingDays, month);
}
exports.getMarketingLeadsRan = getMarketingLeadsRan;
/**
 * C150
 * ='Conv and Average Sale'!D76
 *
 * @param object
 * @param month
 */
function getConversionRateOnMarketingLeads(object, month) {
    let conversionAndAverageSale = null;
    if (object && "conversionAndAverageSale" in object)
        conversionAndAverageSale = object.conversionAndAverageSale;
    return conversionAndAverageSale && month in conversionAndAverageSale
        ? conversionAndAverageSale[month].budgetedMarketedClosingRate
        : null;
}
exports.getConversionRateOnMarketingLeads = getConversionRateOnMarketingLeads;
/**
 * C151
 * =C149*C150
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcNumberJobsSoldOnMarketingLeads(object, month, companyData, workingDays) {
    var _a, _b;
    const marketingLeadsRan = (_a = getMarketingLeadsRan(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const conversionRateOnMarketingLeads = (_b = getConversionRateOnMarketingLeads(object, month) / 100) !== null && _b !== void 0 ? _b : 0;
    return marketingLeadsRan * conversionRateOnMarketingLeads;
}
exports.calcNumberJobsSoldOnMarketingLeads = calcNumberJobsSoldOnMarketingLeads;
/**
 * C152
 * ='Conv and Average Sale'!D88
 *
 * @param object
 * @param month
 */
function getAverageSaleOnMarketingLeads(object, month) {
    let conversionAndAverageSale = null;
    if (object && "conversionAndAverageSale" in object)
        conversionAndAverageSale = object.conversionAndAverageSale;
    return conversionAndAverageSale && month in conversionAndAverageSale
        ? conversionAndAverageSale[month].budgetedMarketedLeadsAvgSale
        : null;
}
exports.getAverageSaleOnMarketingLeads = getAverageSaleOnMarketingLeads;
/**
 * C153
 * =+C151*C152
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcTotalMarketingGeneratedRevenue(object, month, companyData, workingDays) {
    var _a, _b;
    const numberJobsSoldOnMarketingLeads = (_a = calcNumberJobsSoldOnMarketingLeads(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const averageSaleOnMarketingLeads = (_b = getAverageSaleOnMarketingLeads(object, month)) !== null && _b !== void 0 ? _b : 0;
    return numberJobsSoldOnMarketingLeads * averageSaleOnMarketingLeads;
}
exports.calcTotalMarketingGeneratedRevenue = calcTotalMarketingGeneratedRevenue;
/**
 * C155
 * ='Call Count'!D117
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getOtherLeadsRan(object, month, companyData, workingDays) {
    return (0, workshop_1.requiredOtherLeadCountBasedStaffingCalc)(object, object.preWork, companyData, workingDays, month);
}
exports.getOtherLeadsRan = getOtherLeadsRan;
/**
 * C156
 * ='Conv and Average Sale'!D80
 *
 * @param object
 * @param month
 */
function getConversionRateOnOtherLeads(object, month) {
    let conversionAndAverageSale = null;
    if (object && "conversionAndAverageSale" in object)
        conversionAndAverageSale = object.conversionAndAverageSale;
    return conversionAndAverageSale && month in conversionAndAverageSale
        ? conversionAndAverageSale[month].budgetedOtherLeadsClosingRate
        : null;
}
exports.getConversionRateOnOtherLeads = getConversionRateOnOtherLeads;
/**
 * C157
 * =IF(C155=0,0,C155*C156)
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcNumberJobsSoldOnOtherLeads(object, month, companyData, workingDays) {
    var _a, _b;
    const otherLeadsRan = (_a = getOtherLeadsRan(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const conversionRateOnOtherLeads = (_b = getConversionRateOnOtherLeads(object, month) / 100) !== null && _b !== void 0 ? _b : 0;
    return otherLeadsRan * conversionRateOnOtherLeads;
}
exports.calcNumberJobsSoldOnOtherLeads = calcNumberJobsSoldOnOtherLeads;
/**
 * C158
 * ='Conv and Average Sale'!D92
 *
 * @param object
 * @param month
 */
function getAverageSaleOnOtherLeads(object, month) {
    let conversionAndAverageSale = null;
    if (object && "conversionAndAverageSale" in object)
        conversionAndAverageSale = object.conversionAndAverageSale;
    return conversionAndAverageSale && month in conversionAndAverageSale
        ? conversionAndAverageSale[month].budgetedOtherLeadsAvgSale
        : null;
}
exports.getAverageSaleOnOtherLeads = getAverageSaleOnOtherLeads;
/**
 * C159
 * =+C157*C158
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcTotalOtherGeneratedRevenue(object, month, companyData, workingDays) {
    var _a, _b;
    const numberJobsSoldOnOtherLeads = (_a = calcNumberJobsSoldOnOtherLeads(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const averageSaleOnOtherLeads = (_b = getAverageSaleOnOtherLeads(object, month)) !== null && _b !== void 0 ? _b : 0;
    return numberJobsSoldOnOtherLeads * averageSaleOnOtherLeads;
}
exports.calcTotalOtherGeneratedRevenue = calcTotalOtherGeneratedRevenue;
/**
 * C162
 * =C143+C149+C155
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcSalesLeadsRanPerMonth(object, month, companyData, workingDays) {
    var _a, _b, _c;
    const techLeadsRan = (_a = getTechLeadsRan(object, month)) !== null && _a !== void 0 ? _a : 0;
    const marketingLeadsRan = (_b = getMarketingLeadsRan(object, month, companyData, workingDays)) !== null && _b !== void 0 ? _b : 0;
    const otherLeadsRan = (_c = getOtherLeadsRan(object, month, companyData, workingDays)) !== null && _c !== void 0 ? _c : 0;
    return techLeadsRan + marketingLeadsRan + otherLeadsRan;
}
exports.calcSalesLeadsRanPerMonth = calcSalesLeadsRanPerMonth;
/**
 * C163
 * ='Call Count'!D109
 *
 * @param object
 * @param month
 */
function getNumberOfSalesStaff(object, month) {
    let callCount = null;
    if (object && "callCount" in object)
        callCount = object.callCount;
    return callCount && month in callCount
        ? callCount[month].budgetedNumOfSalespersons
        : null;
}
exports.getNumberOfSalesStaff = getNumberOfSalesStaff;
/**
 * C164
 * =IFERROR(C162/working days in month/C163,0)
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcCallsPerSalesPersonPerDay(object, month, companyData, workingDays) {
    var _a, _b;
    const salesLeadsRanPerMonth = (_a = calcSalesLeadsRanPerMonth(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const numberOfSalesStaff = (_b = getNumberOfSalesStaff(object, month)) !== null && _b !== void 0 ? _b : 0;
    if (!salesLeadsRanPerMonth || !workingDays || !numberOfSalesStaff)
        return 0;
    return salesLeadsRanPerMonth / workingDays / numberOfSalesStaff;
}
exports.calcCallsPerSalesPersonPerDay = calcCallsPerSalesPersonPerDay;
/**
 * C166
 * =C157+C151+C145
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcJobsSold(object, month, companyData, workingDays) {
    var _a, _b, _c;
    const numberJobsSoldOnOtherLeads = (_a = calcNumberJobsSoldOnOtherLeads(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const numberJobsSoldOnMarketingLeads = (_b = calcNumberJobsSoldOnMarketingLeads(object, month, companyData, workingDays)) !== null && _b !== void 0 ? _b : 0;
    const numberJobsSoldOnTechLeads = (_c = calcNumberJobsSoldOnTechLeads(object, month)) !== null && _c !== void 0 ? _c : 0;
    return (numberJobsSoldOnOtherLeads +
        numberJobsSoldOnMarketingLeads +
        numberJobsSoldOnTechLeads);
}
exports.calcJobsSold = calcJobsSold;
/**
 * C165
 * =IFERROR(C166/C162,0)
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcConversionRate(object, month, companyData, workingDays) {
    var _a, _b;
    const jobsSold = (_a = calcJobsSold(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const salesLeadsRanPerMonth = (_b = calcSalesLeadsRanPerMonth(object, month, companyData, workingDays)) !== null && _b !== void 0 ? _b : 0;
    if (!jobsSold || !salesLeadsRanPerMonth)
        return 0;
    return jobsSold / salesLeadsRanPerMonth;
}
exports.calcConversionRate = calcConversionRate;
/**
 * C168
 * =C159+C153+C147
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcMonthlySales(object, month, companyData, workingDays) {
    var _a, _b, _c;
    const totalOtherGeneratedRevenue = (_a = calcTotalOtherGeneratedRevenue(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const totalMarketingGeneratedRevenue = (_b = calcTotalMarketingGeneratedRevenue(object, month, companyData, workingDays)) !== null && _b !== void 0 ? _b : 0;
    const totalTechGeneratedRevenue = (_c = calcTotalTechGeneratedRevenue(object, month)) !== null && _c !== void 0 ? _c : 0;
    return (totalOtherGeneratedRevenue +
        totalMarketingGeneratedRevenue +
        totalTechGeneratedRevenue);
}
exports.calcMonthlySales = calcMonthlySales;
/**
 * C167
 * =IFERROR(C168/C166,0)
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcAverageSale(object, month, companyData, workingDays) {
    var _a, _b;
    const monthlySales = (_a = calcMonthlySales(object, month, companyData, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const jobsSold = (_b = calcJobsSold(object, month, companyData, workingDays)) !== null && _b !== void 0 ? _b : 0;
    if (!monthlySales || !jobsSold)
        return 0;
    return monthlySales / jobsSold;
}
exports.calcAverageSale = calcAverageSale;
/**
 * C171
 * ='Call Count'!D135
 *
 * @param object
 * @param month
 */
function getNeededInstallationStaffing(object, month) {
    let callCount = null;
    if (object && "callCount" in object)
        callCount = object.callCount;
    return callCount && month in callCount
        ? callCount[month].budgetedNumOfCrews
        : null;
}
exports.getNeededInstallationStaffing = getNeededInstallationStaffing;
/**
 * C172
 * ='Direct Costs'!D139
 *
 * @param object
 * @param month
 */
function soldDaysInstalled(object, month) {
    let directCosts = null;
    if (object && "directCosts" in object)
        directCosts = object.directCosts;
    return directCosts && month in directCosts
        ? directCosts[month].soldDayPerMonthInstallGoal
        : null;
}
exports.soldDaysInstalled = soldDaysInstalled;
/**
 * C175
 * =IFERROR(C172/(C171*C$1),0)
 *
 * @param object
 * @param month
 * @param workingDays
 */
function calcSoldDayEfficiency(object, month, workingDays) {
    const daysInstalled = soldDaysInstalled(object, month);
    const neededInstallationStaffing = getNeededInstallationStaffing(object, month);
    if (!daysInstalled || !neededInstallationStaffing || !workingDays)
        return 0;
    return daysInstalled / (neededInstallationStaffing * workingDays);
}
exports.calcSoldDayEfficiency = calcSoldDayEfficiency;
