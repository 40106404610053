"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.big5Subcategories = void 0;
exports.big5Subcategories = {
    OFFICE_SALARY_BENEFITS: {
        OFFICE_SALARIES: "Office Salaries",
        OFFICE_PAYROLL_TAXES: "Office Payroll Taxes",
        RECRUITING_EXPENSE: "Recruiting Expense",
        OFFICE_HEALTH_INSURANCE_BENEFITS: "Office Health Insurance & Benefits",
        RETIREMENT_PLANS: "Retirement Plans",
    },
    ADVERTISING: {
        ADVERTISING_EXPENSES: "Advertising Expenses",
    },
    OTHER: {
        BAD_DEBTS: "Bad Debts",
        BANK_CREDIT_CARD_FEES: "Bank/Credit Card Fees",
        COMPUTER_SOFTWARE_SERVICE_EXPENSE: "Computer Software Service Expense",
        DEPRECIATION: "Depreciation",
        DONATIONS: "Donations",
        DUES_SUBSCRIPTIONS: "Dues and Subscriptions",
        INSURANCE: "Insurance",
        LICENSES_FEES: "Licenses and Fees",
        MISCELLANEOUS: "Miscellaneous",
        OFFICE_SUPPLIES_POSTAGE: "Office Supplies and Postage",
        PROFESSIONAL_FEES: "Professional Fees",
        RENT_BUILDING_REAL_ESTATE_TAXES: "Rent Building Real Estate Taxes",
        RENT_EQUIPMENT_UNIFORMS: "Rent Equipment and Uniforms",
        REPAIRS_MAINTENANCE: "Repairs and Maintenance",
        SMALL_TOOLS_SUPPLIES: "Small Tools and Supplies",
        TRAINING_EDUCATION: "Training and Education",
        TRAVEL_ENTERTAINMENT: "Travel and Entertainment",
        UTILITIES_TELEPHONE: "Utilities and Telephone",
    },
    VEHICLE: {
        VEHICLE_MAINTENANCE_REPAIRS: "Vehicle Maintenance and Repairs",
        FUEL: "Fuel",
        VEHICLE_LEASE_PAYMENTS_DEPRECIATION: "Vehicle Lease Payments/Depreciation",
        AUTO_INSURANCE_OTHER_VEHICLE: "Auto Insurance Other Vehicle",
    },
    DIRECT_LABOR: {},
    MATERIALS: {},
};
