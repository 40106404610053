import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./components/app/app";
import "react-tooltip/dist/react-tooltip.css";
import { InitializeGoogleAnalytics } from "./utils/ga/googleAnalytics";

const envForLogging = ["qa", "staging", "production"];
const container = document.getElementById("root");
const root = createRoot(container);
if (envForLogging.includes(process.env.REACT_APP_NODE_ENV)) {
  Sentry.init({
    beforeSend: (event, hint) => {
      const error = hint.originalException;
      //@ts-ignore
      if (error && error.message && error.message.match(/Login required/i)) {
        // do not send known issue
        return null;
      }
      return event;
    },
    dsn: "https://5248548dfd264407b6d7103bdd7a3afa@o280339.ingest.sentry.io/6662937",
    release: process.env.REACT_APP_TOOLS_VERSION,
    environment: process.env.REACT_APP_NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
if (process.env.REACT_APP_NODE_ENV === "production") {
  InitializeGoogleAnalytics();
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
