import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function FinancialSurveyIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m1139.02,137.49H61.89c-26.1,0-47.24,21.16-47.24,47.24v547.64c0,26.09,21.15,47.24,47.24,47.24h1077.13c26.1,0,47.24-21.16,47.24-47.24V184.73c0-26.1-21.15-47.24-47.24-47.24Zm-175.32,547.62H237.21c-16.77-62.33-65.74-111.31-128.08-128.08v-196.98c62.33-16.77,111.31-65.74,128.08-128.08h726.49c16.77,62.33,65.74,111.31,128.08,128.08v196.99c-62.33,16.76-111.3,65.74-128.07,128.07h-.01Z" />
      <path d="m789.3,426.24c17.06,105.09-54.31,204.1-159.39,221.16-105.1,17.05-204.11-54.32-221.16-159.41-17.06-105.09,54.31-204.11,159.39-221.16,105.1-17.06,204.11,54.31,221.16,159.41" />
      <path d="m1139.02,822.58H61.89c-26.1,0-47.24,21.16-47.24,47.24s21.15,47.24,47.24,47.24h1077.13c26.1,0,47.24-21.16,47.24-47.24s-21.15-47.24-47.24-47.24Z" />
      <path d="m1139.02,960.02H61.89c-26.1,0-47.24,21.16-47.24,47.24s21.15,47.24,47.24,47.24h1077.13c26.1,0,47.24-21.16,47.24-47.24s-21.15-47.24-47.24-47.24Z" />
    </svg>
  );
}
