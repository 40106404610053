"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleDirectCostsToSave = exports.soldHourPerDayServiceGoalCalc = exports.soldHourPerMonthServiceGoalCalc = exports.soldDaysPerDayInstallGoalCalc = exports.soldDayPerMonthInstallGoalCalc = exports.grossProfitCurrencyCalc = exports.totalDirectCostCurrencyCalc = exports.budgetedOtherCurrencyCalc = exports.budgetedSaleExpCurrencyCalc = exports.budgetedMaterialPercCurrencyCalc = exports.budgetedLaborPercCurrencyCalc = exports.totalIncomeCurrencyCalculation = exports.sppRevenueCurrencyCalculation = exports.financeFeePercentCurrencyCalculation = exports.refundPercCurrencyCalculation = exports.subcontractorPercCurrencyCalculation = void 0;
const utils_1 = require("../../../../utils");
function getMonthlyDirectCostData(workshopData, month) {
    let monthlyDirectCostData = null;
    if (workshopData &&
        "directCosts" in workshopData &&
        month in workshopData.directCosts) {
        monthlyDirectCostData = workshopData.directCosts[month];
    }
    return monthlyDirectCostData;
}
/**
 * D183
 * @param workshopData
 * @param month
 */
const subcontractorPercCurrencyCalculation = (workshopData, month) => {
    const monthlyDirectCostData = getMonthlyDirectCostData(workshopData, month);
    if (!monthlyDirectCostData)
        return 0;
    if (workshopData.callCount[month].monthlyTotalRevenueCalc) {
        const subcontractorPercent = workshopData.directCosts.directCostsYearly.subcontractorPercInput / 100;
        return (workshopData.callCount[month].monthlyTotalRevenueCalc *
            subcontractorPercent);
    }
    return 0;
};
exports.subcontractorPercCurrencyCalculation = subcontractorPercCurrencyCalculation;
/**
 * D184
 * @param workshopData
 * @param month
 */
const refundPercCurrencyCalculation = (workshopData, month) => {
    const monthlyDirectCostData = getMonthlyDirectCostData(workshopData, month);
    if (!monthlyDirectCostData)
        return 0;
    if (workshopData.callCount[month].monthlyTotalRevenueCalc) {
        const refundPercent = workshopData.directCosts.directCostsYearly.refundPercInput / 100;
        return (refundPercent * workshopData.callCount[month].monthlyTotalRevenueCalc);
    }
    return 0;
};
exports.refundPercCurrencyCalculation = refundPercCurrencyCalculation;
/**
 * D185
 * @param workshopData
 * @param month
 */
const financeFeePercentCurrencyCalculation = (workshopData, month) => {
    const monthlyDirectCostData = getMonthlyDirectCostData(workshopData, month);
    if (!monthlyDirectCostData)
        return 0;
    if (workshopData.callCount[month].monthlyTotalRevenueCalc) {
        const financeFeePercent = workshopData.directCosts.directCostsYearly.financeFeePercInput / 100;
        return (financeFeePercent * workshopData.callCount[month].monthlyTotalRevenueCalc);
    }
    return 0;
};
exports.financeFeePercentCurrencyCalculation = financeFeePercentCurrencyCalculation;
/**
 * D147
 * @param workshopData
 * @param month
 */
const sppRevenueCurrencyCalculation = (workshopData, month) => {
    if (workshopData &&
        workshopData.serviceAgreements &&
        workshopData.serviceAgreements[month]) {
        if (!workshopData.serviceAgreementsMain.areAgreementsRecognizedWhenVisitsRan) {
            return workshopData.serviceAgreements[month]
                .totalRevenueMonthlyRecognition;
        }
        if (workshopData.serviceAgreementsMain.areAgreementsRecognizedWhenVisitsRan) {
            // TODO: HIGH Need to make sure that this still works correctly here is the update code.
            /**
             * if (!workshopData?.serviceAgreements?.departmentServiceAgreements[0]?.visitsAtTimeOfSale) {
             */
            if (!workshopData.serviceAgreements.visitsAtTimeOfSale) {
                return workshopData.serviceAgreements[month]
                    .totalRevenueTimeOfVisit_Visit6MonthFromSale;
            }
            return workshopData.serviceAgreements[month]
                .totalRevenueTimeOfVisit_VisitPerformedTimeOfSale;
        }
    }
    return 0;
};
exports.sppRevenueCurrencyCalculation = sppRevenueCurrencyCalculation;
/**
 * D187
 * Total Income Calc for Install && Service Depts
 * @param workshopData
 * @param departmentType
 * @param month
 */
const totalIncomeCurrencyCalculation = (workshopData, departmentType, month) => {
    if (departmentType === utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT) {
        return (workshopData.callCount[month].monthlyTotalRevenueCalc -
            ((0, exports.subcontractorPercCurrencyCalculation)(workshopData, month) +
                (0, exports.refundPercCurrencyCalculation)(workshopData, month) +
                (0, exports.financeFeePercentCurrencyCalculation)(workshopData, month)));
    }
    const sppRevenue = (0, exports.sppRevenueCurrencyCalculation)(workshopData, month);
    return (workshopData.callCount[month].monthlyTotalRevenueCalc +
        sppRevenue -
        ((0, exports.subcontractorPercCurrencyCalculation)(workshopData, month) +
            (0, exports.refundPercCurrencyCalculation)(workshopData, month) +
            (0, exports.financeFeePercentCurrencyCalculation)(workshopData, month)));
};
exports.totalIncomeCurrencyCalculation = totalIncomeCurrencyCalculation;
/**
 * D191
 * @param workshopData
 * @param departmentType
 * @param month
 */
const budgetedLaborPercCurrencyCalc = (workshopData, departmentType, month) => {
    const monthlyDirectCostData = getMonthlyDirectCostData(workshopData, month);
    if (!monthlyDirectCostData)
        return 0;
    if (workshopData.directCosts[month].budgetedLaborPerInput) {
        const budgetedLaborPercent = workshopData.directCosts[month].budgetedLaborPerInput / 100;
        return (budgetedLaborPercent *
            (0, exports.totalIncomeCurrencyCalculation)(workshopData, departmentType, month));
    }
    return 0;
};
exports.budgetedLaborPercCurrencyCalc = budgetedLaborPercCurrencyCalc;
/**
 * D194
 * @param workshopData
 * @param departmentType
 * @param month
 */
const budgetedMaterialPercCurrencyCalc = (workshopData, departmentType, month) => {
    if (workshopData.directCosts[month].budgetedMaterialPerInput) {
        const budgetedMaterialPercent = workshopData.directCosts[month].budgetedMaterialPerInput / 100;
        return (budgetedMaterialPercent *
            (0, exports.totalIncomeCurrencyCalculation)(workshopData, departmentType, month));
    }
    return 0;
};
exports.budgetedMaterialPercCurrencyCalc = budgetedMaterialPercCurrencyCalc;
/**
 * D197
 * @param workshopData
 * @param departmentType
 * @param month
 */
const budgetedSaleExpCurrencyCalc = (workshopData, departmentType, month) => {
    if (workshopData.directCosts[month].budgetedSalesExpPercInput) {
        const budgetedSalesPercent = workshopData.directCosts[month].budgetedSalesExpPercInput / 100;
        return (budgetedSalesPercent *
            (0, exports.totalIncomeCurrencyCalculation)(workshopData, departmentType, month));
    }
    return 0;
};
exports.budgetedSaleExpCurrencyCalc = budgetedSaleExpCurrencyCalc;
/**
 * D200
 * @param workshopData
 * @param departmentType
 * @param month
 */
const budgetedOtherCurrencyCalc = (workshopData, departmentType, month) => {
    if (workshopData.directCosts[month].budgetedOtherDirectPercInput) {
        const budgetedOtherPercent = workshopData.directCosts[month].budgetedOtherDirectPercInput / 100;
        return (budgetedOtherPercent *
            (0, exports.totalIncomeCurrencyCalculation)(workshopData, departmentType, month));
    }
    return 0;
};
exports.budgetedOtherCurrencyCalc = budgetedOtherCurrencyCalc;
/**
 * D202
 * @param workshopData
 * @param departmentType
 * @param month
 */
const totalDirectCostCurrencyCalc = (workshopData, departmentType, month) => {
    if (workshopData &&
        workshopData.directCosts &&
        workshopData.directCosts[month]) {
        const budgetedLaborCurrency = (0, exports.budgetedLaborPercCurrencyCalc)(workshopData, departmentType, month);
        const budgetedMaterialCurrency = (0, exports.budgetedMaterialPercCurrencyCalc)(workshopData, departmentType, month);
        const budgetedSalesExpCurrency = (0, exports.budgetedSaleExpCurrencyCalc)(workshopData, departmentType, month);
        const budgetedOtherDirectCurrency = (0, exports.budgetedOtherCurrencyCalc)(workshopData, departmentType, month);
        return (budgetedLaborCurrency +
            budgetedMaterialCurrency +
            budgetedSalesExpCurrency +
            budgetedOtherDirectCurrency);
    }
};
exports.totalDirectCostCurrencyCalc = totalDirectCostCurrencyCalc;
/**
 * D204
 * @param workshopData
 * @param departmentType
 * @param month
 */
const grossProfitCurrencyCalc = (workshopData, departmentType, month) => {
    const totalDirectCost = (0, exports.totalDirectCostCurrencyCalc)(workshopData, departmentType, month);
    const totalIncome = (0, exports.totalIncomeCurrencyCalculation)(workshopData, departmentType, month);
    return totalIncome - totalDirectCost;
};
exports.grossProfitCurrencyCalc = grossProfitCurrencyCalc;
/**
 * D209
 * @param workshopData
 * @param departmentType
 * @param month
 */
const soldDayPerMonthInstallGoalCalc = (workshopData, departmentType, month) => {
    if (workshopData.directCosts.directCostsYearly
        .desiredProfitPlusCommissionInput &&
        workshopData.directCosts.directCostsYearly.soldProfitAndCommissionInput) {
        const desiredProfitPlusCommission = workshopData.directCosts.directCostsYearly
            .desiredProfitPlusCommissionInput / 100;
        return (((0, exports.totalIncomeCurrencyCalculation)(workshopData, departmentType, month) -
            (0, exports.budgetedMaterialPercCurrencyCalc)(workshopData, departmentType, month) /
                (1 - desiredProfitPlusCommission)) /
            workshopData.directCosts.directCostsYearly.soldProfitAndCommissionInput);
    }
    return 0;
};
exports.soldDayPerMonthInstallGoalCalc = soldDayPerMonthInstallGoalCalc;
/**
 * D210
 * @param workshopData
 * @param company
 * @param departmentType
 * @param month
 * @param decreasedDays
 */
const soldDaysPerDayInstallGoalCalc = (workshopData, company, departmentType, month, decreasedDays) => {
    const workingDays = (0, utils_1.getWorkingDaysPerMonth)(company === null || company === void 0 ? void 0 : company.weeklyWorkdays, Number(company === null || company === void 0 ? void 0 : company.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), company.fiscalYearEnd, company === null || company === void 0 ? void 0 : company.nonWorkingDays, decreasedDays);
    if (workingDays) {
        return ((0, exports.soldDayPerMonthInstallGoalCalc)(workshopData, departmentType, month) /
            workingDays);
    }
    return 0;
};
exports.soldDaysPerDayInstallGoalCalc = soldDaysPerDayInstallGoalCalc;
/**
 * D175
 * @param workshopData
 * @param profitPricer
 * @param departmentType
 * @param month
 */
const soldHourPerMonthServiceGoalCalc = (workshopData, profitPricer, departmentType, month) => {
    let breakEvenPrice;
    let profitPercentOnMaterials;
    if (profitPricer) {
        breakEvenPrice = profitPricer.breakEvenSellingPrice;
        profitPercentOnMaterials = profitPricer.desiredNetProfit / 100;
    }
    else {
        breakEvenPrice =
            workshopData.directCosts.directCostsYearly.soldBreakEvenFromPPInput || 0;
        profitPercentOnMaterials =
            (workshopData.directCosts.directCostsYearly.profitPercOnMaterialsInput ||
                0) / 100;
    }
    if (profitPercentOnMaterials && breakEvenPrice) {
        // Making this null safe
        return (((0, exports.totalIncomeCurrencyCalculation)(workshopData, departmentType, month) -
            (0, exports.budgetedMaterialPercCurrencyCalc)(workshopData, departmentType, month) /
                (1 - (profitPercentOnMaterials || 0))) /
            breakEvenPrice);
    }
    return 0;
};
exports.soldHourPerMonthServiceGoalCalc = soldHourPerMonthServiceGoalCalc;
/**
 * D176
 * @param workshopData
 * @param company
 * @param profitPricer
 * @param departmentType
 * @param month
 * @param decreasedDays
 */
const soldHourPerDayServiceGoalCalc = (workshopData, company, profitPricer, departmentType, month, decreasedDays) => {
    const workingDays = (0, utils_1.getWorkingDaysPerMonth)(company === null || company === void 0 ? void 0 : company.weeklyWorkdays, Number(company === null || company === void 0 ? void 0 : company.fiscalYear), Number((0, utils_1.shortMonthsToValue)(month)), company.fiscalYearEnd, company === null || company === void 0 ? void 0 : company.nonWorkingDays, decreasedDays);
    if (workingDays) {
        const soldHourPerMonth = (0, exports.soldHourPerMonthServiceGoalCalc)(workshopData, profitPricer, departmentType, month);
        return soldHourPerMonth / workingDays;
    }
    return 0;
};
exports.soldHourPerDayServiceGoalCalc = soldHourPerDayServiceGoalCalc;
const handleDirectCostsToSave = (workshopData, profitPricer, companyData, departmentType) => {
    const months = (0, utils_1.createAllMonthsArray)(companyData.fiscalYear, companyData.fiscalYearEnd);
    const newObjToSave = workshopData.directCosts;
    months.forEach((month) => {
        newObjToSave[month].totalIncome = null;
        const modifiedObj = newObjToSave[month];
        Object.keys(newObjToSave[month]).forEach((key) => {
            switch (key) {
                case "grossIncome":
                    if (departmentType === utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT) {
                        modifiedObj[key] =
                            workshopData.callCount[month].monthlyTotalRevenueCalc;
                    }
                    else {
                        modifiedObj[key] = null;
                    }
                    break;
                case "demandCallSales":
                    if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                        modifiedObj[key] =
                            workshopData.callCount[month].monthlyDemandCallRevenue;
                    }
                    else {
                        modifiedObj[key] = null;
                    }
                    break;
                case "demandSystemCheckCallSales":
                    if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                        modifiedObj[key] =
                            workshopData.callCount[month].monthlyDemandSystemCheckCallRevenue;
                    }
                    else {
                        modifiedObj[key] = null;
                    }
                    break;
                case "systemCheckCallSales":
                    if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                        modifiedObj[key] =
                            workshopData.callCount[month].monthlySystemCheckCallRevenue;
                    }
                    else {
                        modifiedObj[key] = null;
                    }
                    break;
                case "demandAndSystemCheckSales":
                    if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                        modifiedObj[key] =
                            workshopData.callCount[month].monthlyTotalRevenueCalc;
                    }
                    else {
                        modifiedObj[key] = null;
                    }
                    break;
                case "sppRevenue":
                    if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                        modifiedObj[key] = (0, exports.sppRevenueCurrencyCalculation)(workshopData, month);
                    }
                    else {
                        modifiedObj[key] = null;
                    }
                    break;
                case "subcontractorAmount":
                    modifiedObj[key] = (0, exports.subcontractorPercCurrencyCalculation)(workshopData, month);
                    break;
                case "refundAmount":
                    modifiedObj[key] = (0, exports.refundPercCurrencyCalculation)(workshopData, month);
                    break;
                case "financeFeeAmount":
                    modifiedObj[key] = (0, exports.financeFeePercentCurrencyCalculation)(workshopData, month);
                    break;
                case "totalIncome":
                    modifiedObj[key] = (0, exports.totalIncomeCurrencyCalculation)(workshopData, departmentType, month);
                    break;
                case "budgetedLaborAmount":
                    modifiedObj[key] = (0, exports.budgetedLaborPercCurrencyCalc)(workshopData, departmentType, month);
                    break;
                case "budgetedMaterialAmount":
                    modifiedObj[key] = (0, exports.budgetedMaterialPercCurrencyCalc)(workshopData, departmentType, month);
                    break;
                case "budgetedSalesExpAmount":
                    modifiedObj[key] = (0, exports.budgetedSaleExpCurrencyCalc)(workshopData, departmentType, month);
                    break;
                case "budgetedOtherDirectAmount":
                    modifiedObj[key] = (0, exports.budgetedOtherCurrencyCalc)(workshopData, departmentType, month);
                    break;
                case "totalDirectCost":
                    modifiedObj[key] = (0, exports.totalDirectCostCurrencyCalc)(workshopData, departmentType, month);
                    break;
                case "grossProfit":
                    modifiedObj[key] = (0, exports.grossProfitCurrencyCalc)(workshopData, departmentType, month);
                    break;
                case "soldHourPerMonthGoal":
                    if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                        modifiedObj[key] = (0, exports.soldHourPerMonthServiceGoalCalc)(workshopData, profitPricer, departmentType, month);
                    }
                    else {
                        modifiedObj[key] = null;
                    }
                    break;
                case "soldDayPerMonthInstallGoal":
                    if (departmentType === utils_1.DepartmentTypeEnum.INSTALL_DEPARTMENT) {
                        modifiedObj[key] = (0, exports.soldDayPerMonthInstallGoalCalc)(workshopData, departmentType, month);
                    }
                    else {
                        modifiedObj[key] = null;
                    }
                    break;
                default:
            }
        });
    });
    Object.keys(newObjToSave.directCostsYearly).forEach((key) => {
        if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT &&
            profitPricer) {
            newObjToSave.directCostsYearly.pricerId = profitPricer.pricerId;
            newObjToSave.directCostsYearly.pricerDepartmentId =
                profitPricer.pricerDepartmentId;
            // newObjToSave.directCostsYearly.pricerDepartment = profitPricer.pricerDepartment;
        }
        switch (key) {
            case "soldBreakEvenFromPPInput":
                if (profitPricer &&
                    departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                    newObjToSave.directCostsYearly[key] =
                        profitPricer.breakEvenSellingPrice;
                }
                break;
            case "profitPercOnMaterialsInput":
                if (profitPricer &&
                    departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
                    newObjToSave.directCostsYearly[key] = profitPricer.desiredNetProfit;
                }
                break;
            default:
        }
    });
    return { ...workshopData, directCosts: newObjToSave };
};
exports.handleDirectCostsToSave = handleDirectCostsToSave;
