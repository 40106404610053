import React, { ReactElement } from "react";
import NexstarTooltip from "./NexstarTooltip";

type CardHeaderProps = {
  children?: React.ReactNode;
  bgColor?: string;
  border?: string;
  borderColor?: string;
  fontWeight?: string;
  headingTextColor?: string;
  headingTextSize?: string;
  id?: string;
  dataTestId?: string;
  justify?: string;
  title: string | JSX.Element;
  tooltip?: string;
  toolTipColor?: "white" | "gray";
};

export default function CardHeader({
  title,
  children,
  bgColor = "bg-NexstarBlue",
  border = "border-0",
  borderColor = "",
  headingTextColor = "text-white",
  headingTextSize = "text-2xl",
  id,
  dataTestId,
  justify = "justify-between",
  fontWeight = "font-bold",
  tooltip,
  toolTipColor = "gray",
}: CardHeaderProps): ReactElement {
  return (
    <div
      className={`${bgColor} ${border} ${borderColor}`}
      id={id}
      data-testid={dataTestId}
    >
      <div className="mx-auto px-5">
        <div
          className={`flex gap-2 w-full items-center min-h-[62px] ${justify} py-3`}
        >
          <h2
            className={`flex items-center ${headingTextColor} ${headingTextSize} leading-6 ${fontWeight}`}
          >
            {title}
          </h2>
          {tooltip ? (
            <NexstarTooltip content={tooltip} color={toolTipColor} />
          ) : null}
          {/*// JAH: Doing this as if there were no children the spacing was wonky.*/}
          {children ? (
            <div className="flex ml-10 gap-4 flex-grow justify-end items-center">
              {children}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
