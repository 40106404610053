import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function ChevronDownIcon({
  size = "medium",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m453.66,146.75c-11.51-11.51-30.17-11.51-41.68,0l-155.98,155.98L100.01,146.75c-11.51-11.51-30.17-11.51-41.68,0s-11.51,30.17,0,41.68l176.83,176.83c11.51,11.51,30.17,11.51,41.68,0l176.83-176.83c11.51-11.51,11.51-30.17,0-41.68h-.01Z" />
    </svg>
  );
}
