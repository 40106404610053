"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToFEObject = exports.processFilteredItems = exports.mpwWorkshopObjectSplitter2 = exports.getGroupDataForBE = exports.getFMPDataForBE = exports.getBaseDataForBE = exports.processMPWForFE = exports.processMPWForBE = exports.generateFMPKey = exports.generateGroupSK = exports.mpwPreSaveProcessing = void 0;
const ulid_1 = require("ulid");
const utils_1 = require("../utils");
const mpwInitData_1 = require("./mpwInitData");
const luxon_1 = require("luxon");
function mpwPreSaveProcessing(dataObj) {
    //TODO: need to type this
    const itemsToRemove = [];
    mpwInitData_1.orderMarketingWorksheetSections.map((section) => {
        dataObj[section]["items"].map((item) => {
            // TODO TP-1850: what happens if use is false?
            if (item.use) {
                if ((0, utils_1.empty)(item.numberOfPrograms)) {
                    // BK -- if they don't select a number of programs, default to 1
                    item.numberOfPrograms = 1;
                }
                if ((0, utils_1.empty)(item.futureMarketingPlan) && !(0, utils_1.empty)(item.numberOfPrograms)) {
                    // BK -- building the future marketing plan array based on the # of programs they selected if the futureMarketingPlan array is empty and dividing the Annual Costs among the campaigns
                    const newArray = [];
                    for (let i = 0; i < item.numberOfPrograms; i++) {
                        newArray.push((0, utils_1.cloneDeep)(
                        //TODO TP-1850: updated this to build the new id format
                        (0, mpwInitData_1.createFutureMarketingPlanItem)(item.memberId, item.fiscalYear, item.secondaryId, (0, ulid_1.ulid)(), i.toString())));
                        newArray[i].estimatedAnnualCampaignCost =
                            item.totalAnnualCosts / item.numberOfPrograms;
                    }
                    item.futureMarketingPlan = [...newArray];
                }
                else if (item.futureMarketingPlan.length > item.numberOfPrograms &&
                    item.numberOfPrograms !== 0) {
                    // BK -- removing items from the end of the array if they decrease the number of programs
                    const numberOfItemsToRemove = item.futureMarketingPlan.length - item.numberOfPrograms;
                    const removedItems = item.futureMarketingPlan.splice(item.futureMarketingPlan.length - numberOfItemsToRemove, numberOfItemsToRemove);
                    itemsToRemove.push(...removedItems);
                    // BK -- Evenly divide the Annual Costs among the remaining campaigns
                    [...Array(item.futureMarketingPlan.length).keys()].map((idx) => {
                        item.futureMarketingPlan[idx]["estimatedAnnualCampaignCost"] =
                            item.totalAnnualCosts / item.futureMarketingPlan.length;
                    });
                }
                else if (item.futureMarketingPlan.length > item.numberOfPrograms &&
                    item.numberOfPrograms === 0) {
                    // BK -- if you are going from 1 to no selection for the number of programs, you need to set this futureMarketingPlan back to an empty array
                    item.futureMarketingPlan = [];
                }
                else if (item.futureMarketingPlan.length < item.numberOfPrograms) {
                    // BK -- adding items to the end of the array if they increase the number of programs
                    const numberOfItemsToAdd = item.numberOfPrograms - item.futureMarketingPlan.length;
                    const itemsToAdd = [];
                    for (let i = 0; i < numberOfItemsToAdd; i++) {
                        //TODO TP-1850: updated this to build the new id format
                        itemsToAdd.push((0, utils_1.cloneDeep)((0, mpwInitData_1.createFutureMarketingPlanItem)(item.memberId, item.fiscalYear, item.secondaryId, (0, ulid_1.ulid)(), (item.futureMarketingPlan.length + i).toString())));
                        itemsToAdd[i].estimatedAnnualCampaignCost = 0;
                    }
                    item.futureMarketingPlan.push(...itemsToAdd);
                    // BK -- Evenly divide the Annual Costs among the campaigns
                    [...Array(item.futureMarketingPlan.length).keys()].map((idx) => {
                        item.futureMarketingPlan[idx]["estimatedAnnualCampaignCost"] =
                            item.totalAnnualCosts / item.futureMarketingPlan.length;
                    });
                }
            }
        });
    });
    return { dataObj, itemsToRemove };
}
exports.mpwPreSaveProcessing = mpwPreSaveProcessing;
/**
 *
 * @param base in format MPW<fiscalYear>#WORKSHOP
 * @param groupKey
 * @param identifier this will be a groupId or and index id SK or LSI1SK respectively
 */
function generateGroupSK(base, groupKey, identifier) {
    return `${base}#${groupKey.toUpperCase()}#${identifier}`;
}
exports.generateGroupSK = generateGroupSK;
/**
 *
 * @param base in format MPW<fiscalYear>#WORKSHOP#<group>#<groupIndex>
 * @param identifier this will be a fmpId or and index id SK or LSI1SK respectively
 */
function generateFMPKey(base, identifier) {
    return `${base}#FMP#${identifier}`;
}
exports.generateFMPKey = generateFMPKey;
function processMPWForBE(item) {
    var _a, _b;
    const dynamoObject = (0, utils_1.cloneDeep)(item);
    dynamoObject.PK = item.memberId;
    if (!(0, utils_1.objectFieldValidator)(item, ["id"]) || !item.id.includes("#")) {
        dynamoObject.SK = null;
    }
    else
        dynamoObject.SK = (_a = item.id) !== null && _a !== void 0 ? _a : null;
    dynamoObject.LSI1SK = (_b = item.secondaryId) !== null && _b !== void 0 ? _b : null;
    if ("memberId" in dynamoObject)
        delete dynamoObject.memberId;
    if ("id" in dynamoObject)
        delete dynamoObject.id;
    if ("secondaryId" in dynamoObject)
        delete dynamoObject.secondaryId;
    return dynamoObject;
}
exports.processMPWForBE = processMPWForBE;
function processMPWForFE(item) {
    var _a, _b;
    const feObject = (0, utils_1.cloneDeep)(item);
    feObject.memberId = item.PK;
    feObject.id = (_a = item.SK) !== null && _a !== void 0 ? _a : null;
    feObject.secondaryId = (_b = item.LSI1SK) !== null && _b !== void 0 ? _b : null;
    if ("PK" in feObject)
        delete feObject.PK;
    if ("SK" in feObject)
        delete feObject.SK;
    if ("LSI1SK" in feObject)
        delete feObject.LSI1SK;
    return feObject;
}
exports.processMPWForFE = processMPWForFE;
function getBaseDataForBE(itemToSplit) {
    var _a, _b, _c;
    return {
        PK: itemToSplit.PK,
        SK: itemToSplit.SK,
        LSI1SK: itemToSplit.SK,
        fiscalYear: itemToSplit.fiscalYear,
        fiscalYearEnd: (_a = itemToSplit.fiscalYearEnd) !== null && _a !== void 0 ? _a : null,
        annualMarketingBudget: itemToSplit.marketingWorksheet.annualMarketingBudget,
        basicsCanAdd: (0, utils_1.objectFieldValidator)(itemToSplit, ["marketingWorksheet", "basics", "canAdd"], true),
        retainAndGrowCurrentCustomersCanAdd: (0, utils_1.objectFieldValidator)(itemToSplit, ["marketingWorksheet", "retainAndGrowCurrentCustomers", "canAdd"], true),
        guerrillaMarketingIdeasCanAdd: (0, utils_1.objectFieldValidator)(itemToSplit, ["marketingWorksheet", "guerrillaMarketingIdeas", "canAdd"], true),
        massMarketingVehiclesCanAdd: (0, utils_1.objectFieldValidator)(itemToSplit, ["marketingWorksheet", "massMarketingVehicles", "canAdd"], true),
        digitalMarketingCanAdd: (0, utils_1.objectFieldValidator)(itemToSplit, ["marketingWorksheet", "digitalMarketing", "canAdd"], true),
        additionalIdeasCanAdd: (0, utils_1.objectFieldValidator)(itemToSplit, ["marketingWorksheet", "additionalIdeas", "canAdd"], true),
        createdAt: (_b = itemToSplit.createdAt) !== null && _b !== void 0 ? _b : luxon_1.DateTime.now().toUTC().toISO(),
        updatedAt: (_c = itemToSplit.updatedAt) !== null && _c !== void 0 ? _c : luxon_1.DateTime.now().toUTC().toISO(),
    };
}
exports.getBaseDataForBE = getBaseDataForBE;
function getFMPDataForBE(itemsToSplit, PK, lsiSKBase, fiscalYear) {
    const fmpItems = [];
    itemsToSplit.forEach((item, idx) => {
        var _a;
        // Need to remove this here.
        // delete item.id;
        let processedItem = {
            ...processMPWForBE(item),
            PK,
            fiscalYear,
            fmpId: (_a = (0, utils_1.objectFieldValidator)(item, ["fmpId"], true)) !== null && _a !== void 0 ? _a : (0, ulid_1.ulid)(),
        };
        if ((0, utils_1.empty)(processedItem.SK)) {
            processedItem = {
                ...processedItem,
                SK: generateFMPKey(lsiSKBase, processedItem.fmpId),
            };
        }
        if ((0, utils_1.empty)(processedItem.LSI1SK)) {
            processedItem = {
                ...processedItem,
                LSI1SK: generateFMPKey(lsiSKBase, idx.toString()),
            };
        }
        fmpItems.push(processedItem);
    });
    return fmpItems;
}
exports.getFMPDataForBE = getFMPDataForBE;
function getGroupDataForBE(itemsToSplit, group, PK, skBase, fiscalYear) {
    const groupData = [];
    const fmpData = [];
    itemsToSplit.forEach((item, idx) => {
        var _a;
        const processedItem = {
            ...processMPWForBE(item),
            fiscalYear,
            tactic: item.tactic,
            notes: item.notes,
            numberOfPrograms: item.numberOfPrograms,
            totalAnnualCosts: item.totalAnnualCosts,
            priority: item.priority,
            use: item.use,
            custom: item.custom,
            groupId: (_a = (0, utils_1.objectFieldValidator)(item, ["groupId"], true)) !== null && _a !== void 0 ? _a : (0, ulid_1.ulid)(),
        };
        if ((0, utils_1.empty)(processedItem.PK)) {
            processedItem.PK = PK;
        }
        if ((0, utils_1.empty)(processedItem.SK)) {
            processedItem.SK = generateGroupSK(skBase, group, processedItem.groupId);
        }
        if ((0, utils_1.empty)(processedItem.LSI1SK)) {
            processedItem.LSI1SK = generateGroupSK(skBase, group, idx.toString());
        }
        // processedItem = {
        //   ...processedItem,
        //   SK: `${skBase}#${group.toUpperCase()}#${processedItem.groupId}`,
        // };
        groupData.push(processedItem);
        const fmpItems = getFMPDataForBE(item.futureMarketingPlan, PK, processedItem.LSI1SK, fiscalYear);
        fmpData.push(...fmpItems);
    });
    return {
        groupData,
        fmpData,
    };
}
exports.getGroupDataForBE = getGroupDataForBE;
function mpwWorkshopObjectSplitter2(itemToSplit) {
    const basics = itemToSplit.marketingWorksheet.basics;
    const retainAndGrowCurrentCustomers = itemToSplit.marketingWorksheet.retainAndGrowCurrentCustomers;
    const guerrillaMarketingIdeas = itemToSplit.marketingWorksheet.guerrillaMarketingIdeas;
    const massMarketingVehicles = itemToSplit.marketingWorksheet.massMarketingVehicles;
    const digitalMarketing = itemToSplit.marketingWorksheet.digitalMarketing;
    const additionalIdeas = itemToSplit.marketingWorksheet.additionalIdeas;
    const baseData = getBaseDataForBE(itemToSplit);
    const basicsData = getGroupDataForBE(basics.items, "basics", baseData.PK, baseData.SK, baseData.fiscalYear);
    const additionalIdeasData = getGroupDataForBE(additionalIdeas.items, "additionalIdeas", baseData.PK, baseData.SK, baseData.fiscalYear);
    const digitalMarketingData = getGroupDataForBE(digitalMarketing.items, "digitalMarketing", baseData.PK, baseData.SK, baseData.fiscalYear);
    const guerrillaMarketingIdeasData = getGroupDataForBE(guerrillaMarketingIdeas.items, "guerrillaMarketingIdeas", baseData.PK, baseData.SK, baseData.fiscalYear);
    const massMarketingVehiclesData = getGroupDataForBE(massMarketingVehicles.items, "massMarketingVehicles", baseData.PK, baseData.SK, baseData.fiscalYear);
    const retainAndGrowCurrentCustomersCampaignData = getGroupDataForBE(retainAndGrowCurrentCustomers.items, "retainAndGrowCurrentCustomers", baseData.PK, baseData.SK, baseData.fiscalYear);
    const resultObject = {
        additionalIdeasFMP: additionalIdeasData.fmpData,
        additionalIdeasItems: additionalIdeasData.groupData,
        base: getBaseDataForBE(itemToSplit),
        basicsItems: basicsData.groupData,
        basicsFMP: basicsData.fmpData,
        digitalMarketingFMP: digitalMarketingData.fmpData,
        digitalMarketingItems: digitalMarketingData.groupData,
        guerrillaMarketingIdeasFMP: guerrillaMarketingIdeasData.fmpData,
        guerrillaMarketingIdeasItems: guerrillaMarketingIdeasData.groupData,
        massMarketingVehiclesFMP: massMarketingVehiclesData.fmpData,
        massMarketingVehiclesItems: massMarketingVehiclesData.groupData,
        retainAndGrowCurrentCustomersCampaignFMP: retainAndGrowCurrentCustomersCampaignData.fmpData,
        retainAndGrowCurrentCustomersCampaignItems: retainAndGrowCurrentCustomersCampaignData.groupData,
    };
    const dataToReturn = [
        ...resultObject.additionalIdeasFMP,
        ...resultObject.additionalIdeasItems,
        resultObject.base,
        ...resultObject.basicsItems,
        ...resultObject.basicsFMP,
        ...resultObject.digitalMarketingFMP,
        ...resultObject.digitalMarketingItems,
        ...resultObject.guerrillaMarketingIdeasFMP,
        ...resultObject.guerrillaMarketingIdeasItems,
        ...resultObject.massMarketingVehiclesFMP,
        ...resultObject.massMarketingVehiclesItems,
        ...resultObject.retainAndGrowCurrentCustomersCampaignFMP,
        ...resultObject.retainAndGrowCurrentCustomersCampaignItems,
    ];
    const failedValidation = [];
    dataToReturn.forEach((item) => {
        if (!(0, utils_1.objectFieldValidator)(item, ["PK"]))
            failedValidation.push(item);
        if (!(0, utils_1.objectFieldValidator)(item, ["SK"]))
            failedValidation.push(item);
        if (!(0, utils_1.objectFieldValidator)(item, ["LSI1SK"]))
            failedValidation.push(item);
    });
    if (failedValidation.length === 0)
        return dataToReturn;
    else
        throw Error("nota all data has correct keys");
}
exports.mpwWorkshopObjectSplitter2 = mpwWorkshopObjectSplitter2;
function processFilteredItems(baseObject, filteredItems, key) {
    filteredItems.forEach((item) => {
        const splitKey = item.LSI1SK.split("#");
        // this is adding to the item;
        if (splitKey.length === 5) {
            const groupIdx = parseInt(splitKey[4], 10);
            while (baseObject.marketingWorksheet[key].items.length - 1 < groupIdx) {
                baseObject.marketingWorksheet[key].items.push(null);
            }
            // if (baseObject.marketingWorksheet[key].items.length === groupIdx) {
            //   baseObject.marketingWorksheet[key].items.splice(groupIdx, 0, {
            //     ...processMPWForFE(item),
            //     futureMarketingPlan: [],
            //   });
            // }
            baseObject.marketingWorksheet[key].items[groupIdx] = {
                ...processMPWForFE(item),
                futureMarketingPlan: [],
            };
        }
        // we will now add the FMP Items to the
        else if (splitKey.length === 7) {
            const groupIdx = parseInt(splitKey[4], 10);
            const fmpIdx = parseInt(splitKey[6], 10);
            while (baseObject.marketingWorksheet[key].items[groupIdx].futureMarketingPlan
                .length -
                1 <
                fmpIdx) {
                baseObject.marketingWorksheet[key].items[groupIdx].futureMarketingPlan.push(null);
            }
            baseObject.marketingWorksheet[key].items[groupIdx].futureMarketingPlan[fmpIdx] = processMPWForFE(item);
        }
    });
}
exports.processFilteredItems = processFilteredItems;
function convertToFEObject(allTheBEItems) {
    var _a, _b, _c;
    const baseObject = {
        PK: null,
        SK: null,
        LSI1SK: null,
        fiscalYear: null,
        fiscalYearEnd: null,
        marketingWorksheet: {
            annualMarketingBudget: 0,
            basics: {
                items: [],
                canAdd: false,
            },
            retainAndGrowCurrentCustomers: {
                items: [],
                canAdd: false,
            },
            guerrillaMarketingIdeas: {
                items: [],
                canAdd: false,
            },
            massMarketingVehicles: {
                items: [],
                canAdd: false,
            },
            digitalMarketing: {
                items: [],
                canAdd: false,
            },
            additionalIdeas: {
                items: [],
                canAdd: false,
            },
        },
        updatedAt: null,
        createdAt: null,
    };
    const orderedItems = allTheBEItems.sort((a, b) => {
        {
            const skA = a.LSI1SK;
            const skB = b.LSI1SK;
            if (skA < skB) {
                return -1;
            }
            if (skA > skB) {
                return 1;
            }
            // names must be equal
            return 0;
        }
    });
    // Now I need to process all this into sections.
    const base = orderedItems.shift();
    baseObject.PK = base.PK;
    baseObject.SK = base.SK;
    baseObject.LSI1SK = base.LSI1SK;
    baseObject.fiscalYear = base.fiscalYear;
    baseObject.fiscalYearEnd = (_a = base.fiscalYearEnd) !== null && _a !== void 0 ? _a : null;
    baseObject.createdAt = (_b = base.createdAt) !== null && _b !== void 0 ? _b : luxon_1.DateTime.now().toUTC().toISO();
    baseObject.updatedAt = (_c = base.updatedAt) !== null && _c !== void 0 ? _c : luxon_1.DateTime.now().toUTC().toISO();
    baseObject.marketingWorksheet.annualMarketingBudget =
        base.annualMarketingBudget;
    baseObject.marketingWorksheet.basics.canAdd = base.basicsCanAdd;
    baseObject.marketingWorksheet.retainAndGrowCurrentCustomers.canAdd =
        base.retainAndGrowCurrentCustomersCanAdd;
    baseObject.marketingWorksheet.additionalIdeas.canAdd =
        base.additionalIdeasCanAdd;
    baseObject.marketingWorksheet.digitalMarketing.canAdd =
        base.digitalMarketingCanAdd;
    baseObject.marketingWorksheet.massMarketingVehicles.canAdd =
        base.massMarketingVehiclesCanAdd;
    baseObject.marketingWorksheet.guerrillaMarketingIdeas.canAdd =
        base.guerrillaMarketingIdeasCanAdd;
    const additionalItems = orderedItems.filter((item) => item.LSI1SK.includes("ADDITIONALIDEAS"));
    processFilteredItems(baseObject, additionalItems, "additionalIdeas");
    const basics = orderedItems.filter((item) => item.SK.includes("BASICS"));
    processFilteredItems(baseObject, basics, "basics");
    const digitalMarketing = orderedItems.filter((item) => item.LSI1SK.includes("DIGITALMARKETING"));
    processFilteredItems(baseObject, digitalMarketing, "digitalMarketing");
    const guerrillaMarketingIdeas = orderedItems.filter((item) => item.LSI1SK.includes("GUERRILLAMARKETINGIDEAS"));
    processFilteredItems(baseObject, guerrillaMarketingIdeas, "guerrillaMarketingIdeas");
    const massMarketingVehicles = orderedItems.filter((item) => item.LSI1SK.includes("MASSMARKETINGVEHICLES"));
    processFilteredItems(baseObject, massMarketingVehicles, "massMarketingVehicles");
    const retainAndGrow = orderedItems.filter((item) => item.LSI1SK.includes("RETAINANDGROWCURRENTCUSTOMERS"));
    processFilteredItems(baseObject, retainAndGrow, "retainAndGrowCurrentCustomers");
    return baseObject;
}
exports.convertToFEObject = convertToFEObject;
