"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.yearlyCallCountDataInit = exports.callCountDataInit = void 0;
exports.callCountDataInit = {
    targetNumOfCallsRanPerTech: null, // input field
    budgetedNumOfTechs: null, // input field
    // install
    targetNumOfLeadsRanPerSalesperson: null, // input field
    budgetedNumOfSalespersons: null, // input field
    targetNumOfJobsPerCrew: null, // input field
    budgetedNumOfCrews: null, // input field
    monthlyTotalRevenueCalc: null,
};
exports.yearlyCallCountDataInit = {
    departmentId: "",
    departmentName: "",
    departmentTrade: null,
    departmentType: null,
    fiscalYear: null,
    id: null,
    memberId: null,
    jan: exports.callCountDataInit,
    feb: exports.callCountDataInit,
    mar: exports.callCountDataInit,
    apr: exports.callCountDataInit,
    may: exports.callCountDataInit,
    jun: exports.callCountDataInit,
    jul: exports.callCountDataInit,
    aug: exports.callCountDataInit,
    sep: exports.callCountDataInit,
    oct: exports.callCountDataInit,
    nov: exports.callCountDataInit,
    dec: exports.callCountDataInit,
    callCountTotals: {
        totalSumPriorYearAgreementSystemChecks: null,
        totalSumRequiredDemandCallCount: null,
        totalSumBudgetedSystemCheckCallCount: null,
        totalSumPriorYearDemandCallCount: null,
        totalSumPriorYearTechGeneratedLeads: null,
        totalSumBudgetedTechGeneratedLeads: null,
        totalSumPriorYearMarketedLeadCount: null,
        totalSumPriorYearOtherLeadCount: null,
        totalSumMarketedLeadCount: null,
        totalConvertedTechLeadCount: null,
        totalSumOtherLeadCount: null,
        totalTechLeadRevenue: null,
        totalConvertedMarketedLeadCount: null,
        totalMarketedLeadRevenue: null,
        totalConvertedOtherLeadCount: null,
        totalOtherLeadRevenue: null,
        totalDemandConvertedCalls: null,
        totalDemandRevenue: null,
        totalSystemCheckConvertedCalls: null,
        totalSystemCheckRevenue: null,
        totalSumPriorYearDemandSystemCheckCallCount: null,
        totalSumRequiredDemandSystemCheckCallCount: null,
        totalDemandSystemCheckConvertedCalls: null,
        totalDemandSystemCheckRevenue: null,
    },
};
