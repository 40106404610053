"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcTotalRevenueforMonth = exports.calcTotalPercentLeadsGenerated = exports.calcMonthlyDemandSales = exports.calcSystemCheckCallsPercentLeadsGenerated = exports.calcMonthlySystemCheckSales = exports.calcRevenueGeneratedFromCallPerformance = exports.calcDemandCallsPercentLeadsGenerated = exports.getSystemCheckCallsGeneratedFromOpps = exports.getDemandCallsGeneratedFromOpps = exports.getPercentSystemCheckOpportunityCalls = exports.getPercentDemandOpportunityCalls = exports.getNumberSystemCheckOpportunityCalls = exports.getNumberDemandOpportunityCalls = exports.getTechStaffing = exports.getSoldHoursPerMonthGoal = exports.getCallsPerTechPerDay = exports.getConversionRateDemandCalls = exports.getAverageSaleDemandCalls = exports.getConversionRateSystemCheckCalls = exports.getAverageSaleSystemCheckCalls = exports.getMonthlyAgreementRevenue = exports.getNumberDemandTechLeads = exports.getNumberSystemCheckTechLeads = exports.getDemandCallsRanPerMonth = exports.getSystemCheckCallsPerMonth = void 0;
const utils_1 = require("../../../utils");
const workshop_1 = require("../../workshop");
/**
 * C5
 * ='Call Count'!D19
 *
 * @param object
 * @param month
 * @param workingDays
 * @param demandCallType
 */
function getDemandCallsRanPerMonth(object, month, workingDays, demandCallType) {
    return (0, workshop_1.requiredDemandCallCountCalc)(object, workingDays, month, demandCallType);
}
exports.getDemandCallsRanPerMonth = getDemandCallsRanPerMonth;
/**
 * C6
 * ='Call Count'!D11
 * @param object
 * @param month
 */
function getSystemCheckCallsPerMonth(object, month) {
    if (!object ||
        !("serviceAgreements" in object) ||
        !object.serviceAgreements ||
        !(month in object.serviceAgreements))
        return 0;
    if (object.serviceAgreements[month]) {
        // TP-900 JAH: looking at the correct place.
        if (!object.serviceAgreements.visitsAtTimeOfSale) {
            return object.serviceAgreements[month].totalVisitIf6MonthFromSale;
        }
        return object.serviceAgreements[month]
            .totalVisitsBasedOnTimeOfSaleCalculation;
    }
    return 0;
}
exports.getSystemCheckCallsPerMonth = getSystemCheckCallsPerMonth;
/**
 * C7
 * ='Call Count'!D15
 * @param object
 * @param month
 */
function getTechStaffing(object, month) {
    let callCount = null;
    if (object && "callCount" in object)
        callCount = object.callCount;
    if (!callCount)
        return null;
    if (callCount.departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
        return callCount ? callCount[month].budgetedNumOfTechs : null;
    }
    return callCount ? callCount[month].budgetedNumOfCrews : null;
}
exports.getTechStaffing = getTechStaffing;
/**
 * C8
 * ='Call Count'!D4
 * @param object
 * @param month
 */
function getCallsPerTechPerDay(object, month) {
    let callCount = null;
    if (object && "callCount" in object)
        callCount = object.callCount;
    if (!callCount)
        return null;
    if (callCount.departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
        return callCount ? callCount[month].targetNumOfCallsRanPerTech : null;
    }
    return month in callCount ? callCount[month].targetNumOfJobsPerCrew : null;
}
exports.getCallsPerTechPerDay = getCallsPerTechPerDay;
/**
 * C10
 * ='Max Capacity'!D20
 * @param object
 * @param month
 * @param companyData
 * @param decreasedDays
 * @param demandCallType
 */
function getNumberDemandTechLeads(object, month, companyData, decreasedDays, demandCallType) {
    return (0, workshop_1.numOfDemandTurnoversCalc)(object, companyData, month, decreasedDays, demandCallType);
}
exports.getNumberDemandTechLeads = getNumberDemandTechLeads;
/**
 * C11
 * ='Max Capacity'!D19
 * @param object
 * @param month
 */
function getNumberSystemCheckTechLeads(object, month) {
    return (0, workshop_1.numOfSystemCheckTurnoversCalc)(object, month);
}
exports.getNumberSystemCheckTechLeads = getNumberSystemCheckTechLeads;
/**
 * C16
 * ='Conv and Average Sale'!D11
 * @param object
 * @param month
 * @param demandCallType
 */
function getConversionRateDemandCalls(object, month, demandCallType) {
    let casObject = null;
    if (!(0, utils_1.objectFieldValidator)(object, ["conversionAndAverageSale", month]))
        return null;
    if (object && "conversionAndAverageSale" in object)
        casObject = object.conversionAndAverageSale;
    const convRate = demandCallType === "demandCalls"
        ? casObject[month].demandCallBudgetYearConvRate
        : casObject[month].demandSystemCheckCallBudgetYearConvRate;
    return convRate ? convRate : null;
}
exports.getConversionRateDemandCalls = getConversionRateDemandCalls;
/**
 * C17
 * ='Conv and Average Sale'!D19
 * @param object
 * @param month
 */
function getAverageSaleDemandCalls(object, month, demandCallType) {
    let casObject = null;
    if (!(0, utils_1.objectFieldValidator)(object, ["conversionAndAverageSale", month]))
        return null;
    if (object && "conversionAndAverageSale" in object)
        casObject = object.conversionAndAverageSale;
    const avgSale = demandCallType === "demandCalls"
        ? casObject[month].demandCallBudgetYearAvgSale
        : casObject[month].demandSystemCheckCallBudgetYearAvgSale;
    return avgSale ? avgSale : null;
}
exports.getAverageSaleDemandCalls = getAverageSaleDemandCalls;
/**
 * C20
 * ='Conv and Average Sale'!D7
 * @param object
 * @param month
 */
function getConversionRateSystemCheckCalls(object, month) {
    let casObject = null;
    if (object && "conversionAndAverageSale" in object)
        casObject = object.conversionAndAverageSale;
    if (!casObject)
        return null;
    return month in casObject
        ? casObject[month].systemCheckBudgetYearConvRate
        : null;
}
exports.getConversionRateSystemCheckCalls = getConversionRateSystemCheckCalls;
/**
 * C21
 * ='Conv and Average Sale'!D15
 * @param object
 * @param month
 */
function getAverageSaleSystemCheckCalls(object, month) {
    let casObject = null;
    if (object && "conversionAndAverageSale" in object)
        casObject = object.conversionAndAverageSale;
    if (!casObject)
        return null;
    return month in casObject
        ? casObject[month].systemCheckBudgetYearAvgSale
        : null;
}
exports.getAverageSaleSystemCheckCalls = getAverageSaleSystemCheckCalls;
/**
 * C25
 * ='Direct Costs'!D7
 * @param object
 * @param month
 */
function getMonthlyAgreementRevenue(object, month) {
    let dcObject = null;
    if (object && "directCosts" in object)
        dcObject = object.directCosts;
    if (!dcObject)
        return null;
    return month in dcObject ? dcObject[month].sppRevenue : null;
}
exports.getMonthlyAgreementRevenue = getMonthlyAgreementRevenue;
/**
 * C29
 * ='Direct Costs'!D35
 * @param object
 * @param month
 */
function getSoldHoursPerMonthGoal(object, month) {
    let dcObject = null;
    if (object && "directCosts" in object)
        dcObject = object.directCosts;
    if (!dcObject)
        return null;
    return month in dcObject ? dcObject[month].soldHourPerMonthGoal : null;
}
exports.getSoldHoursPerMonthGoal = getSoldHoursPerMonthGoal;
// TP-892: JAH Adding in the getters for the new fields
/**
 * Max Capacity D7
 * @param object
 * @param month
 * @param demandCallType
 * @returns
 */
function getPercentDemandOpportunityCalls(object, month, demandCallType) {
    var _a, _b;
    if (!object ||
        !("maxCapacity" in object) ||
        !object.maxCapacity ||
        !(month in object.maxCapacity) ||
        !object.maxCapacity[month])
        return null;
    return demandCallType === "demandCalls"
        ? (_a = object.maxCapacity[month].budgetYearPercOfDemandCallsOpportunity) !== null && _a !== void 0 ? _a : null
        : (_b = object.maxCapacity[month]
            .budgetYearPercOfDemandSystemCheckCallsOpportunity) !== null && _b !== void 0 ? _b : null;
}
exports.getPercentDemandOpportunityCalls = getPercentDemandOpportunityCalls;
/**
 * Max Capacity D4
 * @param object
 * @param month
 * @returns
 */
function getPercentSystemCheckOpportunityCalls(object, month) {
    var _a;
    if (!object ||
        !("maxCapacity" in object) ||
        !object.maxCapacity ||
        !(month in object.maxCapacity) ||
        !object.maxCapacity[month])
        return null;
    return ((_a = object.maxCapacity[month].budgetYearPercOfSystemCheckOpportunity) !== null && _a !== void 0 ? _a : null);
}
exports.getPercentSystemCheckOpportunityCalls = getPercentSystemCheckOpportunityCalls;
/**
 * Max Capacity D18
 * @param object
 * @param month
 * @param workingDays
 * @param demandCallType
 * @returns
 */
function getNumberDemandOpportunityCalls(object, month, workingDays, demandCallType) {
    var _a, _b;
    const percentage = (_a = getPercentDemandOpportunityCalls(object, month, demandCallType)) !== null && _a !== void 0 ? _a : 0;
    const demandCallsPerMonth = (_b = getDemandCallsRanPerMonth(object, month, workingDays, demandCallType)) !== null && _b !== void 0 ? _b : 0;
    if (!percentage || !demandCallsPerMonth)
        return null;
    // TP-1084 MH: fix to get true percentage of demand calls
    return demandCallsPerMonth * (percentage / 100);
}
exports.getNumberDemandOpportunityCalls = getNumberDemandOpportunityCalls;
/**
 * Max Capacity D17
 * @param object
 * @param month
 * @returns
 */
function getNumberSystemCheckOpportunityCalls(object, month) {
    var _a, _b;
    const percentage = (_a = getPercentSystemCheckOpportunityCalls(object, month)) !== null && _a !== void 0 ? _a : 0;
    const systemCheckCallsPerMonth = (_b = getSystemCheckCallsPerMonth(object, month)) !== null && _b !== void 0 ? _b : 0;
    if (!percentage || !systemCheckCallsPerMonth)
        return null;
    // TP-1084 MH: fix to get true percentage of system check calls
    return systemCheckCallsPerMonth * (percentage / 100);
}
exports.getNumberSystemCheckOpportunityCalls = getNumberSystemCheckOpportunityCalls;
/**
 * Max Capacity D15
 * @param object
 * @param month
 * @param demandCallType
 * @returns
 */
function getDemandCallsGeneratedFromOpps(object, month, demandCallType) {
    var _a, _b;
    if (!object ||
        !("maxCapacity" in object) ||
        !object.maxCapacity ||
        !(month in object.maxCapacity) ||
        !object.maxCapacity[month])
        return null;
    return demandCallType === "demandCalls"
        ? (_a = object.maxCapacity[month].budgetYearPercOfDemandCallsTurnover) !== null && _a !== void 0 ? _a : null
        : (_b = object.maxCapacity[month]
            .budgetYearPercOfDemandSystemCheckCallsTurnover) !== null && _b !== void 0 ? _b : null;
}
exports.getDemandCallsGeneratedFromOpps = getDemandCallsGeneratedFromOpps;
/**
 *  Max Capacity D11
 * @param object
 * @param month
 * @returns
 */
function getSystemCheckCallsGeneratedFromOpps(object, month) {
    var _a;
    if (!object ||
        !("maxCapacity" in object) ||
        !object.maxCapacity ||
        !(month in object.maxCapacity) ||
        !object.maxCapacity[month])
        return null;
    return (_a = object.maxCapacity[month].budgetYearPercOfSystemCheckTurnover) !== null && _a !== void 0 ? _a : null;
}
exports.getSystemCheckCallsGeneratedFromOpps = getSystemCheckCallsGeneratedFromOpps;
/**
 * C12
 * =IFERROR(C10/C5,0)
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 * @param decreasedDays
 * @param demandCallType
 */
function calcDemandCallsPercentLeadsGenerated(object, month, companyData, workingDays, decreasedDays, demandCallType) {
    // TP-912: This is returning NaN and it is breaking in Dynamo
    const numberDemandTechLeads = getNumberDemandTechLeads(object, month, companyData, decreasedDays, demandCallType);
    const demandCallsRanPerMonth = getDemandCallsRanPerMonth(object, month, workingDays, demandCallType);
    if (!numberDemandTechLeads || !demandCallsRanPerMonth)
        return null;
    return numberDemandTechLeads / demandCallsRanPerMonth;
}
exports.calcDemandCallsPercentLeadsGenerated = calcDemandCallsPercentLeadsGenerated;
/**
 * C13
 * =IFERROR(C11/C6,0)
 * @param object
 * @param month
 */
function calcSystemCheckCallsPercentLeadsGenerated(object, month) {
    // TP-912: This is returning NaN and it is breaking in Dynamo
    const numberSystemCheckTechLeads = getNumberSystemCheckTechLeads(object, month);
    const systemCheckCallsPerMonth = getSystemCheckCallsPerMonth(object, month);
    if (!systemCheckCallsPerMonth || !numberSystemCheckTechLeads)
        return null;
    return numberSystemCheckTechLeads / systemCheckCallsPerMonth;
}
exports.calcSystemCheckCallsPercentLeadsGenerated = calcSystemCheckCallsPercentLeadsGenerated;
/**
 * C14
 * =IF(C10+C11=0,0,(C10+C11)/(C5+C6))
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 * @param decreasedDays
 */
function calcTotalPercentLeadsGenerated(object, month, companyData, workingDays, decreasedDays) {
    const numberDemandTechLeads = getNumberDemandTechLeads(object, month, companyData, decreasedDays, "demandCalls");
    const numberDemandSystemCheckTechLeads = getNumberDemandTechLeads(object, month, companyData, decreasedDays, "demandSystemCheckCalls");
    const numberSystemCheckTechLeads = getNumberSystemCheckTechLeads(object, month);
    const condition = numberDemandTechLeads +
        numberSystemCheckTechLeads +
        numberDemandSystemCheckTechLeads;
    if (condition === 0)
        return 0;
    const demandCallsRanPerMonth = getDemandCallsRanPerMonth(object, month, workingDays, "demandCalls");
    const demandSystemCheckCallsRanPerMonth = getDemandCallsRanPerMonth(object, month, workingDays, "demandSystemCheckCalls");
    const systemCheckCallsPerMonth = getSystemCheckCallsPerMonth(object, month);
    return (condition /
        (demandCallsRanPerMonth +
            systemCheckCallsPerMonth +
            demandSystemCheckCallsRanPerMonth));
}
exports.calcTotalPercentLeadsGenerated = calcTotalPercentLeadsGenerated;
/**
 * C18
 * =C5*C16*C17
 * @param object
 * @param month
 * @param workingDays
 * @param demandCallType
 */
function calcMonthlyDemandSales(object, month, workingDays, demandCallType) {
    const demandCallsRanPerMonth = getDemandCallsRanPerMonth(object, month, workingDays, demandCallType);
    const conversionRateDemandCalls = getConversionRateDemandCalls(object, month, demandCallType) / 100;
    const averageSaleDemandCalls = getAverageSaleDemandCalls(object, month, demandCallType);
    return (demandCallsRanPerMonth * conversionRateDemandCalls * averageSaleDemandCalls);
}
exports.calcMonthlyDemandSales = calcMonthlyDemandSales;
/**
 * C22
 * =C6*C20*C21
 * @param object
 * @param month
 */
function calcMonthlySystemCheckSales(object, month) {
    const systemCheckCallsPerMonth = getSystemCheckCallsPerMonth(object, month);
    const conversionRateSystemCheckCalls = getConversionRateSystemCheckCalls(object, month) / 100;
    const averageSaleSystemCheckCalls = getAverageSaleSystemCheckCalls(object, month);
    return (systemCheckCallsPerMonth *
        conversionRateSystemCheckCalls *
        averageSaleSystemCheckCalls);
}
exports.calcMonthlySystemCheckSales = calcMonthlySystemCheckSales;
/**
 * C24
 * =C18+C22
 * @param object
 * @param month
 * @param workingDays
 */
function calcRevenueGeneratedFromCallPerformance(object, month, workingDays) {
    const monthlyDemandSales = calcMonthlyDemandSales(object, month, workingDays, "demandCalls");
    const monthlyDemandSystemCheckSales = calcMonthlyDemandSales(object, month, workingDays, "demandSystemCheckCalls");
    const monthlySystemCheckSales = calcMonthlySystemCheckSales(object, month);
    return (monthlyDemandSales + monthlySystemCheckSales + monthlyDemandSystemCheckSales);
}
exports.calcRevenueGeneratedFromCallPerformance = calcRevenueGeneratedFromCallPerformance;
/**
 * C26
 * =C24+C25
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcTotalRevenueforMonth(object, month, companyData, workingDays) {
    var _a, _b;
    // TP-912: This is returning NaN and it is breaking in Dynamo
    const revenueGeneratedFromCallPerformance = (_a = calcRevenueGeneratedFromCallPerformance(object, month, workingDays)) !== null && _a !== void 0 ? _a : 0;
    const monthlyAgreementRevenue = (_b = getMonthlyAgreementRevenue(object, month)) !== null && _b !== void 0 ? _b : 0;
    return revenueGeneratedFromCallPerformance + monthlyAgreementRevenue;
}
exports.calcTotalRevenueforMonth = calcTotalRevenueforMonth;
