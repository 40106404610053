"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companyBudgetTestObj = void 0;
const MonthlyCompanyType = {
    budgetedLaborAmount: null,
    budgetedLaborPerInput: null,
    budgetedMaterialAmount: null,
    budgetedMaterialPerInput: null,
    budgetedOtherDirectAmount: null,
    budgetedOtherDirectPercInput: null,
    budgetedSalesExpAmount: null,
    budgetedSalesExpPercInput: null,
    demandAndSystemCheckSales: null,
    financeFeeAmount: null,
    grossIncome: null,
    grossProfit: null,
    reductions: null,
    refundAmount: null,
    soldDayPerMonthInstallGoal: null,
    soldHourPerMonthGoal: null,
    sppRevenue: null,
    subcontractorAmount: null,
    totalDirectCost: null,
    totalIncome: null,
};
exports.companyBudgetTestObj = {
    companyTotals: {
        budgetedLaborAmount: 111257,
        budgetedLaborPerInput: null,
        budgetedMaterialAmount: 307938,
        budgetedMaterialPerInput: null,
        budgetedOtherDirectAmount: 1578,
        budgetedOtherDirectPercInput: null,
        budgetedSalesExpAmount: 47345,
        budgetedSalesExpPercInput: null,
        demandAndSystemCheckSales: null,
        financeFeeAmount: null,
        grossIncome: 1062002,
        grossProfit: 578409,
        reductions: 15475,
        refundAmount: null,
        soldDayPerMonthInstallGoal: null,
        soldHourPerMonthGoal: null,
        sppRevenue: null,
        subcontractorAmount: null,
        totalDirectCost: 468117,
        totalIncome: 1046526,
    },
    jan: {
        budgetedLaborAmount: 38087,
        budgetedLaborPerInput: 45,
        budgetedMaterialAmount: 89019,
        budgetedMaterialPerInput: 16,
        budgetedOtherDirectAmount: 498,
        budgetedOtherDirectPercInput: null,
        budgetedSalesExpAmount: 14946,
        budgetedSalesExpPercInput: null,
        demandAndSystemCheckSales: null,
        financeFeeAmount: null,
        grossIncome: 338652,
        grossProfit: 191186,
        reductions: 4916,
        refundAmount: null,
        soldDayPerMonthInstallGoal: null,
        soldHourPerMonthGoal: null,
        sppRevenue: null,
        subcontractorAmount: null,
        totalDirectCost: 142550,
        totalIncome: 333735,
    },
    feb: {
        budgetedLaborAmount: 36777,
        budgetedLaborPerInput: null,
        budgetedMaterialAmount: 108176,
        budgetedMaterialPerInput: null,
        budgetedOtherDirectAmount: 478,
        budgetedOtherDirectPercInput: null,
        budgetedSalesExpAmount: 14343,
        budgetedSalesExpPercInput: null,
        demandAndSystemCheckSales: null,
        financeFeeAmount: null,
        grossIncome: 335785,
        grossProfit: 171211,
        reductions: 4799,
        refundAmount: null,
        soldDayPerMonthInstallGoal: null,
        soldHourPerMonthGoal: null,
        sppRevenue: null,
        subcontractorAmount: null,
        totalDirectCost: 159774,
        totalIncome: 330986,
    },
    mar: {
        budgetedLaborAmount: 36393,
        budgetedLaborPerInput: null,
        budgetedMaterialAmount: 110743,
        budgetedMaterialPerInput: null,
        budgetedOtherDirectAmount: 602,
        budgetedOtherDirectPercInput: null,
        budgetedSalesExpAmount: 18056,
        budgetedSalesExpPercInput: null,
        demandAndSystemCheckSales: null,
        financeFeeAmount: null,
        grossIncome: 387565,
        grossProfit: 216012,
        reductions: 5760,
        refundAmount: null,
        soldDayPerMonthInstallGoal: null,
        soldHourPerMonthGoal: null,
        sppRevenue: null,
        subcontractorAmount: null,
        totalDirectCost: 165793,
        totalIncome: 381805,
    },
    apr: MonthlyCompanyType,
    may: MonthlyCompanyType,
    jun: MonthlyCompanyType,
    jul: MonthlyCompanyType,
    aug: MonthlyCompanyType,
    sep: MonthlyCompanyType,
    oct: MonthlyCompanyType,
    nov: MonthlyCompanyType,
    dec: MonthlyCompanyType,
};
