import { ProfitPricer } from "@nexstar-network/shared-utils";

export const handleNumericInputChange = (
  inputValue: any,
): string | number | null | boolean => {
  let returnValue = inputValue;
  if (typeof returnValue === "boolean") return returnValue;
  if (typeof returnValue === "number") return returnValue;
  // TP-1039: fix for if it comes in as undefined.
  if (returnValue === "" || returnValue === null || returnValue === undefined) {
    returnValue = null;
  } else if (
    !returnValue.endsWith(".") &&
    parseFloat(returnValue).toString() === inputValue
  ) {
    returnValue = parseFloat(returnValue);
  }
  return returnValue;
};

export const handleWhatIfsInputChange = (
  inputValue: any,
): string | number | null => {
  let returnValue = inputValue;
  if (!returnValue.endsWith(".")) {
    returnValue = parseFloat(returnValue);
  }
  return returnValue;
};

const decimalRegex = () => /^-?\d+(\.\d{0,2})?$/;

export const handleNumericInputValue = (
  inputValue: string | number | null,
  decimals?: boolean,
): string | number => {
  if (
    inputValue === undefined ||
    inputValue === null ||
    inputValue === "" ||
    Number.isNaN(inputValue)
  )
    return "";

  //Checking the input only has two decimals if the property decimals is enabled
  const input = inputValue.toString();
  if (
    decimals !== null &&
    decimals !== undefined &&
    !decimalRegex().test(input)
  ) {
    const [wholeValue, decimalVal] = input.split(".");
    return `${wholeValue}.${decimalVal.substring(0, 2)}`;
  }
  return input;
};

// CAS - Impossible to currently TYPE

export const haveObjectsChanged = (obj1: any, obj2: any): boolean =>
  JSON.stringify(obj1) !== JSON.stringify(obj2);

export const hasPricerBeenModified = (pricer: ProfitPricer): boolean => {
  if (pricer.toolId.startsWith("NEW")) return true;
  if ("modified" in pricer && pricer.modified) {
    return true;
  }
  let departmentModified = false;
  for (let i = 0; i < pricer.departments?.length; i++) {
    if (
      ("modified" in pricer.departments[i] && pricer.departments[i].modified) ||
      ("delete" in pricer.departments[i] && pricer.departments[i].delete)
    ) {
      departmentModified = true;
      break;
    }
  }
  return departmentModified;
};

// CAS - Impossible to currently TYPE

export const handleEndingDecimalDisplay = (inputValue: any): number | null => {
  let returnValue = inputValue;
  if (returnValue === null) return null;
  if (typeof returnValue === "string" && returnValue.endsWith(".")) {
    returnValue = parseInt(returnValue, 10);
  }
  return returnValue;
};

export const arrayOfObjectReducerBySingleKey = (
  obj1: Array<Record<string, any>>,
  obj2: Array<Record<string, any>>,
  keyToMatch: string,
): any =>
  obj1.filter((ar) => !obj2.find((rm) => rm[keyToMatch] === ar[keyToMatch]));

export function toLocaleString(value: number) {
  let returnValue = "0";
  if (value) {
    returnValue = value.toLocaleString("en", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
    if (returnValue.endsWith(".0")) {
      returnValue = returnValue.split(".").shift();
    }
  }
  return returnValue;
}

export const overflowFix = () => {
  // A bug prevents overflow:hidden from being removed from the HTML tag. Using setTimeout to remove overflow:hidden manually.
  setTimeout(() => {
    const html = document.documentElement as HTMLElement | null;
    html.style.overflow = null;
  }, 500);
};
