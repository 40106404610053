import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import React from "react";
import { ReleaseNote } from "@nexstar-network/shared-utils";

/**
 * function to push overall release notes for display
 * @param releaseNoteData
 * @constructor
 */
export const DisplayNotes = (releaseNoteData: ReleaseNote) => {
  return (
    <div
      className="border-b border-NexstarGray mb-4 pb-2"
      key={releaseNoteData.version}
    >
      <div className="prose prose-slate max-w-none">
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {releaseNoteData.notes}
        </ReactMarkdown>
      </div>
    </div>
  );
};
