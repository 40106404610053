import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function ChevronUpIcon({
  size = "medium",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m58.34,365.25c11.51,11.51,30.17,11.51,41.68,0l155.98-155.98,155.98,155.98c11.51,11.51,30.17,11.51,41.68,0,11.51-11.51,11.51-30.17,0-41.68l-176.83-176.83c-11.51-11.51-30.17-11.51-41.68,0L58.33,323.58c-11.51,11.51-11.51,30.17,0,41.68h.01Z" />
    </svg>
  );
}
