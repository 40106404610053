import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function ProfitPricerIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m130.8 1081.2c-10.801 0-20.398-9.6016-20.398-20.398l-0.003906-183.6c30-2.3984 57.602-14.398 79.199-36l103.2-103.2v322.8c0 10.801-9.6016 20.398-20.398 20.398zm396 0c10.801 0 20.398-9.6016 20.398-20.398v-192c-32.398-1.1992-62.398-13.199-85.199-36l-97.199-97.199v325.2c0 10.801 9.6016 20.398 20.398 20.398zm253.2 0c10.801 0 20.398-9.6016 20.398-20.398v-391.2l-163.2 163.2c-6 6-13.199 12-20.398 16.801v211.2c0 10.801 9.6016 20.398 20.398 20.398zm253.2 0c10.801 0 20.398-9.6016 20.398-20.398l0.003906-618c-4.8008-3.6016-8.3984-7.1992-10.801-9.6016l-2.3984-2.3984-169.2 168v462c0 10.801 9.6016 20.398 20.398 20.398zm-663.6-619.2c-10.801-10.801-25.199-16.801-40.801-16.801-15.602 0-30 6-40.801 16.801l-237.6 238.8c-22.801 22.801-22.801 58.801 0 80.398l10.801 10.801c10.801 12 25.199 18 40.801 18s30-6 40.801-16.801l187.2-187.2 180 180c10.801 10.801 25.199 16.801 40.801 16.801 15.602 0 30-6 40.801-16.801l450-450c1.1992 1.1992 2.3984 2.3984 3.6016 3.6016 10.801 10.801 22.801 22.801 33.602 33.602l14.398 14.398c6 6 14.398 14.398 27.602 14.398 4.8008 0 10.801-1.1992 16.801-4.8008 3.6016-2.3984 7.1992-4.8008 9.6016-7.1992 9.6016-9.6016 12-22.801 12-32.398 2.3984-43.199 3.6016-85.199 6-128.4l3.6016-64.801c1.1992-14.398-2.3984-24-9.6016-31.199-7.1992-7.1992-16.801-10.801-30-10.801h-3.6016c-66 3.6016-132 6-198 9.6016-8.3984 0-20.398 1.1992-30 10.801-2.3984 2.3984-4.8008 6-7.1992 10.801-9.6016 21.602 3.6016 34.801 10.801 42l16.801 16.801c12 12 22.801 22.801 34.801 34.801l-403.21 394.79z" />
    </svg>
  );
}
