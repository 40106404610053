import axios from "axios";
import { DigitalMetrics } from "@nexstar-network/shared-utils/dist/digitalMetrics/types";
import { gql, GraphQLClient } from "graphql-request";

const GET_ALL_MEMBER_COMPANY_INFORMATION_BY_YEAR = gql`
  query ($memberId: String!, $year: Float!) {
    getAllMemberCompanyInformationByYear(memberId: $memberId, year: $year) {
      companyInformation {
        memberId
        id
        fiscalYearEnd
        fiscalYear
        updatedAt
        updatedBy
        createdAt
        nonWorkingDays {
          label
          date
        }
        weeklyWorkdays {
          mon
          tue
          wed
          thu
          fri
          sat
          sun
        }
      }
      departmentInformation {
        id
        departmentName
        numberEmployees
        methodOfPricing
        departmentType
        departmentTrade
        callsPerTech
        fiscalYear
        departmentOrder
        associatedDeptId
        updatedAt
        updatedBy
        createdAt
      }
      overheadInformation {
        accountNumber
        memberId
        fiscalYear
        overheadOrder
        id
        taxable
        big5
        big5Subcategory
        overheadName
        updatedAt
        updatedBy
        createdAt
      }
    }
  }
`;
const GET_ALL_MEMBER_COMPANY_INFORMATION_BY_YEAR_NO_OVERHEADS = gql`
  query ($memberId: String!, $year: Float!) {
    getAllMemberCompanyInformationByYear(memberId: $memberId, year: $year) {
      companyInformation {
        memberId
        id
        fiscalYearEnd
        fiscalYear
        nonWorkingDays {
          label
          date
        }
        weeklyWorkdays {
          mon
          tue
          wed
          thu
          fri
          sat
          sun
        }
      }
      departmentInformation {
        id
        departmentName
        numberEmployees
        methodOfPricing
        departmentType
        departmentTrade
        callsPerTech
        fiscalYear
        departmentOrder
        associatedDeptId
      }
    }
  }
`;

// TP-372: Needed to add in the overheads data to this request.
const CREATE_NEW_MEMBER_COMPANY_INFO = gql`
  mutation ($newFiscalYearInformation: CreateNewFiscalYear!) {
    createNewFiscalYearMemberCompanyInformation(
      newFiscalYearInformation: $newFiscalYearInformation
    ) {
      success
      errorCode
      errorMessage
    }
  }
`;

type GetCompanyManagementParams = {
  memberId: string;
  year: number;
};

/**
 * gqlKey = getAllMemberCompanyInformationByYear
 * @param parameters
 * @param withOverheads
 * @param getAccessTokenSilently
 */
export async function getCompanyManagement(
  parameters: GetCompanyManagementParams,
  withOverheads: boolean = false,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  if (parameters === null) {
    return null;
  }
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  const graphQLClient = new GraphQLClient(
    `${process.env.REACT_APP_API_BASE_URL}graphql`,
    {
      headers: {
        authorization: accessToken,
      },
    },
  );
  if (withOverheads) {
    return graphQLClient.request(
      GET_ALL_MEMBER_COMPANY_INFORMATION_BY_YEAR,
      parameters,
    );
  } else {
    return graphQLClient.request(
      GET_ALL_MEMBER_COMPANY_INFORMATION_BY_YEAR_NO_OVERHEADS,
      parameters,
    );
  }
}

export async function getDMTByTemplateYear(
  memberId: number,
  year: number,
  selectedChannel: any,
  selectedMonth: string,
  selectedEndMonth: string,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const channelParams = {
    startMonth: selectedMonth,
    endMonth: selectedEndMonth,
  };
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${
      process.env.REACT_APP_API_BASE_URL
    }marketing/DMT/member/${memberId.toString()}/year/${year}/DMTByChannel/${selectedChannel}`,
    method: "put",
    data: channelParams,
  });
}

/**
 *
 * @param params
 * @param getAccessTokenSilently
 */
export async function getAllDMT(
  params: { fiscalYear: number; memberId: number },
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}marketing/DMT/member/${params.memberId}/year/${params.fiscalYear}/all`,
    method: "get",
  }).then((res) => res.data);
}

/**
 *
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function saveDMTRequest(
  parameters: DigitalMetrics,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}marketing/DMT/member/${parameters.memberId}/year/${parameters.fiscalYear}/DMT`,
    method: "post",
    data: parameters,
  });
}

/**
 *
 * @param DMTs
 * @param memberId
 * @param fiscalYear
 * @param getAccessTokenSilently
 */
export async function deleteDMTs(
  DMTs,
  memberId: number,
  fiscalYear: number,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}marketing/DMT/member/${memberId}/year/${fiscalYear}/deleteDMT`,
    method: "put",
    data: DMTs,
  });
}

const MODIFY_COMPANY_INFO = gql`
  mutation ($memberCompanyInformation: ModifyMemberCompanyInformation!) {
    modifyMemberCompanyInformation(
      memberCompanyInformation: $memberCompanyInformation
    ) {
      memberId
      id
      fiscalYear
      errorCode
      errorMessage
    }
  }
`;

/**
 * gqlKey = modifyMemberCompanyInformation
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function saveCompanyManagementRequest(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MODIFY_COMPANY_INFO,
      variables: parameters,
    },
  });
}

const MANAGE_DEPARTMENT = gql`
  mutation ($department: ManageDepartment!) {
    manageDepartment(department: $department) {
      memberId
      id
      departmentTrade
      departmentName
      errorCode
      errorMessage
    }
  }
`;

const MANAGE_MULTIPLE_DEPARTMENTS = gql`
  mutation ($departments: [ManageDepartment!]!) {
    manageDepartments(departments: $departments) {
      memberId
      id
      departmentTrade
      departmentName
      errorCode
      errorMessage
    }
  }
`;

const DELETE_MEMBER_DEPARTMENT = gql`
  mutation ($department: ManageDepartment!) {
    deleteMemberDepartment(department: $department) {
      success
    }
  }
`;

/**
 * gqlKey = manageDepartment
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function saveDepartmentsRequest(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MANAGE_DEPARTMENT,
      variables: parameters,
    },
  });
}

/**
 * gqlKey = manageDepartments
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function saveReorderDepartmentsRequest(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MANAGE_MULTIPLE_DEPARTMENTS,
      variables: parameters,
    },
  });
}

/**
 * gqlKey = deleteMemberDepartment
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function deleteDepartmentRequest(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: DELETE_MEMBER_DEPARTMENT,
      variables: parameters,
    },
  });
}

const MODIFY_SINGLE_OVERHEAD = gql`
  mutation ($overheadToModify: Overheadsv2Input!) {
    modifySingleOverheadItem(overheadToModify: $overheadToModify) {
      memberId
      id
      overheadName
      errorCode
      errorMessage
    }
  }
`;

const MODIFY_MULTIPLE_OVERHEADS = gql`
  mutation ($overheadsToModify: [Overheadsv2Input!]!) {
    modifyMultipleOverheadItem(overheadsToModify: $overheadsToModify) {
      profitPricerId
      id
      overheadName
      errorCode
      errorMessage
    }
  }
`;

const DELETE_OVERHEADS = gql`
  mutation ($overheadsToDelete: [OverheadsToDeleteInput!]!) {
    deleteOverheadItems(overheadsToDelete: $overheadsToDelete) {
      success
      errorCode
      errorMessage
    }
  }
`;

/**
 * gqlKey = modifySingleOverheadItem
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function saveOverheadsRequest(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MODIFY_SINGLE_OVERHEAD,
      variables: parameters,
    },
  });
}

/**
 * gqlKey = modifyMultipleOverheadItem
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function saveReorderOverheadsRequest(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: MODIFY_MULTIPLE_OVERHEADS,
      variables: parameters,
    },
  });
}

/**
 * gqlKey = deleteOverheadItems
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function deleteOverheadRequest(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: DELETE_OVERHEADS,
      variables: parameters,
    },
  });
}

/**
 * gqlKey = createNewFiscalYearMemberCompanyInformation
 * @param parameters
 * @param getAccessTokenSilently
 */
export async function createNewFiscalYearMemberCompanyInformation(
  parameters,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}graphql`,
    method: "post",
    data: {
      query: CREATE_NEW_MEMBER_COMPANY_INFO,
      variables: parameters,
    },
  });
}
