import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function EmailIcon({
  size = "medium",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m273.71,244.22l203.08-156.42c-7.54-4.89-16.7-7.74-26.27-7.74H59.45c-9.17,0-17.51,2.44-24.85,6.92l201.64,157.24c11,8.76,26.27,8.76,37.48,0h0Z" />
      <path d="m11.38,120.38c-.51,2.21-3.37,265.43,2.24,279.46l156.02-155.82L11.38,120.38Z" />
      <path d="m254.97,291.47c-31.15,0-48.56-19.78-52.96-22.4L42.33,428.95c5.3,2.24,11,3.26,17.11,3.26h391.07c4.07,0,8.15-.61,11.81-1.63l-158.26-158.26c-3.65,2.09-19,19.15-49.08,19.15h0Z" />
      <path d="m498.98,122.22l-162.33,125.06,157.65,157.65c8.36-16.73,4.89-280.67,4.68-282.71h0Z" />
    </svg>
  );
}
