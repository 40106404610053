"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncentivesEnumToLabel = exports.BalanceSheetEnumToLabel = exports.CompensationEnumToLabel = exports.YesNoEnumToLabel = exports.AccountingBasisEnumToLabel = exports.BusinessTypeEnumToLabel = exports.OverheadsCategoryEnumToLabel = exports.compensationOptions = exports.employeeOptions = void 0;
exports.employeeOptions = {
    CALL_CENTER_MANAGER: "Call Center Manager",
    CONTROLLER_HEAD_BOOKKEEPER: "Controller / Head Bookkeeper",
    CSR: "CSR",
    DISPATCHER: "Dispatcher",
    ELEC_APPRENTICE_HELPERS: "Elec Apprentice / Helper",
    ELEC_INSTALLER_REMODELER: "Elec Installer / Remodeler",
    ELEC_SERVICE_TECH: "Elec Service Tech",
    GENERAL_OPERATIONS_MANAGER: "General / Operations Manager",
    GENERAL_ADMIN: "General Admin",
    GENERAL_SHOP: "General Shop",
    HR: "HR",
    HVAC_APPRENTICE_HELPER: "HVAC Apprentice / Helper",
    HVAC_INSTALLER: "HVAC Installer / Remodeler",
    HVAC_SERVICE_TECH: "HVAC Service Tech",
    INSIDE_SALES: "Inside Sales",
    INSTALL_COORDINATOR: "Install Coordinator",
    INSTALL_MANAGER: "Install Manager",
    IT: "IT",
    MARKTER: "Marketer",
    NEW_CONST_APPRENTICE_HELPERS: "New Const Apprentice / Helper",
    NEW_CONST_INSTALLER_REMODELER: "New Const Installer / Remodeler",
    OFFICE_MANAGER: "Office Manager",
    OFFICER: "Officer",
    OWNER: "Owner",
    PLUMBING_APPRENTICE_HELPER: "Plumbing Apprentice / Helper",
    PLUMBING_INSTALLER_REMODELER: "Plumbing Installer / Remodeler",
    PLUMBING_SERVICE_TECH: "Plumbing Service Tech",
    SALES_LEAD_COORDINATOR: "Sales Lead Coordinator",
    SALES_MANAGER: "Sales Manager",
    SALESPERSON: "Salesperson",
    SERVICE_MANAGER: "Service Manager",
    STAFF_ACCOUNTANT: "Staff Accountant",
    TRADE_ADMINISTRATOR: "Trade Administrator",
    WAREHOUSE: "Warehouse",
};
exports.compensationOptions = {
    SALARY: "Salary",
    DRAW: "Draw",
    COMMISSION: "Commission",
};
var OverheadsCategoryEnumToLabel;
(function (OverheadsCategoryEnumToLabel) {
    OverheadsCategoryEnumToLabel["OFFICE_SALARIES"] = "Office Salaries";
    OverheadsCategoryEnumToLabel["OFFICE_PAYROLL_TAXES"] = "Office Payroll Taxes";
    OverheadsCategoryEnumToLabel["RECRUITING_EXPENSE"] = "Recruiting Expense";
    OverheadsCategoryEnumToLabel["OFFICE_HEALTH_INSURANCE_BENEFITS"] = "Office Health Insurance & Benefits";
    OverheadsCategoryEnumToLabel["RETIREMENT_PLANS"] = "Retirement Plans";
    OverheadsCategoryEnumToLabel["ADVERTISING_EXPENSES"] = "Advertising Expenses";
    OverheadsCategoryEnumToLabel["BAD_DEBTS"] = "Bad Debts";
    OverheadsCategoryEnumToLabel["BANK_CREDIT_CARD_FEES"] = "Bank/Credit Card Fees";
    OverheadsCategoryEnumToLabel["COMPUTER_SOFTWARE_SERVICE_EXPENSE"] = "Computer Software Service Expense";
    OverheadsCategoryEnumToLabel["DEPRECIATION"] = "Depreciation";
    OverheadsCategoryEnumToLabel["DONATIONS"] = "Donations";
    OverheadsCategoryEnumToLabel["DUES_SUBSCRIPTIONS"] = "Dues and Subscriptions";
    OverheadsCategoryEnumToLabel["INSURANCE"] = "Insurance";
    OverheadsCategoryEnumToLabel["LICENSES_FEES"] = "Licenses and Fees";
    OverheadsCategoryEnumToLabel["MISCELLANEOUS"] = "Miscellaneous";
    OverheadsCategoryEnumToLabel["OFFICE_SUPPLIES_POSTAGE"] = "Office Supplies and Postage";
    OverheadsCategoryEnumToLabel["PROFESSIONAL_FEES"] = "Professional Fees";
    OverheadsCategoryEnumToLabel["RENT_BUILDING_REAL_ESTATE_TAXES"] = "Rent Building Real Estate Taxes";
    OverheadsCategoryEnumToLabel["RENT_EQUIPMENT_UNIFORMS"] = "Rent Equipment and Uniforms";
    OverheadsCategoryEnumToLabel["REPAIRS_MAINTENANCE"] = "Repairs and Maintenance";
    OverheadsCategoryEnumToLabel["SMALL_TOOLS_SUPPLIES"] = "Small Tools and Supplies";
    OverheadsCategoryEnumToLabel["TRAINING_EDUCATION"] = "Training and Education";
    OverheadsCategoryEnumToLabel["TRAVEL_ENTERTAINMENT"] = "Travel and Entertainment";
    OverheadsCategoryEnumToLabel["UTILITIES_TELEPHONE"] = "Utilities and Telephone";
    OverheadsCategoryEnumToLabel["VEHICLE_MAINTENANCE_REPAIRS"] = "Vehicle Maintenance and Repairs";
    OverheadsCategoryEnumToLabel["FUEL"] = "Fuel";
    OverheadsCategoryEnumToLabel["VEHICLE_LEASE_PAYMENTS_DEPRECIATION"] = "Vehicle Lease Payments/Depreciation";
    OverheadsCategoryEnumToLabel["AUTO_INSURANCE_OTHER_VEHICLE"] = "Auto Insurance Other Vehicle";
    // DIRECT_LABOR = "Direct Labor",
    // OTHER = "Other",
    // OFFICE_SALARY_BENEFITS = "Office Salary Benefits",
    // ADVERTISING = "Advertising",
    // VEHICLE = "Vehicle",
})(OverheadsCategoryEnumToLabel || (exports.OverheadsCategoryEnumToLabel = OverheadsCategoryEnumToLabel = {}));
var BusinessTypeEnumToLabel;
(function (BusinessTypeEnumToLabel) {
    BusinessTypeEnumToLabel["CORPORATIONS"] = "Corporations";
    BusinessTypeEnumToLabel["LIMITED_LIABILITY_COMPANIES"] = "Limited Liability Companies";
    BusinessTypeEnumToLabel["GENERAL_PARTNERSHIPS"] = "General Partnerships";
    BusinessTypeEnumToLabel["LIMITED_PARTNERSHIPS"] = "Limited Partnerships";
    BusinessTypeEnumToLabel["BRANCH"] = "Branch";
    BusinessTypeEnumToLabel["SOLE_PROPRIETORSHIP"] = "Sole Proprietorship";
})(BusinessTypeEnumToLabel || (exports.BusinessTypeEnumToLabel = BusinessTypeEnumToLabel = {}));
var AccountingBasisEnumToLabel;
(function (AccountingBasisEnumToLabel) {
    AccountingBasisEnumToLabel["ACCRUAL"] = "Accrual";
    AccountingBasisEnumToLabel["CASH"] = "Cash";
})(AccountingBasisEnumToLabel || (exports.AccountingBasisEnumToLabel = AccountingBasisEnumToLabel = {}));
var YesNoEnumToLabel;
(function (YesNoEnumToLabel) {
    YesNoEnumToLabel["YES"] = "Yes";
    YesNoEnumToLabel["NO"] = "No";
})(YesNoEnumToLabel || (exports.YesNoEnumToLabel = YesNoEnumToLabel = {}));
var CompensationEnumToLabel;
(function (CompensationEnumToLabel) {
    CompensationEnumToLabel["PRIMARY"] = "Primary";
    CompensationEnumToLabel["SECONDARY"] = "Secondary";
    CompensationEnumToLabel["N_A"] = "N/A";
})(CompensationEnumToLabel || (exports.CompensationEnumToLabel = CompensationEnumToLabel = {}));
var BalanceSheetEnumToLabel;
(function (BalanceSheetEnumToLabel) {
    BalanceSheetEnumToLabel["cash"] = "Cash";
    BalanceSheetEnumToLabel["accountsReceivableTrade"] = "Accounts Receivable Trade";
    BalanceSheetEnumToLabel["accountsReceivableOther"] = "Accounts Receivable Other";
    BalanceSheetEnumToLabel["inventories"] = "Inventories";
    BalanceSheetEnumToLabel["otherCurrentAssets"] = "Other Current Assets";
    BalanceSheetEnumToLabel["accountsPayable"] = "Accounts Payable";
    BalanceSheetEnumToLabel["accruedExpenses"] = "Accrued Expenses";
    BalanceSheetEnumToLabel["notesPayable"] = "Notes Payable";
    BalanceSheetEnumToLabel["currentPortionLongTermDebt"] = "Current Portion Long Term Debt";
    BalanceSheetEnumToLabel["landBuildings"] = "Land and Buildings";
    BalanceSheetEnumToLabel["vehiclesEquipment"] = "Vehicles and Equipment";
    BalanceSheetEnumToLabel["lessAccumulatedDepreciation"] = "Less Accumulated Depreciation";
    BalanceSheetEnumToLabel["longTermDebtNetCurrentPortion"] = "Long Term Debt Net Current Portion";
    BalanceSheetEnumToLabel["notesPayableToOwner"] = "Notes Payable to Owner";
    BalanceSheetEnumToLabel["otherLiabilities"] = "Other Liabilities";
    BalanceSheetEnumToLabel["notesAccountsReceivableOwner"] = "Notes Accounts Receivable Owner";
    BalanceSheetEnumToLabel["otherAssets"] = "Other";
    BalanceSheetEnumToLabel["commonPreferredStock"] = "Common and Preferred Stock";
    BalanceSheetEnumToLabel["additionalPaidCapital"] = "Additional Paid in Capital";
    BalanceSheetEnumToLabel["distributionsOwners"] = "Distributions to Owners";
    BalanceSheetEnumToLabel["retainedEarnings"] = "Retained Earnings";
    BalanceSheetEnumToLabel["currentAssets"] = "Current Assets";
    BalanceSheetEnumToLabel["currentLiabilities"] = "Current Liabilities";
    BalanceSheetEnumToLabel["propertyPlantEquipment"] = "Property, Plant, and Equipment";
    BalanceSheetEnumToLabel["nonCurrentLiabilities"] = "Non-Current Liabilities";
    BalanceSheetEnumToLabel["equity"] = "Equity";
})(BalanceSheetEnumToLabel || (exports.BalanceSheetEnumToLabel = BalanceSheetEnumToLabel = {}));
var IncentivesEnumToLabel;
(function (IncentivesEnumToLabel) {
    IncentivesEnumToLabel["noRetirementPlan"] = "No Retirement Plan";
    IncentivesEnumToLabel["fourOneK"] = "401k";
    IncentivesEnumToLabel["profitSharing"] = "Profit Sharing";
    IncentivesEnumToLabel["moneyPurchasePension"] = "Money Purchase Pension";
    IncentivesEnumToLabel["simplePlan"] = "Simple Plan";
    IncentivesEnumToLabel["other"] = "Other";
    IncentivesEnumToLabel["none"] = "None";
})(IncentivesEnumToLabel || (exports.IncentivesEnumToLabel = IncentivesEnumToLabel = {}));
