import React, { ReactElement } from "react";

type UserDropDownLinkType = {
  id: string;
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

export default function UserDropdownLink({
  id,
  children,
  onClick,
  disabled = false,
}: UserDropDownLinkType): ReactElement {
  return (
    <button
      id={id}
      type="button"
      className={`flex gap-2 items-center w-full text-base py-2 px-4 ${
        disabled
          ? "bg-white text-gray-500"
          : "bg-white hover:bg-NexstarSoftGold-light focus:bg-NexstarSoftGold-light cursor-pointer"
      } transition`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
