const IconSizes = {
  xxsmall: ".75rem",
  xsmall: "1rem",
  small: "1.25rem",
  medium: "1.75rem",
  large: "2.5rem",
  xlarge: "3rem",
};

export { IconSizes };

const IconColors = {
  NexstarBlue: "#203b6c",
  NexstarBlueLight: "#50659b",
  NexstarOrange: "#e39022",
  NexstarSlate: "#1f2a44",
  NexstarSkyBlue: "#9BCBEB",
  NexstarSoftGold: "#F2C75C",
  NexstarLime: "#97D700",
  NexstarLimeDark: "#73a55e",
  NexstarSienna: "#DC4405",
  NexstarGray: "#DADBDC",
  NexstarSoftGray: "#F2F2F2",
  NexstarGrayDark: "#a8a9aa",
  white: "#fff",
  black: "#000",
  gray: "#636363",
};

export { IconColors };
