import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function ProfitReportIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m574.63,791.61v-162.93h-83.73v468.2c0,13.35-12.15,25.5-25.52,25.5h-129.58c-22.64-.96-29.78-11.13-30.3-30.68v-463.02h-83.73v215.18c0,13.35-12.15,25.5-25.52,25.5H61.89c-13.36,0-25.52-12.15-25.52-25.5v-234.7c.36-23.34,10.78-30.86,30.7-31.48h103.67V215.48c0-18.79,13.08-25.09,31.1-25.5h129.18c13.36,0,25.52,12.15,25.52,25.5v362.2h83.33v-140.65c.15-16.46,11.4-25.44,26.31-25.5h133.97c13.36,0,25.52,12.15,25.52,25.5v140.65h83.73V170.06c.3-19.31,12.89-24.76,30.7-25.5h129.18c13.36,0,25.52,12.15,25.52,25.5v407.62h83.33V101.12c0-13.35,12.15-25.5,25.52-25.5h129.98c22.62.16,30.07,10.96,30.3,30.68v490.5c3.95,15.27-9.74,32.38-25.52,31.88h-109.25v306.42c0,19.65-11.03,29.55-30.7,29.88h-124.8c-20.84-.47-29.36-10.63-29.9-28.69v-307.62h-83.33v234.52c0,13.35-12.15,31.7-25.52,31.7h-132.77c-19.42-.29-27.55-16.07-27.51-31.7v-71.58Z" />
    </svg>
  );
}
