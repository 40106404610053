import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function BusinessPlanningIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m980.4 327.6h-172.8v-61.199c0-42-34.801-76.801-76.801-76.801h-244.8c-42 0-76.801 34.801-76.801 76.801v61.199h-192c-76.801 0-140.4 61.199-140.4 140.4v400.8c0 76.801 61.199 140.4 140.4 140.4h765.6c76.801 0 140.4-61.199 140.4-140.4v-400.8c-2.3984-78-64.801-140.4-142.8-140.4zm-343.2 554.4v46.801h-73.199v-44.398c-33.602-6-66-18-92.398-33.602-8.3984-3.6016-13.199-14.398-13.199-22.801v-3.6016l19.199-36 4.8008-1.1992c1.1992-1.1992 8.3984-6 16.801-6h6l3.6016 3.6016 3.6016 1.1992c22.801 13.199 50.398 26.398 82.801 26.398 22.801 0 61.199-4.8008 63.602-44.398 0-24-16.801-32.398-69.602-51.602-78-28.801-128.4-55.199-128.4-133.2 0-63.602 38.398-106.8 104.4-120v-49.199h73.199v46.801c27.602 3.6016 55.199 13.199 78 28.801 4.8008 1.1992 9.6016 6 10.801 13.199s1.1992 14.398-3.6016 21.602l-13.199 26.398c-3.6016 9.6016-10.801 14.398-19.199 16.801-6 1.1992-13.199 0-18-4.8008-27.602-14.398-51.602-22.801-74.398-22.801-24 0-54 6-54 40.801 0 26.398 16.801 33.602 60 50.398l9.6016 3.6016c69.602 22.801 128.4 49.199 127.2 123.6-0.007813 63.598-37.211 109.2-104.41 123.6zm109.2-554.4h-278.4v-61.199c0-7.1992 7.1992-16.801 16.801-16.801h247.2c7.1992 0 16.801 7.1992 16.801 16.801-2.4023-0.003906-2.4023 61.199-2.4023 61.199z" />
    </svg>
  );
}
