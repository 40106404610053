import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { Switch } from "@headlessui/react";
import NexstarTooltip from "./NexstarTooltip";

type ToggleProps = {
  checked: any;
  dataTestId?: string;
  label?: string;
  name: string;
  onChange: Dispatch<SetStateAction<boolean>>;
  id: string;
  lightColorScheme?: boolean;
  disabled?: boolean;
  tooltip?: string;
  className?: string;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Toggle({
  checked,
  dataTestId,
  label,
  name,
  onChange,
  id,
  lightColorScheme,
  disabled,
  tooltip,
  className,
}: ToggleProps): ReactElement {
  return (
    <Switch.Group as="div" className="flex items-center" id={id}>
      <Switch
        id={`${id}_switch`}
        name={name}
        checked={checked}
        onChange={onChange}
        className={`group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-none ${className}`}
        disabled={disabled}
        data-testid={dataTestId ? dataTestId : id}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className="pointer-events-none absolute h-full w-full rounded-md bg-transparent"
        />
        <span
          aria-hidden="true"
          className={`${
            checked ? "bg-NexstarOrange" : "bg-black bg-opacity-60"
          } pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out`}
        />
        <span
          aria-hidden="true"
          className={classNames(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out",
          )}
        />
      </Switch>
      <div className="flex gap-2">
        <Switch.Label as="span" className="ml-1 text-base leading-4">
          <span
            className={`text-sm font-bold uppercase ${
              lightColorScheme ? "text-NexstarBlue" : "text-white"
            }`}
          >
            {label}
          </span>
        </Switch.Label>
        {tooltip ? <NexstarTooltip content={tooltip} /> : null}
      </div>
    </Switch.Group>
  );
}
