import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function MPWIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <path d="m473.52,823.29c4.44-8.54,5.13-18.54,1.89-27.61-3.23-9.07-10.09-16.38-18.94-20.18-67.04-30.08-138.37-49.45-211.42-57.41-12.04-1.1-23.79,4.17-30.98,13.88-7.19,9.72-8.8,22.49-4.23,33.69l148.92,365.37h0c2.6,6.37,7.03,11.83,12.74,15.67,5.71,3.84,12.44,5.89,19.32,5.89h83.81c11.26,0,21.82-5.47,28.31-14.67,6.49-9.2,8.1-20.98,4.33-31.59-46.1-129.23-58.71-235.05-33.75-283.04h0Z" />
      <path d="m1040.49,361.6c-10.36-2.64-21.36-.35-29.8,6.21-8.44,6.56-13.38,16.65-13.39,27.34v224.42c0,9.19,3.66,17.99,10.15,24.48,6.5,6.5,15.3,10.14,24.48,10.15,2.89,0,5.76-.36,8.56-1.09,43.42-11.17,79.6-41.13,98.68-81.7,19.08-40.57,19.08-87.54,0-128.12-19.09-40.58-55.26-70.53-98.68-81.7h0Z" />
      <path d="m918.84,50.77c-6.13-2.71-12.91-3.59-19.52-2.53-6.62,1.06-12.78,4.02-17.75,8.51-18.6,16.83-47.35,42.38-82.66,70.11h0c-8.37,6.56-13.26,16.61-13.26,27.24v705.82c0,10.55,4.8,20.52,13.06,27.09,27.36,21.78,53.67,44.22,82.86,70.62,6.66,6.03,15.41,9.24,24.39,8.94,8.98-.3,17.49-4.07,23.74-10.53,6.25-6.46,9.74-15.09,9.74-24.08V82.43c0-6.7-1.95-13.26-5.6-18.88-3.66-5.62-8.86-10.05-14.99-12.77h0Z" />
      <path d="m697.65,175.31c-5.64-2.9-11.97-4.19-18.3-3.76-6.33.44-12.42,2.6-17.61,6.24-105.33,79.17-225.69,136.04-353.74,167.11-36.34,7.51-73.32,11.47-110.43,11.82-53.83,0-103.56,28.71-130.47,75.33-26.91,46.62-26.91,104.05,0,150.66,26.91,46.61,76.65,75.33,130.47,75.33,36.99.21,73.86,4.14,110.06,11.74,128.16,30.91,248.66,87.69,354.11,166.84,6.95,4.91,15.46,7.1,23.91,6.14,8.46-.96,16.26-5,21.93-11.35,5.67-6.35,8.8-14.56,8.81-23.07V206.06c0-6.35-1.75-12.58-5.06-18.01-3.31-5.42-8.04-9.83-13.68-12.75h0Z" />
    </svg>
  );
}
