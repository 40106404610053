import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function CheckIcon({
  size = "small",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m408.14,122.51c-7.89.23-15.38,3.52-20.89,9.18-6.85,6.85-52.22,53.49-99.07,101.6-38.03,39.06-64.83,66.54-79.92,81.98l-84.98-64.73c-6.34-5.52-14.69-8.17-23.05-7.31-8.36.86-15.99,5.14-21.09,11.83-5.09,6.69-7.18,15.2-5.77,23.48,1.4,8.29,6.18,15.63,13.2,20.26l106.35,81.03c5.85,4.45,13.11,6.63,20.44,6.14,7.33-.5,14.24-3.63,19.44-8.82,6.85-6.85,52.22-53.33,99.07-101.44,46.84-48.11,95.16-97.85,98.44-101.13h0c5.85-5.74,9.14-13.6,9.11-21.79-.03-8.19-3.36-16.02-9.25-21.72-5.88-5.71-13.82-8.79-22.01-8.55h0Z" />
    </svg>
  );
}
