import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function BellIcon({
  size = "small",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m136 440c0 17.602 6.3984 33.602 19.199 46.398 12.801 12.801 28.801 19.199 44.801 19.199 14.398 0 27.199-4.8008 36.801-12.801-32-14.398-67.199-40-99.199-67.199-1.6016 3.2031-1.6016 8.0039-1.6016 14.402z" />
      <path d="m491.2 64c0-16-6.3984-30.398-16-40-20.801-20.801-59.199-20.801-80 0-9.6016 9.6016-16 24-16 38.398-22.398-12.801-48-19.199-73.602-19.199-40 0-78.398 16-107.2 44.801l-19.199 19.199c-43.199 43.199-88 70.398-132.8 80-6.3984 1.6016-11.199 3.1992-14.398 8-17.602 12.801-14.398 40 8 76.801 17.602 32 48 68.801 83.199 104 60.801 60.801 124.8 102.4 160 102.4 9.6016 0 17.602-3.1992 22.398-8 3.1992-3.1992 6.3984-9.6016 8-14.398 9.6016-44.801 35.199-89.602 80-132.8l16-16c49.602-49.602 59.199-126.4 28.801-184 14.398 0 28.801-6.3984 38.398-16 9.6016-12.801 14.402-27.199 14.402-43.199zm-33.598 24c-12.801 12.801-33.602 12.801-46.398 0-6.3984-6.3984-9.6016-14.398-9.6016-22.398s3.1992-17.602 9.6016-22.398c6.3984-8.0039 14.398-11.203 23.996-11.203 8 0 17.602 3.1992 22.398 9.6016 6.4023 6.3984 9.6016 14.398 9.6016 22.398 0 9.6016-3.1992 17.602-9.5977 24z" />
    </svg>
  );
}
