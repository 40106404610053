"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBacklogInstallRowData = exports.getPaceToPriorYearRowData = exports.getPriorYearData = exports.getPaceToBudgetRowData = exports.getBudgetRowData = exports.getMTDPaceRowData = exports.getDailyAverageRowData = exports.getDailyVarianceRowData = exports.getDailyGoalRowData = exports.ConsolidatedCalcs = exports.CurrentDayBacklogCalculation = exports.BacklogCalculations = exports.PaceToPriorYearCalcs = exports.PaceToBudgetCalculations = exports.MTDPaceCalculations = exports.DailyAverageCalculations = exports.DailyVarianceCalculations = exports.DailyGoalsCalculations = void 0;
const DailyGoalsCalculations = (monthlyBudget, workingDays) => {
    const dailyGoals = (monthlyBudget / workingDays).toFixed(2);
    const dailyGoal = Number.parseFloat(dailyGoals);
    if (workingDays === null) {
        return 0;
    }
    return dailyGoal;
};
exports.DailyGoalsCalculations = DailyGoalsCalculations;
const DailyVarianceCalculations = (dailyVariances, daysInMonth) => {
    const dailyGoals = (dailyVariances - daysInMonth).toFixed(2);
    const dailyGoal = Number.parseFloat(dailyGoals);
    if (dailyVariances === null) {
        return 0;
    }
    return dailyGoal;
};
exports.DailyVarianceCalculations = DailyVarianceCalculations;
const DailyAverageCalculations = (dailyAverages, workingDayOfMonth) => {
    const averageCalc = (dailyAverages / workingDayOfMonth).toFixed(2);
    const dailyAverage = Number.parseFloat(averageCalc);
    if (!workingDayOfMonth) {
        return 0;
    }
    return dailyAverage;
};
exports.DailyAverageCalculations = DailyAverageCalculations;
const MTDPaceCalculations = (mtdPaces, workingDays) => {
    const mtdPaceCalc = (mtdPaces * workingDays).toFixed(2);
    return Number.parseFloat(mtdPaceCalc);
};
exports.MTDPaceCalculations = MTDPaceCalculations;
const PaceToBudgetCalculations = (mtdPace, budget) => {
    const paceBudgetCalc = (mtdPace - budget).toFixed(2);
    return Number.parseFloat(paceBudgetCalc);
};
exports.PaceToBudgetCalculations = PaceToBudgetCalculations;
const PaceToPriorYearCalcs = (mtdPace, priorYear) => {
    const paceToPriorCalc = (mtdPace - priorYear).toFixed(2);
    return Number.parseFloat(paceToPriorCalc);
};
exports.PaceToPriorYearCalcs = PaceToPriorYearCalcs;
const BacklogCalculations = (backlog, daysSold, installedRev) => {
    const backlogCalcs = (backlog + daysSold - installedRev).toFixed(2);
    return Number.parseFloat(backlogCalcs);
};
exports.BacklogCalculations = BacklogCalculations;
const CurrentDayBacklogCalculation = (revenueSold, installedRev) => {
    if (revenueSold === null) {
        revenueSold = 0;
    }
    if (installedRev === null) {
        installedRev = 0;
    }
    const currentBacklog = (revenueSold - installedRev).toFixed(2);
    return Number.parseFloat(currentBacklog);
};
exports.CurrentDayBacklogCalculation = CurrentDayBacklogCalculation;
// CONSOlIDATED CALCS
const ConsolidatedCalcs = (departmentData, workingDays) => {
    const consolidatedData = {
        today: {
            totalCalls: null,
            totalHours: null,
            totalRevenue: null,
        },
        dailyGoals: {
            totalCalls: null,
            totalHours: null,
            totalRevenue: null,
        },
        dailyAvg: {
            totalCalls: null,
            totalHours: null,
            totalRevenue: null,
        },
        mtdPace: {
            totalCalls: null,
            totalHours: null,
            totalRevenue: null,
        },
        budget: {
            totalCalls: null,
            totalHours: null,
            totalRevenue: null,
        },
        paceToBudget: {
            totalCalls: null,
            totalHours: null,
            totalRevenue: null,
        },
        priorYear: {
            totalCalls: null,
            totalHours: null,
            totalRevenue: null,
        },
        paceToPriorYear: {
            totalCalls: null,
            totalHours: null,
            totalRevenue: null,
        },
    };
    // base values that are used in calculations
    let serviceCalls = 0;
    let installEst = 0;
    let hoursSold = 0;
    let daysInstalled = 0;
    let serviceRev = 0;
    let installedRev = 0;
    let dailyTotalServ = 0;
    let dailyTotalInst = 0;
    let totalGoalsHoursInst = 0;
    let totalGoalsHoursServ = 0;
    let totalGoalRevInstall = 0;
    let totalGoalRevServ = 0;
    let totalCallsAvgServ = 0;
    let totalCallsAvgInst = 0;
    let totalHoursAvgInst = 0;
    let totalHoursAvgServ = 0;
    let totalRevenueAvgInst = 0;
    let totalRevenueAvgServ = 0;
    let totalCallsMTDServ = 0;
    let totalCallsMTDInst = 0;
    let totalHoursMTDServ = 0;
    let totalHoursMTDInst = 0;
    let totalRevenueMTDServ = 0;
    let totalRevenueMTDInst = 0;
    let totalCallsBudgetServ = 0;
    let totalCallsBudgetInst = 0;
    let totalHoursBudgetServ = 0;
    let totalHoursBudgetInst = 0;
    let totalRevenueBudgetServ = 0;
    let totalRevenueBudgetInst = 0;
    let totalCallsPaceToBudgetInst = 0;
    let totalCallsPaceToBudgetServ = 0;
    let totalHoursPaceToBudgetInst = 0;
    let totalHoursPaceToBudgetServ = 0;
    let totalCallsPriorInst = 0;
    let totalCallsPriorServ = 0;
    let totalHoursPriorInst = 0;
    let totalHoursPriorServ = 0;
    let totalRevenuePriorInst = 0;
    let totalRevenuePriorServ = 0;
    let totalCallsPriorPaceInst = 0;
    let totalCallsPriorPaceServ = 0;
    let totalHoursPriorPaceInst = 0;
    let totalHoursPriorPaceServ = 0;
    let totalRevPriorPaceInst = 0;
    let totalRevPriorPaceServ = 0;
    // loops through all departments
    departmentData.forEach((department) => {
        var _a;
        if (department.budget.monthlyBudget.serviceCallsRun !== null) {
            if (department.monthToDate === null) {
                return {
                    serviceCallsRun: 0,
                    hoursSold: 0,
                    serviceRevenue: 0,
                };
            }
            const goalsCallServ = (0, exports.DailyGoalsCalculations)(department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.serviceCallsRun, workingDays);
            const goalsHoursServ = (0, exports.DailyGoalsCalculations)(department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.hoursSold, workingDays);
            const goalRevServ = (0, exports.DailyGoalsCalculations)(department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.serviceRevenue, workingDays);
            const callsAvgServ = (0, exports.DailyAverageCalculations)(department === null || department === void 0 ? void 0 : department.monthToDate.serviceCallsRun, department.currentDay.workingDayOfMonth);
            const hoursAvgServ = (0, exports.DailyAverageCalculations)(department === null || department === void 0 ? void 0 : department.monthToDate.hoursSold, department.currentDay.workingDayOfMonth);
            const revenueAvgServ = (0, exports.DailyAverageCalculations)(department === null || department === void 0 ? void 0 : department.monthToDate.serviceRevenue, department.currentDay.workingDayOfMonth);
            // consolidated calculations for service departments
            serviceCalls += department === null || department === void 0 ? void 0 : department.currentDay.currentDay.serviceCallsRun;
            hoursSold += department === null || department === void 0 ? void 0 : department.currentDay.currentDay.hoursSold;
            serviceRev += department === null || department === void 0 ? void 0 : department.currentDay.currentDay.serviceRevenue;
            dailyTotalServ += goalsCallServ;
            totalGoalsHoursServ += goalsHoursServ;
            totalGoalRevServ += goalRevServ;
            totalCallsAvgServ += callsAvgServ;
            totalHoursAvgServ += hoursAvgServ;
            totalRevenueAvgServ += revenueAvgServ;
            totalCallsMTDServ += callsAvgServ * workingDays;
            totalHoursMTDServ += hoursAvgServ * workingDays;
            totalRevenueMTDServ += revenueAvgServ * workingDays;
            totalCallsBudgetServ += department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.serviceCallsRun;
            totalHoursBudgetServ += department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.hoursSold;
            totalRevenueBudgetServ += department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.serviceRevenue;
            totalCallsPaceToBudgetServ +=
                callsAvgServ * workingDays -
                    (department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.serviceCallsRun);
            totalHoursPaceToBudgetServ +=
                hoursAvgServ * workingDays - (department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.hoursSold);
            totalCallsPriorServ += department === null || department === void 0 ? void 0 : department.budget.priorYear.serviceCallsRun;
            totalHoursPriorServ += department === null || department === void 0 ? void 0 : department.budget.priorYear.hoursSold;
            totalRevenuePriorServ += department === null || department === void 0 ? void 0 : department.budget.priorYear.serviceRevenue;
            totalCallsPriorPaceServ +=
                callsAvgServ * workingDays -
                    (department === null || department === void 0 ? void 0 : department.budget.priorYear.serviceCallsRun);
            totalHoursPriorPaceServ +=
                hoursAvgServ * workingDays - (department === null || department === void 0 ? void 0 : department.budget.priorYear.hoursSold);
            totalRevPriorPaceServ +=
                revenueAvgServ * workingDays -
                    (department === null || department === void 0 ? void 0 : department.budget.priorYear.serviceRevenue);
        }
        else if ((department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.estimates) !== null) {
            if (department.monthToDate === null) {
                return {
                    estimates: 0,
                    daysSold: 0,
                    dollarsSold: 0,
                    daysInstalled: 0,
                    installedRevenue: 0,
                };
            }
            const dailyTotalCallInst = (0, exports.DailyGoalsCalculations)(department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.estimates, workingDays);
            const goalsHoursInst = (0, exports.DailyGoalsCalculations)(department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.daysSold, workingDays);
            const goalRevInstall = (0, exports.DailyGoalsCalculations)(department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.revenueSold, workingDays);
            const callsAvgInst = (0, exports.DailyAverageCalculations)(department === null || department === void 0 ? void 0 : department.monthToDate.estimates, department.currentDay.workingDayOfMonth);
            const hoursAvgInst = (0, exports.DailyAverageCalculations)(department === null || department === void 0 ? void 0 : department.monthToDate.daysInstalled, department.currentDay.workingDayOfMonth);
            const revenueAvgInst = (0, exports.DailyAverageCalculations)(department === null || department === void 0 ? void 0 : department.monthToDate.installedRevenue, department.currentDay.workingDayOfMonth);
            // consolidated calculations for install departments
            installEst += department === null || department === void 0 ? void 0 : department.currentDay.currentDay.estimates;
            daysInstalled += department === null || department === void 0 ? void 0 : department.currentDay.currentDay.daysInstalled;
            installedRev += department === null || department === void 0 ? void 0 : department.currentDay.currentDay.installedRevenue;
            dailyTotalInst += dailyTotalCallInst;
            totalGoalsHoursInst += goalsHoursInst;
            totalGoalRevInstall += goalRevInstall;
            totalCallsAvgInst += callsAvgInst;
            totalHoursAvgInst += hoursAvgInst;
            totalRevenueAvgInst += revenueAvgInst;
            totalCallsMTDInst += callsAvgInst * workingDays;
            totalHoursMTDInst += hoursAvgInst * workingDays;
            totalRevenueMTDInst += revenueAvgInst * workingDays;
            totalCallsBudgetInst += department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.estimates;
            totalHoursBudgetInst += department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.daysSold;
            totalRevenueBudgetInst += department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.revenueSold;
            // TP-1680 MH: needed to subtract accurate budget estimates
            totalCallsPaceToBudgetInst +=
                callsAvgInst * workingDays -
                    ((_a = department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.estimates) !== null && _a !== void 0 ? _a : 0);
            totalHoursPaceToBudgetInst +=
                hoursAvgInst * workingDays - (department === null || department === void 0 ? void 0 : department.budget.monthlyBudget.daysSold);
            totalCallsPriorInst += department === null || department === void 0 ? void 0 : department.budget.priorYear.estimates;
            totalHoursPriorInst += department === null || department === void 0 ? void 0 : department.budget.priorYear.daysSold;
            totalRevenuePriorInst += department === null || department === void 0 ? void 0 : department.budget.priorYear.revenueSold;
            totalCallsPriorPaceInst +=
                callsAvgInst * workingDays - totalCallsPriorInst;
            totalHoursPriorPaceInst +=
                hoursAvgInst * workingDays - totalHoursPriorInst;
            totalRevPriorPaceInst +=
                revenueAvgInst * workingDays - totalRevenuePriorInst;
        }
    });
    // creating consolidated object by adding service & install
    consolidatedData.today.totalCalls = Math.round(serviceCalls + installEst);
    consolidatedData.today.totalRevenue = Math.round(serviceRev + installedRev);
    consolidatedData.dailyGoals.totalCalls = Math.round(dailyTotalServ + dailyTotalInst);
    consolidatedData.dailyGoals.totalRevenue = Math.round(totalGoalRevServ + totalGoalRevInstall);
    consolidatedData.dailyAvg.totalCalls = Math.round(totalCallsAvgServ + totalCallsAvgInst);
    consolidatedData.dailyAvg.totalRevenue = Math.round(totalRevenueAvgServ + totalRevenueAvgInst);
    consolidatedData.mtdPace.totalCalls = Math.round(totalCallsMTDServ + totalCallsMTDInst);
    consolidatedData.mtdPace.totalRevenue = Math.round(totalRevenueMTDServ + totalRevenueMTDInst);
    consolidatedData.budget.totalCalls = Math.round(totalCallsBudgetServ + totalCallsBudgetInst);
    consolidatedData.budget.totalRevenue = Math.round(totalRevenueBudgetServ + totalRevenueBudgetInst);
    consolidatedData.paceToBudget.totalCalls = Math.round(totalCallsPaceToBudgetServ + totalCallsPaceToBudgetInst);
    // TP-1777 MH: changed to use consolidated revenues instead of adding calculated paceToBudget
    consolidatedData.paceToBudget.totalRevenue = Math.round(consolidatedData.mtdPace.totalRevenue -
        consolidatedData.budget.totalRevenue);
    consolidatedData.priorYear.totalCalls = Math.round(totalCallsPriorServ + totalCallsPriorInst);
    consolidatedData.priorYear.totalRevenue = Math.round(totalRevenuePriorServ + totalRevenuePriorInst);
    consolidatedData.paceToPriorYear.totalCalls = Math.round(totalCallsPriorPaceServ + totalCallsPriorPaceInst);
    consolidatedData.paceToPriorYear.totalRevenue = Math.round(totalRevPriorPaceServ + totalRevPriorPaceInst);
    //* multiplied days installed by 8 to get hours of working days *//
    consolidatedData.today.totalHours = hoursSold + daysInstalled * 8;
    consolidatedData.dailyGoals.totalHours =
        totalGoalsHoursServ + totalGoalsHoursInst * 8;
    consolidatedData.dailyAvg.totalHours =
        totalHoursAvgServ + totalHoursAvgInst * 8;
    consolidatedData.mtdPace.totalHours =
        totalHoursMTDServ + totalHoursMTDInst * 8;
    consolidatedData.budget.totalHours =
        totalHoursBudgetServ + totalHoursBudgetInst * 8;
    consolidatedData.paceToBudget.totalHours =
        totalHoursPaceToBudgetServ + totalHoursPaceToBudgetInst * 8;
    consolidatedData.priorYear.totalHours =
        totalHoursPriorServ + totalHoursPriorInst * 8;
    consolidatedData.paceToPriorYear.totalHours =
        totalHoursPriorPaceServ + totalHoursPriorPaceInst * 8;
    return consolidatedData;
};
exports.ConsolidatedCalcs = ConsolidatedCalcs;
function getDailyGoalRowData(paceData, workingDays) {
    var _a;
    const monthlyBudget = (_a = paceData === null || paceData === void 0 ? void 0 : paceData.budget) === null || _a === void 0 ? void 0 : _a.monthlyBudget;
    if (monthlyBudget === null) {
        return {
            // install
            estimates: 0,
            daysSold: 0,
            dollarsSold: 0,
            daysInstalled: 0,
            installedRevenue: 0,
            // service
            serviceCallsRun: 0,
            hoursSold: 0,
            serviceRevenue: 0,
        };
    }
    const { estimates, daysSold, revenueSold, daysInstalled, installedRevenue, serviceCallsRun, hoursSold, serviceRevenue, } = { ...monthlyBudget };
    return {
        // install
        estimates: (0, exports.DailyGoalsCalculations)(estimates, workingDays),
        daysSold: (0, exports.DailyGoalsCalculations)(daysSold, workingDays),
        dollarsSold: (0, exports.DailyGoalsCalculations)(revenueSold, workingDays),
        daysInstalled: (0, exports.DailyGoalsCalculations)(daysInstalled, workingDays),
        installedRevenue: (0, exports.DailyGoalsCalculations)(installedRevenue, workingDays),
        // service
        serviceCallsRun: (0, exports.DailyGoalsCalculations)(serviceCallsRun, workingDays),
        hoursSold: (0, exports.DailyGoalsCalculations)(hoursSold, workingDays),
        serviceRevenue: (0, exports.DailyGoalsCalculations)(serviceRevenue, workingDays),
    };
}
exports.getDailyGoalRowData = getDailyGoalRowData;
function getDailyVarianceRowData(paceData, dailyGoal) {
    var _a;
    const currentDay = (_a = paceData === null || paceData === void 0 ? void 0 : paceData.currentDay) === null || _a === void 0 ? void 0 : _a.currentDay;
    Object.keys(currentDay).forEach((key) => {
        if (!currentDay[key]) {
            currentDay[key] = 0;
        }
    });
    const { estimates, daysSold, revenueSold, daysInstalled, installedRevenue, serviceCallsRun, hoursSold, serviceRevenue, } = { ...currentDay };
    return {
        // install
        estimates: (0, exports.DailyVarianceCalculations)(estimates, dailyGoal.estimates),
        daysSold: (0, exports.DailyVarianceCalculations)(daysSold, dailyGoal.daysSold),
        dollarsSold: (0, exports.DailyVarianceCalculations)(revenueSold, dailyGoal.dollarsSold),
        daysInstalled: (0, exports.DailyVarianceCalculations)(daysInstalled, dailyGoal.daysSold),
        installedRevenue: (0, exports.DailyVarianceCalculations)(installedRevenue, dailyGoal.dollarsSold),
        // service
        serviceCallsRun: (0, exports.DailyVarianceCalculations)(serviceCallsRun, dailyGoal.serviceCallsRun),
        hoursSold: (0, exports.DailyVarianceCalculations)(hoursSold, dailyGoal.hoursSold),
        serviceRevenue: (0, exports.DailyVarianceCalculations)(serviceRevenue, dailyGoal.serviceRevenue),
    };
}
exports.getDailyVarianceRowData = getDailyVarianceRowData;
function getDailyAverageRowData(paceData) {
    var _a, _b;
    const currentDay = (_a = paceData === null || paceData === void 0 ? void 0 : paceData.currentDay) === null || _a === void 0 ? void 0 : _a.currentDay;
    const mtd = paceData.monthToDate;
    if (mtd === null || currentDay === null) {
        return {
            estimates: 0,
            daysSold: 0,
            dollarsSold: 0,
            daysInstalled: 0,
            installedRevenue: 0,
            // service
            serviceCallsRun: 0,
            hoursSold: 0,
            serviceRevenue: 0,
        };
    }
    const updatedMtd = {
        // install
        estimates: mtd.estimates + currentDay.estimates,
        daysSold: mtd.daysSold + currentDay.daysSold,
        dollarsSold: mtd.revenueSold + currentDay.revenueSold,
        daysInstalled: mtd.daysInstalled + currentDay.daysInstalled,
        installedRevenue: mtd.installedRevenue + currentDay.installedRevenue,
        // service
        serviceCallsRun: mtd.serviceCallsRun + currentDay.serviceCallsRun,
        hoursSold: mtd.hoursSold + currentDay.hoursSold,
        serviceRevenue: mtd.serviceRevenue + currentDay.serviceRevenue,
    };
    const workingDayOfMonth = (_b = paceData === null || paceData === void 0 ? void 0 : paceData.currentDay) === null || _b === void 0 ? void 0 : _b.workingDayOfMonth;
    if (workingDayOfMonth === null) {
        return {
            // install
            estimates: 0,
            daysSold: 0,
            dollarsSold: 0,
            daysInstalled: 0,
            installedRevenue: 0,
            // service
            serviceCallsRun: 0,
            hoursSold: 0,
            serviceRevenue: 0,
        };
    }
    return {
        // install
        estimates: (0, exports.DailyAverageCalculations)(updatedMtd.estimates, workingDayOfMonth),
        daysSold: (0, exports.DailyAverageCalculations)(updatedMtd.daysSold, workingDayOfMonth),
        dollarsSold: (0, exports.DailyAverageCalculations)(updatedMtd.dollarsSold, workingDayOfMonth),
        daysInstalled: (0, exports.DailyAverageCalculations)(updatedMtd.daysInstalled, workingDayOfMonth),
        installedRevenue: (0, exports.DailyAverageCalculations)(updatedMtd.installedRevenue, workingDayOfMonth),
        // service
        serviceCallsRun: (0, exports.DailyAverageCalculations)(updatedMtd.serviceCallsRun, workingDayOfMonth),
        hoursSold: (0, exports.DailyAverageCalculations)(updatedMtd.hoursSold, workingDayOfMonth),
        serviceRevenue: (0, exports.DailyAverageCalculations)(updatedMtd.serviceRevenue, workingDayOfMonth),
    };
}
exports.getDailyAverageRowData = getDailyAverageRowData;
function getMTDPaceRowData(dailyAverage, workingDays) {
    return {
        // install
        estimates: (0, exports.MTDPaceCalculations)(dailyAverage.estimates, workingDays),
        daysSold: (0, exports.MTDPaceCalculations)(dailyAverage.daysSold, workingDays),
        dollarsSold: (0, exports.MTDPaceCalculations)(dailyAverage.dollarsSold, workingDays),
        daysInstalled: (0, exports.MTDPaceCalculations)(dailyAverage.daysInstalled, workingDays),
        installedRevenue: (0, exports.MTDPaceCalculations)(dailyAverage.installedRevenue, workingDays),
        // service
        serviceCallsRun: (0, exports.MTDPaceCalculations)(dailyAverage.serviceCallsRun, workingDays),
        hoursSold: (0, exports.MTDPaceCalculations)(dailyAverage.hoursSold, workingDays),
        serviceRevenue: (0, exports.MTDPaceCalculations)(dailyAverage.serviceRevenue, workingDays),
    };
}
exports.getMTDPaceRowData = getMTDPaceRowData;
function getBudgetRowData(paceData) {
    var _a;
    const monthlyBudget = (_a = paceData === null || paceData === void 0 ? void 0 : paceData.budget) === null || _a === void 0 ? void 0 : _a.monthlyBudget;
    if (monthlyBudget === null) {
        return {
            // install
            estimates: 0,
            daysSold: 0,
            dollarsSold: 0,
            daysInstalled: 0,
            installedRevenue: 0,
            // service
            serviceCallsRun: 0,
            hoursSold: 0,
            serviceRevenue: 0,
        };
    }
    return {
        // install
        estimates: monthlyBudget.estimates,
        daysSold: monthlyBudget.daysSold,
        dollarsSold: monthlyBudget.revenueSold,
        daysInstalled: monthlyBudget.daysInstalled,
        installedRevenue: monthlyBudget.installedRevenue,
        // service
        serviceCallsRun: monthlyBudget.serviceCallsRun,
        hoursSold: monthlyBudget.hoursSold,
        serviceRevenue: monthlyBudget.serviceRevenue,
    };
}
exports.getBudgetRowData = getBudgetRowData;
function getPaceToBudgetRowData(mtdPace, budget, monthlyBudget) {
    return {
        // install
        estimates: (0, exports.PaceToBudgetCalculations)(mtdPace.estimates, budget.estimates),
        daysSold: (0, exports.PaceToBudgetCalculations)(mtdPace.daysSold, budget.daysSold),
        dollarsSold: (0, exports.PaceToBudgetCalculations)(mtdPace.dollarsSold, budget.dollarsSold),
        // MH: next two rows changed from daysSold and dollarsSold
        daysInstalled: (0, exports.PaceToBudgetCalculations)(mtdPace.daysInstalled, monthlyBudget.daysSold),
        installedRevenue: (0, exports.PaceToBudgetCalculations)(mtdPace.installedRevenue, monthlyBudget.revenueSold),
        // service
        serviceCallsRun: (0, exports.PaceToBudgetCalculations)(mtdPace.serviceCallsRun, budget.serviceCallsRun),
        hoursSold: (0, exports.PaceToBudgetCalculations)(mtdPace.hoursSold, budget.hoursSold),
        serviceRevenue: (0, exports.PaceToBudgetCalculations)(mtdPace.serviceRevenue, budget.serviceRevenue),
    };
}
exports.getPaceToBudgetRowData = getPaceToBudgetRowData;
function getPriorYearData(paceData) {
    var _a;
    const priorYear = (_a = paceData === null || paceData === void 0 ? void 0 : paceData.budget) === null || _a === void 0 ? void 0 : _a.priorYear;
    if (priorYear === null) {
        return {
            // install
            estimates: 0,
            daysSold: 0,
            dollarsSold: 0,
            daysInstalled: 0,
            installedRevenue: 0,
            // service
            serviceCallsRun: 0,
            hoursSold: 0,
            serviceRevenue: 0,
        };
    }
    return {
        // install
        estimates: priorYear.estimates,
        daysSold: priorYear.daysSold,
        dollarsSold: priorYear.revenueSold,
        // MH: next two rows changed from daysSold and dollarsSold
        daysInstalled: priorYear.daysInstalled,
        installedRevenue: priorYear.installedRevenue,
        // service
        serviceCallsRun: priorYear.serviceCallsRun,
        hoursSold: priorYear.hoursSold,
        serviceRevenue: priorYear.serviceRevenue,
    };
}
exports.getPriorYearData = getPriorYearData;
function getPaceToPriorYearRowData(mtdPace, priorYear) {
    return {
        // install
        estimates: (0, exports.PaceToPriorYearCalcs)(mtdPace.estimates, priorYear.estimates),
        daysSold: (0, exports.PaceToPriorYearCalcs)(mtdPace.daysSold, priorYear.daysSold),
        dollarsSold: (0, exports.PaceToPriorYearCalcs)(mtdPace.dollarsSold, priorYear.dollarsSold),
        // MH: next two rows changed from daysSold and dollarsSold
        daysInstalled: (0, exports.PaceToPriorYearCalcs)(mtdPace.daysInstalled, priorYear.daysInstalled),
        installedRevenue: (0, exports.PaceToPriorYearCalcs)(mtdPace.installedRevenue, priorYear.installedRevenue),
        // service
        serviceCallsRun: (0, exports.PaceToPriorYearCalcs)(mtdPace.serviceCallsRun, priorYear.serviceCallsRun),
        hoursSold: (0, exports.PaceToPriorYearCalcs)(mtdPace.hoursSold, priorYear.hoursSold),
        serviceRevenue: (0, exports.PaceToPriorYearCalcs)(mtdPace.serviceRevenue, priorYear.serviceRevenue),
    };
}
exports.getPaceToPriorYearRowData = getPaceToPriorYearRowData;
function getBacklogInstallRowData(paceData) {
    return {
        // install
        installedRevenue: paceData.monthToDate === null
            ? (0, exports.BacklogCalculations)(paceData === null || paceData === void 0 ? void 0 : paceData.budget.monthlyBudget.backlog, paceData === null || paceData === void 0 ? void 0 : paceData.currentDay.currentDay.revenueSold, paceData === null || paceData === void 0 ? void 0 : paceData.currentDay.currentDay.installedRevenue)
            : (0, exports.BacklogCalculations)((paceData === null || paceData === void 0 ? void 0 : paceData.budget.monthlyBudget.backlog) +
                (paceData === null || paceData === void 0 ? void 0 : paceData.monthToDate.backlog), paceData === null || paceData === void 0 ? void 0 : paceData.currentDay.currentDay.revenueSold, paceData === null || paceData === void 0 ? void 0 : paceData.currentDay.currentDay.installedRevenue),
    };
}
exports.getBacklogInstallRowData = getBacklogInstallRowData;
