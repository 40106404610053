import { useAuth0 } from "@auth0/auth0-react";
//TODO MH: switch to font-awesome
import React from "react";
import UserDropdownLink from "../navigation/UserDropdownLink";
import LogoutIcon from "../../utils/components/icons/LogoutIcon";

function Auth0LogoutButton(): JSX.Element {
  const { logout } = useAuth0();

  return (
    <UserDropdownLink
      id="auth0LogoutButton"
      onClick={() => {
        // TP-296: Updating using local storage to store the toolsByRole/isBetaUser
        window.localStorage.removeItem("isBetaUser");
        window.localStorage.removeItem("toolsByRole");
        window.localStorage.removeItem("coachView");
        window.sessionStorage.removeItem("isBetaUser");
        window.sessionStorage.removeItem("toolsByRole");
        window.sessionStorage.removeItem("userSelectedCompany");
        window.sessionStorage.removeItem("emulatedUser");
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }}
    >
      Logout
      <LogoutIcon color="gray" />
    </UserDropdownLink>
  );
}
export default Auth0LogoutButton;
