"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOverheadObjects = exports.seasonalityPercentCalc = exports.totalGrossProfitPercentage = exports.monthlyGrossProfitPercentage = exports.totalDirectCostsPercentage = exports.monthlyTotalDirectCostsPercentage = exports.totalOtherDirectsPercentage = exports.monthlyOtherDirectsPercentage = exports.totalSalesExpensePercentage = exports.monthlySalesExpensePercentage = exports.totalMaterialPercentage = exports.monthlyMaterialPercentage = exports.totalLaborPercentage = exports.monthlyLaborPercentage = void 0;
const utils_1 = require("../../../../utils");
const monthlyLaborPercentage = (companyData, month) => {
    if (!companyData ||
        !(month in companyData) ||
        !companyData[month].totalIncome)
        return 0;
    return (companyData[month].budgetedLaborAmount / companyData[month].totalIncome);
};
exports.monthlyLaborPercentage = monthlyLaborPercentage;
const totalLaborPercentage = (companyData) => {
    if (!companyData ||
        !("companyTotals" in companyData) ||
        !companyData.companyTotals.totalIncome)
        return 0;
    return (companyData.companyTotals.budgetedLaborAmount /
        companyData.companyTotals.totalIncome);
};
exports.totalLaborPercentage = totalLaborPercentage;
const monthlyMaterialPercentage = (companyData, month) => {
    if (!companyData ||
        !(month in companyData) ||
        !companyData[month].totalIncome)
        return 0;
    return (companyData[month].budgetedMaterialAmount / companyData[month].totalIncome);
};
exports.monthlyMaterialPercentage = monthlyMaterialPercentage;
const totalMaterialPercentage = (companyData) => {
    if (!companyData ||
        !("companyTotals" in companyData) ||
        !companyData.companyTotals.totalIncome)
        return 0;
    return (companyData.companyTotals.budgetedMaterialAmount /
        companyData.companyTotals.totalIncome);
};
exports.totalMaterialPercentage = totalMaterialPercentage;
const monthlySalesExpensePercentage = (companyData, month) => {
    if (!companyData ||
        !(month in companyData) ||
        !companyData[month].totalIncome)
        return 0;
    return (companyData[month].budgetedSalesExpAmount / companyData[month].totalIncome);
};
exports.monthlySalesExpensePercentage = monthlySalesExpensePercentage;
const totalSalesExpensePercentage = (companyData) => {
    if (!companyData ||
        !("companyTotals" in companyData) ||
        !companyData.companyTotals.totalIncome)
        return 0;
    return (companyData.companyTotals.budgetedSalesExpAmount /
        companyData.companyTotals.totalIncome);
};
exports.totalSalesExpensePercentage = totalSalesExpensePercentage;
const monthlyOtherDirectsPercentage = (companyData, month) => {
    if (!companyData ||
        !(month in companyData) ||
        !companyData[month].totalIncome)
        return 0;
    return (companyData[month].budgetedOtherDirectAmount /
        companyData[month].totalIncome);
};
exports.monthlyOtherDirectsPercentage = monthlyOtherDirectsPercentage;
const totalOtherDirectsPercentage = (companyData) => {
    if (!companyData ||
        !("companyTotals" in companyData) ||
        !companyData.companyTotals.totalIncome)
        return 0;
    return (companyData.companyTotals.budgetedOtherDirectAmount /
        companyData.companyTotals.totalIncome);
};
exports.totalOtherDirectsPercentage = totalOtherDirectsPercentage;
const monthlyTotalDirectCostsPercentage = (companyData, month) => {
    if (!companyData ||
        !(month in companyData) ||
        !companyData[month].totalIncome)
        return 0;
    return companyData[month].totalDirectCost / companyData[month].totalIncome;
};
exports.monthlyTotalDirectCostsPercentage = monthlyTotalDirectCostsPercentage;
const totalDirectCostsPercentage = (companyData) => {
    if (!companyData ||
        !("companyTotals" in companyData) ||
        !companyData.companyTotals.totalIncome)
        return 0;
    return (companyData.companyTotals.totalDirectCost /
        companyData.companyTotals.totalIncome);
};
exports.totalDirectCostsPercentage = totalDirectCostsPercentage;
const monthlyGrossProfitPercentage = (companyData, month) => {
    if (!companyData ||
        !(month in companyData) ||
        !companyData[month].totalIncome)
        return 0;
    return companyData[month].grossProfit / companyData[month].totalIncome;
};
exports.monthlyGrossProfitPercentage = monthlyGrossProfitPercentage;
const totalGrossProfitPercentage = (companyData) => {
    if (!companyData ||
        !("companyTotals" in companyData) ||
        !companyData.companyTotals.totalIncome)
        return 0;
    return (companyData.companyTotals.grossProfit /
        companyData.companyTotals.totalIncome);
};
exports.totalGrossProfitPercentage = totalGrossProfitPercentage;
// overhead display calculations
//TODO TP-1818 KRJ
function seasonalityPercentCalc(companyObj) {
    let seasonalityObj = {};
    if (companyObj) {
        utils_1.shortMonthsArray.forEach((month) => {
            seasonalityObj = {
                ...seasonalityObj,
                [month]: companyObj[month].totalIncome / companyObj.companyTotals.totalIncome,
            };
        });
    }
    return seasonalityObj;
}
exports.seasonalityPercentCalc = seasonalityPercentCalc;
function createOverheadObjects(overheads, companyObj) {
    const overheadsForCompany = [];
    overheads.forEach((overhead) => {
        let modifiedOverhead = {
            ...overhead,
            overheadName: overhead.overheadName,
            overheadOrder: overhead.overheadOrder,
            budgetMethod: overhead.budgetMethod,
            monthlyVariance: overhead.monthlyVariance,
        };
        if (overhead.budgetMethod === "FLAT") {
            utils_1.shortMonthsArray.forEach((month) => {
                modifiedOverhead = {
                    ...modifiedOverhead,
                    [month]: overhead.nextYearTotal / 12,
                };
            });
            overheadsForCompany.push(modifiedOverhead);
        }
        if (overhead.budgetMethod === "MANUAL") {
            Object.keys(overhead.monthlyVariance).forEach((month) => {
                modifiedOverhead = {
                    ...modifiedOverhead,
                    [month]: overhead.nextYearTotal * (overhead.monthlyVariance[month] / 100),
                };
            });
            overheadsForCompany.push(modifiedOverhead);
        }
        if (overhead.budgetMethod === "SEASONAL") {
            utils_1.shortMonthsArray.forEach((month) => {
                const seasonality = seasonalityPercentCalc(companyObj);
                modifiedOverhead = {
                    ...modifiedOverhead,
                    [month]: overhead.nextYearTotal * seasonality[month],
                };
            });
            overheadsForCompany.push(modifiedOverhead);
        }
    });
    return overheadsForCompany;
}
exports.createOverheadObjects = createOverheadObjects;
