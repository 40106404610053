import React from "react";
import { IconType } from "../../../../types/IconType";
import { IconColors } from "../../../helpers/IconHelpers";

export default function ThreeDayCallIcon({
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1200 1200"
    >
      <g>
        <path d="m1038 883.2-213.6-110.4c-30-15.602-67.199-7.1992-87.602 19.199l-78 100.8c-76.801-40.801-134.4-80.398-201.6-147.6-72-72-110.4-130.8-148.8-205.2l99.602-78c26.398-20.398 34.801-57.602 19.199-87.602l-110.4-212.4c-19.199-37.199-67.199-49.199-102-26.398l-135.6 91.199c-31.199 21.602-49.199 57.602-46.801 94.801 2.3984 38.398 7.1992 87.602 16.801 121.2 39.602 152.4 134.4 309.6 268.8 442.8 133.2 133.2 290.4 228 442.8 268.8 33.602 8.3984 82.801 13.199 122.4 16.801 38.398 2.3984 75.602-15.602 96-48l88.801-138c19.203-36.004 6.0039-82.805-29.996-102z" />
        <path d="m606 477.6c26.398 7.1992 52.801 21.602 73.199 43.199 21.602 21.602 36 46.801 43.199 73.199 6 21.602 26.398 36 49.199 34.801h4.8008c31.199-1.1992 54-31.199 45.602-61.199-12-43.199-34.801-85.199-69.602-118.8-34.801-34.801-75.602-57.602-118.8-69.602-30-8.3984-60 14.398-61.199 45.602v4.8008c-2.3984 21.598 12 42 33.602 48z" />
        <path d="m620.4 304.8c66 12 129.6 43.199 180 93.602 51.602 51.602 82.801 114 93.602 180 4.8008 24 26.398 42 51.602 40.801h3.6016c30-1.1992 51.602-28.801 45.602-57.602-15.602-86.398-56.398-169.2-122.4-236.4-67.199-67.199-148.8-108-236.4-122.4-28.801-4.8008-56.398 16.801-57.602 45.602v3.6016c-0.007812 26.391 17.992 47.992 41.992 52.793z" />
        <path d="m1170 554.4c-18-128.4-75.602-252-174-350.4s-222-156-350.4-174c-28.801-3.6016-55.199 16.801-56.398 46.801v4.8008c-1.1992 26.398 18 48 43.199 51.602 106.8 14.398 208.8 63.602 290.4 145.2 81.602 81.602 129.6 184.8 145.2 290.4 3.6016 25.199 25.199 44.398 51.602 43.199h4.8008c27.598-2.3984 49.199-28.801 45.598-57.602z" />
      </g>
    </svg>
  );
}
