import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DisplayNotes } from "../helpers/ReleaseNotesHelper";
import NexstarModal from "./tailwind/NexstarModal";
import CardHeader from "./tailwind/CardHeader";
import Button from "./tailwind/Button";
import LoadingCard from "./tailwind/LoadingCard";
import { ReleaseNote } from "@nexstar-network/shared-utils";

type ReleaseDialogTypes = {
  openReleaseDialog: boolean;
  setOpenReleaseDialog: Dispatch<SetStateAction<boolean>>;
};

function ReleaseDialog({
  openReleaseDialog,
  setOpenReleaseDialog,
}: ReleaseDialogTypes): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const [releaseNoteData, setReleaseNoteData] = useState({} as ReleaseNote);
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setOpenReleaseDialog(false);
    window.localStorage.setItem(
      "lastViewedVersion",
      process.env.REACT_APP_TOOLS_VERSION,
    );
  };

  const descriptionElementRef = useRef<HTMLElement>(null);

  const getReleaseData = async (
    getAccessTokenSilently: ({
      audience,
      scope,
      detailedResponse,
    }: {
      audience: string;
      scope: string;
      detailedResponse: boolean;
    }) => Promise<string>,
  ) => {
    const accessToken =
      process.env.REACT_APP_NODE_ENV !== "development"
        ? await getAccessTokenSilently({
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
            scope: "openid profile email",
            detailedResponse: false,
          })
        : process.env.REACT_APP_TEST_API_AUTH_KEY;

    const url = `${process.env.REACT_APP_API_BASE_URL}release/${process.env.REACT_APP_TOOLS_VERSION}`;

    await axios({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      url,
    })
      .then((data: any) => {
        setReleaseNoteData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        throw Error(error.message);
      });
  };

  useEffect(() => {
    if (openReleaseDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      getReleaseData(getAccessTokenSilently);
    }
  }, [openReleaseDialog]);

  const displayReleaseNotes = (): JSX.Element => {
    if (loading !== true) {
      // Needed to add this so that if there is no data in teh response it doesn't break
      if (
        releaseNoteData &&
        "notes" in releaseNoteData &&
        releaseNoteData.notes !== null
      ) {
        return <div>{DisplayNotes(releaseNoteData)}</div>;
      }
      handleClose();
    }
    return (
      <div>
        <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
        <div className="h-4 bg-gray-300 rounded-full mb-5 animate-pulse" />
        <div className="h-4 bg-gray-300 rounded-full animate-pulse" />
      </div>
    );
  };

  if (releaseNoteData === undefined || releaseNoteData === null)
    return <LoadingCard />;

  return (
    <NexstarModal
      id="releaseDialogModal"
      open={openReleaseDialog}
      onClose={handleClose}
      aria-labelledby="release notes dialog"
      aria-describedby="release notes dialog"
      maxWidth="max-w-[700px]"
    >
      <CardHeader title={`What's New - ${releaseNoteData.version}`} />
      <div className="flex flex-col gap-6 p-6">
        {displayReleaseNotes()}
        <div className="flex justify-center">
          <Button onClick={handleClose} id="ReleaseDialogAcknowledgement">
            Thanks, I got it
          </Button>
        </div>
      </div>
    </NexstarModal>
  );
}

export default ReleaseDialog;
