"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeCategory = void 0;
var EmployeeCategory;
(function (EmployeeCategory) {
    EmployeeCategory["CALL_CENTER_MANAGER"] = "CALL_CENTER_MANAGER";
    EmployeeCategory["CONTROLLER_HEAD_BOOKKEEPER"] = "CONTROLLER_HEAD_BOOKKEEPER";
    EmployeeCategory["CSR"] = "CSR";
    EmployeeCategory["DISPATCHER"] = "DISPATCHER";
    EmployeeCategory["ELEC_APPRENTICE_HELPERS"] = "ELEC_APPRENTICE_HELPERS";
    EmployeeCategory["ELEC_INSTALLER_REMODELER"] = "ELEC_INSTALLER_REMODELER";
    EmployeeCategory["ELEC_SERVICE_TECH"] = "ELEC_SERVICE_TECH";
    EmployeeCategory["GENERAL_OPERATIONS_MANAGER"] = "GENERAL_OPERATIONS_MANAGER";
    EmployeeCategory["GENERAL_ADMIN"] = "GENERAL_ADMIN";
    EmployeeCategory["GENERAL_SHOP"] = "GENERAL_SHOP";
    EmployeeCategory["HR"] = "HR";
    EmployeeCategory["HVAC_APPRENTICE_HELPER"] = "HVAC_APPRENTICE_HELPER";
    EmployeeCategory["HVAC_INSTALLER"] = "HVAC_INSTALLER";
    EmployeeCategory["HVAC_SERVICE_TECH"] = "HVAC_SERVICE_TECH";
    EmployeeCategory["INSIDE_SALES"] = "INSIDE_SALES";
    EmployeeCategory["INSTALL_COORDINATOR"] = "INSTALL_COORDINATOR";
    EmployeeCategory["INSTALL_MANAGER"] = "INSTALL_MANAGER";
    EmployeeCategory["IT"] = "IT";
    EmployeeCategory["MARKTER"] = "MARKTER";
    EmployeeCategory["NEW_CONST_APPRENTICE_HELPERS"] = "NEW_CONST_APPRENTICE_HELPERS";
    EmployeeCategory["NEW_CONST_INSTALLER_REMODELER"] = "NEW_CONST_INSTALLER_REMODELER";
    EmployeeCategory["OFFICE_MANAGER"] = "OFFICE_MANAGER";
    EmployeeCategory["OFFICER"] = "OFFICER";
    EmployeeCategory["OWNER"] = "OWNER";
    EmployeeCategory["PLUMBING_APPRENTICE_HELPER"] = "PLUMBING_APPRENTICE_HELPER";
    EmployeeCategory["PLUMBING_INSTALLER_REMODELER"] = "PLUMBING_INSTALLER_REMODELER";
    EmployeeCategory["PLUMBING_SERVICE_TECH"] = "PLUMBING_SERVICE_TECH";
    EmployeeCategory["SALES_LEAD_COORDINATOR"] = "SALES_LEAD_COORDINATOR";
    EmployeeCategory["SALES_MANAGER"] = "SALES_MANAGER";
    EmployeeCategory["SALESPERSON"] = "SALESPERSON";
    EmployeeCategory["SERVICE_MANAGER"] = "SERVICE_MANAGER";
    EmployeeCategory["STAFF_ACCOUNTANT"] = "STAFF_ACCOUNTANT";
    EmployeeCategory["TRADE_ADMINISTRATOR"] = "TRADE_ADMINISTRATOR";
    EmployeeCategory["WAREHOUSE"] = "WAREHOUSE";
})(EmployeeCategory || (exports.EmployeeCategory = EmployeeCategory = {}));
