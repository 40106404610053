import axios from "axios";

async function reportErrorToServiceDesk(
  errorObject: any,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
): Promise<any> {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  return axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}error-logging`,
    method: "post",
    data: errorObject,
  });
}

export { reportErrorToServiceDesk };
