import axios from "axios";
import { empty, ReportParams } from "@nexstar-network/shared-utils";

export const getPHP_API_URI = () =>
  !empty(process.env.REACT_APP_PHP_API_BASE_URL)
    ? process.env.REACT_APP_PHP_API_BASE_URL
    : process.env.REACT_APP_API_BASE_URL;

export const exportDataToExcel = async (
  params: Record<string, any>,
  fileName: string,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
  uri: string,
  fileType: "xlsx" | "csv" = "xlsx",
) => {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  const result = await axios({
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: "blob",
    url: `${getPHP_API_URI()}${uri}`,
    data: params,
  })
    .then((data: any) => {
      // https://medium.com/@storrisi/how-to-show-a-pdf-stream-on-a-react-client-without-any-library-36220fee60cb
      //Create a Blob from the PDF Stream
      const file = new Blob([data.data], {
        type:
          fileType === "xlsx"
            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : "text/csv;charset=utf-8",
      }); //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("href", fileURL);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return true;
    })
    .catch(() => false);
  return result;
};

export const importDataFromExcel = async (
  formData,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) => {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;
  const result = await axios
    .post(`${getPHP_API_URI()}tools/import`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return result;
};

/**
 *
 * @param params
 *     dataFormat: "visualization" | "excel" | "pdf";
 *     dataType: "data" | "template";
 *     exportType: "CV" | "OKPI" | "DT" | "DB" | "CB";
 *     dataRequested: "MTM" | "CONSOLIDATED";
 *     companyId: string; // should only be the number,
 *     fiscalYear: string;
 *     startMonth: MonthKeyType;
 *     endMonth: MonthKeyType;
 *     departments?: string[];
 * @param getAccessTokenSilently
 */
export const getDataVisualizationData = async (
  params: ReportParams,
  getAccessTokenSilently: ({
    audience,
    scope,
    detailedResponse,
  }: {
    audience: string;
    scope: string;
    detailedResponse: boolean;
  }) => Promise<string>,
) => {
  const accessToken =
    process.env.REACT_APP_NODE_ENV !== "development"
      ? await getAccessTokenSilently({
          audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
          scope: "openid profile email",
          detailedResponse: false,
        })
      : process.env.REACT_APP_TEST_API_AUTH_KEY;

  const result = await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: `${process.env.REACT_APP_API_BASE_URL}bpw/data`,
    method: "post",
    data: params,
  })
    .then((result: any) => {
      return result.data;
    })
    .catch(() => false);
  return result;
};
