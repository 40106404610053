import { Dispatch, SetStateAction } from "react";
import { Alert, empty } from "@nexstar-network/shared-utils";
import { DateTime } from "luxon";
import { RESTAPIErrorType } from "../../../types/ErrorMessageType";
import { handleRESTErrors } from "../../../utils/helpers/RequestHelpers";

export const handleMarkSingleAsRead = (
  ev,
  alertID: number,
  numberOfUnread: number,
  setNumberOfUnread: Dispatch<SetStateAction<number>>,
  markAlertsAsRead: any,
  queryClient: any,
  refetch: any,
  preventDefault: boolean,
  enqueueSnackbar: any,
) => {
  setNumberOfUnread(() => numberOfUnread - 1);
  markAlertsAsRead.mutate(alertID, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getNotifications"], {
        refetchType: "all",
      });
      refetch();
    },
    onError: (saveError: RESTAPIErrorType) => {
      handleRESTErrors(saveError, enqueueSnackbar);
    },
  });
  preventDefault && ev.preventDefault();
};

export const markAllAsRead = (
  ev,
  alerts: Array<Alert>,
  setAlerts: Dispatch<SetStateAction<Array<Alert>>>,
  numberOfUnread: number,
  setNumberOfUnread: Dispatch<SetStateAction<number>>,
  userID: number,
  markAlertsAsRead: any,
  queryClient: any,
  refetch: any,
  enqueueSnackbar: any,
) => {
  const markedAsRead = [];
  const alertsToUpdate = { notificationIds: [], userID };
  for (let i = 0; i < alerts.length; i++) {
    markedAsRead.push({ ...alerts[i], readTime: "read" });
    if (empty(alerts[i].readTime)) {
      alertsToUpdate.notificationIds.push(alerts[i].notificationID);
    }
  }
  setNumberOfUnread(0);
  setAlerts([...markedAsRead]);
  markAlertsAsRead.mutate(alertsToUpdate, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getNotifications"], {
        refetchType: "all",
      });
      refetch();
    },
    onError: (saveError: RESTAPIErrorType) => {
      handleRESTErrors(saveError, enqueueSnackbar);
    },
  });
  ev.preventDefault();
};

export const orderedAlerts = (alerts: Alert[]) => {
  if (alerts) {
    return Object.values(alerts).sort(
      (a: any, b: any) =>
        // @ts-ignore
        DateTime.fromISO(b.created_at) - DateTime.fromISO(a.created_at),
    );
  }
};
