import React from "react";
import { Disclosure } from "@headlessui/react";
import ChevronUpIcon from "../icons/ChevronUpIcon";
import ChevronDownIcon from "../icons/ChevronDownIcon";
import NexstarTooltip from "./NexstarTooltip";

type AccordionProps = {
  children: React.ReactNode;
  id: string;
  dataTestId?: string;
  error?: boolean;
  title: string | React.ReactNode;
  tooltip?: string;
  rounded?: boolean;
  defaultOpen?: boolean;
};

export default function Accordion({
  children,
  id,
  dataTestId,
  error,
  title,
  tooltip,
  rounded = true,
  defaultOpen = false,
}: AccordionProps): JSX.Element {
  return (
    <div className="w-full">
      <div className="mx-auto w-full rounded-2xl bg-white">
        {/* The defaultOpen prop is only used initially, but changing the key prop causes the component to remount, so I'm using that to force the Disclosure to open if there is an error -- BK */}
        <Disclosure
          defaultOpen={error || defaultOpen}
          key={`error-state-${error}`}
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                id={id}
                data-testid={dataTestId ? dataTestId : id}
                className={`flex w-full justify-between items-center ${
                  rounded ? "rounded-lg" : "rounded-none"
                } ${
                  error
                    ? "bg-red-200 hover:bg-red-300"
                    : "bg-NexstarSoftGray hover:bg-NexstarGray"
                } px-4 py-2 text-left text-lg font-bold leading-normal text-NexstarBlue focus:outline-none focus-visible:ring focus-visible:ring-NexstarBlue`}
              >
                <div className="flex gap-2">
                  <span>{title}</span>
                  {tooltip && <NexstarTooltip content={tooltip} />}
                </div>
                {open ? (
                  <ChevronDownIcon color="NexstarBlue" size="small" />
                ) : (
                  <ChevronUpIcon color="NexstarBlue" size="small" />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="p-4 text-sm text-gray-500">
                {children}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
