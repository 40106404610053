"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * C5
 * ='Call Count'!D19
 * Required Demand Call Count Based on Staffing
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getDemandCallsRanPerMonth(object, month, companyData, workingDays) {
    return null;
}
/**
 * C6
 * ='Call Count'!D11
 * Budgeted Year System Check Call Count
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSystemCheckCallsPerMonth(object, month, companyData, workingDays) {
    return null;
}
/**
 * C7
 * C5+C6
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function calcTotalCalls(object, month, companyData, workingDays) {
    return null;
}
/**
 * C9
 * ='Call Count'!D15
 * Budgeted Year # of Techs
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getTechStaffing(object, month, companyData, workingDays) {
    return null;
}
/**
 * C10
 * ='Call Count'!D4
 * # of Calls Ran Per Day Per Tech
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getCallsPerTechPerDay(object, month, companyData, workingDays) {
    return null;
}
/**
 * C13
 * ='Conv and Average Sale'!D11
 * Demand Call Budget Year Conversion Rate
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getConversionRateDemandCalls(object, month, companyData, workingDays) {
    return null;
}
/**
 * C14
 * ='Conv and Average Sale'!D19
 * Demand Call Budget Year Average Sale
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getAverageSaleDemandCalls(object, month, companyData, workingDays) {
    return null;
}
/**
 * C15
 * =IFERROR(C16/C5,0)
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getDemandCallsPercentLeadsGenerated(object, month, companyData, workingDays) {
    return null;
}
/**
 * C16
 * ='Max Capacity'!D20
 * # of Demand Turnovers
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getNumberTechLeadsDemand(object, month, companyData, workingDays) {
    return null;
}
/**
 * C19
 * ='Conv and Average Sale'!D7
 * System Check Budget Year Conversion Rate
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getConversionRateSystemCheckCalls(object, month, companyData, workingDays) {
    return null;
}
/**
 * C20
 * ='Conv and Average Sale'!D15
 * System Check Budget Year Average Sale
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getAverageSaleSystemCheckCalls(object, month, companyData, workingDays) {
    return null;
}
/**
 * C21
 * =IFERROR(C22/C6,0)
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSystemCheckCallsPercentLeadsGenerated(object, month, companyData, workingDays) {
    return null;
}
/**
 * C22
 * ='Max Capacity'!D19
 * # of System Check Turnovers
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getNumberTechGeneratedLeadsSystemChecks(object, month, companyData, workingDays) {
    return null;
}
/**
 * C25
 * ='Direct Costs'!D35
 * Sold Hour Per Month Goal
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSoldHoursPerMthGoal(object, month, companyData, workingDays) {
    return null;
}
/**
 * C28
 * ='Service Agreements'!L173
 * New Agreement Distribution
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getAgreementsToSell(object, month, companyData, workingDays) {
    return null;
}
/**
 * C31
 * ='Direct Costs'!D7
 * Monthly Agreement Revenue
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getAgreementRevenue(object, month, companyData, workingDays) {
    return object.directCosts.jan.sppRevenue;
}
/**
 * C32
 * ='Direct Costs'!D13
 * Total Income
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getRevenue(object, month, companyData, workingDays) {
    return null;
}
/**
 C33
 * ='Direct Costs'!E20
 * Budgeted Material %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getMaterial(object, month, companyData, workingDays) {
    return null;
}
/**
 C34
 * ='Direct Costs'!E17
 * Budgeted Labor %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getLabor(object, month, companyData, workingDays) {
    return null;
}
/**
 C35
 * ='Direct Costs'!E23
 * Budgeted Sales Expense %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getSalesExpense(object, month, companyData, workingDays) {
    return null;
}
/**
 C36
 * ='Direct Costs'!E26
 * Budgeted Other Direct %
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getOther(object, month, companyData, workingDays) {
    return null;
}
/**
 C37
 * ='Direct Costs'!E30
 * Gross Profit $
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getGrossProfitPercent(object, month, companyData, workingDays) {
    return null;
}
/**
 C38
 * ='Direct Costs'!D30
 * Gross Profit $
 *
 * @param object
 * @param month
 * @param companyData
 * @param workingDays
 */
function getGrossProfitDollars(object, month, companyData, workingDays) {
    return null;
}
