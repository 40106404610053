import React from "react";
import HelpIcon from "../icons/HelpIcon";

interface NexstarTooltipProps {
  content: string;
  position?: "left" | "right" | "bottom" | "top";
  color?: "white" | "gray";
  size?: "small" | "medium" | "large";
}
export default function NexstarTooltip({
  content,
  position = "right",
  color = "gray",
  size = "small",
}: NexstarTooltipProps): JSX.Element {
  return (
    <div
      data-tooltip-id="my-tooltip"
      data-tooltip-html={content}
      data-tooltip-place={position}
      data-tooltip-variant="light"
      className="inline-flex cursor-help flex-inline"
    >
      <div className="rounded-full opacity-80 hover:opacity-100 hover:ring-NexstarOrange hover:ring-2">
        <HelpIcon size={size} color={color} />
      </div>
    </div>
  );
}
