import React from "react";
import { CRMUser, UserNameOrDefault } from "../../utils/helpers/UserHelper";

function UserEmulationWarning({
  emulatedUser,
  isCoach,
  selectedCompany,
  primaryCompany,
}: {
  emulatedUser: CRMUser;
  isCoach: boolean;
  selectedCompany: any;
  primaryCompany: any;
}): JSX.Element {
  if (!emulatedUser) {
    if (isCoach)
      return (
        <div id="emulation-message" className="bg-NexstarSkyBlue">
          <p className="text-NexstarBlue-dark font-bold leading-tight text-center px-6 py-1">{`${
            primaryCompany.companyId === selectedCompany.companyId
              ? ""
              : "Emulating: "
          }${selectedCompany.companyName}`}</p>
        </div>
      );
    return null;
  }
  return (
    <div className="bg-NexstarOrange">
      <p className="text-NexstarBlue-dark text-base leading-tight p-2">{`Emulating: ${UserNameOrDefault(
        emulatedUser,
      )}`}</p>
    </div>
  );
}

export default UserEmulationWarning;
