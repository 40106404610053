"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcTotalGrowthOverLastYearPercent = exports.calcTotalGrowthOverLastYear = exports.calcTotalRevenue = exports.calcTotalGrowthPercentOverLastYearForDepartment = exports.calcTotalGrowthOverLastYearForDepartment = exports.calcTotalRevenueForDepartment = void 0;
const revenueSummaryServiceCalcsFromFE_1 = require("./revenueSummaryServiceCalcsFromFE");
const revenueSummaryInstallCalcsFromFE_1 = require("./revenueSummaryInstallCalcsFromFE");
const utils_1 = require("../../../../utils");
function calcTotalRevenueForDepartment(departmentId, preWorkData, workshopData, associatedDepartmentId, departmentType) {
    const budgetDemandRevenue = (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearDemandRevenue)(workshopData, departmentId, "demandCalls");
    const budgetDemandSystemCheckRevenue = (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearDemandRevenue)(workshopData, departmentId, "demandSystemCheckCalls");
    const budgetSystemCheckRevenue = (0, revenueSummaryServiceCalcsFromFE_1.calcBudgetedYearSystemCheckRevenue)(workshopData, departmentId);
    const budgetAgreementRevenue = (0, revenueSummaryServiceCalcsFromFE_1.getBudgetYearTotalAgreementRevenue)(workshopData, departmentId);
    const budgetTechLeadRevenue = (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearTechLeadRevenue)(workshopData, departmentId, associatedDepartmentId);
    const budgetMarketedLeadRevenue = (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearMarketedLeadRevenue)(workshopData, departmentId);
    const budgetOtherLeadRevenue = (0, revenueSummaryInstallCalcsFromFE_1.calcBudgetYearOtherLeadRevenue)(workshopData, departmentId);
    return departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT
        ? budgetDemandRevenue +
            budgetDemandSystemCheckRevenue +
            budgetSystemCheckRevenue +
            budgetAgreementRevenue
        : budgetTechLeadRevenue +
            budgetMarketedLeadRevenue +
            budgetOtherLeadRevenue;
}
exports.calcTotalRevenueForDepartment = calcTotalRevenueForDepartment;
function calcTotalGrowthOverLastYearForDepartment(departmentId, preWorkData, workshopData, associatedDepartmentId, departmentType) {
    const growthOverLastYear = (0, revenueSummaryServiceCalcsFromFE_1.calcGrowthOverLastYear)(preWorkData, workshopData, departmentId, "demandCalls");
    const growthOverLastYearDemandSystemCheck = (0, revenueSummaryServiceCalcsFromFE_1.calcGrowthOverLastYear)(preWorkData, workshopData, departmentId, "demandSystemCheckCalls");
    const systemCheckGrowth = (0, revenueSummaryServiceCalcsFromFE_1.calcSystemCheckGrowthOverLastYear)(preWorkData, workshopData, departmentId);
    const agreementGrowth = (0, revenueSummaryServiceCalcsFromFE_1.calcAgreementGrowthOverLastYear)(preWorkData, workshopData, departmentId);
    const techGrowthOverLastYear = (0, revenueSummaryInstallCalcsFromFE_1.calcTechGeneratedGrowthOverPriorYear)(preWorkData, workshopData, departmentId, associatedDepartmentId);
    const marketedGrowthOverLastYear = (0, revenueSummaryInstallCalcsFromFE_1.calcMarketedLeadGrowthOverLastYear)(preWorkData, workshopData, departmentId);
    const otherGrowthOverLastYear = (0, revenueSummaryInstallCalcsFromFE_1.calcOtherLeadsGrowthOverLastYear)(preWorkData, workshopData, departmentId);
    return departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT
        ? growthOverLastYear +
            systemCheckGrowth +
            agreementGrowth +
            growthOverLastYearDemandSystemCheck
        : techGrowthOverLastYear +
            marketedGrowthOverLastYear +
            otherGrowthOverLastYear;
}
exports.calcTotalGrowthOverLastYearForDepartment = calcTotalGrowthOverLastYearForDepartment;
function calcTotalGrowthPercentOverLastYearForDepartment(departmentId, preWorkData, workshopData, associatedDepartmentId, departmentType) {
    const totalGrowthLastYear = calcTotalGrowthOverLastYearForDepartment(departmentId, preWorkData, workshopData, associatedDepartmentId, departmentType);
    const serviceGrowth = (0, revenueSummaryServiceCalcsFromFE_1.getPriorYearAgreementsRevenue)(preWorkData, departmentId) +
        (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearSystemCheckRevenue)(preWorkData, departmentId) +
        (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearDemandRevenue)(preWorkData, departmentId, "demandCalls") +
        (0, revenueSummaryServiceCalcsFromFE_1.calcPreviousYearDemandRevenue)(preWorkData, departmentId, "demandSystemCheckCalls");
    const installGrowth = (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearOtherLeadRevenue)(preWorkData, workshopData, departmentId) +
        (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearMarketedLeadRevenue)(preWorkData, workshopData, departmentId) +
        (0, revenueSummaryInstallCalcsFromFE_1.calcPriorYearTechGeneratedLeadRevenue)(preWorkData, workshopData, departmentId);
    if (departmentType === utils_1.DepartmentTypeEnum.SERVICE_DEPARTMENT) {
        if (!serviceGrowth)
            return 0;
        return totalGrowthLastYear / serviceGrowth;
    }
    if (!installGrowth)
        return 0;
    return totalGrowthLastYear / installGrowth;
}
exports.calcTotalGrowthPercentOverLastYearForDepartment = calcTotalGrowthPercentOverLastYearForDepartment;
function calcTotalRevenue(departmentId, preWorkData, workshopData, associatedId, departmentType) {
    let totalRevenue = 0;
    // departments.forEach((department) => {
    const revenueForDept = calcTotalRevenueForDepartment(departmentId, preWorkData, workshopData, associatedId, departmentType);
    totalRevenue += revenueForDept;
    // });
    return totalRevenue;
}
exports.calcTotalRevenue = calcTotalRevenue;
function calcTotalGrowthOverLastYear(departmentId, preWorkData, workshopData, associatedId, departmentType) {
    let totalGrowth = 0;
    // departments.forEach((department) => {
    const growthLastYearForDept = calcTotalGrowthOverLastYearForDepartment(departmentId, preWorkData, workshopData, associatedId, departmentType);
    totalGrowth += growthLastYearForDept;
    // });
    return totalGrowth;
}
exports.calcTotalGrowthOverLastYear = calcTotalGrowthOverLastYear;
function calcTotalGrowthOverLastYearPercent(departmentId, preWorkData, workshopData, associatedId, departmentType) {
    const totalRevenue = calcTotalRevenue(departmentId, preWorkData, workshopData, associatedId, departmentType);
    const totalGrowthOverLastYear = calcTotalGrowthOverLastYear(departmentId, preWorkData, workshopData, associatedId, departmentType);
    const totalRevenueMinusGrowth = totalRevenue - totalGrowthOverLastYear;
    if (!totalGrowthOverLastYear || !totalRevenueMinusGrowth)
        return 0;
    return totalGrowthOverLastYear / totalRevenueMinusGrowth;
}
exports.calcTotalGrowthOverLastYearPercent = calcTotalGrowthOverLastYearPercent;
