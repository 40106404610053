import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function InNewTabIcon({
  size = "small",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        stroke={IconColors[color]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="11"
      >
        <path transform="scale(5.12)" d="m86.639 13.996-38.741 39.207" />
        <path transform="scale(5.12)" d="m63.978 12.674h23.381v23.225" />
        <path
          transform="scale(5.12)"
          d="m48.367 23.04h-32.125v61.841h61.841l-7.63e-4 -32.126"
        />
      </g>
    </svg>
  );
}
