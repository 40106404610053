"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MPWPreworkEnum = exports.MPWWorkshopEnum = exports.CompensationEnum = exports.YesNoEnum = exports.AccountingBasisEnum = exports.BusinessTypeEnum = exports.OverheadsCategoryEnum = exports.otherFinancesEnum = exports.DMTKPINameEnum = exports.ChannelNameEnum = exports.ChannelTemplateEnum = exports.AppAccessEnum = exports.AppsEnum = exports.Big5SubcategoryEnum = exports.Big5Enum = exports.DepartmentTradeEnum = exports.DepartmentTypeEnum = exports.ActionPlanPriorityEnum = exports.ActionPlanStatusEnum = exports.TimeOffCategoryEnum = exports.ToolsEnum = exports.MethodOfPricing = exports.AccessEnum = exports.MemberMembership = void 0;
var MemberMembership;
(function (MemberMembership) {
    MemberMembership["GOLD"] = "GOLD";
    MemberMembership["SILVER"] = "SILVER";
    MemberMembership["MAL"] = "MAL";
    MemberMembership["NONE"] = "NONE";
})(MemberMembership || (exports.MemberMembership = MemberMembership = {}));
var AccessEnum;
(function (AccessEnum) {
    AccessEnum["ADMIN"] = "ADMIN";
    AccessEnum["USER"] = "USER";
})(AccessEnum || (exports.AccessEnum = AccessEnum = {}));
var MethodOfPricing;
(function (MethodOfPricing) {
    MethodOfPricing["HOURLY"] = "HOURLY";
    MethodOfPricing["DAILY"] = "DAILY";
})(MethodOfPricing || (exports.MethodOfPricing = MethodOfPricing = {}));
var ToolsEnum;
(function (ToolsEnum) {
    ToolsEnum["PROFITPRICER"] = "PROFITPRICER";
})(ToolsEnum || (exports.ToolsEnum = ToolsEnum = {}));
var TimeOffCategoryEnum;
(function (TimeOffCategoryEnum) {
    TimeOffCategoryEnum["PAID_VACATION"] = "PAID_VACATION";
    TimeOffCategoryEnum["PAID_HOLIDAY"] = "PAID_HOLIDAY";
    TimeOffCategoryEnum["PAID_ILLNESS"] = "PAID_ILLNESS";
    TimeOffCategoryEnum["PAID_OTHER"] = "PAID_OTHER";
    TimeOffCategoryEnum["UNPAID_VACATION"] = "UNPAID_VACATION";
    TimeOffCategoryEnum["UNPAID_HOLIDAY"] = "UNPAID_HOLIDAY";
    TimeOffCategoryEnum["UNPAID_ILLNESS"] = "UNPAID_ILLNESS";
    TimeOffCategoryEnum["UNPAID_OTHER"] = "UNPAID_OTHER";
})(TimeOffCategoryEnum || (exports.TimeOffCategoryEnum = TimeOffCategoryEnum = {}));
var ActionPlanStatusEnum;
(function (ActionPlanStatusEnum) {
    ActionPlanStatusEnum["ACTION_PLAN_STATUS_NOT_STARTED"] = "ACTION_PLAN_STATUS_NOT_STARTED";
    ActionPlanStatusEnum["ACTION_PLAN_STATUS_STARTED"] = "ACTION_PLAN_STATUS_STARTED";
    ActionPlanStatusEnum["ACTION_PLAN_STATUS_COMPLETED"] = "ACTION_PLAN_STATUS_COMPLETED";
})(ActionPlanStatusEnum || (exports.ActionPlanStatusEnum = ActionPlanStatusEnum = {}));
var ActionPlanPriorityEnum;
(function (ActionPlanPriorityEnum) {
    ActionPlanPriorityEnum["ACTION_PLAN_PRIORITY_LOW"] = "ACTION_PLAN_PRIORITY_LOW";
    ActionPlanPriorityEnum["ACTION_PLAN_PRIORITY_MEDIUM"] = "ACTION_PLAN_PRIORITY_MEDIUM";
    ActionPlanPriorityEnum["ACTION_PLAN_PRIORITY_HIGH"] = "ACTION_PLAN_PRIORITY_HIGH";
})(ActionPlanPriorityEnum || (exports.ActionPlanPriorityEnum = ActionPlanPriorityEnum = {}));
var DepartmentTypeEnum;
(function (DepartmentTypeEnum) {
    DepartmentTypeEnum["INSTALL_DEPARTMENT"] = "INSTALL_DEPARTMENT";
    DepartmentTypeEnum["SERVICE_DEPARTMENT"] = "SERVICE_DEPARTMENT";
})(DepartmentTypeEnum || (exports.DepartmentTypeEnum = DepartmentTypeEnum = {}));
var DepartmentTradeEnum;
(function (DepartmentTradeEnum) {
    DepartmentTradeEnum["DEPARTMENT_TRADE_HVAC"] = "DEPARTMENT_TRADE_HVAC";
    DepartmentTradeEnum["DEPARTMENT_TRADE_PLUMBING"] = "DEPARTMENT_TRADE_PLUMBING";
    DepartmentTradeEnum["DEPARTMENT_TRADE_ELECTRICAL"] = "DEPARTMENT_TRADE_ELECTRICAL";
    DepartmentTradeEnum["DEPARTMENT_TRADE_DRAINS"] = "DEPARTMENT_TRADE_DRAINS";
    DepartmentTradeEnum["DEPARTMENT_TRADE_SEWER"] = "DEPARTMENT_TRADE_SEWER";
    DepartmentTradeEnum["DEPARTMENT_TRADE_COMM"] = "DEPARTMENT_TRADE_COMM";
    DepartmentTradeEnum["DEPARTMENT_TRADE_OTHER"] = "DEPARTMENT_TRADE_OTHER";
})(DepartmentTradeEnum || (exports.DepartmentTradeEnum = DepartmentTradeEnum = {}));
var Big5Enum;
(function (Big5Enum) {
    Big5Enum["DIRECT_LABOR"] = "DIRECT_LABOR";
    Big5Enum["MATERIALS"] = "MATERIALS";
    Big5Enum["OFFICE_SALARY_BENEFITS"] = "OFFICE_SALARY_BENEFITS";
    Big5Enum["ADVERTISING"] = "ADVERTISING";
    Big5Enum["VEHICLE"] = "VEHICLE";
    Big5Enum["OTHER"] = "OTHER";
})(Big5Enum || (exports.Big5Enum = Big5Enum = {}));
var Big5SubcategoryEnum;
(function (Big5SubcategoryEnum) {
    // SALARY
    Big5SubcategoryEnum["OFFICE_SALARIES"] = "OFFICE_SALARIES";
    Big5SubcategoryEnum["OFFICE_PAYROLL_TAXES"] = "OFFICE_PAYROLL_TAXES";
    Big5SubcategoryEnum["RECRUITING_EXPENSE"] = "RECRUITING_EXPENSE";
    Big5SubcategoryEnum["OFFICE_HEALTH_INSURANCE_BENEFITS"] = "OFFICE_HEALTH_INSURANCE_BENEFITS";
    Big5SubcategoryEnum["RETIREMENT_PLANS"] = "RETIREMENT_PLANS";
    // ADVERTISING
    Big5SubcategoryEnum["ADVERTISING_EXPENSES"] = "ADVERTISING_EXPENSES";
    // OTHER
    Big5SubcategoryEnum["BAD_DEBTS"] = "BAD_DEBTS";
    Big5SubcategoryEnum["BANK_CREDIT_CARD_FEES"] = "BANK_CREDIT_CARD_FEES";
    Big5SubcategoryEnum["COMPUTER_SOFTWARE_SERVICE_EXPENSE"] = "COMPUTER_SOFTWARE_SERVICE_EXPENSE";
    Big5SubcategoryEnum["DEPRECIATION"] = "DEPRECIATION";
    Big5SubcategoryEnum["DONATIONS"] = "DONATIONS";
    Big5SubcategoryEnum["DUES_SUBSCRIPTIONS"] = "DUES_SUBSCRIPTIONS";
    Big5SubcategoryEnum["INSURANCE"] = "INSURANCE";
    Big5SubcategoryEnum["LICENSES_FEES"] = "LICENSES_FEES";
    Big5SubcategoryEnum["MISCELLANEOUS"] = "MISCELLANEOUS";
    Big5SubcategoryEnum["OFFICE_SUPPLIES_POSTAGE"] = "OFFICE_SUPPLIES_POSTAGE";
    Big5SubcategoryEnum["PROFESSIONAL_FEES"] = "PROFESSIONAL_FEES";
    Big5SubcategoryEnum["RENT_BUILDING_REAL_ESTATE_TAXES"] = "RENT_BUILDING_REAL_ESTATE_TAXES";
    Big5SubcategoryEnum["RENT_EQUIPMENT_UNIFORMS"] = "RENT_EQUIPMENT_UNIFORMS";
    Big5SubcategoryEnum["REPAIRS_MAINTENANCE"] = "REPAIRS_MAINTENANCE";
    Big5SubcategoryEnum["SMALL_TOOLS_SUPPLIES"] = "SMALL_TOOLS_SUPPLIES";
    Big5SubcategoryEnum["TRAINING_EDUCATION"] = "TRAINING_EDUCATION";
    Big5SubcategoryEnum["TRAVEL_ENTERTAINMENT"] = "TRAVEL_ENTERTAINMENT";
    Big5SubcategoryEnum["UTILITIES_TELEPHONE"] = "UTILITIES_TELEPHONE";
    // VEHICLE
    Big5SubcategoryEnum["VEHICLE_MAINTENANCE_REPAIRS"] = "VEHICLE_MAINTENANCE_REPAIRS";
    Big5SubcategoryEnum["FUEL"] = "FUEL";
    Big5SubcategoryEnum["VEHICLE_LEASE_PAYMENTS_DEPRECIATION"] = "VEHICLE_LEASE_PAYMENTS_DEPRECIATION";
    Big5SubcategoryEnum["AUTO_INSURANCE_OTHER_VEHICLE"] = "AUTO_INSURANCE_OTHER_VEHICLE";
})(Big5SubcategoryEnum || (exports.Big5SubcategoryEnum = Big5SubcategoryEnum = {}));
var AppsEnum;
(function (AppsEnum) {
    AppsEnum["PROFITPRICER"] = "PROFITPRICER";
    AppsEnum["THREEDAYCALL"] = "THREEDAYCALL";
    AppsEnum["ACTIONPLAN"] = "ACTIONPLAN";
    AppsEnum["EXPORT"] = "EXPORT";
    AppsEnum["IMPORT"] = "IMPORT";
    AppsEnum["REPORTS"] = "REPORTS";
    AppsEnum["COMPANYMANAGEMENT"] = "COMPANYMANAGEMENT";
    AppsEnum["DAILYPACE"] = "DAILYPACE";
    AppsEnum["WHATIFS"] = "WHATIFS";
    AppsEnum["ADMIN"] = "ADMIN";
    AppsEnum["DMT"] = "DMT";
    AppsEnum["MPW"] = "MPW";
    AppsEnum["PROFITREPORT"] = "PROFITREPORT";
    AppsEnum["FINANCIALSURVEY"] = "FINANCIALSURVEY";
    AppsEnum["BPW"] = "BPW";
    AppsEnum["CHARTS"] = "CHARTS";
})(AppsEnum || (exports.AppsEnum = AppsEnum = {}));
var AppAccessEnum;
(function (AppAccessEnum) {
    AppAccessEnum["BETA"] = "BETA";
    AppAccessEnum["ACCESS"] = "ACCESS";
    AppAccessEnum["NO_ACCESS"] = "NO_ACCESS";
})(AppAccessEnum || (exports.AppAccessEnum = AppAccessEnum = {}));
var ChannelTemplateEnum;
(function (ChannelTemplateEnum) {
    ChannelTemplateEnum["PAID_SEARCH"] = "PAID_SEARCH";
    ChannelTemplateEnum["LOCAL_SERVICE_ADS"] = "LOCAL_SERVICE_ADS";
    ChannelTemplateEnum["ONLINE_LEAD_GENERATION"] = "ONLINE_LEAD_GENERATION";
})(ChannelTemplateEnum || (exports.ChannelTemplateEnum = ChannelTemplateEnum = {}));
var ChannelNameEnum;
(function (ChannelNameEnum) {
    ChannelNameEnum["PAID_SEARCH"] = "Paid Search";
    ChannelNameEnum["LOCAL_SERVICE_ADS"] = "Local Service Ads";
    ChannelNameEnum["ONLINE_LEAD_GENERATION"] = "Online Lead Generation";
})(ChannelNameEnum || (exports.ChannelNameEnum = ChannelNameEnum = {}));
var DMTKPINameEnum;
(function (DMTKPINameEnum) {
    DMTKPINameEnum["clickThroughRate"] = "Click-Through Rate";
    DMTKPINameEnum["conversionRate"] = "Conversion Rate";
    DMTKPINameEnum["bookingRate"] = "Booking Rate";
    DMTKPINameEnum["returnOnInvestment"] = "Return on Investment";
})(DMTKPINameEnum || (exports.DMTKPINameEnum = DMTKPINameEnum = {}));
var otherFinancesEnum;
(function (otherFinancesEnum) {
    otherFinancesEnum["interestIncome"] = "Interest Income";
    otherFinancesEnum["otherIncome"] = "Other Income";
    otherFinancesEnum["pppForgiveness"] = "PPP Forgiveness";
    otherFinancesEnum["interestExpenses"] = "Interest Expenses";
    otherFinancesEnum["otherExpenses"] = "Other Expenses";
    otherFinancesEnum["incomeTaxes"] = "Income Taxes";
})(otherFinancesEnum || (exports.otherFinancesEnum = otherFinancesEnum = {}));
var OverheadsCategoryEnum;
(function (OverheadsCategoryEnum) {
    OverheadsCategoryEnum["OFFICE_SALARIES"] = "OFFICE_SALARIES";
    OverheadsCategoryEnum["OFFICE_PAYROLL_TAXES"] = "OFFICE_PAYROLL_TAXES";
    OverheadsCategoryEnum["RECRUITING_EXPENSE"] = "RECRUITING_EXPENSE";
    OverheadsCategoryEnum["OFFICE_HEALTH_INSURANCE_BENEFITS"] = "OFFICE_HEALTH_INSURANCE_BENEFITS";
    OverheadsCategoryEnum["RETIREMENT_PLANS"] = "RETIREMENT_PLANS";
    OverheadsCategoryEnum["ADVERTISING_EXPENSES"] = "ADVERTISING_EXPENSES";
    OverheadsCategoryEnum["BAD_DEBTS"] = "BAD_DEBTS";
    OverheadsCategoryEnum["BANK_CREDIT_CARD_FEES"] = "BANK_CREDIT_CARD_FEES";
    OverheadsCategoryEnum["COMPUTER_SOFTWARE_SERVICE_EXPENSE"] = "COMPUTER_SOFTWARE_SERVICE_EXPENSE";
    OverheadsCategoryEnum["DEPRECIATION"] = "DEPRECIATION";
    OverheadsCategoryEnum["DONATIONS"] = "DONATIONS";
    OverheadsCategoryEnum["DUES_SUBSCRIPTIONS"] = "DUES_SUBSCRIPTIONS";
    OverheadsCategoryEnum["INSURANCE"] = "INSURANCE";
    OverheadsCategoryEnum["LICENSES_FEES"] = "LICENSES_FEES";
    OverheadsCategoryEnum["MISCELLANEOUS"] = "MISCELLANEOUS";
    OverheadsCategoryEnum["OFFICE_SUPPLIES_POSTAGE"] = "OFFICE_SUPPLIES_POSTAGE";
    OverheadsCategoryEnum["PROFESSIONAL_FEES"] = "PROFESSIONAL_FEES";
    OverheadsCategoryEnum["RENT_BUILDING_REAL_ESTATE_TAXES"] = "RENT_BUILDING_REAL_ESTATE_TAXES";
    OverheadsCategoryEnum["RENT_EQUIPMENT_UNIFORMS"] = "RENT_EQUIPMENT_UNIFORMS";
    OverheadsCategoryEnum["REPAIRS_MAINTENANCE"] = "REPAIRS_MAINTENANCE";
    OverheadsCategoryEnum["SMALL_TOOLS_SUPPLIES"] = "SMALL_TOOLS_SUPPLIES";
    OverheadsCategoryEnum["TRAINING_EDUCATION"] = "TRAINING_EDUCATION";
    OverheadsCategoryEnum["TRAVEL_ENTERTAINMENT"] = "TRAVEL_ENTERTAINMENT";
    OverheadsCategoryEnum["UTILITIES_TELEPHONE"] = "UTILITIES_TELEPHONE";
    OverheadsCategoryEnum["VEHICLE_MAINTENANCE_REPAIRS"] = "VEHICLE_MAINTENANCE_REPAIRS";
    OverheadsCategoryEnum["FUEL"] = "FUEL";
    OverheadsCategoryEnum["VEHICLE_LEASE_PAYMENTS_DEPRECIATION"] = "VEHICLE_LEASE_PAYMENTS_DEPRECIATION";
    OverheadsCategoryEnum["AUTO_INSURANCE_OTHER_VEHICLE"] = "AUTO_INSURANCE_OTHER_VEHICLE";
    // DIRECT_LABOR = "DIRECT_LABOR",
    // OTHER = "OTHER",
    // OFFICE_SALARY_BENEFITS = "OFFICE_SALARY_BENEFITS",
    // ADVERTISING = "ADVERTISING",
    // VEHICLE = "VEHICLE",
})(OverheadsCategoryEnum || (exports.OverheadsCategoryEnum = OverheadsCategoryEnum = {}));
var BusinessTypeEnum;
(function (BusinessTypeEnum) {
    BusinessTypeEnum["CORPORATIONS"] = "CORPORATIONS";
    BusinessTypeEnum["LIMITED_LIABILITY_COMPANIES"] = "LIMITED_LIABILITY_COMPANIES";
    BusinessTypeEnum["GENERAL_PARTNERSHIPS"] = "GENERAL_PARTNERSHIPS";
    BusinessTypeEnum["LIMITED_PARTNERSHIPS"] = "LIMITED_PARTNERSHIPS";
    BusinessTypeEnum["BRANCH"] = "BRANCH";
    BusinessTypeEnum["SOLE_PROPRIETORSHIP"] = "SOLE_PROPRIETORSHIP";
})(BusinessTypeEnum || (exports.BusinessTypeEnum = BusinessTypeEnum = {}));
var AccountingBasisEnum;
(function (AccountingBasisEnum) {
    AccountingBasisEnum["ACCRUAL"] = "ACCRUAL";
    AccountingBasisEnum["CASH"] = "CASH";
})(AccountingBasisEnum || (exports.AccountingBasisEnum = AccountingBasisEnum = {}));
var YesNoEnum;
(function (YesNoEnum) {
    YesNoEnum["YES"] = "YES";
    YesNoEnum["NO"] = "NO";
})(YesNoEnum || (exports.YesNoEnum = YesNoEnum = {}));
var CompensationEnum;
(function (CompensationEnum) {
    CompensationEnum["PRIMARY"] = "PRIMARY";
    CompensationEnum["SECONDARY"] = "SECONDARY";
    CompensationEnum["N_A"] = "N_A";
})(CompensationEnum || (exports.CompensationEnum = CompensationEnum = {}));
var MPWWorkshopEnum;
(function (MPWWorkshopEnum) {
    MPWWorkshopEnum["MARKETING_WORKSHEET"] = "marketingWorksheet";
    MPWWorkshopEnum["FUTURE_MARKETING_PLAN"] = "futureMarketingPlan";
    MPWWorkshopEnum["IMPLEMENTATION_CHART"] = "implementationChart";
    MPWWorkshopEnum["PRODUCTION_CHART"] = "productionChart";
    MPWWorkshopEnum["FINALIZE"] = "finalize";
})(MPWWorkshopEnum || (exports.MPWWorkshopEnum = MPWWorkshopEnum = {}));
var MPWPreworkEnum;
(function (MPWPreworkEnum) {
    MPWPreworkEnum["CURRENT_SITUATION"] = "currentSituation";
    MPWPreworkEnum["MARKETING_QUESTIONS"] = "marketingQuestions";
    MPWPreworkEnum["FINALIZE"] = "finalize";
})(MPWPreworkEnum || (exports.MPWPreworkEnum = MPWPreworkEnum = {}));
