import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DisplayNotes } from "../helpers/ReleaseNotesHelper";
import LoadingCard from "./tailwind/LoadingCard";
import CardWrapper from "./tailwind/CardWrapper";
import CardHeader from "./tailwind/CardHeader";
import CardBody from "./tailwind/CardBody";
import Accordion from "./tailwind/Accordion";

type ReleaseNoteType = {
  version: string;
  releaseNotes: Record<
    string,
    {
      videoUrl: string;
      releaseNotes: string;
    }
  >;
};

function ReleaseNotes(): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const [releaseNoteData, setReleaseNoteData] = useState({} as ReleaseNoteType);
  const [loading, setLoading] = useState(true);

  const getReleaseData = async (
    getAccessTokenSilently: ({
      audience,
      scope,
      detailedResponse,
    }: {
      audience: string;
      scope: string;
      detailedResponse: boolean;
    }) => Promise<string>,
  ) => {
    const accessToken =
      process.env.REACT_APP_NODE_ENV !== "development"
        ? await getAccessTokenSilently({
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
            scope: "openid profile email",
            detailedResponse: false,
          })
        : process.env.REACT_APP_TEST_API_AUTH_KEY;

    const url = `${process.env.REACT_APP_API_BASE_URL}release`;

    await axios({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      url,
    })
      .then((data: any) => {
        setReleaseNoteData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        throw Error(error.message);
      });
  };

  useEffect(() => {
    getReleaseData(getAccessTokenSilently);
  }, []);

  /**
   * function to display all notes for each version release
   */
  const displayReleaseVersions = (): JSX.Element => {
    if (loading !== true) {
      // Needed to add this so that if there is no data in teh response it doesn't break
      if (releaseNoteData !== null && !releaseNoteData?.releaseNotes?.isError) {
        const sortedVersions = Object.keys(releaseNoteData).sort().reverse();
        const releaseVersions = [];
        // TP-680 MH: creating the outlined box for each version to then use function below with array of release notes per version.
        sortedVersions.forEach((version, idx) => {
          releaseVersions.push(
            <div key={version} className="border border-NexstarGray-dark">
              <Accordion
                title={`Release: ${version}`}
                id={`release-accordion-${version}`}
                rounded={false}
                defaultOpen={idx === 0}
              >
                <div className="p-6">
                  {DisplayNotes(releaseNoteData[version])}
                </div>
              </Accordion>
            </div>,
          );
        });
        return (
          <div className="flex flex-col gap-6 max-w-4xl mx-auto">
            {releaseVersions}
          </div>
        );
      }
    }
    return <LoadingCard />;
  };

  if (releaseNoteData === undefined || releaseNoteData === null)
    return <LoadingCard />;

  return (
    <CardWrapper id="releaseNotes" className="p-6">
      <CardHeader id="ReleaseNotesHeader" title="What's New" />
      <CardBody>{displayReleaseVersions()}</CardBody>
    </CardWrapper>
  );
}

export default ReleaseNotes;
