import React from "react";
import { noUserInProd } from "../../utils/helpers/UserHelper";
import { useUserContext } from "../context/user/UserContext";

function ToolsFooter(): JSX.Element {
  const { getUser } = useUserContext();
  const currentYear = new Date().getFullYear();

  if (noUserInProd(getUser())) return null;

  return (
    <footer className="absolute bottom-0 w-full bg-NexstarBlue-dark p-3 text-white text-sm text-center z-[1298]">
      <p>
        © {currentYear} Nexstar Network. All rights reserved. Unauthorized
        distribution is prohibited.
      </p>
    </footer>
  );
}

export default ToolsFooter;
