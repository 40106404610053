import React from "react";
import { IconType } from "../../../types/IconType";
import { IconColors, IconSizes } from "../../helpers/IconHelpers";

export default function PhoneIcon({
  size = "medium",
  color = "white",
  className,
}: IconType): JSX.Element {
  return (
    <svg
      fill={IconColors[color]}
      width={IconSizes[size]}
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m57.53,136.57c1.03-12.84,6.66-24.87,15.87-33.88l37.19-37.19c6.69-6.77,15.91-10.43,25.42-10.08,9.51.35,18.44,4.67,24.62,11.91l49.66,57.38c5.66,6.53,8.64,14.97,8.33,23.61-.31,8.64-3.88,16.84-10,22.96l-32.14,32.15h0c-5.25,5.02-7.45,12.45-5.79,19.52,7.44,31.79,23.81,60.79,47.19,83.57,22.79,23.38,51.78,39.75,83.57,47.19,7.08,1.66,14.5-.55,19.52-5.79l32.15-32.14h0c6.11-6.11,14.31-9.68,22.96-9.99,8.64-.31,17.07,2.66,23.61,8.33l57.39,49.66c7.25,6.17,11.57,15.1,11.92,24.62.35,9.52-3.31,18.74-10.09,25.42l-37.19,37.19c-9.01,9.2-21.05,14.83-33.88,15.87-10.47.93-21.01.9-31.48-.09-66.1-6.08-137.8-47.79-194.37-104.36-56.58-56.57-98.29-128.27-104.36-194.36-1-10.47-1.03-21-.09-31.48h0Z" />
    </svg>
  );
}
