"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcOtherLeadGrowthOverLastYearPercent = exports.calcOtherLeadsGrowthOverLastYear = exports.calcBudgetYearOtherLeadRevenue = exports.calcBudgetYearOtherLeadAverageSale = exports.calcBudgetYearOtherSoldJobs = exports.getBudgetYearOtherLeadsClosingRate = exports.getBudgetYearNumOtherLeads = exports.calcPriorYearOtherLeadRevenue = exports.getPriorYearOtherLeadAverageSale = exports.calcPriorYearOtherSoldJobs = exports.getPriorYearOtherLeadsClosingRate = exports.getPriorYearNumOtherLeads = exports.calcMarketedLeadGrowthOverLastYearPercent = exports.calcMarketedLeadGrowthOverLastYear = exports.calcBudgetYearMarketedLeadRevenue = exports.calcBudgetYearMarketedLeadAverageSale = exports.calcBudgetYearMarketedSoldJobs = exports.getBudgetYearMarketedLeadsClosingRate = exports.getBudgetYearNumMarketedLeads = exports.calcPriorYearMarketedLeadRevenue = exports.getPriorYearMarketedLeadAverageSale = exports.calcPriorYearMarketedSoldJobs = exports.getPriorYearMarketedLeadsClosingRate = exports.getPriorYearNumMarketedLeads = exports.calcTechGeneratedGrowthOverPriorYearPercent = exports.calcTechGeneratedGrowthOverPriorYear = exports.calcBudgetYearTechLeadRevenue = exports.calcBudgetYearTechGeneratedLeadAverageSale = exports.calcBudgetYearTechGeneratedSoldJobs = exports.getBudgetYearTechGeneratedLeadsClosingRate = exports.getBudgetYearNumTechGeneratedLeads = exports.calcPriorYearTechGeneratedLeadRevenue = exports.getPriorYearTechGeneratedLeadAverageSale = exports.calcPriorYearTechGeneratedSoldJobs = exports.getPriorYearTechGeneratedLeadsClosingRate = exports.getPriorYearNumTechGeneratedLeads = void 0;
function getPriorYearNumTechGeneratedLeads(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    return (workshopData[departmentId].callCount.totalSumPriorYearTechGeneratedLeads ||
        0);
}
exports.getPriorYearNumTechGeneratedLeads = getPriorYearNumTechGeneratedLeads;
function getPriorYearTechGeneratedLeadsClosingRate(preWorkData, departmentId) {
    if (!preWorkData ||
        !(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.installAvgCall)
        return 0;
    return (preWorkData[departmentId].nonMonthlyData.installAvgCall
        .techConvRateLastYear || 0);
}
exports.getPriorYearTechGeneratedLeadsClosingRate = getPriorYearTechGeneratedLeadsClosingRate;
function calcPriorYearTechGeneratedSoldJobs(preWorkData, workshopData, departmentId) {
    const techLeads = getPriorYearNumTechGeneratedLeads(workshopData, departmentId);
    const closingRate = getPriorYearTechGeneratedLeadsClosingRate(preWorkData, departmentId);
    if (!techLeads || !closingRate)
        return 0;
    return techLeads * (closingRate / 100);
}
exports.calcPriorYearTechGeneratedSoldJobs = calcPriorYearTechGeneratedSoldJobs;
function getPriorYearTechGeneratedLeadAverageSale(preWorkData, departmentId) {
    if (!preWorkData ||
        !(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.installAvgCall)
        return 0;
    return (preWorkData[departmentId].nonMonthlyData.installAvgCall
        .techAvgSaleLastYear || 0);
}
exports.getPriorYearTechGeneratedLeadAverageSale = getPriorYearTechGeneratedLeadAverageSale;
function calcPriorYearTechGeneratedLeadRevenue(preWorkData, workshopData, departmentId) {
    const prioryearSoldJobs = calcPriorYearTechGeneratedSoldJobs(preWorkData, workshopData, departmentId);
    const priorYearAvgSale = getPriorYearTechGeneratedLeadAverageSale(preWorkData, departmentId);
    if (!prioryearSoldJobs || !priorYearAvgSale)
        return 0;
    return prioryearSoldJobs * priorYearAvgSale;
}
exports.calcPriorYearTechGeneratedLeadRevenue = calcPriorYearTechGeneratedLeadRevenue;
// TECH LEAD - BUDGET YEAR
function getBudgetYearNumTechGeneratedLeads(workshopData, associatedId) {
    if (!workshopData ||
        !(associatedId in workshopData) ||
        !("maxCapacity" in workshopData[associatedId]) ||
        !workshopData[associatedId].maxCapacity)
        return 0;
    return workshopData[associatedId].maxCapacity.totalTurnovers || 0;
}
exports.getBudgetYearNumTechGeneratedLeads = getBudgetYearNumTechGeneratedLeads;
/**
 * CC AB195/AB181
 * @param workshopData
 * @param departmentId
 */
function getBudgetYearTechGeneratedLeadsClosingRate(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const { totalConvertedTechLeadCount, totalSumBudgetedTechGeneratedLeads } = workshopData[departmentId].callCount || {};
    if (!totalConvertedTechLeadCount || !totalSumBudgetedTechGeneratedLeads)
        return 0;
    return totalConvertedTechLeadCount / totalSumBudgetedTechGeneratedLeads;
}
exports.getBudgetYearTechGeneratedLeadsClosingRate = getBudgetYearTechGeneratedLeadsClosingRate;
function calcBudgetYearTechGeneratedSoldJobs(workshopData, departmentId, associatedDepartmentId) {
    const techLeads = getBudgetYearNumTechGeneratedLeads(workshopData, associatedDepartmentId);
    const closingRate = getBudgetYearTechGeneratedLeadsClosingRate(workshopData, departmentId);
    if (!techLeads || !closingRate)
        return 0;
    return techLeads * closingRate;
}
exports.calcBudgetYearTechGeneratedSoldJobs = calcBudgetYearTechGeneratedSoldJobs;
/**
 *  call count AB196/AB195
 * @param workshopData
 * @param departmentId
 */
function calcBudgetYearTechGeneratedLeadAverageSale(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const { totalTechLeadRevenue, totalConvertedTechLeadCount } = workshopData[departmentId].callCount || {};
    if (!totalConvertedTechLeadCount || !totalTechLeadRevenue)
        return 0;
    return totalTechLeadRevenue / totalConvertedTechLeadCount;
}
exports.calcBudgetYearTechGeneratedLeadAverageSale = calcBudgetYearTechGeneratedLeadAverageSale;
function calcBudgetYearTechLeadRevenue(workshopData, departmentId, associatedDepartmentId) {
    const soldJobs = calcBudgetYearTechGeneratedSoldJobs(workshopData, departmentId, associatedDepartmentId);
    const avgSale = calcBudgetYearTechGeneratedLeadAverageSale(workshopData, departmentId);
    if (!soldJobs || !avgSale)
        return 0;
    return soldJobs * avgSale;
}
exports.calcBudgetYearTechLeadRevenue = calcBudgetYearTechLeadRevenue;
function calcTechGeneratedGrowthOverPriorYear(preWorkData, workshopData, departmentId, associatedDepartmentId) {
    const budgetLeadRevenue = calcBudgetYearTechLeadRevenue(workshopData, departmentId, associatedDepartmentId);
    const priorYearLeadRevenue = calcPriorYearTechGeneratedLeadRevenue(preWorkData, workshopData, departmentId);
    return budgetLeadRevenue - priorYearLeadRevenue;
}
exports.calcTechGeneratedGrowthOverPriorYear = calcTechGeneratedGrowthOverPriorYear;
function calcTechGeneratedGrowthOverPriorYearPercent(preWorkData, workshopData, departmentId, associatedDepartmentId) {
    const growOverPriorYear = calcTechGeneratedGrowthOverPriorYear(preWorkData, workshopData, departmentId, associatedDepartmentId);
    const leadRevenue = calcPriorYearTechGeneratedLeadRevenue(preWorkData, workshopData, departmentId);
    if (!growOverPriorYear || !leadRevenue)
        return 0;
    return growOverPriorYear / leadRevenue;
}
exports.calcTechGeneratedGrowthOverPriorYearPercent = calcTechGeneratedGrowthOverPriorYearPercent;
// MARKETED LEAD - PRIOR YEAR
function getPriorYearNumMarketedLeads(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    return (workshopData[departmentId].callCount.totalSumPriorYearMarketedLeadCount || 0);
}
exports.getPriorYearNumMarketedLeads = getPriorYearNumMarketedLeads;
function getPriorYearMarketedLeadsClosingRate(preWorkData, departmentId) {
    if (!preWorkData ||
        !(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.installAvgCall)
        return 0;
    return (preWorkData[departmentId].nonMonthlyData.installAvgCall
        .marketConvRateLastYear || 0);
}
exports.getPriorYearMarketedLeadsClosingRate = getPriorYearMarketedLeadsClosingRate;
function calcPriorYearMarketedSoldJobs(preWorkData, workshopData, departmentId) {
    const marketedLeads = getPriorYearNumMarketedLeads(workshopData, departmentId);
    const marketedClosingRate = getPriorYearMarketedLeadsClosingRate(preWorkData, departmentId);
    if (!marketedLeads || !marketedClosingRate)
        return 0;
    return marketedLeads * (marketedClosingRate / 100);
}
exports.calcPriorYearMarketedSoldJobs = calcPriorYearMarketedSoldJobs;
function getPriorYearMarketedLeadAverageSale(preWorkData, departmentId) {
    if (!preWorkData ||
        !(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.installAvgCall)
        return 0;
    return (preWorkData[departmentId].nonMonthlyData.installAvgCall
        .marketAvgSaleLastYear || 0);
}
exports.getPriorYearMarketedLeadAverageSale = getPriorYearMarketedLeadAverageSale;
function calcPriorYearMarketedLeadRevenue(preWorkData, workshopData, departmentId) {
    const marketedSoldJobs = calcPriorYearMarketedSoldJobs(preWorkData, workshopData, departmentId);
    const marketedAvgSale = getPriorYearMarketedLeadAverageSale(preWorkData, departmentId);
    if (!marketedSoldJobs || !marketedAvgSale)
        return 0;
    return marketedSoldJobs * marketedAvgSale;
}
exports.calcPriorYearMarketedLeadRevenue = calcPriorYearMarketedLeadRevenue;
// MARKETED LEAD - BUDGET YEAR
function getBudgetYearNumMarketedLeads(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    return workshopData[departmentId].callCount.totalSumMarketedLeadCount || 0;
}
exports.getBudgetYearNumMarketedLeads = getBudgetYearNumMarketedLeads;
/**
 * CC AB197/AB188
 * @param workshopData
 * @param departmentId
 */
function getBudgetYearMarketedLeadsClosingRate(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const totalSumMarketedLeadCount = getBudgetYearNumMarketedLeads(workshopData, departmentId);
    const { totalConvertedMarketedLeadCount } = workshopData[departmentId].callCount || {};
    if (!totalConvertedMarketedLeadCount || !totalSumMarketedLeadCount)
        return 0;
    return totalConvertedMarketedLeadCount / totalSumMarketedLeadCount;
}
exports.getBudgetYearMarketedLeadsClosingRate = getBudgetYearMarketedLeadsClosingRate;
function calcBudgetYearMarketedSoldJobs(workshopData, departmentId) {
    const marketedLeads = getBudgetYearNumMarketedLeads(workshopData, departmentId);
    const marketedClosingRate = getBudgetYearMarketedLeadsClosingRate(workshopData, departmentId);
    if (!marketedLeads || !marketedClosingRate)
        return 0;
    return marketedLeads * marketedClosingRate;
}
exports.calcBudgetYearMarketedSoldJobs = calcBudgetYearMarketedSoldJobs;
/**
 *  call count AB198/AB197
 * @param workshopData
 * @param departmentId
 */
function calcBudgetYearMarketedLeadAverageSale(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const { totalMarketedLeadRevenue, totalConvertedMarketedLeadCount } = workshopData[departmentId].callCount || {};
    if (!totalMarketedLeadRevenue || !totalConvertedMarketedLeadCount)
        return 0;
    return totalMarketedLeadRevenue / totalConvertedMarketedLeadCount;
}
exports.calcBudgetYearMarketedLeadAverageSale = calcBudgetYearMarketedLeadAverageSale;
function calcBudgetYearMarketedLeadRevenue(workshopData, departmentId) {
    const marketedSoldJobs = calcBudgetYearMarketedSoldJobs(workshopData, departmentId);
    const marketedAvgSale = calcBudgetYearMarketedLeadAverageSale(workshopData, departmentId);
    if (!marketedSoldJobs || !marketedAvgSale)
        return 0;
    return marketedSoldJobs * marketedAvgSale;
}
exports.calcBudgetYearMarketedLeadRevenue = calcBudgetYearMarketedLeadRevenue;
function calcMarketedLeadGrowthOverLastYear(preWorkData, workshopData, departmentId) {
    const budgetMarketedLeadRevenue = calcBudgetYearMarketedLeadRevenue(workshopData, departmentId);
    const priorYearMarketedLeadRevenue = calcPriorYearMarketedLeadRevenue(preWorkData, workshopData, departmentId);
    return budgetMarketedLeadRevenue - priorYearMarketedLeadRevenue;
}
exports.calcMarketedLeadGrowthOverLastYear = calcMarketedLeadGrowthOverLastYear;
function calcMarketedLeadGrowthOverLastYearPercent(preWorkData, workshopData, departmentId) {
    const growthOverLastYear = calcMarketedLeadGrowthOverLastYear(preWorkData, workshopData, departmentId);
    const marketedRevenue = calcPriorYearMarketedLeadRevenue(preWorkData, workshopData, departmentId);
    if (!growthOverLastYear || !marketedRevenue)
        return 0;
    return growthOverLastYear / marketedRevenue;
}
exports.calcMarketedLeadGrowthOverLastYearPercent = calcMarketedLeadGrowthOverLastYearPercent;
// Other LEAD - PRIOR YEAR
// TP-1901 MH: changed to get sum of leads instead of converted leads
function getPriorYearNumOtherLeads(preWork, departmentId) {
    let priorYearNumOtherLeads = 0;
    if (!preWork || !preWork[departmentId] || !preWork[departmentId].monthlyData)
        return 0;
    Object.values(preWork[departmentId].monthlyData).forEach((monthlyData) => {
        priorYearNumOtherLeads += monthlyData.numOfOtherLeadsRan;
    });
    return priorYearNumOtherLeads;
}
exports.getPriorYearNumOtherLeads = getPriorYearNumOtherLeads;
function getPriorYearOtherLeadsClosingRate(preWorkData, departmentId) {
    if (!preWorkData ||
        !(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.installAvgCall)
        return 0;
    return (preWorkData[departmentId].nonMonthlyData.installAvgCall
        .otherConvRateLastYear || 0);
}
exports.getPriorYearOtherLeadsClosingRate = getPriorYearOtherLeadsClosingRate;
function calcPriorYearOtherSoldJobs(preWorkData, workshopData, departmentId) {
    const numOfLeads = getPriorYearNumOtherLeads(preWorkData, departmentId);
    const closingRate = getPriorYearOtherLeadsClosingRate(preWorkData, departmentId);
    if (!numOfLeads || !closingRate)
        return 0;
    return numOfLeads * (closingRate / 100);
}
exports.calcPriorYearOtherSoldJobs = calcPriorYearOtherSoldJobs;
function getPriorYearOtherLeadAverageSale(preWorkData, departmentId) {
    if (!preWorkData ||
        !(departmentId in preWorkData) ||
        !preWorkData[departmentId].nonMonthlyData ||
        !preWorkData[departmentId].nonMonthlyData.installAvgCall)
        return 0;
    return (preWorkData[departmentId].nonMonthlyData.installAvgCall
        .otherAvgRateLastYear || 0);
}
exports.getPriorYearOtherLeadAverageSale = getPriorYearOtherLeadAverageSale;
function calcPriorYearOtherLeadRevenue(preWorkData, workshopData, departmentId) {
    const soldJobs = calcPriorYearOtherSoldJobs(preWorkData, workshopData, departmentId);
    const avgSale = getPriorYearOtherLeadAverageSale(preWorkData, departmentId);
    if (!soldJobs || !avgSale)
        return 0;
    return soldJobs * avgSale;
}
exports.calcPriorYearOtherLeadRevenue = calcPriorYearOtherLeadRevenue;
//OTHER LEAD - BUDGET YEAR
/**
 * AB193
 * @param workshopData
 * @param departmentId
 */
function getBudgetYearNumOtherLeads(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    return workshopData[departmentId].callCount.totalSumOtherLeadCount || 0;
}
exports.getBudgetYearNumOtherLeads = getBudgetYearNumOtherLeads;
/**
 * CC AB199/AB193
 * @param workshopData
 * @param departmentId
 */
function getBudgetYearOtherLeadsClosingRate(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const budgetedLeads = getBudgetYearNumOtherLeads(workshopData, departmentId);
    const { totalConvertedOtherLeadCount } = workshopData[departmentId].callCount || {};
    if (!budgetedLeads || !totalConvertedOtherLeadCount)
        return 0;
    return totalConvertedOtherLeadCount / budgetedLeads;
}
exports.getBudgetYearOtherLeadsClosingRate = getBudgetYearOtherLeadsClosingRate;
function calcBudgetYearOtherSoldJobs(workshopData, departmentId) {
    const numOtherLeads = getBudgetYearNumOtherLeads(workshopData, departmentId);
    const closingRate = getBudgetYearOtherLeadsClosingRate(workshopData, departmentId);
    if (!numOtherLeads || !closingRate)
        return 0;
    return numOtherLeads * closingRate;
}
exports.calcBudgetYearOtherSoldJobs = calcBudgetYearOtherSoldJobs;
/**
 *  call count AB200/AB199
 * @param workshopData
 * @param departmentId
 */
function calcBudgetYearOtherLeadAverageSale(workshopData, departmentId) {
    if (!workshopData ||
        !(departmentId in workshopData) ||
        !("callCount" in workshopData[departmentId]))
        return 0;
    const { totalOtherLeadRevenue, totalConvertedOtherLeadCount } = workshopData[departmentId].callCount || {};
    if (!totalConvertedOtherLeadCount || !totalOtherLeadRevenue)
        return 0;
    return totalOtherLeadRevenue / totalConvertedOtherLeadCount;
}
exports.calcBudgetYearOtherLeadAverageSale = calcBudgetYearOtherLeadAverageSale;
function calcBudgetYearOtherLeadRevenue(workshopData, departmentId) {
    const soldJobs = calcBudgetYearOtherSoldJobs(workshopData, departmentId);
    const avgSale = calcBudgetYearOtherLeadAverageSale(workshopData, departmentId);
    if (!soldJobs || !avgSale)
        return 0;
    return soldJobs * avgSale;
}
exports.calcBudgetYearOtherLeadRevenue = calcBudgetYearOtherLeadRevenue;
function calcOtherLeadsGrowthOverLastYear(preWorkData, workshopData, departmentId) {
    const budgetLeadRevenue = calcBudgetYearOtherLeadRevenue(workshopData, departmentId);
    const priorYearLeadRevenue = calcPriorYearOtherLeadRevenue(preWorkData, workshopData, departmentId);
    return budgetLeadRevenue - priorYearLeadRevenue;
}
exports.calcOtherLeadsGrowthOverLastYear = calcOtherLeadsGrowthOverLastYear;
function calcOtherLeadGrowthOverLastYearPercent(preWorkData, workshopData, departmentId) {
    const otherLeadsGrowthOverLastYear = calcOtherLeadsGrowthOverLastYear(preWorkData, workshopData, departmentId);
    const priorYearOtherLeadRevenue = calcPriorYearOtherLeadRevenue(preWorkData, workshopData, departmentId);
    if (!otherLeadsGrowthOverLastYear || !priorYearOtherLeadRevenue)
        return 0;
    return otherLeadsGrowthOverLastYear / priorYearOtherLeadRevenue;
}
exports.calcOtherLeadGrowthOverLastYearPercent = calcOtherLeadGrowthOverLastYearPercent;
