"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertCompanyVarianceOverheads = exports.calcVariancePercentOfNetGrossMargin = exports.calcVariancePercentOfNetDirectCosts = exports.calcVariancePercentOfNetSalesOther = exports.calcVariancePercentOfNetLabor = exports.calcVariancePercentOfNetMaterials = exports.calcVariancePercentOfNetSales = exports.calcVariancePercentOfNetReductions = exports.calcVariancePercentOfNetDepartmentSales = exports.calcVarianceOfNetGrossMargin = exports.calcVarianceOfNetDirectCosts = exports.calcVarianceOfNetSalesOther = exports.calcVarianceOfNetLabor = exports.calcVarianceOfNetMaterials = exports.calcVarianceOfNetSales = exports.calcVarianceOfNetReductions = exports.calcVarianceOfNetDepartmentSales = exports.calcPercentOfNetGrossMargin = exports.calcPercentOfNetDirectCosts = exports.calcPercentOfNetSalesOther = exports.calcPercentOfNetLabor = exports.calcPercentOfNetMaterials = exports.calcPercentOfNetReductions = exports.calcPercentOfNetSales = exports.getGrossMargin = exports.getTotalDirectCosts = exports.getTotalSalesAndOther = exports.getDepartmentSalesAndOther = exports.getTotalDirectLabor = exports.getDepartmentDirectLabor = exports.getTotalMaterials = exports.getDepartmentMaterials = exports.getNetSales = exports.getTotalReductionToSales = exports.getDepartmentReductionToSales = exports.getTotalSales = exports.getInstallDepartmentTotalSales = exports.getServiceDepartmentTotalSales = exports.getMonthAndTimeframeObj = exports.calctwoPlustwo = void 0;
const enums_1 = require("../../../utils/enums");
const utils_1 = require("../../../utils");
function calctwoPlustwo() {
    return 2 + 2;
}
exports.calctwoPlustwo = calctwoPlustwo;
function getMonthAndTimeframeObj(obj, timeframe, month) {
    const monthData = obj && month in obj && obj[month] ? obj[month] : null;
    if (!monthData)
        return null;
    return timeframe in monthData && monthData[timeframe]
        ? monthData[timeframe]
        : null;
}
exports.getMonthAndTimeframeObj = getMonthAndTimeframeObj;
function getServiceDepartmentTotalSales(obj) {
    var _a, _b, _c, _d, _e, _f, _g;
    const demandService = ((_a = obj.demandService) !== null && _a !== void 0 ? _a : 0) +
        ((_b = obj.demandSystemCheckService) !== null && _b !== void 0 ? _b : 0) +
        ((_c = obj.systemCheckService) !== null && _c !== void 0 ? _c : 0);
    const spp = (_d = obj.spp) !== null && _d !== void 0 ? _d : 0;
    const customField1 = (_e = obj.customField1) !== null && _e !== void 0 ? _e : 0;
    const customField2 = (_f = obj.customField2) !== null && _f !== void 0 ? _f : 0;
    const customField3 = (_g = obj.customField3) !== null && _g !== void 0 ? _g : 0;
    return demandService + spp + customField1 + customField2 + customField3;
}
exports.getServiceDepartmentTotalSales = getServiceDepartmentTotalSales;
function getInstallDepartmentTotalSales(obj) {
    var _a, _b, _c, _d;
    const sales = (_a = obj.sales) !== null && _a !== void 0 ? _a : 0;
    const customField1 = (_b = obj.customField1) !== null && _b !== void 0 ? _b : 0;
    const customField2 = (_c = obj.customField2) !== null && _c !== void 0 ? _c : 0;
    const customField3 = (_d = obj.customField3) !== null && _d !== void 0 ? _d : 0;
    return sales + customField1 + customField2 + customField3;
}
exports.getInstallDepartmentTotalSales = getInstallDepartmentTotalSales;
/**
 * Columns C, F, L
 * Rows 4-19
 *
 * @param objs
 * @param timeframe
 * @param month
 */
function getTotalSales(objs, timeframe, month) {
    if (!objs)
        return 0;
    let total = 0;
    for (let i = 0; i < objs.length; i++) {
        const calcObj = getMonthAndTimeframeObj(objs[i], timeframe, month);
        if (!calcObj)
            continue;
        total +=
            objs[i].departmentType === enums_1.DepartmentTypeEnum.INSTALL_DEPARTMENT
                ? getInstallDepartmentTotalSales(calcObj)
                : getServiceDepartmentTotalSales(calcObj);
    }
    return total;
}
exports.getTotalSales = getTotalSales;
/**
 * Helper for
 * Columns C, F, L
 * Rows 21-25
 * @param obj
 * @param reductionType
 */
function getDepartmentReductionToSales(obj, reductionType) {
    var _a, _b, _c;
    if (!obj)
        return 0;
    const subcontractors = (_a = obj.subcontractors) !== null && _a !== void 0 ? _a : 0;
    const refunds = (_b = obj.refunds) !== null && _b !== void 0 ? _b : 0;
    const financeFees = (_c = obj.financeFees) !== null && _c !== void 0 ? _c : 0;
    switch (reductionType) {
        case "subcontractors": {
            return subcontractors;
        }
        case "refunds": {
            return refunds;
        }
        case "financeFees": {
            return financeFees;
        }
        case "total": {
            return subcontractors + refunds + financeFees;
        }
    }
}
exports.getDepartmentReductionToSales = getDepartmentReductionToSales;
/**
 * Columns C, F, L
 * Rows 21-25
 * @param objs
 * @param timeframe
 * @param month
 * @param reductionType
 */
function getTotalReductionToSales(objs, timeframe, month, reductionType) {
    if (!objs)
        return 0;
    let total = 0;
    for (let i = 0; i < objs.length; i++) {
        const calcObj = getMonthAndTimeframeObj(objs[i], timeframe, month);
        if (!calcObj)
            continue;
        total += getDepartmentReductionToSales(calcObj, reductionType);
    }
    return total;
}
exports.getTotalReductionToSales = getTotalReductionToSales;
/**
 * Columns C, F, L
 * Rows 26
 * @param objs
 * @param timeframe
 * @param month
 */
function getNetSales(objs, timeframe, month) {
    const totalSales = getTotalSales(objs, timeframe, month);
    const totalReductionsToSales = getTotalReductionToSales(objs, timeframe, month, "total");
    return totalSales - totalReductionsToSales;
}
exports.getNetSales = getNetSales;
/**
 * Helper for
 * Columns C, F, L
 * Rows 29 - 33
 *
 * @param obj
 * @param reductionType
 */
function getDepartmentMaterials(obj, reductionType) {
    var _a, _b;
    if (!obj)
        return 0;
    const parts = (_a = obj.parts) !== null && _a !== void 0 ? _a : 0;
    const equipment = (_b = obj.equipment) !== null && _b !== void 0 ? _b : 0;
    switch (reductionType) {
        case "parts": {
            return parts;
        }
        case "equipment": {
            return equipment;
        }
        case "total": {
            return parts + equipment;
        }
    }
}
exports.getDepartmentMaterials = getDepartmentMaterials;
/**
 * Columns C, F, L
 * Rows 29 - 33
 *
 * @param objs
 * @param timeframe
 * @param month
 * @param reductionType
 */
function getTotalMaterials(objs, timeframe, month, reductionType) {
    if (!objs)
        return 0;
    let total = 0;
    for (let i = 0; i < objs.length; i++) {
        const calcObj = getMonthAndTimeframeObj(objs[i], timeframe, month);
        if (!calcObj)
            continue;
        total += getDepartmentMaterials(calcObj, reductionType);
    }
    return total;
}
exports.getTotalMaterials = getTotalMaterials;
/**
 * Helper for
 * Columns C, F, L
 * Rows 36-44
 *
 * @param obj
 * @param reductionType
 */
function getDepartmentDirectLabor(obj, reductionType) {
    var _a, _b, _c, _d, _e, _f;
    if (!obj)
        return 0;
    const wages = (_a = obj.wages) !== null && _a !== void 0 ? _a : 0;
    const ficaExpense = (_b = obj.ficaExpense) !== null && _b !== void 0 ? _b : 0;
    const workersCompInsurance = (_c = obj.workersCompInsurance) !== null && _c !== void 0 ? _c : 0;
    const unemploymentTax = (_d = obj.unemploymentTax) !== null && _d !== void 0 ? _d : 0;
    const medicalExpense = (_e = obj.medicalExpense) !== null && _e !== void 0 ? _e : 0;
    const retirementContributions = (_f = obj.retirementContributions) !== null && _f !== void 0 ? _f : 0;
    switch (reductionType) {
        case "wages": {
            return wages;
        }
        case "ficaExpense": {
            return ficaExpense;
        }
        case "workersCompInsurance": {
            return workersCompInsurance;
        }
        case "unemploymentTax": {
            return unemploymentTax;
        }
        case "medicalExpense": {
            return medicalExpense;
        }
        case "retirementContributions": {
            return retirementContributions;
        }
        case "total": {
            return (wages +
                ficaExpense +
                workersCompInsurance +
                unemploymentTax +
                medicalExpense +
                retirementContributions);
        }
    }
}
exports.getDepartmentDirectLabor = getDepartmentDirectLabor;
/**
 * Columns C, F, L
 * Rows 36-44
 *
 * @param objs
 * @param timeframe
 * @param month
 * @param reductionType
 */
function getTotalDirectLabor(objs, timeframe, month, reductionType) {
    if (!objs)
        return 0;
    let total = 0;
    for (let i = 0; i < objs.length; i++) {
        const calcObj = getMonthAndTimeframeObj(objs[i], timeframe, month);
        if (!calcObj)
            continue;
        total += getDepartmentDirectLabor(calcObj, reductionType);
    }
    return total;
}
exports.getTotalDirectLabor = getTotalDirectLabor;
/**
 * Helper for
 * Columns C, F, L
 * Rows 46 - 50
 *
 * @param obj
 * @param reductionType
 */
function getDepartmentSalesAndOther(obj, reductionType) {
    var _a, _b;
    if (!obj)
        return 0;
    const salesExpense = (_a = obj.salesExpense) !== null && _a !== void 0 ? _a : 0;
    const other = (_b = obj.other) !== null && _b !== void 0 ? _b : 0;
    switch (reductionType) {
        case "salesExpense": {
            return salesExpense;
        }
        case "other": {
            return other;
        }
        case "total": {
            return salesExpense + other;
        }
    }
}
exports.getDepartmentSalesAndOther = getDepartmentSalesAndOther;
/**
 * Columns C, F, L
 * Rows 46 - 50
 *
 * @param objs
 * @param timeframe
 * @param month
 * @param reductionType
 */
function getTotalSalesAndOther(objs, timeframe, month, reductionType) {
    if (!objs)
        return 0;
    let total = 0;
    for (let i = 0; i < objs.length; i++) {
        const calcObj = getMonthAndTimeframeObj(objs[i], timeframe, month);
        if (!calcObj)
            continue;
        total += getDepartmentSalesAndOther(calcObj, reductionType);
    }
    return total;
}
exports.getTotalSalesAndOther = getTotalSalesAndOther;
/**
 * Columns C, F, L
 * Rows 52
 *
 * @param objs
 * @param timeframe
 * @param month
 */
function getTotalDirectCosts(objs, timeframe, month) {
    var _a, _b, _c;
    const totalMaterials = (_a = getTotalMaterials(objs, timeframe, month, "total")) !== null && _a !== void 0 ? _a : 0;
    const totalDirectLabor = (_b = getTotalDirectLabor(objs, timeframe, month, "total")) !== null && _b !== void 0 ? _b : 0;
    const totalSalesAndOther = (_c = getTotalSalesAndOther(objs, timeframe, month, "total")) !== null && _c !== void 0 ? _c : 0;
    return totalMaterials + totalDirectLabor + totalSalesAndOther;
}
exports.getTotalDirectCosts = getTotalDirectCosts;
/**
 * Columns C, F, L
 * Rows 54
 *
 * @param objs
 * @param timeframe
 * @param month
 */
function getGrossMargin(objs, timeframe, month) {
    var _a, _b;
    const totalDirectCosts = (_a = getTotalDirectCosts(objs, timeframe, month)) !== null && _a !== void 0 ? _a : 0;
    const totalNetSales = (_b = getNetSales(objs, timeframe, month)) !== null && _b !== void 0 ? _b : 0;
    return totalNetSales - totalDirectCosts;
}
exports.getGrossMargin = getGrossMargin;
/**
 * Columns D, G, M
 * Rows 5 -19
 *
 * @param objs
 * @param timeframe
 * @param month
 * @param type
 * @param departmentId
 */
function calcPercentOfNetSales(objs, timeframe, month, type, departmentId) {
    if (!objs)
        return 0;
    const netSales = getNetSales(objs, timeframe, month);
    let returnValue = 0;
    switch (type) {
        case "department": {
            if (!departmentId)
                break;
            const departmentToCalc = objs.filter((obj) => obj.departmentId.endsWith(departmentId));
            if (!departmentToCalc || !departmentToCalc.length)
                break;
            const department = departmentToCalc[0];
            const departmentSales = department.departmentType === enums_1.DepartmentTypeEnum.INSTALL_DEPARTMENT
                ? getInstallDepartmentTotalSales(getMonthAndTimeframeObj(department, timeframe, month))
                : getServiceDepartmentTotalSales(getMonthAndTimeframeObj(department, timeframe, month));
            if (!departmentSales)
                break;
            returnValue = departmentSales / netSales;
            break;
        }
        case "total": {
            const totalSales = getTotalSales(objs, timeframe, month);
            if (!totalSales)
                break;
            returnValue = totalSales / netSales;
            break;
        }
    }
    return returnValue ? returnValue * 100 : 0;
}
exports.calcPercentOfNetSales = calcPercentOfNetSales;
/**
 * Columns D, G, M
 * Rows 21 - 25
 *
 * @param objs
 * @param timeframe
 * @param month
 * @param type
 */
function calcPercentOfNetReductions(objs, timeframe, month, type) {
    var _a;
    const netSales = getNetSales(objs, timeframe, month);
    const reductions = (_a = getTotalReductionToSales(objs, timeframe, month, type)) !== null && _a !== void 0 ? _a : 0;
    return reductions && netSales ? (reductions / netSales) * 100 : 0;
}
exports.calcPercentOfNetReductions = calcPercentOfNetReductions;
/**
 * Columns D, G, M
 * Rows 29 - 33
 *
 * @param objs
 * @param timeframe
 * @param month
 * @param type
 */
function calcPercentOfNetMaterials(objs, timeframe, month, type) {
    var _a;
    const netSales = getNetSales(objs, timeframe, month);
    const materials = (_a = getTotalMaterials(objs, timeframe, month, type)) !== null && _a !== void 0 ? _a : 0;
    return materials && netSales ? (materials / netSales) * 100 : 0;
}
exports.calcPercentOfNetMaterials = calcPercentOfNetMaterials;
/**
 * Columns D, G, M
 * Rows 36 - 44
 *
 * @param objs
 * @param timeframe
 * @param month
 * @param type
 */
function calcPercentOfNetLabor(objs, timeframe, month, type) {
    var _a;
    const netSales = getNetSales(objs, timeframe, month);
    const labor = (_a = getTotalDirectLabor(objs, timeframe, month, type)) !== null && _a !== void 0 ? _a : 0;
    return labor && netSales ? (labor / netSales) * 100 : 0;
}
exports.calcPercentOfNetLabor = calcPercentOfNetLabor;
/**
 * Columns D, G, M
 * Rows 46-50
 *
 * @param objs
 * @param timeframe
 * @param month
 * @param type
 */
function calcPercentOfNetSalesOther(objs, timeframe, month, type) {
    var _a;
    const netSales = getNetSales(objs, timeframe, month);
    const salesAndOther = (_a = getTotalSalesAndOther(objs, timeframe, month, type)) !== null && _a !== void 0 ? _a : 0;
    return salesAndOther && netSales ? (salesAndOther / netSales) * 100 : 0;
}
exports.calcPercentOfNetSalesOther = calcPercentOfNetSalesOther;
/**
 *
 * Columns D, G, M
 * Rows 50
 *
 * @param objs
 * @param timeframe
 * @param month
 */
function calcPercentOfNetDirectCosts(objs, timeframe, month) {
    var _a;
    const netSales = getNetSales(objs, timeframe, month);
    const directCosts = (_a = getTotalDirectCosts(objs, timeframe, month)) !== null && _a !== void 0 ? _a : 0;
    return directCosts && netSales ? (directCosts / netSales) * 100 : 0;
}
exports.calcPercentOfNetDirectCosts = calcPercentOfNetDirectCosts;
/**
 *
 * Columns D, G, M
 * Rows 52
 *
 * @param objs
 * @param timeframe
 * @param month
 */
function calcPercentOfNetGrossMargin(objs, timeframe, month) {
    var _a;
    const netSales = getNetSales(objs, timeframe, month);
    const grossMargin = (_a = getGrossMargin(objs, timeframe, month)) !== null && _a !== void 0 ? _a : 0;
    return grossMargin && netSales ? (grossMargin / netSales) * 100 : 0;
}
exports.calcPercentOfNetGrossMargin = calcPercentOfNetGrossMargin;
function getVarianceBounds(timeframe) {
    if (timeframe === "currentYear")
        return {
            lowerTimeframeBound: "lastYear",
            upperTimeframeBound: "currentYear",
        };
    else if (timeframe === "budget") {
        return {
            lowerTimeframeBound: "budget",
            upperTimeframeBound: "currentYear",
        };
    }
    else
        return null;
}
/**
 * I, N
 * 5 - 19
 *
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 * @param departmentId
 */
function calcVarianceOfNetDepartmentSales(objs, varianceTimeframe, month, type, departmentId) {
    if (!objs)
        return 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    switch (type) {
        case "department": {
            if (!departmentId)
                break;
            const departmentToCalc = objs.filter((obj) => obj.departmentId.endsWith(departmentId));
            if (!departmentToCalc || !departmentToCalc.length)
                break;
            const department = departmentToCalc[0];
            const departmentSales1 = department.departmentType === enums_1.DepartmentTypeEnum.INSTALL_DEPARTMENT
                ? getInstallDepartmentTotalSales(getMonthAndTimeframeObj(department, lowerTimeframeBound, month))
                : getServiceDepartmentTotalSales(getMonthAndTimeframeObj(department, lowerTimeframeBound, month));
            const departmentSales2 = department.departmentType === enums_1.DepartmentTypeEnum.INSTALL_DEPARTMENT
                ? getInstallDepartmentTotalSales(getMonthAndTimeframeObj(department, upperTimeframeBound, month))
                : getServiceDepartmentTotalSales(getMonthAndTimeframeObj(department, upperTimeframeBound, month));
            return departmentSales2 - departmentSales1;
        }
        case "total": {
            const totalSales1 = getTotalSales(objs, lowerTimeframeBound, month);
            const totalSales2 = getTotalSales(objs, upperTimeframeBound, month);
            return totalSales2 - totalSales1;
        }
    }
}
exports.calcVarianceOfNetDepartmentSales = calcVarianceOfNetDepartmentSales;
/**
 * I, N
 * 21 - 25
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVarianceOfNetReductions(objs, varianceTimeframe, month, type) {
    var _a, _b;
    if (!objs)
        return 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const reductions1 = (_a = getTotalReductionToSales(objs, lowerTimeframeBound, month, type)) !== null && _a !== void 0 ? _a : 0;
    const reductions2 = (_b = getTotalReductionToSales(objs, upperTimeframeBound, month, type)) !== null && _b !== void 0 ? _b : 0;
    return reductions2 - reductions1;
}
exports.calcVarianceOfNetReductions = calcVarianceOfNetReductions;
/**
 * I, N
 * 26
 * @param objs
 * @param varianceTimeframe
 * @param month
 */
function calcVarianceOfNetSales(objs, varianceTimeframe, month) {
    var _a, _b;
    if (!objs)
        return 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const reductions1 = (_a = getNetSales(objs, lowerTimeframeBound, month)) !== null && _a !== void 0 ? _a : 0;
    const reductions2 = (_b = getNetSales(objs, upperTimeframeBound, month)) !== null && _b !== void 0 ? _b : 0;
    return reductions2 - reductions1;
}
exports.calcVarianceOfNetSales = calcVarianceOfNetSales;
/**
 * I, N
 * 29 -33
 *
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVarianceOfNetMaterials(objs, varianceTimeframe, month, type) {
    var _a, _b;
    if (!objs)
        return 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const materials1 = (_a = getTotalMaterials(objs, lowerTimeframeBound, month, type)) !== null && _a !== void 0 ? _a : 0;
    const materials2 = (_b = getTotalMaterials(objs, upperTimeframeBound, month, type)) !== null && _b !== void 0 ? _b : 0;
    return materials2 - materials1;
}
exports.calcVarianceOfNetMaterials = calcVarianceOfNetMaterials;
/**
 * I, N
 * 36 - 44
 *
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVarianceOfNetLabor(objs, varianceTimeframe, month, type) {
    var _a, _b;
    if (!objs)
        return 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const labor1 = (_a = getTotalDirectLabor(objs, lowerTimeframeBound, month, type)) !== null && _a !== void 0 ? _a : 0;
    const labor2 = (_b = getTotalDirectLabor(objs, upperTimeframeBound, month, type)) !== null && _b !== void 0 ? _b : 0;
    return labor2 - labor1;
}
exports.calcVarianceOfNetLabor = calcVarianceOfNetLabor;
/**
 * I, N
 * 46 - 50
 *
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVarianceOfNetSalesOther(objs, varianceTimeframe, month, type) {
    var _a, _b;
    if (!objs)
        return 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const salesAndOther1 = (_a = getTotalSalesAndOther(objs, lowerTimeframeBound, month, type)) !== null && _a !== void 0 ? _a : 0;
    const salesAndOther2 = (_b = getTotalSalesAndOther(objs, upperTimeframeBound, month, type)) !== null && _b !== void 0 ? _b : 0;
    return salesAndOther2 - salesAndOther1;
}
exports.calcVarianceOfNetSalesOther = calcVarianceOfNetSalesOther;
/**
 * I, N
 * 52
 * @param objs
 * @param varianceTimeframe
 * @param month
 */
function calcVarianceOfNetDirectCosts(objs, varianceTimeframe, month) {
    var _a, _b;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const directCosts1 = (_a = getTotalDirectCosts(objs, lowerTimeframeBound, month)) !== null && _a !== void 0 ? _a : 0;
    const directCosts2 = (_b = getTotalDirectCosts(objs, upperTimeframeBound, month)) !== null && _b !== void 0 ? _b : 0;
    return directCosts2 - directCosts1;
}
exports.calcVarianceOfNetDirectCosts = calcVarianceOfNetDirectCosts;
/**
 * I, N
 * 54
 *
 * @param objs
 * @param varianceTimeframe
 * @param month
 */
function calcVarianceOfNetGrossMargin(objs, varianceTimeframe, month) {
    var _a, _b;
    if (!objs)
        return 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const grossMargin1 = (_a = getGrossMargin(objs, lowerTimeframeBound, month)) !== null && _a !== void 0 ? _a : 0;
    const grossMargin2 = (_b = getGrossMargin(objs, upperTimeframeBound, month)) !== null && _b !== void 0 ? _b : 0;
    return grossMargin2 - grossMargin1;
}
exports.calcVarianceOfNetGrossMargin = calcVarianceOfNetGrossMargin;
/**
 * J, O
 * 5 - 19
 *
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 * @param departmentId
 */
function calcVariancePercentOfNetDepartmentSales(objs, varianceTimeframe, month, type, departmentId) {
    var _a, _b;
    if (!objs)
        return 0;
    const { lowerTimeframeBound } = getVarianceBounds(varianceTimeframe);
    switch (type) {
        case "department": {
            if (!departmentId)
                break;
            const departmentToCalc = objs.filter((obj) => obj.departmentId.endsWith(departmentId));
            if (!departmentToCalc || !departmentToCalc.length)
                break;
            const department = departmentToCalc[0];
            const departmentVariance = calcVarianceOfNetDepartmentSales(objs, varianceTimeframe, month, type, departmentId);
            const departmentSales = department.departmentType === enums_1.DepartmentTypeEnum.INSTALL_DEPARTMENT
                ? getInstallDepartmentTotalSales(getMonthAndTimeframeObj(department, lowerTimeframeBound, month))
                : getServiceDepartmentTotalSales(getMonthAndTimeframeObj(department, lowerTimeframeBound, month));
            return departmentSales && departmentVariance
                ? (departmentVariance / departmentSales) * 100
                : 0;
        }
        case "total": {
            const salesVariance = (_a = calcVarianceOfNetDepartmentSales(objs, varianceTimeframe, month, type)) !== null && _a !== void 0 ? _a : 0;
            const sales = (_b = getTotalSales(objs, lowerTimeframeBound, month)) !== null && _b !== void 0 ? _b : 0;
            return sales && salesVariance ? (salesVariance / sales) * 100 : 0;
        }
    }
}
exports.calcVariancePercentOfNetDepartmentSales = calcVariancePercentOfNetDepartmentSales;
/**
 * J, O
 * 21 - 25
 *
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVariancePercentOfNetReductions(objs, varianceTimeframe, month, type) {
    var _a, _b, _c, _d;
    if (!objs)
        return 0;
    // TP-1002: Needed to do some conditional things to flip calcs
    if (type === "total" && varianceTimeframe === "budget") {
        const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
        const reductionsUpperBound = (_a = calcPercentOfNetReductions(objs, upperTimeframeBound, month, type)) !== null && _a !== void 0 ? _a : 0;
        const reductionsLowerBound = (_b = calcPercentOfNetReductions(objs, lowerTimeframeBound, month, type)) !== null && _b !== void 0 ? _b : 0;
        return reductionsUpperBound && reductionsLowerBound
            ? reductionsUpperBound - reductionsLowerBound
            : 0;
    }
    // TP-897: updating the calcs to be the same as the file
    const { lowerTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const reductionsVariance = (_c = calcVarianceOfNetReductions(objs, varianceTimeframe, month, type)) !== null && _c !== void 0 ? _c : 0;
    const reductions = (_d = getTotalReductionToSales(objs, lowerTimeframeBound, month, type)) !== null && _d !== void 0 ? _d : 0;
    return reductions && reductionsVariance
        ? (reductionsVariance / reductions) * 100
        : 0;
}
exports.calcVariancePercentOfNetReductions = calcVariancePercentOfNetReductions;
/**
 * J, O
 * 26
 *
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVariancePercentOfNetSales(objs, varianceTimeframe, month) {
    var _a, _b;
    if (!objs)
        return 0;
    const { lowerTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const reductionsVariance = (_a = calcVarianceOfNetSales(objs, varianceTimeframe, month)) !== null && _a !== void 0 ? _a : 0;
    const reductions = (_b = getNetSales(objs, lowerTimeframeBound, month)) !== null && _b !== void 0 ? _b : 0;
    return reductions && reductionsVariance
        ? (reductionsVariance / reductions) * 100
        : 0;
    // const {lowerTimeframeBound, upperTimeframeBound} = getVarianceBounds(varianceTimeframe);
    // const upperBound = calcPercentOfNetSales(objs, upperTimeframeBound, month, "total") ?? 0;
    // const lowerBound = calcPercentOfNetSales(objs, lowerTimeframeBound, month, "total") ?? 0;
    // return upperBound && lowerBound ? upperBound - lowerBound : 0;
}
exports.calcVariancePercentOfNetSales = calcVariancePercentOfNetSales;
/**
 * J, O
 * 29 -33
 *
 * Needs to be known that in the sheet they just subtract J33-G33
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVariancePercentOfNetMaterials(objs, varianceTimeframe, month, type) {
    var _a, _b, _c, _d, _e;
    if (!objs)
        return 0;
    if (type === "total") {
        const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
        // TP-897: updating the calcs to be the same as the file
        let upperBound = null;
        let lowerBound = null;
        if (varianceTimeframe === "budget") {
            upperBound =
                (_a = calcVariancePercentOfNetMaterials(objs, upperTimeframeBound, month, "total")) !== null && _a !== void 0 ? _a : 0;
            lowerBound = calcPercentOfNetMaterials(objs, upperTimeframeBound, month, "total");
        }
        else {
            upperBound =
                (_b = calcPercentOfNetMaterials(objs, upperTimeframeBound, month, "total")) !== null && _b !== void 0 ? _b : 0;
            lowerBound =
                (_c = calcPercentOfNetMaterials(objs, lowerTimeframeBound, month, "total")) !== null && _c !== void 0 ? _c : 0;
        }
        return upperBound && lowerBound ? upperBound - lowerBound : 0;
    }
    const { lowerTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const materialsVariance = (_d = calcVarianceOfNetMaterials(objs, varianceTimeframe, month, type)) !== null && _d !== void 0 ? _d : 0;
    const materials = (_e = getTotalMaterials(objs, lowerTimeframeBound, month, type)) !== null && _e !== void 0 ? _e : 0;
    return materials && materialsVariance
        ? (materialsVariance / materials) * 100
        : 0;
}
exports.calcVariancePercentOfNetMaterials = calcVariancePercentOfNetMaterials;
/**
 * J, O
 * 36 - 44
 *
 * Needs to be known that in the sheet they just subtract J44-G44
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVariancePercentOfNetLabor(objs, varianceTimeframe, month, type) {
    var _a, _b, _c, _d, _e;
    if (!objs)
        return 0;
    if (type === "total") {
        const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
        // TP-897: updating the calcs to be the same as the file
        let upperBound = null;
        let lowerBound = null;
        if (varianceTimeframe === "budget") {
            upperBound =
                (_a = calcVariancePercentOfNetLabor(objs, upperTimeframeBound, month, "total")) !== null && _a !== void 0 ? _a : 0;
            lowerBound = calcPercentOfNetLabor(objs, upperTimeframeBound, month, "total");
        }
        else {
            upperBound =
                (_b = calcPercentOfNetLabor(objs, upperTimeframeBound, month, "total")) !== null && _b !== void 0 ? _b : 0;
            lowerBound =
                (_c = calcPercentOfNetLabor(objs, lowerTimeframeBound, month, "total")) !== null && _c !== void 0 ? _c : 0;
        }
        return upperBound && lowerBound ? upperBound - lowerBound : 0;
    }
    const { lowerTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const laborVariance = (_d = calcVarianceOfNetLabor(objs, varianceTimeframe, month, type)) !== null && _d !== void 0 ? _d : 0;
    const labor = (_e = getTotalDirectLabor(objs, lowerTimeframeBound, month, type)) !== null && _e !== void 0 ? _e : 0;
    return labor && laborVariance ? (laborVariance / labor) * 100 : 0;
}
exports.calcVariancePercentOfNetLabor = calcVariancePercentOfNetLabor;
/**
 * J, O
 * 46 - 50
 *
 * Needs to be known that in the sheet they just subtract J50-G50
 * @param objs
 * @param varianceTimeframe
 * @param month
 * @param type
 */
function calcVariancePercentOfNetSalesOther(objs, varianceTimeframe, month, type) {
    var _a, _b, _c, _d, _e;
    if (!objs)
        return 0;
    if (type === "total") {
        const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
        // TP-897: updating the calcs to be the same as the file
        let upperBound = null;
        let lowerBound = null;
        if (varianceTimeframe === "budget") {
            upperBound =
                (_a = calcVariancePercentOfNetSalesOther(objs, upperTimeframeBound, month, "total")) !== null && _a !== void 0 ? _a : 0;
            lowerBound = calcPercentOfNetSalesOther(objs, upperTimeframeBound, month, "total");
        }
        else {
            upperBound =
                (_b = calcPercentOfNetSalesOther(objs, upperTimeframeBound, month, "total")) !== null && _b !== void 0 ? _b : 0;
            lowerBound =
                (_c = calcPercentOfNetSalesOther(objs, lowerTimeframeBound, month, "total")) !== null && _c !== void 0 ? _c : 0;
        }
        return upperBound && lowerBound ? upperBound - lowerBound : 0;
    }
    const { lowerTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const salesAndOtherVariance = (_d = calcVarianceOfNetSalesOther(objs, varianceTimeframe, month, type)) !== null && _d !== void 0 ? _d : 0;
    const salesAndOther = (_e = getTotalSalesAndOther(objs, lowerTimeframeBound, month, type)) !== null && _e !== void 0 ? _e : 0;
    return salesAndOther && salesAndOtherVariance
        ? (salesAndOtherVariance / salesAndOther) * 100
        : 0;
}
exports.calcVariancePercentOfNetSalesOther = calcVariancePercentOfNetSalesOther;
/**
 * J, O
 * 52
 *
 * Needs to be known that in the sheet they just subtract J52-G52
 * @param objs
 * @param varianceTimeframe
 * @param month
 */
function calcVariancePercentOfNetDirectCosts(objs, varianceTimeframe, month) {
    var _a, _b, _c;
    if (!objs)
        return 0;
    // const {lowerTimeframeBound} = getVarianceBounds(varianceTimeframe);
    // const directCostsVariance = calcVarianceOfNetDirectCosts(objs, varianceTimeframe, month) ?? 0;
    // const directCosts = getTotalDirectCosts(objs, lowerTimeframeBound, month) ?? 0;
    // return directCosts && directCostsVariance ? (directCostsVariance / directCosts) * 100 : 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    // TP-897: updating the calcs to be the same as the file
    let upperBound = null;
    let lowerBound = null;
    if (varianceTimeframe === "budget") {
        upperBound =
            (_a = calcVariancePercentOfNetDirectCosts(objs, upperTimeframeBound, month)) !== null && _a !== void 0 ? _a : 0;
        lowerBound = calcPercentOfNetDirectCosts(objs, upperTimeframeBound, month);
    }
    else {
        upperBound =
            (_b = calcPercentOfNetDirectCosts(objs, upperTimeframeBound, month)) !== null && _b !== void 0 ? _b : 0;
        lowerBound =
            (_c = calcPercentOfNetDirectCosts(objs, lowerTimeframeBound, month)) !== null && _c !== void 0 ? _c : 0;
    }
    return upperBound && lowerBound ? upperBound - lowerBound : 0;
}
exports.calcVariancePercentOfNetDirectCosts = calcVariancePercentOfNetDirectCosts;
/**
 * J, O
 * 54
 *
 * Needs to be known that in the sheet they just subtract J54-G54
 * @param objs
 * @param varianceTimeframe
 * @param month
 */
function calcVariancePercentOfNetGrossMargin(objs, varianceTimeframe, month) {
    var _a, _b;
    if (!objs)
        return 0;
    // const {lowerTimeframeBound} = getVarianceBounds(varianceTimeframe);
    // const grossMarginVariance = calcVarianceOfNetGrossMargin(objs, varianceTimeframe, month) ?? 0;
    // const grossMargin = getGrossMargin(objs, lowerTimeframeBound, month) ?? 0;
    // return grossMargin && grossMarginVariance ? (grossMarginVariance / grossMargin) * 100 : 0;
    const { lowerTimeframeBound, upperTimeframeBound } = getVarianceBounds(varianceTimeframe);
    const upperBound = (_a = calcPercentOfNetGrossMargin(objs, upperTimeframeBound, month)) !== null && _a !== void 0 ? _a : 0;
    const lowerBound = (_b = calcPercentOfNetGrossMargin(objs, lowerTimeframeBound, month)) !== null && _b !== void 0 ? _b : 0;
    // TP-1003: IDK why but here they invert the calc
    if (varianceTimeframe === "currentYear") {
        return upperBound && lowerBound ? lowerBound - upperBound : 0;
    }
    return upperBound && lowerBound ? upperBound - lowerBound : 0;
}
exports.calcVariancePercentOfNetGrossMargin = calcVariancePercentOfNetGrossMargin;
function convertCompanyVarianceOverheads(ovhId) {
    let companyVarianceData = {};
    utils_1.shortMonthsArrayDynamoDB.forEach((month) => {
        companyVarianceData = {
            ...companyVarianceData,
            [month]: {
                id: ovhId,
                budgetAmount: null,
                budgetPercent: null,
                budgetVSActualVarianceAmount: null,
                budgetVSActualVariancePercent: null,
                currentVSPreviousVarianceAmount: null,
                currentVSPreviousVariancePercent: null,
                currentYearAmount: null,
                currentYearPercent: null,
                previousYearAmount: null,
                previousYearPercent: null,
            },
        };
    });
    return companyVarianceData;
}
exports.convertCompanyVarianceOverheads = convertCompanyVarianceOverheads;
