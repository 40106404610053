import React, { ReactElement, useEffect, useState } from "react";
import CardHeader from "../../../utils/components/tailwind/CardHeader";
import CardBody from "../../../utils/components/tailwind/CardBody";
import CardWrapper from "../../../utils/components/tailwind/CardWrapper";
import Container from "../../../utils/components/tailwind/Container";
import { UseQueryType } from "../../../types/ErrorMessageType";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import InformationIcon from "../../../utils/components/icons/InformationIcon";
import ButtonToolTip from "../../../utils/components/tailwind/ButtonToolTip";
import { DateTime } from "luxon";
import { useAuth0 } from "@auth0/auth0-react";
import { CRMUser, UserIdOrDefault } from "../../../utils/helpers/UserHelper";
import LoadingCard from "../../../utils/components/tailwind/LoadingCard";
import { empty } from "@nexstar-network/shared-utils";
import parse from "html-react-parser";

import { enqueueSnackbar } from "notistack";
import { getUserAlerts, markAsRead } from "../utils/alertsRequests";
import { handleMarkSingleAsRead, orderedAlerts } from "../utils/alertsHelpers";
import AlertsToggle from "./AlertsToggle";

export default function AlertCenter(): ReactElement {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const userId = UserIdOrDefault(user as CRMUser);
  const [displayOnlyRead, setDisplayOnlyRead] = useState<boolean>(false);
  const [numberOfUnread, setNumberOfUnread] = useState<number>(0);
  const [alerts, setAlerts] = useState<Array<any>>([]);
  const {
    data: alertData,
    isLoading: alertLoading,
    refetch,
    error: alertError,
  }: UseQueryType<any> = useQuery({
    queryKey: ["getNotifications"],

    queryFn: () => getUserAlerts(userId, getAccessTokenSilently),
  });
  const markAlertsAsRead = useMutation({
    mutationFn: (alertID: number) =>
      markAsRead(userId, alertID, getAccessTokenSilently),
  });

  useEffect(() => {
    if (alertData && alertData.alerts && !alertLoading) {
      let numberOfUnreadAlerts = 0;
      for (let i = 0; i < alertData.length; i++) {
        if (empty(alertData.alerts[i].pivot.readDate)) {
          numberOfUnreadAlerts++;
        }
      }
      setNumberOfUnread(numberOfUnreadAlerts);
      setAlerts(alertData.alerts);
    }
  }, [alertData]);

  const DisplayAlertsForDashboard = (alertsToDisplay: any[]) => {
    if (alerts && alerts.length > 0 && !alertLoading) {
      const displayItems: any[] = [];
      alertsToDisplay.map((alert) => {
        if (displayOnlyRead) {
          if (empty(alert.pivot.readDate)) {
            displayItems.push(alert);
          }
        } else {
          displayItems.push(alert);
        }
      });
      if (displayItems.length) {
        return displayItems.map((alert) => (
          <>
            <div
              key={alert.alertID}
              className="pt-4 pb-3 flex grow border-t-2 border-gray-100"
            >
              <button
                className="hover:bg-NexstarSoftGray text-gray-900 h-20 w-full items-center px-5 py-2 text-sm h-16 border-0"
                onClick={(ev) =>
                  handleMarkSingleAsRead(
                    ev,
                    alert.alertID,
                    numberOfUnread,
                    setNumberOfUnread,
                    markAlertsAsRead,
                    queryClient,
                    refetch,
                    false,
                    enqueueSnackbar,
                  )
                }
              >
                <div className="flex flex-col-3 justify-start">
                  <div className="pr-3 mt-[-2]">
                    <InformationIcon color="NexstarSkyBlue" size="medium" />
                  </div>
                  <div
                    className={`flex-row flex-wrap text-[17px] font-[450] mt-1 ${
                      !empty(alert.pivot.readDate)
                        ? "text-NexstarGray-dark"
                        : "text-black"
                    } w-full text-left justify-between`}
                  >
                    {parse(alert.body)}
                  </div>
                  {empty(alert.pivot.readDate) ? (
                    <ButtonToolTip tooltip="Mark as read">
                      <button
                        className="h-[24px] w-[24px] hover:bg-NexstarGray-dark hover:rounded-full"
                        onClick={(ev) =>
                          handleMarkSingleAsRead(
                            ev,
                            alert.alertID,
                            numberOfUnread,
                            setNumberOfUnread,
                            markAlertsAsRead,
                            queryClient,
                            refetch,
                            true,
                            enqueueSnackbar,
                          )
                        }
                      >
                        <div className="bg-NexstarBlue-dark rounded-full w-[8px] h-[8px] font-bold text-white text-xs ml-2" />
                      </button>
                    </ButtonToolTip>
                  ) : (
                    <button className="h-[24px] w-[24px]">
                      <div className="bg-NexstarGray-dark rounded-full w-[8px] h-[8px] font-bold text-white text-xs ml-2" />
                    </button>
                  )}
                </div>
                <div className="flex flex-col-1 justify-start">
                  <div
                    className={`flex-row w-full text-[12px] text-left ml-10 mt-1 ${
                      !empty(alert.pivot.readDate)
                        ? "text-NexstarGray-dark"
                        : "text-black"
                    }`}
                  >
                    Updated At:{" "}
                    <span>
                      {DateTime.fromISO(alert.created_at)
                        .toLocal()
                        .toFormat("MM-dd-yyyy")}
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </>
        ));
      }
    }
    return (
      <div id="landing_page_no_notifications">
        <div className="h-[125px] pt-10 text-xl text-black text-center">
          No Alerts
        </div>
      </div>
    );
  };

  if (alertError) throw new Error(alertError.message);
  if (alertLoading) return <LoadingCard />;
  return (
    <Container
      id="Notification_Main"
      data-testid="Notification_Main"
      padding="p-2"
      className="mb-7"
    >
      <CardWrapper data-testid="Notification_Center" id="Notification_Center">
        <div>
          <CardHeader
            title="Alert Center"
            bgColor="bg-NexstarGray"
            headingTextColor="text-NexstarBlue"
            border="border-b border-NexstarGray-dark"
            dataTestId="notification_center_Header"
            toolTipColor="gray"
          >
            <div className="justify-end text-black mr-3">
              <AlertsToggle
                onChange={() => setDisplayOnlyRead(!displayOnlyRead)}
                checked={displayOnlyRead}
                name="Only Show Unread"
                label="Only Show Unread"
              />
            </div>
          </CardHeader>
          <CardBody>
            {DisplayAlertsForDashboard(orderedAlerts(alerts))}
          </CardBody>
        </div>
      </CardWrapper>
    </Container>
  );
}
