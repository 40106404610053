"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.grossProfitPercentage = exports.totalDirectCostPercentage = exports.totalDirectCostsValueCalc = exports.totalIncomePercentage = exports.financeFeePercentage = exports.refundPercentage = exports.subcontractorPercentage = exports.grossIncomePercentage = exports.sppRevenuePercentage = exports.demandAndSystemCheckPercentage = void 0;
// TODO this is same as grossIncome, combine them???
const demandAndSystemCheckPercentage = (workshopData, month, key) => {
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    return (workshopData.directCosts[month][key] /
        workshopData.directCosts[month].totalIncome);
};
exports.demandAndSystemCheckPercentage = demandAndSystemCheckPercentage;
const sppRevenuePercentage = (workshopData, month) => {
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    if (!workshopData.directCosts[month].sppRevenue ||
        !workshopData.directCosts[month].totalIncome)
        return 0;
    return (workshopData.directCosts[month].sppRevenue /
        workshopData.directCosts[month].totalIncome);
};
exports.sppRevenuePercentage = sppRevenuePercentage;
const grossIncomePercentage = (workshopData, month) => {
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    if (!workshopData.directCosts[month].totalIncome)
        return 0;
    return (workshopData.directCosts[month].grossIncome /
        workshopData.directCosts[month].totalIncome);
};
exports.grossIncomePercentage = grossIncomePercentage;
const subcontractorPercentage = (workshopData, month) => {
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    if (!workshopData.directCosts[month].subcontractorAmount ||
        !workshopData.directCosts[month].totalIncome)
        return 0;
    return (workshopData.directCosts[month].subcontractorAmount /
        workshopData.directCosts[month].totalIncome);
};
exports.subcontractorPercentage = subcontractorPercentage;
const refundPercentage = (workshopData, month) => {
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    if (!workshopData.directCosts[month].refundAmount ||
        !workshopData.directCosts[month].totalIncome)
        return 0;
    return (workshopData.directCosts[month].refundAmount /
        workshopData.directCosts[month].totalIncome);
};
exports.refundPercentage = refundPercentage;
const financeFeePercentage = (workshopData, month) => {
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    if (!workshopData.directCosts[month].financeFeeAmount ||
        !workshopData.directCosts[month].totalIncome)
        return 0;
    return (workshopData.directCosts[month].financeFeeAmount /
        workshopData.directCosts[month].totalIncome);
};
exports.financeFeePercentage = financeFeePercentage;
const totalIncomePercentage = (workshopData, month) => {
    // do we need this???? Always 100%
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    if (!workshopData.directCosts[month].totalIncome)
        return 0;
    return (workshopData.directCosts[month].totalIncome /
        workshopData.directCosts[month].totalIncome);
};
exports.totalIncomePercentage = totalIncomePercentage;
// These are last year percentages on Direct Costs. Leaving here in case need to use or reference
// export const lastYearLaborPercentage = (preWork: PreworkDataTypeFE, month: MonthKeyType) => {
//   return preWork.monthlyData[month].laborPercentage;
// };
//
// export const lastYearMaterialsPercentage = (preWork: PreworkDataTypeFE, month: MonthKeyType) => {
//   return preWork.monthlyData[month].materialPercentage;
// };
//
// export const lastYearSalesExpensePercentage = (preWork: PreworkDataTypeFE, month: MonthKeyType) => {
//   return preWork.monthlyData[month].salesExpensePercentage;
// };
//
// export const lastYearOtherDirectPercentage = (preWork: PreworkDataTypeFE, month: MonthKeyType) => {
//   return preWork.monthlyData[month].otherPercentage;
// };
// TP-886 MH: need this because totalDirectCosts is not being saved by department
const totalDirectCostsValueCalc = (workshopData, month) => {
    var _a, _b, _c, _d;
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts) ||
        !workshopData.directCosts[month])
        return 0;
    const monthlyDC = workshopData.directCosts[month];
    const budgetedLaborAmount = (_a = monthlyDC.budgetedLaborAmount) !== null && _a !== void 0 ? _a : 0;
    const budgetedMaterialAmount = (_b = monthlyDC.budgetedMaterialAmount) !== null && _b !== void 0 ? _b : 0;
    const budgetedSalesExpAmount = (_c = monthlyDC.budgetedSalesExpAmount) !== null && _c !== void 0 ? _c : 0;
    const budgetedOtherDirectAmount = (_d = monthlyDC.budgetedOtherDirectAmount) !== null && _d !== void 0 ? _d : 0;
    return (budgetedLaborAmount +
        budgetedMaterialAmount +
        budgetedSalesExpAmount +
        budgetedOtherDirectAmount);
};
exports.totalDirectCostsValueCalc = totalDirectCostsValueCalc;
const totalDirectCostPercentage = (workshopData, month) => {
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    if (!workshopData.directCosts[month].totalIncome)
        return 0;
    const totalDirectCostsValueCalc1 = (0, exports.totalDirectCostsValueCalc)(workshopData, month);
    return (totalDirectCostsValueCalc1 / workshopData.directCosts[month].totalIncome);
};
exports.totalDirectCostPercentage = totalDirectCostPercentage;
const grossProfitPercentage = (workshopData, month) => {
    if (!workshopData ||
        !("directCosts" in workshopData) ||
        !(month in workshopData.directCosts))
        return 0;
    if (!workshopData.directCosts[month].totalIncome ||
        !workshopData.directCosts[month].grossProfit)
        return 0;
    return (workshopData.directCosts[month].grossProfit /
        workshopData.directCosts[month].totalIncome);
};
exports.grossProfitPercentage = grossProfitPercentage;
