import React from "react";

type ContainerProps = {
  alignItems?: string;
  children: React.ReactNode;
  bgColor?: string;
  id?: string;
  justifyContent?: string;
  maxWidth?: string;
  padding?: string;
  className?: string;
};

export default function Container({
  alignItems = "items-start",
  children,
  bgColor = "bg-transparent",
  id,
  justifyContent = "justify-between",
  maxWidth = "max-w-full",
  padding = "p-0",
  className,
}: ContainerProps): JSX.Element {
  return (
    <div
      id={id}
      className={`flex flex-row flex-wrap w-full ${maxWidth} ${justifyContent} ${alignItems} gap-4 mx-auto ${padding} ${bgColor} ${
        className ?? ""
      }`}
    >
      {children}
    </div>
  );
}
